import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { TrackerContext } from '_app';
import { useRouter } from 'next/router';
import ProgressiveImage from '@Components/ProgressiveImage';
import { SessionStorage } from '@Utils/storage';
import { moveElementToStart } from '@Utils/format';
import { isNonEmptyString, isNonEmptyArray, isServer } from '@Utils/checks';
import { getSizeColorVariantEventData, getTrackingEventObject } from '@Utils/common';
import { PRODUCT_COLOR_SELECTOR } from '@Constants/events';
import { SHOW_TRANSPARENT_GLOBAL_LOADER } from '@Constants/storageKeys';
import { useGetBottomSheetProductDetailMutation } from '@Containers/Product-Details/apiSlice';

const getSelectedColor = (selectedProductId: string, variations: Array<any>) =>
  isNonEmptyArray(variations) && variations.find((el) => el?.id === selectedProductId)?.color;

const ProductColorVariation = ({ variations, product_id, isSizeSelectorSheet, isBottomSheet }) => {
  const [newVariation, setNewVariation] = useState(variations);
  const router = useRouter(),
    selectedColor = useMemo(() => getSelectedColor(product_id, variations) ?? '', [product_id, variations]),
    track = useContext(TrackerContext),
    variantsContainerRef = useRef(null);
    const [getRecommendationProductDetails, { isLoading: productDetailsLoading }] = useGetBottomSheetProductDetailMutation();

    // To ensure current variant is the first variant on server side rendered color variants

    useEffect(() => {
      if (isServer()) {
        const newVariationArr = moveElementToStart(variations, product_id);
        setNewVariation(newVariationArr);
      }
    }, []);

    useEffect(()=>{
        const newVariationArr =  moveElementToStart(variations, product_id);
        setNewVariation(newVariationArr);
        
        if(variantsContainerRef.current)
          variantsContainerRef.current.scrollTo(0, 0);

    }, [product_id, variations]);

  const changeProduct = useCallback(
    (slug: string, color = '') => {
      track(
        getTrackingEventObject(
          0,
          getSizeColorVariantEventData({ isSizeSelectorBottomSheet: isSizeSelectorSheet }).feedType,
          PRODUCT_COLOR_SELECTOR.COLOR_SELECT_ACTION_TYPE,
          product_id,
          router.pathname,
          { meta: { selected_color: color } }
        )
      );
      // Enables transparent loader on route change
      SessionStorage.setItem(SHOW_TRANSPARENT_GLOBAL_LOADER, JSON.stringify(true));

      router.replace({
        pathname: `/product/${slug}`
      });
    },
    [track, isSizeSelectorSheet, product_id]
  );

  const bottomSheetChangeProduct = (id) => {
    getRecommendationProductDetails({product_ids: [id]})
  }

  const handleChange  = (slug, color, id) => {
    isBottomSheet ? bottomSheetChangeProduct(slug, color, id) : changeProduct(slug, color)
  }

  return (
    <>
      {isNonEmptyString(selectedColor) && (
        <div className={`${isBottomSheet ? 'text-left' : '' } p-[16px] pt-[24px] font-[600] text-[14px] text-nm_font_secondary`}>{`${
          isSizeSelectorSheet ? 'Selected ' : ''
        }Color: ${selectedColor}`}</div>
      )}
      <div className='hideScrollBar flex overflow-x-auto z-0 w-full snap-x snap-mandatory box-border' ref={variantsContainerRef}>
        {newVariation?.map((el, idx) => {
          const { id, slug, thumbnail, color } = el ?? {};

          return (
            <div
              key={`product-color-variation-${id}-${idx}`}
              className='snap-end h-[56px] p-[4px] mr-[16px] mb-[4px] rounded-[9px]'
              style={{
                marginLeft: idx === 0 ? '16px' : '0px',
                border: product_id === id ? `2px solid #31B375` : '2px solid #F2F2F2'
              }}
              onClick={() => handleChange(slug, color, id)}
            >
              <ProgressiveImage
                src={thumbnail}
                sizes='56px'
                className='h-full w-[56px] rounded-[6px] box-border object-cover cursor-pointer'
                alt='Product Color Variation Image'
              />
            </div>
          );
        })}
      </div>
    </>
  );
};

export default ProductColorVariation;
