import { Box, Button } from '@mui/material';
import useTranslation from '@Utils/useTranslation';

import Amount from '@Components/Amount';

import styles from './styles';
import { OrderDetailsFooterProps } from './types';

const OrderDetailsFooter = ({ amountPayable, handleButtonPress, handleViewDetailsPress }: OrderDetailsFooterProps) => {
  const { t: translate } = useTranslation();

  const handleCheckoutPress = () => {
    handleButtonPress();
  };

  return (
    <Box px={2} py={3} sx={styles.container}>
      <Box>
        <Amount
          style={styles.amountPayable}
          amount={amountPayable}
          toFixed
          currencyStyle={{ width: '12px', height: '12px' }}
          strokeWidth={2}
        />
        <Box mt={0.5} onClick={handleViewDetailsPress} sx={styles.viewDetails}>
          {translate('view_details')}
        </Box>
      </Box>
      <Button onClick={handleCheckoutPress} sx={styles.proceedToPayment} variant='text' disableRipple>
        {translate('proceed_to_checkout')}
      </Button>
    </Box>
  );
};

export default OrderDetailsFooter;
