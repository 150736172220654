import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface Address {
  selectedAddressIndex: number;
}

const initialState: Address = {
  selectedAddressIndex: 0
};

export const addressSlice = createSlice({
  name: 'address',
  initialState,
  reducers: {
    setSelectedAddressIndex: (state, action: PayloadAction<number>) => {
      state.selectedAddressIndex = action.payload;
    },
    resetAddressSlice: () => initialState
  }
});

export const { setSelectedAddressIndex, resetAddressSlice } = addressSlice.actions;
export default addressSlice.reducer;
