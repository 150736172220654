import { FormatPriceProps } from './types';

const FormatPrice = ({ style, value, isTwoFixed = false, symbol = '' }: FormatPriceProps) => {
  const updatedValue = value > -1 ? value : value * -1;
  const price = isTwoFixed ? updatedValue.toFixed(2) : updatedValue;

  return <span style={style}>{`${symbol}₹${price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}</span>;
};

export default FormatPrice;
