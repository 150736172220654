import { isServer } from '@Utils/checks';
import { useCallback, useEffect } from 'react';

interface IUseModalLocationHashParams {
  router: any;
  pathname: any;
  hash: string;
  openModal?: Function;
  closeModal?: Function;
}

const useModalLocationHash = ({ router, pathname, hash, openModal, closeModal }: IUseModalLocationHashParams) => {
  if (isServer()) return {};

  const handleLocationHashOnOpen = useCallback(
    (replace?: boolean ) => {
      if (!window.location.hash?.includes(hash))
        if (replace) router.replace({hash: hash}, '', { shallow: true });
        else router.push({hash: hash}, '', { shallow: true });
    },
    [window.location.hash]
  );

  const handleLocationHashOnClose = useCallback(() => {
    if (window.location.hash?.includes(hash)) router.back();
  }, [window.location.hash, pathname]);

  useEffect(() => {
    if (window.location.hash?.includes(hash)) openModal?.(true);
    else closeModal?.(false);
  }, [pathname, window.location.hash]);

  return { handleLocationHashOnOpen, handleLocationHashOnClose };
};

export default useModalLocationHash;
