const styles = {
  fullScreenImage: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'center',
    paddingTop: '40px',
    zIndex: 1000,
  },
  closeButton: {
    position: 'absolute',
    top: '0px',
    right: '10px',
    background: 'transparent',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    zIndex: 1001
  },
  image: {
    height: '70vh',
    maxWidth: '450px',
    objectFit: 'cover',
    margin: 'auto 0'
  },
  carouselContainer: {
    maxHeight: '80vh',
  }
};

export default styles;
