import { setBuyNowCart } from '@Components/RecommendedProducts/recommendedProductsSlice';


export const addToBuyNowCart = (product, selectedSize, buy_now_cart_items, dispatch, variationID) => {

    const localBuyNowCart = Array.isArray(buy_now_cart_items) && buy_now_cart_items.length > 0 ? buy_now_cart_items : [];
  
    const newCartItem = {
      product_id: product?.product_id,
      variation_id: variationID,
      variation: { attribute_pa_size: selectedSize },
      quantity: 1,
      thumb_url: product?.image_url,
      title: product?.name
    };
  
    dispatch(setBuyNowCart([...localBuyNowCart, newCartItem]));
    return addToBuyNowCart;
  };
  