import { memo } from "react";
import ProductExpSheetItem from "../RatingReviewSheet/components/ProductExpSheetItem";
const ProductExpSheet = ({data}) => {

    return (
        <div className="mb-[50px]">
        <div className="text-[20px] text-left font-[700] leading-[28px] py-[24px] px-[16px]">What our customer say about the product</div>
        <hr className='opacity-[0.3] mt-0' />

       {data?.map(feature => {
            return <ProductExpSheetItem feature={feature}/>
    })}
        </div>
    )
}

export default memo(ProductExpSheet); 
