import { createTheme } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import { primaryFont } from '@Utils/fonts';

declare module '@mui/material/styles' {
  interface Theme {
    status: {
      danger: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
  }

  interface BreakpointOverrides {
    xs: false; // removes the `xs` breakpoint
    sm: false;
    md: false;
    lg: false;
    xl: false;
    mobile: true; // adds the `mobile` breakpoint
    tablet: true;
    laptop: true;
    desktop: true;
  }
}

const theme = createTheme({
  status: {
    danger: orange[500]
  },
  typography: {
    fontFamily: primaryFont.style.fontFamily, // Set the font family globally
  },
  palette: {
    primary: {
      main: '#39B277'
    },
    secondary: {
      main: '#1F1F1F'
    }
  },
  breakpoints: {
    values: {
      mobile: 0,
      tablet: 640,
      laptop: 1024,
      desktop: 1200
    }
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        asterisk: {
          color: (theme) => theme.palette.error.main,
          fontSize: '1.2em', // adjust size as needed
          marginLeft: 0.5, // adjust spacing as needed
        },
      },
    },
  },
});

export default theme;
