import { useRouter } from 'next/router';
import { useDebouncedCallback } from 'use-debounce';
import { CSSProperties, useCallback, useContext, useEffect, useState } from 'react';
import Loader from '@Components/Loader';
import ProductCard from '@Components/ProductCard';
import ScrollToTopButton from '@Components/ScrollToTopButton';
import { fetchSimilarProducts } from '@Containers/Product-Details/api';
import { getPriceRange, getTrackingEventObject } from '@Utils/common';
import { ProductCartType } from '@Constants/common';
import styles from './styles';
import { TrackerContext } from '_app';
import { GLOBAL_ACTION_TYPES } from '@Constants/events';

const SimilarProducts = ({ productName, id, similarProductsData = {}, bannerHeight, productInfoVisible }: { productName: string; id: string; similarProductsData?: any; bannerHeight: string; productInfoVisible?: boolean }) => {
  const track = useContext(TrackerContext),
    [similarProducts, setSimilarProducts] = useState(similarProductsData),
    [similarProductsLoaded, setSimilarProductsLoaded] = useState(false),
    [pageNum, setPageNum] = useState(1),
    [loading, setLoading] = useState(false),
    router = useRouter();

  const debounceScrollPagination = useDebouncedCallback(
    () => {
      setPageNum((prevState) => prevState + 1);
    },
    500,
    { leading: true }
  );

  const handleSimilarProductsScroll = useCallback(() => {
    if (window.innerHeight + window.scrollY >= document.body.scrollHeight * 0.85 && similarProducts.has_next) {
      debounceScrollPagination();
    }
  }, [similarProducts]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetchSimilarProducts({
          product_id: id,
          page_num: pageNum
        });

        if (response?.successful) {
          setSimilarProducts((prevState) => {
            const nextSimilarProducts = response?.similar_products;

            return { ...(response ?? {}), similar_products: [...(prevState?.similar_products ?? []), ...(nextSimilarProducts ?? [])] };
          });
        } else {
          console.error('Failed to fetch similar products');
        }

        setLoading(false);
      } catch {
        setLoading(false);
        console.error('Failed to fetch similar products');
      }
    };

    if (similarProductsLoaded && similarProducts.has_next) fetchData();
    else if (!similarProductsLoaded) {
      setSimilarProductsLoaded(true);
    }
  }, [pageNum]);

  useEffect(() => {
    if(similarProductsLoaded){
      setSimilarProducts(similarProductsData);
      setSimilarProductsLoaded(false);
      setPageNum(1);
    }
  }, [similarProductsData]);

  return (
    <>
      <Loader isLoading={loading} />
      <ScrollToTopButton customStyles={{ top: `calc(${bannerHeight ?? '0px'} + 80px)`}} hideButton={productInfoVisible} onScrollCallback={handleSimilarProductsScroll}/>
      <div style={styles.similarProductsTitle}>Similar To</div>
      <div style={styles.productName}>{productName}</div>
      <div style={styles.similarProductCardsListing as CSSProperties}>
        {similarProducts?.similar_products?.map((product: any, key: number) => {
          const { minSalePrice, regularPrice, discount, maxSalePrice, shouldShowPriceRange } = getPriceRange(product?.variations);

          return (
            <ProductCard
              product={product}
              showSizeCards={false}
              productId={product?.product_id}
              slug={product?.slug}
              key={key}
              productImage={product?.image_url}
              discount={discount}
              oldPrice={regularPrice}
              minSalePrice={minSalePrice}
              maxSalePrice={maxSalePrice}
              shouldShowPriceRange={shouldShowPriceRange}
              sizeCardsData={product?.variations}
              showWishList
              productImageStyle={{
                width: '100%',
                objectFit: 'cover',
                aspectRatio: 2 / 3
              }}
              type={ProductCartType.SIMILAR_PRODUCTS}
              name={product?.name}
              category={product?.category}
              productInfo={product?.product_info}
              styleId={product?.style_id}
              rating={product?.rating}
              productImagesSet={product?.image_gallery_urls || []}
              isTitleLink
              fastDeliveryText={product?.fast_delivery_text || ''}
              feedPosition={key}
              itemClick={() => {
                track(getTrackingEventObject(key, 'similar-products', GLOBAL_ACTION_TYPES.CLICK, product?.product_id, router.asPath));
              }}
              onIntersection={() => {
                track(getTrackingEventObject(key, 'similar-products', GLOBAL_ACTION_TYPES.SCROLL, product?.product_id, router.asPath, { tagData: product?.display_tag ?? {} }));
              }}
              isLazyLoading
              tag={product?.display_tag}
            />
          );
        })}
      </div>
    </>
  );
};

export default SimilarProducts;
