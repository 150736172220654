const styles = {
  tooltip: {
    position: 'relative'
  },
  tooltipContent: {
    position: 'absolute',
    backgroundColor: '#D6F2E0',
    color: 'black',
    borderRadius: '4px',
    padding: '4px 8px',
    fontSize: '10px',
    whiteSpace: 'nowrap',
    zIndex: 1,
    left: '0%',
    top: '1px'
  },
  tooltipArrow: {
    position: 'absolute',
    width: 0,
    height: 0,
    borderLeft: '4px solid transparent',
    borderRight: '6px solid transparent',
    borderBottom: '6px solid #D6F2E0',
    transform: 'translateX(-50%)',
    top: '-5px',
    left: '30px'
  }
};

export default styles;
