import { colors } from '@Constants/common';

const styles = {
  loginRegisterText: {
    color: colors.PRIMARY,
    fontWeight: 500
  },
  countryIconWrapper: {
    position: 'absolute',
    left: '12px',
    bottom: '8px'
  },
  inputLabel: {
    left: '40px',
    top: '27px',
    color: colors.DIM_GRAY
  },
  phoneNumberInput: {
    width: '100%',
    marginTop: '24px',
    '& input': {
      paddingLeft: '50px'
    }
  },
  sendOTPButton: {
    height: 48,
    marginTop: '56px',
    fontWeight: 600,
    textTransform: 'none',
    width: '100%',
    color: colors.WHITE,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: colors.PRIMARY,
      boxShadow: 'none'
    }
  },
  title: {
    fontWeight: 600,
    fontSize: '22px',
    marginTop: '40px'
  },
  subTitle: {
    fontWeight: 400,
    fontSize: '14px',
    marginTop: '16px'
  },
  resendWrapper: {
    fontWeight: 500,
    color: colors.DIM_GRAY,
    fontSize: '14px'
  },
  otpInput: {
    display: 'flex',
    gap: '12px',
    marginTop: 24,
    padding: '0 14px'
  },
  resendButton: {
    marginTop: '22px',
    textTransform: 'none',
    paddingLeft: 0,
    fontWeight: 500,
    color: colors.PRIMARY_DARK
  },
  resendLabel: {
    color: colors.PRIMARY
  },
  backIcon: {
    width: '24px',
    height: '24px',
    cursor: 'pointer',
    marginBottom: '48px'
  },
  loginBannerContainer: {
    marginBottom: '20px'
  },
  imageStyle: {
    width: '100%',
    borderRadius: '5px'
  },
  linkStyle: {},
  disabledLink: {
    pointerEvents: 'none'
  }
};

export default styles;
