import React, { memo, useEffect, useState } from 'react';
import { randomSort } from '@Containers/Product-Details';
import styles from '../../styles';
import styleModule from '../../style.module.css';

const TickerModule = ({ product, id }) => {
  const [tickerData, setTickerData] = useState(() => randomSort(product?.ticker_data)),
    [selectedTickerIndex, setSelectedTickerIndex] = useState(0);

  useEffect(() => {
    let interval;
    const randomSorted = randomSort(product?.ticker_data);
    setTickerData(randomSorted);
    if (product?.ticker_data) {
      interval = setInterval(() => {
        setSelectedTickerIndex((s) => (s + 1) % (randomSorted.length ?? 1));
      }, 10000);
    }
    return () => {
      clearInterval(interval);
      setSelectedTickerIndex(0);
    };
  }, [id]);

  return (
    !!tickerData &&
    tickerData?.length > 0 && (
      <div key={selectedTickerIndex} className={styleModule['ticker-details']} style={styles.tickerPill}>
        {tickerData[selectedTickerIndex]?.user && <span style={styles.userIcon}>{tickerData[selectedTickerIndex].user}</span>}
        <span style={styles.tickerText}>
          {tickerData[selectedTickerIndex].content.split(' ').map((str) => {
            if (str === 'x' || str === 'y') {
              return <span style={{ fontWeight: 700 }}>{tickerData[selectedTickerIndex]?.[str]} </span>;
            }
            return str + ' ';
          })}
        </span>
      </div>
    )
  );
};

export default memo(TickerModule);
