import { colors } from '@Constants/common';

const styles = {
  deliveryDetails: {
    padding: '12px 22px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    cursor: 'pointer'
  },
  deliveryDetailsContent: {
    display: 'flex',
    flexDirection: 'column',
    fontWeight: 500,
    fontSize: '12px',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'space-between',
    minWidth: '30%',
    padding: '0px 5px'
  },
  text: {
    fontWeight: 700,
    fontSize: '12px',
    color: colors.BLACK,
    paddingTop: '8px',
    textAlign: 'center',
  },
  descriptionText: {
    fontWeight: 400,
    fontSize: '9px',
    color: colors.BLACK,
    textAlign: 'center',
  }
};

export default styles;
