import { MAX_CART_QUANTITY } from '@Constants/common';
import { CartItem, CartTotals, UpdateCartBody } from '@Containers/Cart/types';
import { CartProduct } from '@Containers/GuestCart/types';
import { useAppSelector } from '@Store/store';

export default function useCartUtils() {
  const cartItems: CartProduct[] = useAppSelector((state) => state.guestCart.products);
  const cartQuantities = useAppSelector((state) => state.cart.cart_quantities);

  const checkIfAlreadyInCart = (productId: number, variationId: number) =>
    cartItems.findIndex((item) => item.product_id === Number(productId) && item.variation_id === variationId);

  const getCartOfferPayload = (cart: Record<string, CartItem>, couponCode: string, cart_totals?: CartTotals) => ({
    cart,
    cart_totals: cart_totals || {},
    applied_coupons: [couponCode]
  });

  const getUpdatedQuantity = (id: string, quantity = 1, shouldAdd = true) => {
    if (cartQuantities[id]) {
      if (cartQuantities[id] >= MAX_CART_QUANTITY) return -1;

      return shouldAdd ? cartQuantities[id] + quantity : cartQuantities[id] - quantity;
    }

    return 1;
  };

  const getUpdateCartPayload = (
    id: number,
    variation_id: number,
    size: string,
    quantity: number
  ): Array<UpdateCartBody> => [
    {
      product_id: id,
      variation_id,
      variation: { attribute_pa_size: size },
      quantity
    }
  ];

  return {
    checkIfAlreadyInCart,
    getCartOfferPayload,
    getUpdateCartPayload,
    getUpdatedQuantity
  };
}
