export const TIME = {
  TWENTY_FOUR_HOURS_IN_MS: 86400000
};

export const TIME_IN_SECS = {
  '24Hrs': 3600 * 24,
  '6Hrs': 3600 * 6,
  '1Hr': 3600,
  '1Min': 60
};

export const timeUnitMap = {
  days: 4,
  hours: 3,
  mins: 2,
  secs: 1
};
