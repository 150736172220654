export const getFilledArray = (rating: any) => {
    return Array.from(Array(Number(rating)).keys());
  };

 export const getUnFilledArray = (rating: any) => {
    return Array.from(Array(5 - rating).keys());
  };

export const getParsedCarousalData = (data: any[]) =>
    data?.map((item: any) => ({
      url: item,
      type: 'image',
      
    })
  );