import useTranslation from '@Utils/useTranslation';
import styles from './styles';

const SimpleInstallment = ({ installmentPrice, isVisible }: { installmentPrice: number; isVisible: boolean }) => {
  const { t: translate } = useTranslation();

  return isVisible ? (
    <div style={styles.simpleInstallments}>{`${translate('installments')} ${installmentPrice} ${translate(
      'with'
    )}`}</div>
  ) : null;
};

export default SimpleInstallment;
