import { memo, useEffect, useState, useContext } from 'react';
import useTranslation from '@Utils/useTranslation';
import { useRouter } from 'next/router';
import { formatWord } from '@Utils/format';
import { ProductInfoProps } from './types';
import { RootState ,useAppSelector } from '@Store/store';
import styles from './styles';
import { isNonEmptyString } from '@Utils/checks';
import { TrackerContext } from '_app';
import { PDP_PAGE_EVENTS, RECOMMENDED_PRODUCT_EVENTS } from '@Constants/events';
import { getTrackingEventObject } from '@Utils/common';

const ProductInfo = ({ productInfo, marketerInfo }: ProductInfoProps) => {
  const { t: translate } = useTranslation();
  const router = useRouter(),
        track = useContext(TrackerContext);
  const { countryOfOrigin, marketerAddress, marketerAddress2 } = marketerInfo;
  const [lessProducts, setLessProducts] = useState<{ string: string; value: string }[]>([]);
  const [moreProducts, setMoreProducts] = useState<{ string: string; value: string }[]>([]);
  const [isMore, setIsMore] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const { bottom_sheet_open: configKey } = useAppSelector((state: RootState) => state.recommedationSheet);


  useEffect(() => {
    const keys = Object.keys(productInfo);
    const productLessArray: { string: string; value: string }[] = [];
    const productMoreArray: { string: string; value: string }[] = [];

    keys.map((key, index) => {
      const updatedKey = key.charAt(0)?.toUpperCase() + key.slice(1);

      if (index <= 2)
        productLessArray.push({
          string: updatedKey.split('_').join(' '),
          value: productInfo[key]
        });
      else if (index > 2)
        productMoreArray.push({
          string: updatedKey.split('_').join(' '),
          value: productInfo[key]
        });
      setLessProducts(productLessArray);
      setMoreProducts(productMoreArray);

      return 0;
    });
  }, [productInfo]);

  const handleMoreClick = () => {
    const id = Number(productInfo.product_id)
    setIsMore(!isMore);
    if(configKey === 'initial')
      track(getTrackingEventObject(0, RECOMMENDED_PRODUCT_EVENTS.FEED_TYPE, RECOMMENDED_PRODUCT_EVENTS.VIEW_MORE_PRODUCT_DETAILS, id, router.asPath));
    else
      track(getTrackingEventObject(0, PDP_PAGE_EVENTS.PDP_FEED_TYPE, PDP_PAGE_EVENTS.VIEW_MORE_PRODUCT, id, router.asPath));
  };

  useEffect(() => {
    setIsMore(false);
    setIsLoaded(true);
  },[])

  return (
    <>
      <div className="flex justify-between items-center">
        <div style={styles.productInfoTitle}>Product Details</div>
        <div style={styles.moreButton} onClick={handleMoreClick}>
          {isMore ? translate('less') : translate('more')}
        </div>
      </div>
      {lessProducts.map((product: any) => (
        <div key={product.string}>
          <div style={styles.productInfo}>
            <div style={styles.productInfoItem}>{translate(product.string)}</div>
            <div>{formatWord(product.value)}</div>
          </div>
          <hr style={styles.productInfoDivider} />
        </div>
      ))}
      {isMore && (
        <>
          {moreProducts.map((product: any) => (
            <div key={product.string}>
              <div style={styles.productInfo}>
                <div style={styles.productInfoItem}>{translate(product.string)}</div>
                <div>{formatWord(product.value)}</div>
              </div>
              <hr style={styles.productInfoDivider} />
            </div>
          ))}
        </>
      )}
      {!isLoaded &&
        Object.keys(productInfo).map((key: any) => (
          <div key={key}>
            <div style={styles.productInfo}>
              <div style={styles.productInfoItem}>{translate(key)}</div>
              <div>{formatWord(productInfo[key])}</div>
            </div>
            <hr style={styles.productInfoDivider} />
          </div>
        ))}
      {isMore && (
        <>
          <h5 style={styles.otherInfoTitle} className="text-[14px] font-[500]">
            {translate('other_information')}
          </h5>
          {isNonEmptyString(countryOfOrigin) && (
            <>
              <div style={styles.productInfo}>
                <div style={styles.productInfoItem}>{translate('country_of_origin')}</div>
                <div>{countryOfOrigin}</div>
              </div>
              <hr style={styles.productInfoDivider} />
            </>
          )}
          {isNonEmptyString(marketerAddress?.title) && isNonEmptyString(marketerAddress?.address) && (
              <div style={{...styles.productInfo, textAlign: 'start', display: 'block'}}>
                <div style={{...styles.productInfoItem, marginBottom: 14}}>{marketerAddress.title?.replace(':', '')}</div>
                <div style={{marginBottom: 20, whiteSpace: 'pre-line'}}>{marketerAddress.address}</div>
              </div>
          )}
        </>
      )}
    </>
  );
};

export default memo(ProductInfo);
