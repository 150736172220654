import { colors } from '@Constants/common';

export const styleDefault = {
  wrapper: {
    backgroundColor: colors.WHITE,
    padding: '0px 0px 15px 0px',
  },
  couponHeaderContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  container: {
    display: 'flex',
    backgroundColor: colors.WHITE,
  },
  inputFieldContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: 2.3,
    borderRadius: '6px 6px 0 0',
    borderBottom: `1px solid ${colors.DARK_GRAY}`,
    backgroundColor: colors.WHITE_SMOKE
  },
  inputFieldNotEmpty: {
    borderBottomColor: colors.PRIMARY
  },
  inputField: {
    flex: 1,
    border: 'none',
    outline: 'none',
    fontSize: '14px',
    backgroundColor: colors.WHITE_SMOKE
  },
  inputTextStyleFailure: {
    color: colors.RED
  },
  applyButton: {
    flex: 1,
    fontSize: '14px',
    fontWeight: 600,
    border: `1px solid ${colors.LIGHT_GREY}`,
    borderRadius: '8px',
    marginLeft: '16px',
    color: colors.LIGHT_GREY,
    background: colors.WHITE,
    cursor: 'not-allowed',
    textTransform: 'none',
    '&:hover': { backgroundColor: colors.WHITE },
    transition:'0.4s all ease'
  },
  enableApplyBtn: {
    color: colors.DARK_GREEN,
    borderColor: colors.DARK_GREEN,
    cursor: 'pointer',
  },
  gradientContainer: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    borderRadius: '8px',
    padding: '8px 12px'
  },
  gradientSuccessBackground: {
    background: 'linear-gradient(90deg, #AAEED7 -9.84%, rgba(170, 238, 215, 0) 107.34%);'
  },
  gradientFailureBackground: {
    background: 'linear-gradient(90deg, #DA1E28 -9.84%, rgba(218, 30, 40, 0) 107.34%);',
    color: colors.WHITE
  }
};

export const styleAllCouponsVariant = {
  wrapper: {
    padding: '0px 24px 24px 24px',
  },
  container: {
    display: 'flex',
    padding: '5px 15px',
    borderRadius: '6px',
    backgroundColor: colors.WHITE,
    border: `1px solid ${colors.LIGHT_GREY}`,
  },
  inputFieldContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: 2.3,
  },
  inputFieldContainerV2: {
    display: 'flex',
    alignItems: 'center',
  },
  inputFieldNotEmpty: {},
  inputField: {
    flex: 1,
    border: 'none',
    outline: 'none',
    fontSize: '14px',
    padding: '0px',
  },
  inputFieldV2: {
    border: 'none',
    outline: 'none',
    fontSize: '14px',
    padding: '0px',
  },
  applyButton: {
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '20px',
    marginLeft: '16px',
    color: colors.LIGHT_GREY,
    transition:'0.4s all ease'
  },
  enableApplyBtn:{
    color: colors.DARK_GREEN
  },
};

