import React, { memo } from 'react';

const BannerText = () => {
  return (
    <div className="text-nm_black_2 flex flex-col items-start">
      <div className="text-[14px] font-[600] leading-[20px]">
        <span className="font-[700]">20% OFF</span> on first app order
      </div>
      <div className="text-[10px] font-[500] leading-[14px] mt-[4px]">
        Only for NEW USER <span className="text-nm_neutral_line">|</span> 2.5M+ (4.5<span className="text-nm_bumble_bee_yellow_dark_2">★</span> ) Installs
      </div>
    </div>
  );
};

export default memo(BannerText);
