import {
  BaseQueryApi,
  QueryReturnValue,
} from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import {
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from "@reduxjs/toolkit/dist/query/react";
import { Mutex } from "async-mutex";

import LocalStorage from '@Utils/storage';
import { API_DOMAIN } from '@Constants/config';
import { getTimeDiff, onLogout, validateCookie } from '@Utils/common';
import { Tokens, Analytics } from '@Constants/common';
import { API_TAGS, RequestTypes } from '@Constants/api';
import { uuid } from 'uuidv4';
import { CookieValueTypes, getCookie, setCookie } from 'cookies-next';
import { isServer } from '@Utils/checks';
import { parse } from 'cookie';
import { HEADERS } from "@Constants/headers";
import { isNonEmptyString } from "@Utils/checks";

const mutex = new Mutex();

const ISSERVER = isServer();

const getLogoutCookie = (context) => {

    let logoutCookie : CookieValueTypes | null,
        sessionCookie : CookieValueTypes | null,
        sessionIpCookie : CookieValueTypes | null;

    if (ISSERVER) {
        const { req } = context;
        
        const cookie = req?.headers?.cookie;
        const parsedCookie = cookie ? parse(cookie) : null;
        logoutCookie = parsedCookie?._nm ?? null;
        sessionCookie = parsedCookie?.session_id ?? null;
        sessionIpCookie = parsedCookie?.session_ip ?? null;
      } else {
        logoutCookie = getCookie('_nm');
        sessionCookie = getCookie('session_id');
        sessionIpCookie = getCookie('session_ip');
      }

    return `_nm=${logoutCookie};session_id=${sessionCookie};session_ip=${sessionIpCookie}`;
}

export const getLocationHeader = (context = null) => {
  const { req } = context ?? {},
         userLocation = req?.headers ? req.headers[HEADERS.X_LOCATION] : getCookie(HEADERS.X_LOCATION),
         locationHeaderValue = userLocation ? JSON.parse(userLocation) : '';

    return isNonEmptyString(locationHeaderValue) ? locationHeaderValue : null;
}

const setCookieOnServerResponse = (context, responseHeaders: Headers) => {
    if (responseHeaders && isServer()) {
        const { req } = context;
        const reqCookies = req?.headers.cookie;

        if(reqCookies)
          context?.res?.setHeader('Set-Cookie', reqCookies);
      }
}

const setCookieOnServerResponseV2 = (context, response) => {
  if (response && isServer()) {
      const cookieValue = response?.headers?.get('Set-Cookie');
      const { res } = context ?? {};

      if(cookieValue)
        res?.setHeader('Set-Cookie', cookieValue);
    }
}

const fetchQ = fetchBaseQuery({
  baseUrl: API_DOMAIN,
  ...(!ISSERVER && { credentials: "include"}),
  prepareHeaders: async (headers) => {
    const accessToken = getCookie(Tokens.ACCESS_TOKEN),
          userLocation = getLocationHeader();
    
    headers.set('Content-Type', 'application/json');
    headers.set('caller', 'web_app');
    headers.set('timestamp', Date.now().toString());
    if (accessToken) headers.set('Authorization', `Bearer ${accessToken}`);
    if(userLocation) headers.set(HEADERS.X_LOCATION, userLocation);

    return headers;
  },
});

const getRefreshHeaders = () => {
  // const refreshToken = LocalStorage.getItem(Tokens.REFRESH_TOKEN);
  const refreshToken = getCookie(Tokens.REFRESH_TOKEN);

  return {
    method: 'POST',
    headers:{
    "Content-Type": "application/json",
    Authorization: `Bearer ${refreshToken}`,
    timestamp: Date.now().toString(),}
  };
};

const baseQuery: any = async (
  args: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: {}
) => {
  try {
    await mutex.waitForUnlock();
    let result: QueryReturnValue<
      unknown,
      FetchBaseQueryError,
      FetchBaseQueryMeta
    > = await fetchQ(args, api, extraOptions);

    if (result?.error?.status === 401)
      if (!mutex.isLocked()) {
        const release = await mutex.acquire();

        try {
          if(getCookie(Tokens.REFRESH_TOKEN)){
            const refreshResult = await fetch(`${API_DOMAIN}token/refresh`, getRefreshHeaders());
            const refreshResultData = await refreshResult.json();

            if (refreshResultData.successful) {
              const { access_token, access_token_expiry } = refreshResultData;

              setCookie(Tokens.ACCESS_TOKEN, access_token, (access_token_expiry ? { maxAge: getTimeDiff({ time: access_token_expiry }) } : {}));

              result = await fetchQ(args, api, extraOptions);
            } else {
              onLogout();
            }
          }
        } catch (error) {
          onLogout();
        } finally {
          release();
        }
      } else {
        await mutex.waitForUnlock();
        result = await fetchQ(args, api, extraOptions);
      }

    return result;
  } catch (error) {
    return error;
  }
};

const authQuery: any = async (
  args: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: {}
) => {
  const result = await fetchQ(args, api, extraOptions);

  return result;
};

export const nextFetch = {
  get: async (context, url, optHeaders = {}) => {
    try {   

      const logoutCookie = getLogoutCookie(context),
            userLocation = getLocationHeader(context),
            accessToken = getCookie(Tokens.ACCESS_TOKEN),
            cookie = context?.req?.headers?.cookie,
            parsedCookie = cookie ? parse(cookie) : null;

            console.log(context?.req?.headers?.cookie)
            console.log(parsedCookie);
          console.log(logoutCookie);

      const reqHeaders : RequestInit & { timestamp: number } = {
        method: 'GET',
        timestamp: Date.now(),
        ...(!ISSERVER && { credentials: "include"}),
        headers:{
          ...(context?.req?.headers ?? {}),
          ...(logoutCookie && ISSERVER && { cookie: logoutCookie }),
          ...(!!(accessToken || parsedCookie?.access_token) && {'Authorization': `Bearer ${accessToken || parsedCookie?.access_token}`}),
          ...(userLocation && { [HEADERS.X_LOCATION]: userLocation}),
          ...optHeaders
        }
      }
      const res = await fetch(`${API_DOMAIN}${url}`, reqHeaders);
      if(res?.ok){
        // setCookieOnServerResponse(context, res.headers);
        setCookieOnServerResponseV2(context, res);
        console.log(res)

        const data = await res.json();

        return data;
      }else {
        return {}
      }
    } catch (e) {
      return {};
    }
  },
  post: async (context, url, body, sessionId, optHeaders = {}, onApiFailure = null) => {
    try {

      console.log('************************ API CALLS DEBUG ******************************');
      console.log(context?.req?.headers, url);

      const accessToken = getCookie(Tokens.ACCESS_TOKEN),
            logoutCookie = getLogoutCookie(context),
            cookie = context?.req?.headers?.cookie,
            parsedCookie = cookie ? parse(cookie) : null,
            userLocation = getLocationHeader(context);

            console.log('post server call request header: ', {
              'Content-Type': 'application/json',
              'caller': 'web_app',
              'timestamp': Date.now().toString(),
              ...(context?.req?.headers ?? {}),
              ...(userLocation && { [HEADERS.X_LOCATION]: userLocation}),
              ...(!!(accessToken || parsedCookie?.access_token) && {'Authorization': `Bearer ${accessToken || parsedCookie?.access_token}`}),
              ...(logoutCookie && ISSERVER && { cookie: logoutCookie }),
              ...optHeaders,
            }, url);

      const reqHeaders : RequestInit = {
        method: 'POST',
        ...(!ISSERVER && { credentials: "include"}),
        headers: {
          'Content-Type': 'application/json',
          'caller': 'web_app',
          'timestamp': Date.now().toString(),
          ...(context?.req?.headers ?? {}),
          ...(userLocation && { [HEADERS.X_LOCATION]: userLocation}),
          ...(!!(accessToken || parsedCookie?.access_token) && {'Authorization': `Bearer ${accessToken || parsedCookie?.access_token}`}),
          ...(logoutCookie && ISSERVER && { cookie: logoutCookie }),
          ...optHeaders,
        },
        body: JSON.stringify(body)
      }

      const res = await fetch(`${API_DOMAIN}${url}`, reqHeaders)
      if(res?.ok){
        // setCookieOnServerResponse(context, res.headers);
        setCookieOnServerResponseV2(context, res);
        
        const data = await res.json();

        return data;
      }else {
        if(onApiFailure)
          onApiFailure?.();
        else
          return {}
      }
    } catch (e) {
      if(onApiFailure)
        onApiFailure?.();
      else
        return {}
    }
  },
};

export const baseApi = createApi({
  reducerPath: "api",
  baseQuery,
  endpoints: () => ({}),
  tagTypes: API_TAGS,
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
});

export const authApi = createApi({
  reducerPath: "auth",
  baseQuery: authQuery,
  endpoints: () => ({}),
  refetchOnMountOrArgChange: false,
  refetchOnReconnect: false,
});
