import { useState, useContext } from "react";
import ProgressBar from "@Components/ProgressBar"
import CustomDialog from "@Components/CustomModal";
import FilterSheetCloseIcon from '@assets/icons/FilterSheetCloseIcon.svg';
import ProductExpSheet from '../ProductExpSheet';
import { TrackerContext } from '_app';
import { getTrackingEventObject } from '@Utils/common';
import { PDP_PAGE_EVENTS, GLOBAL_ACTION_TYPES } from '@Constants/events';


const ProductExpSection = ({features, id}) => {

    const track = useContext(TrackerContext)

    const [openSheet, setOpenSheet] = useState(false);

    const handleProdExpSheet = () => {
        setOpenSheet(true);
        track(getTrackingEventObject(0, PDP_PAGE_EVENTS.PDP_FEED_TYPE, PDP_PAGE_EVENTS.VIEW_PRODUCT_DETAILS_CLICK, id, GLOBAL_ACTION_TYPES.CLICK));
    }

    const handleClose = () => {
        setOpenSheet(false);
    }

    return (
        <div className="my-6 mx-4">
            <div>
                <p className='text-[14px] text-nm_black_2 font-[700] leading-[20px] m-0'>What our customers say about the product</p>
            </div>

            <div className=''>
                {features?.summary.map((feature) => {
                    return <div className='my-2'>
                        <div className='flex items-end'>
                            <div className='w-[70%] mr-4'>
                                <p className='my-2 text-nm_light_gray_11 text-[12px] font-[500] leading-[16px]'>{feature.key}</p>
                                <ProgressBar mainBg={'nm_white_smoke'} progressBg={'bg-nm_dark_green_2'} progress = {feature.percentage}/>
                            </div>
                            <div className='w-[30%] text-[12px] leading-[16px] font-[500] text-end'>{feature.most_chosen_option} {`(${feature.percentage}%)`}</div>
                        </div>
                </div>
                })}
            </div>
            
            <div className='text-end text-nm_dark_green_2 underline text-[12px] leading-[16px] font-[700] mt-4'>
               <span className="cursor-pointer" onClick={handleProdExpSheet}>View details</span> 
            </div>
            <div className='sheet'>
                <CustomDialog
                    open={openSheet && features?.details}
                    onClose={handleClose} 
                    setOpen={handleClose} 
                    isBottomSheet 
                    showCloseButton
                    customCloseIcon={<FilterSheetCloseIcon />}
                >
                    <div className={`w-[100vw] max-w-[450px] box-border pb-[90px] h-fit rounded-t-[16px] max-h-[91vh] overflow-y-scroll hideScrollBar bg-nm_white overflow-hidden`}>
                    <ProductExpSheet data={features?.details}/>
                    </div>
                </CustomDialog>
            </div>
        </div>
    )
}

export default ProductExpSection;