export const ANALYTIC_EVENTS = {
  ADD_TO_CART: 'add_to_cart',
  BUY_NOW: 'buy_now',
  PROCEED_TO_CHECKOUT: 'proceed_to_checkout',
  PLACE_ORDER: 'place_order',
  PURCHASE_ORDER: 'purchase_order',
  SIGN_UP: 'signup',
  LOGIN: 'login'
};

export const ECOMM_EVENTS = {
  PRODUCT_CLICK: 'productClick',
  PRODUCT_DETAIL: 'productDetail',
  ADD_TO_CART: 'addToCart',
  REMOVE_FROM_CART: 'removeFromCart',
  CHECKOUT: 'checkout',
  PURCHASE_ORDER: 'purchaseOrder',
  CUSTOM_PRODUCT_IMPRESSION: 'customProductImpression'
};

export const ECOMM_EVENTS_GA4 = {
  PURCHASE_ORDER: 'purchase',
  PRODUCT_CLICK: 'select_item',
  PRODUCT_DETAIL: 'view_item',
  ADD_TO_CART: 'add_to_cart',
  REMOVE_FROM_CART: 'remove_from_cart',
  CHECKOUT: 'begin_checkout',
  BLACK_FRIDAY_WISHLIST_CLICK: 'black_friday_wishlist_click',
  POPUP_REGISTER_ADD_CALENDER: 'popup_register_add_to_calender',
  POPUP_REGISTER: 'popup_register_now',
  STORE_REGISTER: 'store_register_now',
  NEW_STORE_REGISTER: 'new_store_register_now'
};

export const VISUAL_SEARCH_EVENTS = {
  ID: 'image_search',
  BOUNDING_BOX_BUTTON_VIEW: 'bb_btn_view',
  BOUNDING_BOX_BUTTON_CLICK: 'bb_btn_click',
  CAMERA_BUTTON_VIEW: 'camera_btn_view',
  CAMERA_BUTTON_CLICK: 'camera_btn_click',
};

export const STORE_EVENTS = {
  EVENT_TYPE: 'STORES',
  STORE_CARD_VIEW: 'store_impression',
  STORE_CARD_CLICK: 'store_impression_click',
};

export const RECOMMENDED_PRODUCT_EVENTS = {
  FEED_TYPE: 'recommended-products',
  ADD_NOW_BTN_CLICK: 'add-now-btn-click',
  IMAGE_CLICK: 'image_click',
  ADD_TO_BAG_CLICK: 'add-to-bag-click',
  BOTTOM_SHEET_WISHLIST_CLICK: 'bottom-sheet-wishlist-click',
  VIEW_ALL_REVIEWS_CLICK: 'view-all-reviews-click',
  SIZE_GUIDE : 'size-guide',
  VIEW_MORE_PRODUCT_DETAILS: 'view-more-product-details',
  RATINGS_CLICK: 'ratings-click',
  IMAGE_CAROUSEL_SHEET_CLICK: 'image-carousel-sheet-click',
  IMAGE_OPEN_SHEET_CLICK: 'image-open-sheet-click',
};

export const SIZE_COLOR_SELECTOR_BOTTOM_SHEET = {
  FEED_TYPE: 'size-color-selector-bottom-sheet',
  MODAL_OPEN_ACTION_TYPE: 'modal-open',
  MODAL_CLOSE_ACTION_TYPE: 'modal-close',
};

export const PDP_PAGE_EVENTS = {
  PDP_FEED_TYPE: 'pdp',
  FEED_TYPE: 'product',
  PDP_REVIEW_FEED_TYPE: 'pdp-reviews',
  PDP_REVIEW_CAROUSEL_FEED_TYPE: 'pdp-reviews-carousel',
  BUY_NOW_CLICK_ACTION_TYPE: 'buy-now-click',
  ADD_TO_BAG_CLICK_ACTION_TYPE: 'add-to-cart-click',
  WISHLIST_CLICK: 'wishlist-click',
  SIZE_GUIDE_CLICK: 'size-guide-click',
  RETURN_POLICY_CLICK: 'return-policy-click',
  VIEW_MORE_PRODUCT: 'view-more-product',
  VIEW_ALL_REVIEWS: 'view-all-reviews',
  IMAGE_CAROUSEL_CLICK: 'image-carousel-click',
  RATINGS: 'ratings',
  IMAGE_OPEN_CLICK: 'image-open-click',
  PRICE_VIEW : 'price-view',
  VIEW_ALL_REVIEWS_BOTTOM_SHEET_OPEN : 'view_all_reviews_bottom_sheet_open',
  IMG_GALLERY_CLICK: 'img-gallery-click',
  IMG_GALLERY_NEXT: 'img-gallery-next',
  VIEW_PRODUCT_DETAILS_CLICK: 'view-product-details-click',
  REVIEW_CAROUSEL: 'review-carousel',
  IMAGE_CAROUSEL_OPEN: 'image-carousel-open',
  IMAGE_CAROUSEL_NEXT: 'image-carousel-next',
  ALL_REVIEWS_SHEET_IMAGE_OPEN: 'all-reviews-sheet-image-open',
  ALL_REVIEWS_SHEET_IMAGE_NEXT: 'all-reviews-sheet-image-next',
  NOTIFY_OOS_FEED_TYPE: 'notify_me'
};

export const SHOP_PAGE_EVENTS = {
  SHOP_BANNER_FEED_TYPE: 'shop-banner',
  SHOP_FILTER_BAR_FEED_TYPE: 'shop-filter-bar',
  FILTER_SHEET_FEED_TYPE: 'filter-sheet',
  FILTER_WIDGET_FEED_TYPE: 'filter-widget',
  ALL_FILTERS_SHEET_FEED_TYPE: 'all-filters-sheet',
  BOTTOM_FILTERS_FEED_TYPE: 'bottom-filters',
  NO_INTERNET_ERROR_FEED_TYPE: 'plp-no-internet-connection',
  NO_PRODUCTS_ERROR_FEED_TYPE: 'plp-no-products',
};

export const SIGNUP_SHEET_EVENTS = {
  FEED_TYPE: 'sign-up-sheet',
  SIGN_UP_SHEET_RENDER: 'sign-up-sheet-render',
  SIGN_UP_BTN_CLICK: 'sign-up-btn-click',
  SIGN_UP_SHEET_CLOSE: 'sign-up-sheet-close',
  SUCCESS_POPUP_RENDER: 'success-popup-render',
  SUCCESS_POPUP_CLOSE: 'success-popup-close',
  SUCCESS_POPUP_REDIRECT_CLICK: 'success-popup-redirect-click',
  FAILURE_POPUP_RENDER: 'failure-popup-render',
  FAILURE_POPUP_CLOSE: 'failure-popup-close',
};

export const LOGIN_BOTTOM_SHEET_EVENTS = {
  FEED_TYPE: 'login-bottom-sheet',
  PHONE_NUM_SCREEN: 'phone-number-input-screen',
  OTP_SCREEN: 'otp-input-screen',
};

export const PRODUCT_SIZE_SELECTOR = {
  SIZE_SELECT_ACTION_TYPE: 'size-select',
}

export const PRODUCT_COLOR_SELECTOR = {
  COLOR_SELECT_ACTION_TYPE: 'color-select',
}

export const PRODUCT_CARD_EVENTS = {
  FEED_TYPE: 'product-card',
}

export const HEADER_EVENTS = {
  HEADER_ID: 'header',
  SEARCH_ICON_ID: 'search-icon'
}

export const SEARCH_PAGE_EVENTS = {
  SEARCH_FEED_TYPE: 'search-feed',
  PILLS_MORE_ID: 'showAll',
}

export const IMG_LOAD_FAILURE_EVENTS = {
  PDP_CAROUSEL : 'product-carousel-img-load-failure',
  PRODUCT_CARD : 'product-card-img-load-failure',
}

export const DOWNLOAD_EVENTS = {
  FEED_TYPE : 'app-download-cta'
}

export const GLOBAL_ACTION_TYPES = {
  CLICK : 'click',
  SCROLL : 'scroll',
  VIEW: 'view',
}

export const APP_HEADER_NAV_EVENTS = {
  BACK_BTN_CLICK : 'app-header-nav-back-clicked',
}

export const CART_PAGE_EVENTS = {
  CART : 'cart',
  MOVE_TO_WISHLIST : 'move-to-wishlist',
  REMOVE : 'remove',
  DELETE_BTN_CLICK : 'delete-btn-clicked',
}

export const REFERRAL_EVENTS = {
  REFER_AND_EARN_PAGE_FEED_TYPE: 'refer-and-earn',
  REFERRALS_FEED_TYPE: 'referrals',
  COPY_REF_LINK: 'copy-referral-link',
  SHARE_REF_LINK: 'share-referral-link',
}

export const ADDRESS_EVENTS = {
  ADDRESS_OPTION_TOGGLE: 'address-option-toggle',
  ADD_ADDRESS_CTA_CLICK: 'add-address-cta-click',
  EDIT_ADDRESS_CTA_CLICK: 'edit-address-cta-click',
  GO_BACK_CTA_CLICK: 'go-back-cta-click',
}

export const CHECKOUT_EVENTS = {
 CHECKOUT_FEED_TYPE: 'checkout',
 PRICE_BREAKUP_BOTTOM_SHEET_OPENED : 'price-breakup-bottom-sheet-opened',
 CHECKOUT_LIST_ITEMS_FEED_TYPE: 'checkout-list-items',
 CHECKOUT_LIST_END_REACHED  :'checkout-list-end-reached',
 VIEW_ALL_ITEMS_CLICKED : 'view-all-items-clicked',
 ALL_ITEMS_BOTTOM_SHEET_OPENED : 'all-items-bottom-sheet-opened',
 ADD_NEW_ADDRESS_CLICKED : 'add-new-address-clicked',
 CHANGE_ADDRESS_CLICKED : 'change-address-clicked',
 APPLY_COUPON_CLICKED : 'apply-coupon-clicked',
 REMOVE_COUPON_CLICKED : 'remove-coupon-clicked',
 APPLY_COUPON_FROM_LIST_CLICKED : 'apply-coupon-from-list-clicked',
 APPLY_COUPON_FROM_INPUT_CLICKED : 'apply-coupon-from-input-clicked',
 REMOVE_COUPON_FROM_INPUT_CLICKED : 'remove-coupon-from-input-clicked',
 VIEW_ALL_COUPONS_CLICKED : 'clicked-on-view-all-coupons-cta',
 COUPON_BOTTOM_SHEET_OPENED : 'coupon-bottom-sheet-opened',
 COUPON_KNOW_MORE_CLICKED: 'coupon-know-more-clicked',
 COUPON_APPLIED_SUCCESS_MODAL_RENDERED: 'coupon-applied-success-modal-rendered',
 REMAINING_WALLET_BALANCE_CLICKED : 'remaining-wallet-balance-clicked',
 PAYMENT_OPTIONS_VIEWED : 'payment-options-viewed',
 PAYMENT_OPTION_CLICKED : 'payment-option-clicked',
 PLACE_ORDER_CLICKED : 'place-order-clicked',
 PAY_NOW_CLICKED : 'pay-now-clicked',
 COD_CONFIRMATION_BOTTOM_SHEET_OPENED : 'cod-confirmation-bottom-sheet-opened',
 SUCCESSFULLY_SWIPED_COD_CONFIRMATION : 'successfully-swiped-cod-confirmation',
 COD_CONFIRMATION_BOTTOM_SHEET_CLOSED : 'cod-confirmation-bottom-sheet-closed',
 COD_DISABLE_CTA_CLICK : 'cod-disable-cta-click',
 PRIVACY_POLICY_CTA_CLICK: 'privacy-policy-cta-click',
 ADDRESS_NOT_ADDED_TOAST_SHOWN: 'address-not-added-toast-shown',
 ADDRESS_NOT_SELECTED_TOAST_SHOWN: 'address-not-selected-toast-shown',
}

export const CART_EVENTS = {
  REMOVE_COUPON_CLICKED : 'remove-coupon-clicked',
  APPLY_COUPON_FROM_LIST_CLICKED : 'apply-coupon-from-list-clicked',
  APPLY_COUPON_FROM_INPUT_CLICKED : 'apply-coupon-from-input-clicked',
  VIEW_ALL_COUPONS_CLICKED : 'clicked-on-view-all-coupons-cta',
  COUPON_BOTTOM_SHEET_OPENED : 'coupon-bottom-sheet-opened',
  COUPON_APPLIED_SUCCESS_MODAL_RENDERED: 'coupon-applied-success-modal-rendered',
 }

