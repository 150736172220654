import { useRef } from 'react';

export const useTrackData = () => {
  const trackObj = useRef<any>({});

  const addEvent = (id, data) => {
    trackObj.current[id] = data;
  };

  const clearEventData = () => {
    trackObj.current = {};
  };

  const hasEvent = (id) => {
    return !!trackObj.current[id];
  }

  return { trackObj, addEvent, clearEventData, hasEvent };
};