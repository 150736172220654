import { setDeliveryInfo } from '@Containers/Home/homeSlice';
import { setVisualSearchEnabled } from '@Containers/Search/searchSlice';
import { baseApi } from '@Services/api';
import LocalStorage from '@Utils/storage';
import { setAppConfigData } from '@Layouts/layoutSlice';
import { setSizeSelectorConfig } from '@Containers/Shop/shopSlice';

export const onboardingApis = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    requestOtp: builder.mutation({
      query: (payload) => ({
        url: 'otp/request',
        method: 'POST',
        body: payload
      })
    }),
    verifyOtp: builder.mutation({
      query: (payload) => ({
        url: 'otp/verify',
        method: 'POST',
        body: payload
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(onboardingApis.endpoints.getPageConfig.initiate({ page_type: 'default' }));
          }
        }
        catch (err) {}
      },
    }),
    getAppConfig: builder.query({
      query: () => ({
        url: `get_page_config?page_type=default`,
        method: 'GET'
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(setSizeSelectorConfig(data?.size_bottom_sheet_config ?? {}));
            dispatch(setVisualSearchEnabled(data?.is_image_search_enabled ?? false));
            dispatch(setDeliveryInfo({ deliveryInfo: data?.delivery_info ?? {} }));
          }
        }
        catch (err) {
        }
      },
    }),
    getSystemConfig: builder.query({
      query: () => ({
        url: `get_config?config_name=system_config`,
        method: 'GET'
      }),
      async onQueryStarted(args, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          if (data?.login_screen_banner) LocalStorage.setItem('loginBanner', data?.login_screen_banner);
          else LocalStorage.removeItem('loginBanner');
        } catch (err) {
          console.log(err);
        }
      }
    }),
    getPageConfig: builder.query({
      query: (payload) => ({
        url: `get_page_config?page_type=${payload.page_type}${payload?.utmString ? `&${payload.utmString}` : ''}`,
        method: 'GET'
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          if(data && args?.page_type === 'default' ){
            dispatch(setAppConfigData(data));
            dispatch(setSizeSelectorConfig(data?.size_bottom_sheet_config ?? {}));
          }
          
          if (args?.page_type === 'default')
            LocalStorage.setItem('returnWindow', data?.return_order_item_window?.toString());
                    } catch (err) {
          LocalStorage.setItem('returnWindow', '0');
        }
      }, 
    }),
    getConfigByName: builder.query({
      query: (payload) => ({
        url: `get_config?config_name=${payload.config_name}`,
        method: 'GET'
      }),
      async onQueryStarted(args, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          LocalStorage.setItem(`${args.config_name}_config`, JSON.stringify(data));
        } catch (err) {
          LocalStorage.setItem(`${args.config_name}_config`, JSON.stringify({}));
        }
      }
    })
    ,
    sendUserLocation: builder.mutation({
      query: (payload) => ({
        url: 'me/location',
        method: 'POST',
        body: payload
      })
    }),
  })
});

export const {
  useRequestOtpMutation,
  useVerifyOtpMutation,
  useGetAppConfigQuery,
  useLazyGetAppConfigQuery,
  useGetPageConfigQuery,
  useLazyGetPageConfigQuery,
  useLazyGetSystemConfigQuery,
  useGetSystemConfigQuery,
  useGetConfigByNameQuery,
  useSendUserLocationMutation,
} = onboardingApis;
