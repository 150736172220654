import { useState } from 'react';
import { Tokens } from '../constants/common'; // replace with actual path to constants
import { getCookie } from 'cookies-next';
import { nextFetch } from '@Services/api';
import { useRouter } from 'next/router';
import { getAccurateTimeStamp, setServerTimeDiff } from '@Utils/common';
import { SERVER_TIME_DIFF_COOKIE_KEY } from '@Constants/storageKeys';

export const useSendTrackData = () => {
  const [lastRequestTime, setLastRequestTime] = useState(0),
        router= useRouter(),
        { asPath } = router;

  const sendTrackData = async (trackObj) => {
    if(asPath.includes('/redirect-to-app'))
      return;

    let localCopy = Object.values(trackObj);
    let sortedCopy = localCopy.sort((a, b) => a.time - b.time);
    // const accessToken = LocalStorage.getItem(Tokens.ACCESS_TOKEN);
    const accessToken = getCookie(Tokens.ACCESS_TOKEN),
          serverTimeDiff = getCookie(SERVER_TIME_DIFF_COOKIE_KEY);

    if(!serverTimeDiff){
      await setServerTimeDiff();

      sortedCopy = sortedCopy.map(item => {
        if (!item?.server_time_diff) {
            const { currTimeStamp } = getAccurateTimeStamp({ timestamp: item?.time });

            return {
                ...(item ?? {}),
                time: currTimeStamp
            };
        }
        
        return item; 
      });
    }

    await nextFetch
      .post(
        null,
        `track_event_data`,
        {
          medium: 'web_app',
          items: sortedCopy
        },
        null,
        {
          'Content-Type': 'application/json',
          timestamp: Date.now(),
          caller: 'web_app',
          Authorization: `Bearer ${accessToken || ''}`
        }
      )
      .then(() => {
        setLastRequestTime(Date.now());
      });
  };

  return { sendTrackData, lastRequestTime, setLastRequestTime };
};
