import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ANALYTIC_EVENTS } from '@Constants/events';
import { useUpdateCartMutation } from '@Containers/Cart/apiSlice';
import { useRequestOtpMutation, useVerifyOtpMutation } from '@Containers/Onboarding/apiSlice';
import { useGetProfileMutation } from '@Containers/Profile/apiSlice';
import { useAddToWishlistMutation } from '@Containers/Wishlist/apiSlice';
import GTM from '@Utils/gtm';
import LocalStorage from '@Utils/storage';
import { useAppDispatch, useAppSelector } from '@Store/store';
import { resetGuestCartSlice } from '@Containers/GuestCart/guestCartSlice';
import { OTPSessionType } from '@Containers/Login/types';
import { getTimeDiff, parseErrorMessage } from '@Utils/common';
import { OTP_LENGTH, RESEND_OTP_TIME, Tokens } from '@Constants/common';
import { setShowLoginBottomSheet } from '@Containers/Login/loginSlice';
import { useUpdateHistoricalTrendingMutation } from '@Containers/Search/apiSlice';
import { setCookie } from 'cookies-next';
import { setSignupSheetOpen } from '@Containers/SignupBottomSheet/signupBottomSheetSlice';
import { SessionStorage } from '@Utils/storage';
import { SHOW_SIGN_UP_SUCCESS_OR_FAILURE_MODAL } from '@Constants/storageKeys';

const useLogin = (otp: string | undefined, source: Object) => {
  const [resendTime, setResendTime] = useState<number>(0);
  const [showResendBtn, setShowResendBtn] = useState<boolean>(false);
  const [showError, setShowError] = useState<string>('');
  const [OTPSession, setOTPSession] = useState<OTPSessionType | undefined>();

  const [requestOtp, { data, error, isLoading }] = useRequestOtpMutation();
  const [verifyOtp, { data: verifyData, error: verifyErr, isLoading: verifyLoading }] = useVerifyOtpMutation();
  const [addToWishlist] = useAddToWishlistMutation();
  const [updateCart] = useUpdateCartMutation();
  const [getProfile] = useGetProfileMutation();
  const [isSuspicious, setIsSuspicious] = useState(false);
  const [updateHistoryTrend] = useUpdateHistoricalTrendingMutation()

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const prevPath: string = useAppSelector((state) => state.login.prevPath);

  useEffect(() => {
    if (!resendTime && !OTPSession) return;
    const intervalId = setInterval(() => {
      setResendTime(resendTime - 1);
    }, 1000);

    if (OTPSession && resendTime === 0) {
      clearInterval(intervalId);
      setShowResendBtn(true);
    }

    return () => clearInterval(intervalId);
  }, [resendTime]);

  useEffect(() => {
    if (data) {
      const { otp_session_id, mobile_number } = data;

      setOTPSession({ otp_session_id, mobile_number });
      setResendTime(RESEND_OTP_TIME);
      setShowResendBtn(false);
    } else if (error) {
      setShowError(parseErrorMessage(error));
    }
  }, [data, error]);

  const resetLoginStates = () => {
    setOTPSession(undefined);
    setShowResendBtn(false);
    setResendTime(0);
  };

  const handleUpdateCartAndWishList = async () => {
    const productIds = LocalStorage?.getItem('wishlist');
    const cartItems = LocalStorage.getItem('guestCartProducts');
    const cartResponse = await updateCart(cartItems ? [...JSON.parse(cartItems)] : []);
    const wishListResponse = await addToWishlist(productIds || []);
    const me: any = await getProfile({});
    const { new_user: isNewUser } = verifyData?.user || {};

    if (cartResponse && wishListResponse) {
      LocalStorage?.removeItem('guestCartProducts');
      dispatch(resetGuestCartSlice());
      LocalStorage?.removeItem('wishlist');
      if (me) {
        dispatch(setShowLoginBottomSheet(false));
        dispatch(setSignupSheetOpen(''));
        navigate(prevPath, true);
      }
    }
    if (isNewUser)
      GTM.sendEvent(ANALYTIC_EVENTS.SIGN_UP, {
        user_id: me?.data?.id,
        signup_type: 'phone_number'
      });
    else
      GTM.sendEvent(ANALYTIC_EVENTS.LOGIN, {
        user_type: 'repeat',
        user_id: me?.data?.id,
        login_type: 'phone_number'
      });
  };

  const handlePushRecentSearchAndViewedProducts = async () => {
    const recentSearches = JSON.parse(LocalStorage?.getItem('recentSearches'));
    const viewedProducts = JSON.parse(LocalStorage.getItem('viewedProducts'));
    let payload = {};

    if(recentSearches) {
      payload['recent_searches'] = recentSearches
    }

    if(viewedProducts) {
      payload['recently_viewed'] = viewedProducts
    }

    if(Object.keys(payload).length>0){
      const updateHistoryResponse = await updateHistoryTrend(payload)
      if(updateHistoryResponse){
        LocalStorage?.removeItem('recentSearches');
        LocalStorage?.removeItem('viewedProducts');
        LocalStorage?.removeItem('viewedProductsData');
      }
    }
  }

  const setLoginCreds = (vrData)=>{
    handleUpdateCartAndWishList();
    handlePushRecentSearchAndViewedProducts();

    const { access_token, refresh_token, access_token_expiry, refresh_token_expiry } = vrData;

    setCookie(Tokens.ACCESS_TOKEN, access_token, (access_token_expiry ? { maxAge: getTimeDiff({ time: access_token_expiry }) } : {}));
    setCookie(Tokens.REFRESH_TOKEN, refresh_token, (refresh_token_expiry ? { maxAge: getTimeDiff({ time: refresh_token_expiry }) } : {}));
  }
  useEffect(() => {
    if (verifyData) {

      if(source?.via_wallet_nudge){
        if(verifyData?.sign_up_via_wallet === true)
          SessionStorage.setItem(SHOW_SIGN_UP_SUCCESS_OR_FAILURE_MODAL, 'success');
        else
          SessionStorage.setItem(SHOW_SIGN_UP_SUCCESS_OR_FAILURE_MODAL, 'failure');
      }

      if(verifyData?.is_new_login){
        //handle susLogin here
        setIsSuspicious(true);
      }else{
        setLoginCreds(verifyData)
      }
    } else if (verifyErr) {
      setShowError(parseErrorMessage(verifyErr));
    }
  }, [verifyData, verifyErr]);

  useEffect(() => {
    if (otp?.length === OTP_LENGTH)
      verifyOtp({
        ...OTPSession,
        otp,
      });
  }, [otp]);

  return {
    showResendBtn,
    resendTime,
    showError,
    setShowError,
    requestOtp,
    isLoading,
    verifyLoading,
    OTPSession,
    resetLoginStates,
    isSuspicious,
    setIsSuspicious,
    setLoginCreds
  };
};

export default useLogin;
