import { colors } from '@Constants/colors';

const styles = {
  bogoCard: {
    width: '95%',
    height: '80px',
    background: '#FFF',
    borderRadius: '8px'
  },
  bogoCardContent: {
    display: 'flex',
    flexDirection: 'row',
    gap: '12px',
    textAlign: 'left',
    height: '100%',
    paddingRight: '5px'
  },
  bogoDescription: {
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '16px',
    color: colors.DIM_GRAY
  },
  bogoQty: { fontWeight: 400, fontSize: '12px', lineHeight: '20px', color: colors.DIM_GRAY },
  bogoImage: {
    width: '60px',
    height: '100%',
    borderRadius: '8px 0px 0px 8px'
  },
  bogoDetails: { display: 'flex', justifyContent: 'space-evenly', flexDirection: 'column', padding: '8px 0px' },
  bogoPriceDetails: { display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' },
  indicatorStyle: { display: 'flex', flexDirection: 'row', gap: '4px', justifyContent: 'center', marginTop: '4px' },
  indicatorCircle: {
    width: '8px',
    height: '8px',
    borderRadius: '25px'
  },
  carouselData: {
    display: 'flex',
    flexDirection: 'row',
    overflow: 'scroll',
    cursor: 'pointer',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    scrollbarWidth: 'none'
  }
};

export default styles;
