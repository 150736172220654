import { CartItem } from '@Containers/GuestCart/types';
import { STOCK_STATUS } from '@Constants/common';
import { isNonEmptyObject } from './checks';

export const findCartCount = (
  cart_items: Record<string, CartItem>,
  calculate_quantity: any,
  product_variations: any
) => {
  let count = 0;
  let discount = 0;
  const out_of_stock_items: any = [];
  const item_quantities: any = {};

  Object.keys(cart_items).forEach((item) => {
    const { product_id, variation_id, quantity, line_discount, variation, line_subtotal, title } = cart_items[item];

    count += quantity || 0;
    discount += line_discount;
    if (calculate_quantity) item_quantities[`${product_id}${variation_id}`] = quantity;

    if (product_variations) {
      const itemVariation = product_variations[product_id]?.find(
        (itemVariations: any) => itemVariations.variation_id === variation_id
      );

      if (itemVariation?.stock_status === STOCK_STATUS.outOfStock) {
        const data = {
          product_id,
          variation_id,
          quantity,
          variation,
          line_subtotal,
          title
        };

        out_of_stock_items.push(data);
      }
    }
  });

  return { count, item_quantities, discount, out_of_stock_items };
};

export const getCartItems = (payload: any, product_variations: any) => {
  let data = { ...payload };

  if (isNonEmptyObject(payload.cart) && !payload.product_variations) data = { ...data, product_variations };

  return data;
};

export const calculateDiscountPercentage = (price: number, discount: number) => {
  if (price === 0) return;
  const discountPercentage = (discount / price) * 100;

  return Math.round(discountPercentage);
};

export const getProductIdsFromCart = (cartItems: Record<string, CartItem>) => {
  const cartItemsArray = Object.keys(cartItems).map((key) => cartItems[key]);

  if (cartItemsArray.length === 0) return null;
  const productIds = cartItemsArray.map((item) => item.product_id);

  return productIds;
};
