import { memo, useContext } from 'react';
import SimilarProductsIcon from '@assets/icons/SimilarProductsIcon.svg';
import styles from './styles.module.css';
import { CONTAINER_IDS } from '../../constants';
import { PdpContext } from '@Containers/Product-Details';

const ViewSimilarProducts = ({ productId }) => {
  const { scrollToSimilarProducts } = useContext(PdpContext) ?? {};

  return (
    <button
      id={CONTAINER_IDS.SIMILAR_PRODUCTS_BTN}
      className={`ml-[10px] py-[1px] px-[1px] rounded-[60px] font-medium text-[12px] leading-[16px] bg-nm_white bg-opacity-[0.75] customButton customButton--noScale ${styles.spinAnimation}`}
      onClick={scrollToSimilarProducts}
    >
      <div id="pdp-similar-products" className="bg-nm_white py-[4px] px-[8px] rounded-[60px] flex items-center justify-center text-nm_black">
        <SimilarProductsIcon className="mr-[4px]" />
        View Similar
      </div>
    </button>
  );
};

export default memo(ViewSimilarProducts);
