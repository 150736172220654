import React, { CSSProperties, memo, useEffect, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import CloseIcon from '@assets/icons/Close.svg';
import CustomDialog from '@Components/CustomModal';
import styles from '../../styles';
import { WebPopup } from '@Constants/common';
import { SHOW_TRANSPARENT_GLOBAL_LOADER } from '@Constants/storageKeys';
import { SessionStorage } from '@Utils/storage';
import { validateRedirection } from '@Utils/checks';

const PopupBanner = ({ open, popupData, handleWebPopUpClose, handleClose }) => {
  const router = useRouter(),
    [popupOpacity, setPopupOpacity] = useState(0),
    { redirectUrl, imageUrl } = popupData ?? {},
    isValidRedirection = validateRedirection(redirectUrl ?? '', router.pathname);

  useEffect(()=>{
    if(open)
      if(popupData?.redirectUrl?.includes("web_popup"))
        SessionStorage.setItem(WebPopup.APP_DOWNLOAD_POPUP, 'true');
      else
        SessionStorage.setItem(WebPopup.WEB_POPUP, 'true');
  }, [open])

  const handleClickOnPopup = (event) => {
    if (!redirectUrl || !isValidRedirection) event.preventDefault();
    else SessionStorage.setItem(SHOW_TRANSPARENT_GLOBAL_LOADER, JSON.stringify(true));

    handleWebPopUpClose();
  };

  return (
    <CustomDialog open={open} onClose={handleClose} setOpen={handleClose}>
      <div style={{ ...styles.modalStyleContainer, opacity: popupOpacity } as CSSProperties}>
        <CloseIcon style={styles.modalClose} onClick={handleWebPopUpClose} />
        <div style={styles.modalStyle as CSSProperties}>
          <Link href={redirectUrl ?? ''} onClick={handleClickOnPopup} className="outline-none focus:outline-none">
            <img style={{ width: '100%' }} src={imageUrl} alt="popup-img" loading="eager" onLoad={() => setPopupOpacity(1)} />
          </Link>
        </div>
      </div>
    </CustomDialog>
  );
};

export default memo(PopupBanner);
