import RightWings from "@assets/images/ReviewWingsRight.svg";
import LeftWings from '@assets/images/ReviewWingsLeft.svg';
import { memo } from "react";
import { CONTAINER_IDS } from '../../constants';

const RatingsFrame = ({reviewCount, rating, ratingCount}) => {

    return (
        <div id={CONTAINER_IDS.RATING_FRAME} className="flex flex-col items-center p-[20px]" style={{
            background: 'linear-gradient(180deg, #E9FCE2, #FEFFFE )'
          }}>
            <div className="text-[22px] font-bold leading-6">
            Ratings & Reviews
            </div>   
            <div className="flex text-[14px] font-[500] text-nm_light_gray_11 leading-4">
               { ratingCount > 10 && <><p className="my-0 mt-4">{ratingCount} ratings </p> <p className="my-0 mx-1 mt-4 ">|</p> <p className="my-0 mt-4">{reviewCount} reviews</p></> }
            </div>

            <div className="flex justify-center items-center my-4">
                <LeftWings/>
                <p className='text-[30px] leading-9 font-bold m-0 px-2 ' >{rating}</p>
                <RightWings/>
            </div>

            <div className="text-nm_light_gray_11 font-bold text-[16px] leading-5">Our loyal customers love us</div>
   
        </div>
    )
}

export default memo(RatingsFrame);
