import useTranslation from '@Utils/useTranslation';
import FastDeliveryBolt from '@assets/icons/FastDeliveryBolt.svg';
import NormalDeliveryTruck from '@assets/icons/NormalDeliveryTruck.svg';
import { memo } from 'react';

interface ExpectedDeliveryProps {
  fastDelivery: boolean;
  dateRange: string;
  variations: any;
  selectedSize: string;
}

const styles = {
  normalDeliveryGradient: {
    background: 'linear-gradient(90deg, #C3FFE2 0%, #FFFFFF 80.69%)',
    borderTop: '1px solid #C3FFE2'
  },
  fastDeliveryGradient: {
    background: 'linear-gradient(90deg, #FFEECE 0%, #FFFFFF 80.69%)',
    borderTop: '1px solid #FFEECE'
  }
};

const getRange = (fastDelivery, variations, selectedSize) => {
  const selectedVariation = variations.find((variation) => variation?.size === selectedSize);
  
  return fastDelivery ? selectedVariation?.fast_delivery_text : selectedVariation?.normal_delivery_text;
};

const getGradientStyles = (fastDelivery) => (fastDelivery ? styles.fastDeliveryGradient : styles.normalDeliveryGradient);

const { t: translate } = useTranslation();

const ExpectedDelivery = ({ fastDelivery, dateRange, selectedSize, variations }: ExpectedDeliveryProps) => {
  return (
    <div className="mx-[16px] mb-[24px] text-[10px] flex items-center">
      <div
        className="p-[2px] pl-[4px] mr-[5px] flex gap-[2px] items-center font-[500] italic"
        style={{ borderRadius: '4px 0px 0px 4px', ...getGradientStyles(fastDelivery) }}
      >
        {fastDelivery ? (
          <>
            <FastDeliveryBolt />
            {translate('fast_delivery_text')}
          </>
        ) : (
          <>
            <NormalDeliveryTruck />
            {translate('normal_delivery_text')}
          </>
        )}
      </div>
      <div className="pl-[4px] text-nm_dim_gray border-solid border-[0px] border-l-[1px] border-l-nm_grey">
        {getRange(fastDelivery, variations, selectedSize) ?? dateRange}
      </div>
    </div>
  );
};

export default memo(ExpectedDelivery);
