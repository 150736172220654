import Link from 'next/link';
import { useNavigate } from 'react-router-dom';
import { CSSProperties } from 'react';
import styles from './styles';

interface BreadCrumbProps {
  breadcrumbs: Array<Record<string, string>>;
  callback: () => void;
}

const BreadCrumb = ({ breadcrumbs = [], callback }: BreadCrumbProps) => {
  const navigate = useNavigate();

  const redirect = (e: { preventDefault: () => void }, page: string) => {
    e.preventDefault();
    callback();
    navigate(page);
  };

  return breadcrumbs.length ? (
    <div style={styles.container as CSSProperties}>
      {breadcrumbs?.map((item, index) => {
        if (item?.url && item?.route)
          return (
            <>
              {index !== 0 && <span style={styles.linkSeparator}> {'/'} </span>}
              <Link
                href={item?.url ?? ''}
                key={item?.name}
                prefetch={false}
                onClick={(e) => redirect(e, item?.route)}
                style={{ ...styles.link, ...(index === breadcrumbs.length - 1 && styles.selectedLink) }}
              >
                {item?.name}
              </Link>
            </>
          );
      })}
    </div>
  ) : (
    <></>
  );
};

export default BreadCrumb;
