import CustomDialog from '@Components/CustomModal';
import SignupSuccessIcon from '@assets/icons/SignupSuccessIcon.svg';
import FilterSheetCloseIcon from '@assets/icons/FilterSheetCloseIcon.svg';
import Link from 'next/link';
import { isNonEmptyObject, validateRedirection } from '@Utils/checks';
import { useRouter } from 'next/router';
import CountdownTimer from '@Components/CountdownTimer';
import { RootState, useAppDispatch, useAppSelector } from '@Store/store';
import { useCallback, useContext, useEffect } from 'react';
import { setSignupSuccessPopupOpen } from '@Containers/SignupBottomSheet/signupBottomSheetSlice';
import { TrackerContext } from '_app';
import { getTrackingEventObject, getTrackEventMetadata } from '@Utils/common';
import { GLOBAL_ACTION_TYPES, SIGNUP_SHEET_EVENTS } from '@Constants/events';

const SuccessModal = ({ successModalConfig }) => {
  const track = useContext(TrackerContext),
    { asPath } = useRouter(),
    {
      title,
      sub_title: subTitle,
      referral_success_message: successMsg,
      redirect_button_config: redirectButtonConfig,
      expiry_time: expiry
    } = successModalConfig ?? {},
    { label, redirectTo } = redirectButtonConfig ?? {},
    router = useRouter(),
    dispatch = useAppDispatch(),
    { signup_success_popup_open: signupSuccessPopupOpen } = useAppSelector((state: RootState) => state.signupSlice),
    isValidRedirection = validateRedirection(redirectTo ?? '', router.pathname);

  useEffect(() => {
    if (isNonEmptyObject(successModalConfig) && signupSuccessPopupOpen)
      track(getTrackingEventObject(-1, SIGNUP_SHEET_EVENTS.FEED_TYPE, GLOBAL_ACTION_TYPES.VIEW, SIGNUP_SHEET_EVENTS.SUCCESS_POPUP_RENDER, asPath, { meta: getTrackEventMetadata()}));
  }, [successModalConfig, signupSuccessPopupOpen]);

  const handleRedirectionClick = useCallback(
    (event) => {
      track(getTrackingEventObject(-1, SIGNUP_SHEET_EVENTS.FEED_TYPE, GLOBAL_ACTION_TYPES.CLICK, SIGNUP_SHEET_EVENTS.SUCCESS_POPUP_REDIRECT_CLICK, asPath, { meta: getTrackEventMetadata()}));
      dispatch(setSignupSuccessPopupOpen(false));

      if (!redirectTo || !isValidRedirection) event.preventDefault();
    },
    [redirectTo, isValidRedirection]
  );

  const handlePopupClose = useCallback(() => {
    track(getTrackingEventObject(-1, SIGNUP_SHEET_EVENTS.FEED_TYPE, GLOBAL_ACTION_TYPES.CLICK, SIGNUP_SHEET_EVENTS.SUCCESS_POPUP_CLOSE, asPath, { meta: getTrackEventMetadata()}));
    dispatch(setSignupSuccessPopupOpen(false));
  }, []);

  if (!isNonEmptyObject(successModalConfig)) return <></>;

  return (
    <CustomDialog open={signupSuccessPopupOpen} onClose={handlePopupClose} setOpen={handlePopupClose}  showCloseButton customCloseIcon={<FilterSheetCloseIcon />}>
      <div className="max-w-[320px] bg-nm_white mx-[20px] px-[13px] pb-[16px] rounded-[16px] box-border ">
        <div className="relative overflow-hidden rounded-[16px] p-[14px] flex items-center justify-center">
          <SignupSuccessIcon />
          <div className="w-[640px] aspect-square rounded-full bg-nm_light_green_3_transparent_10 absolute top-[-532px] left-[-175px] " />
        </div>
        <div className="p-[17px] text-center flex flex-col gap-[23px]">
          {(title || successMsg) && (
            <div className="font-[500]">
              {title && <p className="m-0 p-0 text-[14px] leading-[20px] text-nm_dim_gray">{title}</p>}
              {successMsg && <p className="m-0 p-0 mt-[6px] text-[20px] leading-[24px] text-nm_black">{successMsg}</p>}
            </div>
          )}
          {((expiry && expiry !== 0) || subTitle) && (
            <div>
              {subTitle && <p className="m-0 p-0 text-[14px] font-[500] leading-[20px] text-nm_dim_gray px-[35px]">{subTitle}</p>}
              <div className="mt-[12px] flex justify-center">
                <CountdownTimer expiry={expiry} />
              </div>
            </div>
          )}
        </div>
        {label && redirectTo && (
          <Link href={redirectTo ?? '/shop'} onClick={handleRedirectionClick}>
            <button className="mt-[11px] customButton customButton--noScale customButton--transparent text-[16px] font-[600] leading-[20px] text-nm_dark_green_2">
              {label}
            </button>
          </Link>
        )}
      </div>
    </CustomDialog>
  );
};

export default SuccessModal;
