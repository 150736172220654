import { colors } from '@Constants/colors';

const styles = {
  paginationContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: '20px'
  },
  activeDot: {
    width: '6px',
    height: '6px',
    borderRadius: '3px',
    backgroundColor: colors.PRIMARY
  },
  inactiveDot: {
    width: '6px',
    height: '6px',
    borderRadius: '3px',
    backgroundColor: colors.LIGHT_SILVER
  },
  customDotsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '15px',
  },
  customDot: {
    width: '6px',
    height: '6px',
    borderRadius: '5px',
    backgroundColor: colors.LIGHT_SILVER,
    margin: '0 5px',
  },
  activeCustomDot: {
    backgroundColor: colors.PRIMARY,
    width: '8px',
    height: '8px',
  },
  mt: {
    marginTop: '3px'
  },
};

export default styles;