export const getImageWidth = () => {
  if (typeof window === 'undefined') return (450 * 38) / 100;
  if (window?.innerWidth > 450) return (450 * 38) / 100;

  return (window?.innerWidth * 38) / 100;
};

export const getProductImageSizeSet = (productImagesSet) => {
  if (!Array.isArray(productImagesSet) || productImagesSet.length <= 0) {
    return '';
  }
  const sortedproductImages = [...productImagesSet].filter((item) => item.width > 100 && item.width <= 900).sort((a, b) => a.width - b.width);

  const sizes = sortedproductImages.map((item) => `(max-width: ${item.width}px) ${item.width}px`).join(', ') || '';

  return sizes;
};

export const getProductImageSrcSet = (productImagesSet) => {
  if (!Array.isArray(productImagesSet) || productImagesSet.length <= 0) {
    return '';
  }
  const sortedproductImages = [...productImagesSet].filter((item) => item.width > 100 && item.width <= 900).sort((a, b) => a.width - b.width);
  const srcSet = sortedproductImages.map((item) => `${item.path} ${item.width}w`).join(', ') || '';

  return srcSet;
};
