import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { forwardRef, useState, useEffect, useCallback } from 'react';
import useTranslation from '@Utils/useTranslation';
import Marquee from '@Components/Marquee';
import Toast, { Positions } from '@Components/Toast';
import { ToastTypes } from '@Components/Toast/type';
import { ShowToastType } from '@Components/AddressCard/types';
import { isNonEmptyString } from '@Utils/checks';
import { copyToClipboard, parseMilliSeconds } from '@Utils/common';
import { getCookie } from 'cookies-next';
import { RootState, useAppDispatch, useAppSelector } from '@Store/store';
import CloseIconLight from '@assets/icons/CloseIconLightV2.svg';
import { closeMarquee } from '@Layouts/layoutSlice';

interface BannerType {
  text: string;
  copyText?: string;
  copiedContent?: string;
  fontSize?: string;
  defaultFontSize?: string;
  bgColor?: string;
  textColor?: string;
  redirectUrl?: string;
}

interface TopHeaderProps {
  bannerData?: BannerType;
  top?: string;
  onClick?: () => void;
  customStyles?: any;
  showCloseIcon?: boolean;
}

interface TextComponentProps {
  arr: Array<string>;
  joinText?: string;
  appConfigData: any;
  copyText?: string;
  copiedContent?: string;
  remainingTime?: number;
}

const defaultToastState = {
    message: '',
    type: undefined,
    show: false
  },
  isTimerEnabled = false, // TODO: Handle Timer based on BE flag - NEED TO BE FIXED
  TIMER_DURATION = 900;

const formatTime = (time: number) => {
  const {minutes, seconds} = parseMilliSeconds(time);
  return `${minutes}m ${seconds}s`;
};

const TextComponent = ({ arr, joinText, appConfigData, copiedContent, copyText, remainingTime }: TextComponentProps) => {
  const { t: translate } = useTranslation();
  const [showToast, setShowToast] = useState<ShowToastType>(defaultToastState);

  const handleCopyClick = () => {
    copyToClipboard(copiedContent || copyText, 'copy');
    setShowToast({
      message: translate('copied'),
      type: ToastTypes.CHIP,
      show: true
    });
  };

  return (
    <>
       {arr.map((value, i) => {
          if (i == 0) return <span key={i} dangerouslySetInnerHTML={{ __html: value }}></span>;
          
          return (
            <React.Fragment key={i}>
              <span onClick={handleCopyClick} className="font-bold px-[3px] cursor-pointer">
                {joinText}
              </span>
              {value}
            </React.Fragment>
          );
        })}
        {!appConfigData?.data?.is_bogo_live_for_user && isTimerEnabled && remainingTime && <span className=" font-bold ml-[4px]">{`${formatTime(remainingTime)}`}</span>}
      <Toast
        message={showToast.message}
        open={showToast.show}
        type={showToast.type}
        position={Positions.BOTTOM}
        onClose={() => setShowToast(defaultToastState)}
      />
    </>
  );
};

const handleTopHeaderClick = (event, redirectUrl, pathname) => {
  if (isNonEmptyString(pathname) && pathname.includes(redirectUrl ?? '')) event.preventDefault();
};

const TopHeader = forwardRef(({ bannerData, top = '0px', showCloseIcon = true, onClick, customStyles}: TopHeaderProps, ref: any) => {
  const dispatch = useAppDispatch(),
    { text, copyText, fontSize, defaultFontSize, bgColor, textColor, copiedContent, redirectUrl } = bannerData ?? {},
    textArray = copyText ? text?.split(copyText) : text ? [text] : [],
    [remainingTime, setRemainingTime] = useState(-1),
     { app_config_data: appConfigData, showMarquee } = useAppSelector((state: RootState) => state.layout),
    textSize = fontSize ?? defaultFontSize ?? '14px',
    { pathname } = useRouter(),
    handleClose = useCallback(() => dispatch(closeMarquee()), []);

  useEffect(() => {
    if (!isTimerEnabled) return;

    if (appConfigData?.data?.is_bogo_live_for_user) {
      return;
    }
    let timestamp = getCookie('timer'),
      difference = Math.floor((Date.now() - timestamp) / 1000),
      intervalId;

    if (TIMER_DURATION - difference <= 0) {
      handleClose?.();
      return;
    }
    // set the remaining time
    setRemainingTime(TIMER_DURATION - difference);

    intervalId = setInterval(() => {
      if (remainingTime === 0) {
        clearInterval(intervalId);
        return;
      }
      setRemainingTime((prevTime) => prevTime - 1);
    }, 1000);
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        let timestamp = getCookie('timer');
        let difference = Math.floor((Date.now() - timestamp) / 1000);
        setRemainingTime(TIMER_DURATION - difference);
      }
    });
    return () => clearInterval(intervalId);
  }, [remainingTime, handleClose]);

  const renderContent = () => (
    <div
      ref={ref}
      className="fixed z-[110] w-full max-w-[450px] flex justify-center text-[12px] leading-[18px] py-[8px] px-[12px] box-border text-center text-nm_black bg-nm_yellow"
      style={{
        fontSize: textSize,
        lineHeight: textSize,
        top,
        height: remainingTime === 0 ? '0px' : 'auto',
        padding: remainingTime === 0 ? '0' : '10px 0px',
        visibility: remainingTime === 0 ? 'hidden' : 'visible',
        ...(bgColor && { backgroundColor: bgColor }),
        ...(textColor && { color: textColor }),
        ...(customStyles && customStyles)
      }}
      onClick={onClick && onClick}
    >
      <Marquee autoFill pauseOnClick>
        <div style={{ margin: '0px 30px' }}>
          <TextComponent arr={textArray ?? []} joinText={copyText} copiedContent={copiedContent} remainingTime={remainingTime} appConfigData={appConfigData} />
        </div>
      </Marquee>
      {showCloseIcon ? <CloseIconLight className='px-[12px] py-[2px]' onClick={handleClose} /> : <></>}
    </div>
  );

  if(!text || !showMarquee)
      return <></>;

  return redirectUrl && isNonEmptyString(redirectUrl) ? (
    <Link href={redirectUrl ?? ''} onClick={(event) => handleTopHeaderClick(event, redirectUrl, pathname)} className='cursor-pointer'>
      {renderContent()}
    </Link>
  ) : (
    renderContent()
  );
});

export default TopHeader;
