import { Box, Link } from '@mui/material';
import useTranslation from '@Utils/useTranslation';

import NoReturns from '@assets/icons/NoReturns.svg';
import { colors } from '@Constants/colors';
import BottomSheet from '@Components/BottomSheet';

import styles from './styles';
import { useState } from 'react';

const NoReturn = () => {
  const { t: translate } = useTranslation();
  const [openDrawer, setOpenDrawer] = useState(false);

  const linkPress = () => {
    setOpenDrawer(true);
  };

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };

  return (
    <>
      <Box sx={styles.container}>
        <NoReturns color={colors.DIM_GRAY} />
        <Link onClick={linkPress} sx={styles.text}>
          {translate('no_return_applicable')}
        </Link>
      </Box>
      <BottomSheet
        open={openDrawer}
        handleClose={handleCloseDrawer}
        bottomSheetStyle={{ padding: '32px 32px', position: 'relative' }}
        showCloseButton
      >
        <>
          <div style={styles.heading}>{translate('no_return_policy')}</div>
          <span style={styles.subText}>On special Days like <span style={styles.boldText}>(Black Friday, Buy 1 Get 1, Festive sale, Non Returnable Coupon)</span> we don’t accept returns.</span>
          <div></div>
          <span style={styles.subText}>{translate('no_return_policy_explanation_1')}</span>
          <span style={styles.boldText}>{translate('no_return_policy_explanation_2')}</span>
          <span style={styles.subText}>{translate('no_return_policy_explanation_3')}</span>
        </>
      </BottomSheet>
    </>
  );
};

export default NoReturn;
