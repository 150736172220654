import { colors } from '@Constants/colors';

const styles = {
  simpleInstallments: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    color: colors.DIM_GRAY
  }
};

export default styles;
