import { colors } from '@Constants/colors';

const styles = {
  similarProductsTitle: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    color: colors.FONT_SECONDARY,
    paddingTop: '24px',
    paddingLeft: '16px'
  },
  productName: {
    paddingLeft: '16px',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    color: colors.DIM_GRAY,
    paddingTop: '4px'
  },
  similarProductCardsListing: {
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: '3%',
    marginTop: '20px'
  }
};

export default styles;
