import { colors } from '@Constants/colors';

const styles = {
  ratingsTitle: {
    paddingTop: '24px',
    paddingBottom: '17px',
    paddingLeft: '17px',
    fontWeight: 700,
    fontSize: '16px',
    color: colors.FONT_SECONDARY
  },
  ratingIcon: {
    paddingLeft: '17px',
    paddingBottom: '16px',
    display: 'flex',
    flexDirection: 'row',
    gap: '4px'
  },
  reviewContent: {
    padding: '0px 16px',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    color: colors.FONT_SECONDARY
  },
  reviewGallery: {
    paddingLeft: '16px',
    paddingTop: '12px',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '12px',
    marginBottom: '10px'
  },
  reviewAuthor: {
    paddingLeft: '16px',
    paddingTop: '12px',
    paddingBottom: '10px',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '16px',
    color: colors.DIM_GRAY
  },
  verifiedBuyer: {
    paddingLeft: '16px',
    display: 'flex',
    flexDirection: 'row',
    fontWeight: 400,
    fontSize: '12px',
    color: colors.DIM_GRAY,
    alignItems: 'center'
  },
  verifiedBuyerText: {
    paddingLeft: '5px'
  },
  ellipse: {
    paddingLeft: '8px',
    paddingRight: '6px'
  },
  divider: {
    width: '100%',
    marginTop: '16px',
    marginBottom: '16px'
  },
  viewAllReview: {
    display: 'flex',
    justifyContent: 'end',
    color: colors.PRIMARY_DARK,
    fontWeight: 500,
    fontSize: '12px',
    marginRight: '16px',
    marginBottom: '16px',
    cursor: 'pointer'
  },
  reviewImage: {
    position: 'relative',
    color: '#fff',
    maxHeight: '100px',
    margin: '0px 2px',
    textAlign: 'center',
    backgroundColor: '#000',
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center'
  }
};

export default styles;
