import { formatDateString } from '@Utils/format';
import { useRouter } from 'next/router';
import { useContext, useState, useEffect, useCallback, memo, CSSProperties } from 'react';
import { useInView } from 'react-intersection-observer';
import { RatingReviewsContext } from '../RatingsV2';
import { TrackerContext } from '_app';
import { isNonEmptyString } from '@Utils/checks';
import RatingNew from '@assets/icons/RatingNew.svg';
import GreenTick from '@assets/icons/GreenTick.svg';
import useTranslation from '@Utils/useTranslation';
import Ellipse from '@assets/icons/Ellipse.svg';
import Rating from '@assets/icons/star.svg';
import { getTrackingEventObject } from '@Utils/common';
import { PDP_PAGE_EVENTS, GLOBAL_ACTION_TYPES } from '@Constants/events';
import { getFilledArray, getUnFilledArray, getParsedCarousalData } from '../RatingReviewSheet/RatingUtlis';
import { useAppDispatch } from '@Store/store'
import { setImgIndex, setRatingsData, setImgClickSrc } from '../RatingsV2/ratingReviewSheetSlice';
import styles from '../Ratings/styles';

const ReviewImage = ({ loading = 'lazy', customStyles = {}, src = '', alt = 'review-img', onIntersection = () => {} }) => {
    const { ref, inView } = useInView({ threshold: 0.5, triggerOnce: false, trackVisibility: false, delay: 0 });
  
    useEffect(() => {
      if (inView) onIntersection?.();
    }, [inView]);
  
    if (!isNonEmptyString(src)) return <></>;
  
    return <img className='cursor-pointer' ref={ref} loading={loading} style={customStyles} src={src} alt={alt} />;
  };

const ReviewContent = ({review, index, renderReviewGallery, reviews, translate}) => {
    return  <div className={`${index==0 ? 'mt-[8px]' : '' }`} key={`${review.review_date}${index}`}>
    {review?.review_photos && review?.review_photos.length > 0 && renderReviewGallery(review?.review_photos, index, false, review?.product_id, review)}
    <div className='flex gap-1 mt-[8px]'>
        {getFilledArray(review.rating).map((item: Key | null | undefined) => (
        <Rating key={item} />
        ))}
        {getUnFilledArray(review.rating).map((item) => (
        <RatingNew key={item} />
        ))}
    </div>
    <div className='text-[12px] leading-4 text-nm_font_secondary py-[8px] text-start'>{review.review_content}</div>
    <div className='text-[12px] leading-4 font-medium text-nm_dim_gray text-start'>{review.review_author}</div>
    <div className='flex items-center gap-2'>
        <GreenTick />
        <div className='text-[12px] leading-4 text-nm_dim_gray'>{translate('verified_buyer')}</div>
        <Ellipse />
        <div className='text-[12px] leading-4 text-nm_dim_gray my-[8px]'>{formatDateString(review.review_date)}</div>
    </div> 
    <div className='mb-[16px] mt-[4px]'>
        {isNonEmptyString(review?.size) && <div className={`text-nm_black_2 border border-solid border-nm_light_gray_7 bg-nm_white_smoke rounded-lg text-[12px] leading-4 px-[8px] py-[6px] w-fit `}>Size bought: {review.size.toUpperCase()}</div>}
    </div>

    {index + 1 !== reviews.length ? (
        <hr className=" opacity-[0.3] mb-[16px]" />
    ) : (
        <div style={{ marginBottom: '26px' }}></div>
    )}
    </div>
}

const RatingReviewSheet = ({}) => {

    const { t: translate } = useTranslation(),
      track = useContext(TrackerContext),
      router = useRouter(),
      dispatch = useAppDispatch();

    const [activeTab, setActiveTab] = useState('all_reviews');

    const {reviewsData} = useContext(RatingReviewsContext);
    
    const handleTabSwitch = useCallback((value) => {
      setActiveTab(value);
      track(getTrackingEventObject(0, PDP_PAGE_EVENTS.PDP_FEED_TYPE, value, reviewsData[0]?.parent_product_id, router.asPath));

    },[activeTab])

    const reviewsWithPictures = reviewsData?.filter(review => review.review_photos.length > 0);

    const filteredReview = reviewsWithPictures.flatMap(review => 
        review.review_photos.map(eachPhoto => ({
          author: review.review_author,
          content: review.review_content,
          photo: eachPhoto,
          id : review.product_id,
          rating: review.rating,
          date: review.review_date,
          size: review.size
        }))
      );

    const handleImageClick = (idx: number, index: number, review, imageUrlArray) => {
      const imageObjectIndex = filteredReview.findIndex( img => img.photo == imageUrlArray[idx])

      dispatch(setImgIndex(imageObjectIndex));
      dispatch(setRatingsData(filteredReview));
      dispatch(setImgClickSrc('sheet'));
      track(getTrackingEventObject(0, PDP_PAGE_EVENTS.PDP_FEED_TYPE, PDP_PAGE_EVENTS.ALL_REVIEWS_SHEET_IMAGE_OPEN, reviewsData[0].parent_product_id, GLOBAL_ACTION_TYPES.CLICK , { meta_data: { index: index, activeTab:activeTab} }));
    };

    function renderReviewGallery(photos: Array<string>, index, checkCustomerImage = false, productId, review) {
        const image_arr = photos;
    
        return (
          <div className='flex flex-wrap gap-2'>
            <div style={{ display: 'flex', position: 'relative' }}>
              {image_arr && image_arr?.length > 1 ? (
                image_arr.map((img, idx) => {
                  const handleOnClick = () => {
                    handleImageClick(idx, index, review, image_arr);
                  };
    
                  return idx < 2 || checkCustomerImage ? (
                    <div onClick={handleOnClick}  key={idx} style={{ height: '100px', margin: '0px 2px' }}>
                      <ReviewImage
                        loading="lazy"
                        src={img}
                        alt="product"
                        customStyles={{ width: '76px', height: '94px', objectFit: 'cover', borderRadius: '5px' }}
                        idx={idx}
                        productId={productId}
                        reviewId={index}
                      />
                    </div>
                  ) : idx == 2 ? (
                    <div onClick={handleOnClick} style={styles.reviewImage as CSSProperties}>
                      <ReviewImage
                        loading="lazy"
                        src={img}
                        alt="product"
                        customStyles={{width: '76px', height: '94px', objectFit: 'cover', borderRadius: '5px', opacity: image_arr.length > 3 ? '0.6' : 1 }}
                        idx={idx}
                        productId={productId}
                        reviewId={index}
                      />
                      {image_arr.length > 3 && (
                        <div
                          style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            fontSize: '30px'
                          }}
                        >{`+${image_arr.length - 2}`}</div>
                      )}
                    </div>
                  ) : (
                    <></>
                  );
                })
              ) : (
                <div
                  onClick={() => {handleImageClick(0, index, review, image_arr)}}
                  style={{ display: 'flex', alignItems: 'middle', justifyContent: 'center', maxHeight: '100px' }}
                >
                  <ReviewImage
                    loading="lazy"
                    src={image_arr?.[0]}
                    alt="product"
                    customStyles={{ width: '76px', height: '94px', objectFit: 'cover', borderRadius: '5px' }}
                    idx={0}
                    productId={productId}
                    reviewId={index}
                  />
                </div>
              )}
            </div>
          </div>
        );
      }

    return (
        <div>

            <div className="text-[20px] leading-7 font-bold px-[16px] py-[24px] text-start">
                Reviews {`(${reviewsData?.length})`}
            </div>

            <div className='grid grid-cols-2 gap-[10px] px-[16px] pt-[8px]'>
                <div className={`${activeTab === 'all_reviews' ? 'text-nm_dark_green_2 border-solid border-b-2  border-t-0 border-l-0 border-r-0 border-nm_dark_green_2 ': 'nm_black_2' } cursor-pointer text-[14px] pb-[20px] leading-5 font-bold`} onClick={() => handleTabSwitch("all_reviews")}>{translate('all_reviews')}</div>
                <div className={`${activeTab === 'review_img' ? 'text-nm_dark_green_2 border-b-2 border-solid border-t-0 border-l-0 border-r-0 border-nm_dark_green_2 ': 'nm_black_2' } cursor-pointer text-[14px] leading-5 pb-[20px] font-bold`} onClick={() => handleTabSwitch("review_img")}>{translate('reviews_with_images')}</div>
            </div>
            <hr className='opacity-[0.3] mt-0' />

            { activeTab === 'all_reviews' ?
            <div className='px-[16px] max-h-[60vh] overflow-y-scroll hideScrollBar pb-[54px]'>
                {
                    reviewsData?.map((review, index) =>{
                        return <>
                        <ReviewContent
                        review={review}
                        index={index}
                        renderReviewGallery={renderReviewGallery}
                        reviews={reviewsData}
                        translate={translate}
                        />
                        </>
                    })
                }
            </div>
            :
            <div className='px-[16px] max-h-[60vh] overflow-y-scroll hideScrollBar pb-[54px]'>
                {
                    reviewsData?.map((review, index) =>{
                        if(review.review_photos.length > 0)
                            return <>
                            <ReviewContent
                            review={review} 
                            index={index} 
                            renderReviewGallery={renderReviewGallery} 
                            reviews={reviewsData}
                            translate={translate}
                            />
                        </>
                    })
                }
            </div>
            }
        </div>
    )
}

export default memo(RatingReviewSheet);