import { Dialog, DialogContent } from '@Components/shadcn/ui/dialog';

const CustomDialog = ({
  children,
  open,
  onClose,
  setOpen,
  isBottomSheet = false,
  showCloseButton = false,
  customCloseIcon,
  customCloseIconStyles = {},
  customStyles = {},
  isnewReviewBottomSheet = false,
  ...props
}) => {
  return (
    <Dialog open={open} onClose={onClose} onOpenChange={setOpen}>
      <DialogContent className={`bg-[transparent] border-none`} removeCloseIcon isBottomSheet={isBottomSheet} style={{
            maxHeight: 'calc(100% - 50px)',
            ...(isnewReviewBottomSheet && {maxHeight: '100%' } ),
            ...(customStyles && customStyles),
          }} {...props}>
        {showCloseButton &&
          (customCloseIcon ? (
            <div className='w-full max-w-[450px] border-box mb-[2px] text-right'>
              <button onClick={onClose} className={'customButton customButton--noScale customButton--transparent px-[16px] ' + (!isBottomSheet && ' px-[23px]')} style={customCloseIconStyles}>
                {customCloseIcon}
              </button>
            </div>
          ) : (
            <div
              onClick={onClose}
              className="rounded-full bg-nm_white text-[10px] p-[2px] h-[18px] w-[18px] flex items-center justify-center absolute right-[15px] top-[-35px] customButton customButton--noScale"
            >
              &#10005;
            </div>
          ))}
        {children}
      </DialogContent>
    </Dialog>
  );
};

export default CustomDialog;
