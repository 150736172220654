import React, { useEffect, useState } from 'react';
import HourGlassIcon from '@assets/icons/HourGlassIcon.svg';
import { parseMilliSecondsV2 } from '@Utils/common';
import { useCountdownTimer } from '@Hooks/useCountdownTimer';

const renderTimer = (time: string, width: number, charLength: number, fontSize: number, separator?: boolean, prefix?: string, customStyles?: Object) => {
  return (
    <>
      {prefix && (
        <span className="ml-[1px] pr-[0.75px]" style={{ width: width / 2 + 1.75, fontSize: fontSize, ...(customStyles && customStyles) }}>
          {prefix}
        </span>
      )}
      <div className={'flex mx-[2px] pt-[0.5px]'} style={{ width: width * charLength, fontSize: fontSize, ...(customStyles && customStyles) }}>
        {time.split('').map((item) => (
          <div className={''} style={{ minWidth: width }}>
            {item}
          </div>
        ))}
      </div>
      {separator && <span style={{ width: width / 2, fontSize: fontSize, ...(customStyles && customStyles) }}>{':'}</span>}
    </>
  );
};

const CountdownTimer = ({ expiry, timerOnly = false }) => {
  const [milliSecsLeft, setMilliSecsLeft] = useState(0),
    { days, hours, minutes } = parseMilliSecondsV2({ milliSecs: milliSecsLeft, maxTimeUnit: 'days' });

  useCountdownTimer(
    () => {
      if (!milliSecsLeft && milliSecsLeft <= 0) return;

      setMilliSecsLeft((prevMilliSecLeft) => prevMilliSecLeft - 1000);
    },
    milliSecsLeft && milliSecsLeft > 0 ? 1000 : null,
    milliSecsLeft,
    setMilliSecsLeft
  );

  useEffect(() => {
    if (expiry && expiry > 0) setMilliSecsLeft(expiry);
  }, [expiry]);

  const TimerModule = () => (
    <span className={timerOnly ? 'flex gap-[2px] items-center justify-center' : ''}>
      <div className="text-nm_danger_red text-[10px] font[500] leading-[14px] flex items-center justify-center">
        <HourGlassIcon /> {timerOnly ? '' : 'EXPIRING SOON'}
      </div>
      {!timerOnly && <hr className="mt-[4px] mb-[6px] border-solid border-[0.5px] border-nm_light_gray_7" />}
      <div className="flex justify-center text-[10px] font-[400] leading-[12px]">
        {renderTimer(days, 8, days.length, 12, false, undefined, { color: '#1A1A1A', fontWeight: 600, margin: 0 })}
        {renderTimer('D', 3.5, days.length, 10, false, undefined, { color: '#6A6A6A', fontWeight: 400 })}
        {renderTimer(hours, 8, 2, 12, false, ':', { color: '#1A1A1A', fontWeight: 600, margin: 0 })}
        {renderTimer('H', 3.5, days.length, 10, false, undefined, { color: '#6A6A6A', fontWeight: 400 })}
        {renderTimer(minutes, 8, 2, 12, false, ':', { color: '#1A1A1A', fontWeight: 600, margin: 0 })}
        {renderTimer('M', 3.5, days.length, 10, false, undefined, { color: '#6A6A6A', fontWeight: 400 })}
      </div>
    </span>
  );

  return timerOnly ? (
    <TimerModule />
  ) : (
    <div className="rounded-[8px] border-solid border-[1px] border-nm_light_gray_7 px-[12px] py-[4px] w-fit">
      {expiry && expiry > 0 && milliSecsLeft > 0 ? (
        <TimerModule />
      ) : (
        <div className="text-nm_danger_red text-[10px] font[500] leading-[14px] flex items-center justify-center">EXPIRED</div>
      )}
    </div>
  );
};

export default CountdownTimer;
