import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState = {
  app_config_data: {},
  showMarquee: true,
  showAppDownloadBanner: true,
};

export const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    setAppConfigData: (state, action: PayloadAction<any>) => {
      state.app_config_data = action.payload;
    },
    closeMarquee: (state) => {
      state.showMarquee = false;
    },
    closeAppDownloadBanner: (state) => {
      state.showAppDownloadBanner = false;
    },
    resetBannerState: (state) => {
      state.showMarquee = true;
      state.showAppDownloadBanner = true;
    },
    resetLayoutSlice: () => initialState
  }
});

export const { setAppConfigData, closeMarquee, closeAppDownloadBanner, resetBannerState, resetLayoutSlice } = layoutSlice.actions;
export default layoutSlice.reducer;