import { colors } from '@Constants/common';

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '10px',
    padding: '6px 8px',
    borderRadius: '4px',
    color: colors.DIM_GRAY,
    backgroundColor: colors.WHITE_SMOKE_2,
    cursor: 'pointer'
  },
  select: {
    fontWeight: 500,
    fontSize: '12px',
    border: 'none',
    display: 'inline-block',
    margin: '0px 4px',
    color: colors.FONT_SECONDARY,
    backgroundColor: colors.WHITE_SMOKE_2
  }
};

export default styles;
