
const styles = {
    fullScreenImage: {
      width: '100vw',
      alignItems: 'start',
      justifyContent: 'center',
      zIndex: 1000,
      maxWidth:'450px', 
      boxSizing:'border-box', 
      display:'block', 
      overflowY: 'scroll', 
      overflowX: 'hidden', 
      scrollbarWidth: 'none', 
      height: '100%',
      maxHeight: '100%'
    },
    image: {
      height: '70vh',
      width: '100%',
      maxWidth: '450px',
      objectFit: 'cover',
      margin: 'auto 0'
    },
  };
  
  export default styles;
  