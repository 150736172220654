import { colors } from '@Constants/common';

const styles = {
  container: {
    backgroundColor: colors.PRIMARY_LIGHT_2,
    display: 'flex',
    gap: '6px',
    alignItems: 'center',
    cursor: 'pointer'
  },
  helpText: {
    color: colors.PRIMARY_DARK,
    fontSize: '12px',
    fontWeight: '500'
  }
};

export default styles;
