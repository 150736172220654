import { OrderDetails } from '@Containers/Orders/type';
import { ProductProps } from '@Containers/Shop/type';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface ScrollData {
  ordersPageNumber: number;
  shopPageNumber: number;
  shopPageScrollTop: number;
  ordersPageScrollTop: number;
  cachedOrders: OrderDetails[];
  cachedShopProducts: ProductProps[];
  totalShopPages: number;
  totalOrdersPages: number;
  shouldOrdersScroll: boolean;
  cachedShopPageData: Object | null;
}

const initialState = (): ScrollData => {
  return {
    ordersPageNumber: 1,
    shopPageNumber: 0,
    shopPageScrollTop: 0,
    ordersPageScrollTop: 0,
    cachedOrders: [],
    cachedShopProducts: [],
    totalShopPages: 0,
    totalOrdersPages: 1,
    shouldOrdersScroll: false,
    cachedShopPageData: {},
  };
};

export const scrollSlice = createSlice({
  name: 'scroll',
  initialState: initialState(),
  reducers: {
    setOrdersPageNumber: (state, action: PayloadAction<number>) => {
      state.ordersPageNumber = action.payload;
    },
    setCachedOrders: (state, action: PayloadAction<Array<OrderDetails> | []>) => {
      state.cachedOrders = action.payload;
    },
    setShopPageScrollTop: (state, action: PayloadAction<number>) => {
      state.shopPageScrollTop = action.payload;
    },
    setOrdersPageScrollTop: (state, action: PayloadAction<number>) => {
      state.ordersPageScrollTop = action.payload;
    },
    setCachedShopProducts: (state, action: PayloadAction<Array<ProductProps> | []>) => {
      state.cachedShopProducts = action.payload;
    },
    setCachedShopPageData: (state, action: PayloadAction<Object | null>) => {
      state.cachedShopPageData = action.payload;
    },
    setShopPageNumber: (state, action: PayloadAction<number>) => {
      state.shopPageNumber = action.payload;
    },
    setShopTotalPageNumber: (state, action: PayloadAction<number>) => {
      state.totalShopPages = action.payload;
    },
    setOrdersTotalPageNumber: (state, action: PayloadAction<number>) => {
      state.totalOrdersPages = action.payload;
    },
    resetOrdersScroll: (state) => {
      state.ordersPageNumber = 1;
      state.totalOrdersPages = 1;
      state.ordersPageScrollTop = 0;
      state.cachedOrders = [];
    },
    resetShopScroll: (state) => {
      state.shopPageScrollTop = 0;
      state.cachedShopProducts = [];
      state.shopPageNumber = 0;
      state.totalShopPages = 0;
    },
    setShouldOrdersScroll: (state, action: PayloadAction<boolean>) => {
      state.shouldOrdersScroll = action.payload;
    }
  }
});

export const {
  setOrdersPageNumber,
  setOrdersTotalPageNumber,
  setShopPageScrollTop,
  setCachedShopProducts,
  setCachedShopPageData,
  setShopPageNumber,
  setShopTotalPageNumber,
  resetOrdersScroll,
  setShouldOrdersScroll,
  resetShopScroll,
  setCachedOrders,
  setOrdersPageScrollTop
} = scrollSlice.actions;

export default scrollSlice.reducer;