import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { StoreData } from './types';

const initialState: StoreData = {
  store_data: []
};

export const storesSlice = createSlice({
  name: 'stores',
  initialState,
  reducers: {
    setStoreData: (state, action: PayloadAction<StoreData>) => {
      state.store_data = action.payload;
    },
    resetStoresSlice: () => initialState
  }
});

export const { setStoreData, resetStoresSlice } = storesSlice.actions;
export default storesSlice.reducer;
