import { colors } from '@Constants/common';
import useTranslation from '@Utils/useTranslation';

import styles from './styles';
import { CSSProperties } from 'react';

const FooterAddress: React.FC<{ isBackgroundDark?: boolean }> = ({ isBackgroundDark = false }) => {
  const { t: translate } = useTranslation();

  return (
    <div style={{ ...styles.addressSection, ...(isBackgroundDark && { backgroundColor: colors.WHITE }) } as CSSProperties}>
      <div style={{ ...styles.addressTitle, ...(isBackgroundDark && { color: colors.PRIMARY }) }}>{translate('address_title')}</div>
      <div style={styles.address}>{translate('address_text')}</div>
    </div>
  );
};

export default FooterAddress;
