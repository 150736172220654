import { colors } from '@Constants/common';

const styles = {
  contentWrapper: {
    padding: '12px 16px',
    height: '70vh',
    overflow: 'auto'
  },
  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  sizeGuideText: {
    fontSize: '18px',
    fontWeight: '500'
  },
  subHeaderText: {
    fontSize: '16px',
    fontWeight: '500'
  },
  divider: {
    height: '1px',
    marginTop: '12px',
    backgroundColor: colors.NEUTRAL_GRAY
  },
  switchWrapper: {
    display: 'flex',
    gap: '8px'
  },
  switchLabel: {
    fontSize: '12px',
    fontWeight: '400',
    color: colors.DIM_GRAY
  },
  switchOuter: {
    display: 'flex',
    alignItems: 'center',
    width: '40px',
    height: '20px',
    borderRadius: '16px',
    cursor: 'pointer'
  },
  slider: {
    position: 'absolute',
    cursor: 'pointer',
    top: '2px',
    width: '16px',
    height: '16px',
    borderRadius: '50%',
    backgroundColor: colors.WHITE,
    transition: '0.2s'
  },
  tabOuter: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '32px',
    borderRadius: '8px',
    cursor: 'pointer',
    margin: '18px 0px',
    backgroundColor: colors.WHITE_SMOKE_2
  },
  tab: {
    position: 'absolute',
    cursor: 'pointer',
    top: '2px',
    width: '50%',
    height: '28px',
    borderRadius: '8px',
    backgroundColor: colors.PRIMARY_DARK,
    transition: '0.2s',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  tabLabel: {
    fontSize: '12px',
    fontWeight: '400'
  },
  inactiveTab: {
    width: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  sizeChartImageWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    margin: '24px 0px'
  },
  description: {
    fontSize: '14px',
    fontWeight: '400',
    color: colors.DIM_GRAY,
    marginTop: '8px',
    marginBottom: '24px'
  },
  subTitle: {
    fontSize: '16px',
    color: colors.BLACK,
    fontWeight: '500',
    margin: '16px 0px'
  },
  stepContainer: {
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderTop: '1px solid',
    borderColor: colors.NEUTRAL_GRAY,
    marginBottom: '32px',
    position: 'relative',
    width: '100%'
  },
  step: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    position: 'relative'
  },
  stepSelected: {
    alignItems: 'center'
  },
  stepSelectedRight: {
    alignItems: 'flex-end'
  },
  stepSelectedLeft: {
    alignItems: 'flex-start'
  },
  stepActive: {
    width: '40px',
    position: 'absolute',
    height: '8px',
    borderRadius: '10px',
    backgroundColor: colors.PRIMARY_DARK,
    top: '-5px',
    overflow: 'hidden'
  },
  stepText: {
    fontSize: '12px',
    lineHeight: '16px',
    color: colors.DIM_GRAY,
    marginTop: '20px'
  },
  stepTextActive: {
    fontSize: '12px',
    lineHeight: '16px',
    color: colors.BLACK,
    marginTop: '20px'
  },
  borderStyle: {
    borderRadius: '20px'
  },
  tableContainer: {
    borderRadius: '8px',
    border: '1px solid #E6E6E6',
    marginBottom: '32px',
    '&.MuiTableContainer-root::-webkit-scrollbar': {
      height: '4px'
    }
  },
  tableHead: {
    backgroundColor: colors.PRIMARY_DARK,
    color: colors.WHITE,
    borderRight: '1px solid',
    borderColor: colors.WHITE
  },
  tableData: {
    backgroundColor: colors.WHITE
  },
  tableDataGrey: {
    backgroundColor: colors.WHITE_SMOKE
  },
  tableHeadText: {
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: '500',
    color: colors.WHITE,
    borderRight: '1px solid',
    padding: '12px',
    borderBottom: 'none',
    textAlign: 'center'
  },
  tableText: {
    fontSize: '12px',
    padding: '12px',
    borderRight: '1px solid #E6E6E6',
    borderBottom: 'none',
    textAlign: 'center'
  }
};

export default styles;
