import { ENV, WEB_URL } from "@Constants/config";
// import Head from 'next/head';
import { useRouter } from "next/router";
import React from "react";

const staticPages = [
  "/about-us",
  "/privacy-policy",
  "/returns-and-exchange",
  "/terms-and-conditions",
  "/about-us",
];

const CanonicalHead = () => {
  const router = useRouter();
  let link;
  if (
    router.pathname == "/womens-collection/" ||
    router.pathname == "/womens-collection" ||
    router.pathname == "/collections"
  ) {
    link = `${WEB_URL}womens-collection`;
  } else if (router.pathname.includes("/womens-collection")) {
    link = `${WEB_URL}${
      router.asPath.split("?")?.[0]?.substring(1) ?? "womens-collection"
    }`;
  } else if (router.pathname.includes("/product/")) {
    link = `${WEB_URL}${router.asPath.split("?")?.[0]?.substring(1)}`;
  } else {
    const path = router.pathname.split("?")?.[0];
    if (staticPages.includes(path)) {
      link = `${WEB_URL}${path.split("/")?.[1]}`;
    }
  }

  let isNoIndex;
  if (router.asPath.includes("/womens-collection/cardigans")) {
    isNoIndex = true;
  }

  return (
    <></>
    // <Head key={'canonical'}>
    //   {ENV === 'stage' && <meta name="robots" content="noindex"></meta>}
    //   {link && <link rel="canonical" href={link} />}
    //   {isNoIndex && <meta name="robots" content="noindex"></meta>}
    // </Head>
  );
};

export default CanonicalHead;
