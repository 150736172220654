import { useRef, useState } from 'react';
import useTranslation from '@Utils/useTranslation';
import { Box } from '@mui/material';

import { CarouselDataItem } from '@Components/CustomCarousel/types';

import { BogoProductsCarouselProps } from './types';
import styles from './styles';

const BogoProductsCarousel = ({ bogoData, cartData }: BogoProductsCarouselProps) => {
  const { t: translate } = useTranslation();

  const containerRef = useRef<HTMLInputElement>(null);
  const cardRef = useRef<HTMLInputElement>(null);

  const [selectedCard, setSelectedCard] = useState('card1');

  const handleMouseDown = (event: { clientX: any }) => {
    const container = containerRef.current;
    const startX = event.clientX;
    const scrollLeft = container?.scrollLeft ?? 0;

    const handleMouseMove = (event: { clientX: number }) => {
      const x = event.clientX - startX;

      if (container !== null) container.scrollLeft = scrollLeft - 3 * x;
    };

    document.addEventListener('mousemove', handleMouseMove);

    document.addEventListener('mouseup', () => {
      document.removeEventListener('mousemove', handleMouseMove);
    });
  };

  const handleScroll = () => {
    const cardWidth = document.getElementById('card1')?.clientWidth;
    let cardNumber = 0;

    if (containerRef?.current && cardWidth) {
      cardNumber =
        Object.keys(bogoData)?.length -
        Math.round((containerRef?.current?.scrollWidth - containerRef?.current?.scrollLeft) / cardWidth);
      if (selectedCard !== `card${cardNumber + 1}`) setSelectedCard(`card${cardNumber + 1}`);
    }
  };

  const customRenderItem = (item: CarouselDataItem) => {
    const { imageUrl = '', quantity = 0, title = '' } = item;

    return (
      <Box sx={styles.bogoCard}>
        <Box sx={styles.bogoCardContent}>
          <img
            draggable={false}
            src={imageUrl}
            alt='carousel-images'
            style={{ ...styles.bogoImage, ...{ objectFit: 'cover' } }}
          />
          <Box sx={styles.bogoDetails}>
            <div style={styles.bogoDescription}>{title}</div>
            <div style={styles.bogoQty}>{`${translate('qty')}: ${quantity}`}</div>
          </Box>
        </Box>
      </Box>
    );
  };

  const renderIndicator = (data: (string | number)[]) => {
    if (data?.length > 1)
      return (
        <Box sx={styles.indicatorStyle}>
          {data?.map((bogoProduct: string | number, index) => {
            return (
              <Box
                key={bogoProduct}
                sx={[
                  { backgroundColor: selectedCard === `card${index + 1}` ? '#39B277' : '#E6E6E6' },
                  styles.indicatorCircle
                ]}
              />
            );
          })}
        </Box>
      );
  };

  const getParsedCarouselData = (data: (string | number)[]) => {
    return (
      <Box sx={styles.carouselData} ref={containerRef} onMouseDown={handleMouseDown} onScroll={handleScroll}>
        {data?.map((bogoProduct: string | number, index) => (
          <div
            id={`card${index + 1}`}
            ref={cardRef}
            key={cartData?.[bogoProduct]?.product_id}
            style={index + 1 === data?.length ? { minWidth: '100%' } : { minWidth: '85%' }}
          >
            {customRenderItem({
              imageUrl: cartData?.[bogoProduct]?.thumb_url,
              title: cartData?.[bogoProduct]?.title,
              productId: cartData?.[bogoProduct]?.product_id,
              quantity: bogoData?.[bogoProduct],
              slug: cartData?.[bogoProduct]?.slug
            })}
          </div>
        ))}
      </Box>
    );
  };

  return (
    <>
      {getParsedCarouselData(Object.keys(bogoData))}
      {renderIndicator(Object.keys(bogoData))}
    </>
  );
};

export default BogoProductsCarousel;
