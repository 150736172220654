import React, { memo } from 'react';
import styles from '../../styles';

const ProductModal = ({ product }) => {
  const modelDetails = product?.model_details;
  
  return (
    !!Object.keys(modelDetails ?? {}).length && (
      <div style={styles.modelInfoContainer}>
        {modelDetails?.title && <div style={styles.modelTitle}>{modelDetails?.title}</div>}
        <div style={styles.modelBody}>
          <img style={styles.modelImage} src={product?.image_url} alt="model-image" />
          <div style={styles.modelDataBody}>
            {Object.entries(modelDetails).map(([key, value]) => {
              if (key == 'title') {
                return null;
              }
              return (
                <div style={styles.modelDataContainer}>
                  <div style={styles.modelData}>
                    {key.charAt(0).toUpperCase() + key.slice(1)}: {value?.toString()}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    )
  );
};

export default memo(ProductModal);
