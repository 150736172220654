import { FC, useState, useRef, useEffect, CSSProperties, useContext } from 'react';
import { useRouter } from 'next/router';
// import videojs from 'video.js';
import "video.js/dist/video-js.css";

import { CarouselDataItem } from "@Components/CustomCarousel/types";
// import { VideoJS } from "@Components/VideoJS"; // RETAINED CODE: NOT USED
import VideoDot from "@assets/icons/videoDot.svg";
import { colors } from "@Constants/colors";
import CustomCarousel from "@Components/CustomCarousel";

import { ProductDetailsPageCarouselProps } from "./types";
import FullScreenImageCarousel from "../FullScreenImageCarousel";
import styles from "./styles";
import CustomDialog from "@Components/CustomModal";
import useModalLocationHash from "@Hooks/useLocationModalHash";
import { isEmptyString } from "@Utils/checks";
import { getProductImageSizeSet, getProductImageSrcSet } from "@Components/ProductCard/utils";
import ProgressiveImage from "@Components/ProgressiveImage";
import { getTrackingEventObject } from '@Utils/common';
import { TrackerContext } from '_app';
import { IMG_LOAD_FAILURE_EVENTS, PDP_PAGE_EVENTS, RECOMMENDED_PRODUCT_EVENTS } from '@Constants/events';
import { RootState, useAppSelector, } from '@Store/store';

const ProductDetailsCarousel: FC<ProductDetailsPageCarouselProps> = ({ carouselData, carouselVideoData, imageMetadata, productId }) => {
  const track = useContext(TrackerContext);
  const router = useRouter();
  const { bottom_sheet_open: configKey } = useAppSelector((state: RootState) => state.recommedationSheet);
  const [showFullScreenImage, setShowFullScreenImage] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const videoRef = useRef(null);
  let videoIndex = -1;
  const { handleLocationHashOnOpen, handleLocationHashOnClose } = useModalLocationHash({ router: router, pathname: router.pathname, hash: '#fullScreenImg', openModal: ()=>{}, closeModal: ()=>setShowFullScreenImage(false)});
  
  const handleCarouselItemClick = (index: number) => {
    setShowFullScreenImage(true);
    setSelectedIndex(index);
    handleLocationHashOnOpen?.();
    if(configKey === 'initial')
      track(getTrackingEventObject(0, RECOMMENDED_PRODUCT_EVENTS.FEED_TYPE,  RECOMMENDED_PRODUCT_EVENTS.IMAGE_OPEN_SHEET_CLICK, productId, router.asPath,));
    
    else
      track(getTrackingEventObject(0, PDP_PAGE_EVENTS.PDP_FEED_TYPE,  PDP_PAGE_EVENTS.IMAGE_OPEN_CLICK, productId, router.asPath,));
    setCurrentIndex(index);
  };

  const handleFullScreenImageClose = () => {
    setShowFullScreenImage(false);
    handleLocationHashOnClose?.();
  };

  const customRenderItem = (item: CarouselDataItem, index: number) => {
    const { type: itemType, url: itemUrl } = item ?? {};

    if (itemType == "video") {
      videoIndex = index;
    }

    const relIndex = index === 0 || itemType != 'image' ? index : index - carouselVideoData.length,
          imgSrcSet = getProductImageSrcSet(imageMetadata?.[relIndex]),
          imgSizeSet = getProductImageSizeSet(imageMetadata?.[relIndex]);

    const handleImgLoadFailure = () => {
      track(
        getTrackingEventObject(index, PDP_PAGE_EVENTS.PDP_FEED_TYPE, IMG_LOAD_FAILURE_EVENTS.PDP_CAROUSEL, productId, router.asPath, {
          meta: { src: itemUrl, srcSet: imgSrcSet }
        })
      );
    };

    // RETAINED CODE: NOT USED
    // const videoJsOptions = {
    //   autoplay: "muted",
    //   controls: false,
    //   loop: true,
    //   fluid: true,
    //   sources: [
    //     {
    //       src: item.url,
    //     },
    //   ],
    // };

    // RETAINED CODE: NOT USED
    // const handlePlayerReady = (player) => {
    //   player.on("click", (e) => {
    //     if (e.pointerType === "mouse") {
    //       if (player.isFullscreen()) {
    //         player.exitFullscreen();
    //         player.muted(true);
    //       } else {
    //         player.requestFullscreen();
    //         player.muted(false);
    //       }
    //     }
    //   });
    //   player.on("touchstart", (e) => {
    //     player.on("touchend", (e) => {
    //       player.off("touchend");

    //       if (player.isFullscreen()) {
    //         player.exitFullscreen();
    //         player.muted(true);
    //       } else {
    //         player.requestFullscreen();
    //         player.muted(false);
    //       }
    //     });
    //     player.on("touchmove", (e) => {
    //       player.off("touchend");
    //       player.off("touchmove");
    //     });
    //   });
    // };

    return (
      <div
        onClick={() => {
          itemType == "video"
            ? ""
            : handleCarouselItemClick(
                index >= carouselVideoData.length
                  ? index - carouselVideoData.length
                  : index
              );
        }}
        style={{
          width: "95%",
          height: "390px",
          position: "relative" 
        }}
      >
        {itemType === "image"?
            <ProgressiveImage 
              loading={index < 2 ? 'eager' : 'lazy'}
              src={isEmptyString(imgSrcSet) || isEmptyString(imgSizeSet) ? itemUrl : undefined} 
              alt='carousel-images' 
              style={{ width: '100%', height: '100%', objectFit: 'cover' }} 
              srcSet={imgSrcSet}
              sizes={imgSizeSet}
              handleImgLoadFailure={handleImgLoadFailure}
            />
        :
        <video
          ref={videoRef}
          autoPlay
          muted
          loop
          style={{ width: '100%', height: '390px', objectFit:'cover' }} // This makes the video fluid
          // onLoadedMetadata={handlePlayerReady}
          onClick={()=>{}}
          // playsInline added to avoid full screen
          playsInline
        >
          <source src={item.url} />
          Your browser does not support the video tag.
        </video>
        }
        
      </div>
    );
  };
  let carousel_data = [...carouselData]
  carousel_data.splice(1, 0, ...carouselVideoData);

  const customDotRenderer = () => (
    <div style={styles.customDotsContainer as CSSProperties}>
      {Array.from({ length: carousel_data?.length }).map((_, index) =>
        carousel_data[index].type === "video" ? (
          <VideoDot
            color={
              index === currentIndex ? colors.PRIMARY : colors.LIGHT_SILVER
            }
          />
        ) : (
          <div
            key={carousel_data[index].url}
            style={{
              ...styles.customDot,
              ...(index === currentIndex && styles.activeCustomDot),
            }}
          />
        )
      )}
    </div>
  );

  const onCarouselImageChange = (index: number) => {
    if(configKey === 'initial')
      track(getTrackingEventObject(0, RECOMMENDED_PRODUCT_EVENTS.FEED_TYPE,  RECOMMENDED_PRODUCT_EVENTS.IMAGE_CAROUSEL_SHEET_CLICK, productId, router.asPath,));
    
    else
      track(getTrackingEventObject(0, PDP_PAGE_EVENTS.PDP_FEED_TYPE,  PDP_PAGE_EVENTS.IMAGE_CAROUSEL_CLICK, productId, router.asPath,));
    setCurrentIndex(index);
  };
  useEffect(() => {
    try {
      if (videoIndex === currentIndex && videoRef.current) {
        // videoRef.current.playsInline = true;
        videoRef.current.play();
      }else if(videoRef.current){
          videoRef.current.pause();
        }
    } catch (e) {}
  }, [currentIndex]);

  return (
    <>
      <CustomCarousel
        renderItem={customRenderItem}
        carouselData={carousel_data}
        autoPlay={false}
        showIndicator={false}
        centerMode={true}
        showArrows={true}
        onChange={onCarouselImageChange}
      />
      {customDotRenderer()}
      {/* Full screen image carousel */}
      {showFullScreenImage && (
        <CustomDialog open={showFullScreenImage} onClose={handleFullScreenImageClose} setOpen={handleFullScreenImageClose}>
          <FullScreenImageCarousel
            carouselData={carouselData}
            onClose={handleFullScreenImageClose}
            selectedIndex={selectedIndex}
          />
        </CustomDialog>
      )}
    </>
  );
};

export default ProductDetailsCarousel;
