import { colors } from '@Constants/colors';

const styles = {
  containerWishlist: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginTop: '24px',
  },
  productTitle: {
    fontWeight: 400,
    fontSize: '16px',
    color: colors.DIM_GRAY,
    width: '80%',
    margin: 0,
  },
  bottomSheetpProductTitle: {
    fontWeight: 400,
    fontSize: '16px',
    color: colors.DIM_GRAY,
    margin: 0,
  },
  productPrice: {
    paddingTop: '16px',
    paddingLeft: '16px',
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    alignItems: 'center'
  },
  productNewPrice: {
    fontWeight: 500,
    fontSize: '18px',
    color: colors.PRIMARY_DARK,
    lineHeight: '24px'
  },
  productOldPrice: {
    fontWeight: 400,
    fontSize: '14px',
    color: colors.DIM_GRAY,
    lineHeight: '20px'
  },
  productDiscount: {
    fontWeight: 500,
    fontSize: '14px',
    color: colors.GREEN_CYAN,
    lineHeight: '20px'
  },
  simpleInstallments: {
    padding: '12px 16px 24px 16px'
  },
  divider: {
    marginTop: '24px',
    marginBottom: '24px',
    height: '4px',
    backgroundColor: colors.LIGHT_GRAY,
    borderColor: colors.LIGHT_GRAY
  },
  visibleProductSku: {
    paddingLeft: '16px',
    paddingTop: '8px',
    fontWeight: 200,
    fontSize: '12px',
    color: colors.DIM_GRAY,
    width: '80%'
  },
  hiddenProductSku: {
    paddingLeft: '16px',
    paddingTop: '8px',
    fontWeight: 200,
    fontSize: '12px',
    color: colors.DIM_GRAY,
    width: '80%',
    display: 'none'
  },
  flex: {
    display: 'flex',
  },
  offerTag: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#31B275',
    color: "#FFF",
    fontSize: "11px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    paddingLeft: '4px',
    paddingRight: '4px'
  },
  offerTagRibbon: {
    borderTop: '9px solid #31B27599',
    borderLeft: '2.5px solid #31B275',
    borderBottom: '9px solid #31B27599',
    borderRight: '2.5px solid white',
    marginRight: '3px'
  }
};

export default styles;
