import { Box } from '@mui/material';
import useTranslation from '@Utils/useTranslation';

import WhatsappHelp from '@assets/icons/WhatsappHelp.svg';
import { getWhatsAppLink, showHelpButton } from '@Utils/common';

import styles from './styles';

const HelpButton = ({ helpText }: { helpText: string }) => {
  const { t: translate } = useTranslation();
  const showWhatsappHelp = showHelpButton();

  const handleHelpButtonPress = () => {
    const whatsappLink = getWhatsAppLink(helpText);

    window.open(whatsappLink, '_blank');
  };

  return showWhatsappHelp ? (
    <Box onClick={handleHelpButtonPress} borderRadius={2} px={1} py={0.75} sx={styles.container}>
      <WhatsappHelp />
      <Box sx={styles.helpText}>{translate('help')}</Box>
    </Box>
  ) : (
    <Box />
  );
};

export default HelpButton;
