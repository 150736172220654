import { colors } from '@Constants/common';

const styles = {
  quantitySelectorTitle: {
    paddingLeft: '17px',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    color: colors.FONT_SECONDARY
  },
  quantityCards: {
    paddingLeft: '16px',
    paddingTop: '20px',
    display: 'flex',
    gap: '20px',
    alignItems: 'center'
  },
  quantityCard: {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    backgroundColor: colors.WHITE_SMOKE_2,
    display: 'flex',
    gap: '2px',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer'
  },
  quantityCardText: {
    fontWeight: 500,
    fontSize: '12px',
    color: colors.FONT_SECONDARY
  },
  disabled: {
    color: colors.LIGHT_SILVER
  }
};

export default styles;
