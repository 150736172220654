import { useEffect, useRef } from 'react';
import { Box, Button } from '@mui/material';
import useTranslation from '@Utils/useTranslation';
import { useNavigate } from 'react-router-dom';

import CartEmpty from '@assets/icons/CartEmpty.svg';
import EmptyWishlist from '@assets/images/emptyWishlist.svg';
import EmptyOrders from '@assets/images/ordersEmpty.svg';
import EmptyAddressImage from '@assets/images/emptyAddress.svg';
import { ROUTES } from '@Constants/routes';

import styles from './styles';
interface EmptyViewProps {
  buttonText?: string;
  title?: string;
  imageType?: string;
  children?: JSX.Element;
  subTitle?: string;
  onButtonPress?: () => void;
}

const EmptyPageState = ({ buttonText, title, imageType, children, subTitle, onButtonPress }: EmptyViewProps) => {
  const { t: translate } = useTranslation();
  const navigate = useNavigate();

  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    scrollRef?.current?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  const handleButtonClick = () => {
    if (onButtonPress) onButtonPress();
    else navigate(ROUTES.HOME);
  };

  const getImageSource = () => {
    switch (imageType) {
      case 'WISHLIST':
        return <EmptyWishlist />;
      case 'ORDERS':
        return <EmptyOrders />;
      case 'ADDRESS':
        return <EmptyAddressImage />;
      case 'CART':
        return <CartEmpty />;
      default:
        return null;
    }
  };

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.container}>
        {getImageSource()}
        <Box sx={styles.title} mt={6}>
          {translate(title || '')}
        </Box>
        <Box sx={styles.subtitle} mt={2}>
          {translate(subTitle || '')}
        </Box>
        {children}
      </Box>
      <Box sx={styles.buttonContainer} onClick={handleButtonClick}>
        <Button sx={styles.homeButton} variant='contained' disableRipple>
          {translate(buttonText || '')}
        </Button>
        <div ref={scrollRef} />
      </Box>
    </Box>
  );
};

export default EmptyPageState;
