import { Box, Button, Dialog, DialogActions, DialogTitle } from '@mui/material';

import CloseIcon from '@assets/icons/Close.svg';
import { ConfirmationPopupType } from './types';
import styles from './styles';
import useTranslation from '@Utils/useTranslation';

const ConfirmationPopup: React.FC<ConfirmationPopupType> = ({
  open,
  title,
  handleClose,
  yesBtnLabel,
  yesBtnHandle,
  noBtnLabel,
  hideNoBtn
}) => {
  const { t: translate } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      sx={styles.root}
    >
      <DialogTitle id='alert-dialog-title' sx={styles.dialogTitle}>
        {title}
        <Box>
          <CloseIcon onClick={handleClose} style={{ cursor: 'pointer' }} />
        </Box>
      </DialogTitle>
      <DialogActions sx={styles.dialogAction}>
        <Button
          variant={hideNoBtn ? 'contained' : 'outlined'}
          color='primary'
          onClick={yesBtnHandle}
          sx={{ ...styles.yesBtn, ...(hideNoBtn ? { width: '100%' } : {}) }}
        >
          {yesBtnLabel}
        </Button>
        {!hideNoBtn && (
          <Button variant='contained' color='primary' disabled={false} sx={styles.noBtn} onClick={handleClose}>
            {noBtnLabel || translate('no')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationPopup;
