
import React, { memo } from 'react';
import useTranslation from '@Utils/useTranslation';
import Chart from '@assets/icons/Chart.svg';
import ArrowRightIcon from '@assets/icons/ArrowRight.svg';
import SizeChartBottomSheet from '@Components/SizeChartBottomSheet';
import styles from '../../styles';
import { LINGERIE } from '@Constants/common';
import { RootState, useAppSelector, } from '@Store/store';


const SizeGuideSheet = ({ sizeGuideOpen, setSizeGuideOpen, handleSizeGuideOpen = () => {},  product, setBackPressed, backPressed }) => {
  const { t: translate } = useTranslation();
  const { bottom_sheet_open: configKey } = useAppSelector((state: RootState) => state.recommedationSheet);

  return (
    <>
      {product?.size_guide && configKey != 'sizeSelector' && (
        <div
          role="presentation"
          style={styles.sizeGuideButtonWrapper}
          onClick={handleSizeGuideOpen}
        >
          <div className='flex gap-[8px] items-center'>
            <Chart />
            <div>{translate('size_guide')}</div>
          </div>
          <ArrowRightIcon />
        </div>
      )}
     
      <SizeChartBottomSheet
        open={sizeGuideOpen}
        setOpen={setSizeGuideOpen}
        isLingerie={product?.category === LINGERIE}
        sizeGuideData={product?.size_guide ?? { menu_data: [{ title: '', key: '' }] }}
        backPressed={backPressed}
        setBackPressed={setBackPressed}
      />
    </>
  );
};

export default memo(SizeGuideSheet);
