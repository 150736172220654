import { Box } from '@mui/material';
import ReferAndEarnImage from '@assets/images/ReferAndEarnImage.svg';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@Constants/routes';

import styles from './styles';

interface ReferAndEarnProps {
  cacheProducts?: () => void;
}

const ReferAndEarnButton = ({ cacheProducts }: ReferAndEarnProps) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (cacheProducts) cacheProducts();
    navigate(ROUTES.REFERANDEARN);
  };

  return (
    <Box sx={styles.referAndEarnContainer}>
      <Box sx={styles.referAndEarn} onClick={handleClick}>
        <ReferAndEarnImage />
      </Box>
    </Box>
  );
};

export default ReferAndEarnButton;
