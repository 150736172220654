import { Box } from '@mui/system';

import styles from './styles';

interface ItemBreakupDetails {
  name: string;
  amount: string;
  disclaimer?: string;
  is_last?: boolean;
  color: string;
}

interface ItemBreakupType {
  title: string;
  sub_title: string;
  breakup_map: Array<ItemBreakupDetails>;
  footer: string;
}

interface ItemBreakupProps {
  data: ItemBreakupType;
  isPendingPayment?: boolean;
}

const PriceBreakup = ({ data, isPendingPayment = false }: ItemBreakupProps) => {
  
  return (
    <Box sx={styles.ordersDetails}>
      {!isPendingPayment && (
        <div style={styles.titleText}>{data?.title}</div>
      )}
      <div style={styles.priceInclusiveTax}>{data?.sub_title}</div>
      {data?.breakup_map.map((item) => (
        <Box key={item?.name}>
          {item?.is_last ? (
            <Box style={styles.amountDetails}>
              <Box sx={styles.orderDetailsRow}>
                <div style={styles.boldText}>{item?.name}</div>
                <div style={styles.boldText}>{item?.amount}</div>
              </Box>
            </Box>
          ) : (
            <Box key={item.name}>
              <Box sx={styles.orderDetailsRow}>
                <div style={styles.orderKey}>{item?.name}</div>
                <Box sx={[styles.amount, item?.color && {color: item.color}]}>{item?.amount}</Box>
              </Box>
            </Box>
          )}
        </Box>
      ))}
      {!isPendingPayment && (
        <Box sx={styles.footer}>
        <div style={styles.footerText}>{data?.footer}</div>
      </Box>
      )}
    </Box>
  );
};

export default PriceBreakup;