import { colors } from '@Constants/colors';

const styles = {
  referAnEarnLayout: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '24px 0'
  },
  referTitle: {
    paddingLeft: '18px',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    color: colors.FONT_SECONDARY
  },
  referContent: {
    paddingTop: '5px',
    paddingLeft: '18px',
    paddingBottom: '12px',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    color: colors.DIM_GRAY
  },
  referNow: {
    paddingLeft: '18px',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '16px',
    color: colors.PRIMARY_DARK,
    cursor: 'pointer'
  }
};

export default styles;
