import { Map } from '@Constants/common';
import { CartItem } from '@Containers/Cart/types';
import { ProductDetails } from '@Containers/Product-Details/types';
import { VariationId, VariationType } from '@Types/common';
import LocalStorage from './storage';
import { SizeChartDataType } from '@Components/SizeChartBottomSheet/type';
import { isNonEmptyArray } from '@Utils/checks';

const DEFAULT_MAX_GTM_ARRAY_SIZE = 100;

type ProductInfo = {
  bust_size?: string;
  color?: string;
  fabric?: string;
  front_length?: string;
  lining?: string;
  neckline?: string;
  occasion?: string;
  print_type?: string;
  size?: string;
  sleeve_length?: string;
  sleeves?: string;
  transparency?: string;
  vendor_name?: string;
  waist_size?: string;
  washcare?: string;
  live_date?: string;
  hip_size?: string;
  length?: string;
  style_type?: string;
  [key: string]: any;
};

type ProductType = {
  category: string;
  image_url: string;
  live_date?: string;
  name: string;
  new_price: string;
  old_price: string;
  product_description: string;
  product_id: number;
  slug?: string;
  style_id?: string;
  vendor_name?: string;
  size_guide?: SizeChartDataType;
  product_info: {
    color?: string;
    fabric?: string;
    linking?: string;
    neckline?: string;
    occassion?: string;
    print_type?: string;
    sleeve_length?: string;
    sleeves?: string;
    style_type?: string;
    transparency?: string;
    washcare?: string;
    [key: string]: any;
  };
  variation_ids: VariationId[];
  variations: VariationType[];
};

const GTM = {
  sendEvent: (eventName: string, payload: Map) => {
    window?.dataLayer?.push({
      event: eventName,
      new_web_app: true,
      ...payload
    });
  },

  sendEcommEvent: (eventName: string, payload: Map) => {
    window?.dataLayer?.push({
      event: eventName,
      new_web_app: true,
      ecommerce: {
        currencyCode: 'INR',
        ...payload
      }
    });
  }
};

export const limitGtmArraySize = arr => {
  if(!isNonEmptyArray(arr))
    return arr;

  // limit to max of 100 products on local storage
  return arr.slice(0, Math.min(DEFAULT_MAX_GTM_ARRAY_SIZE, arr.length));
}

export const addToGtmProductList = (product: ProductDetails) => {
  if (!product) return;

  const existingProducts = JSON.parse(LocalStorage.getItem('gtm-product-list') || '[]');
  const isExisting = existingProducts.find((prdct: ProductDetails) => ((prdct.parent_id === product.parent_id) || (prdct.product_id === product.product_id)));
  
  if (!isExisting) LocalStorage.setItem('gtm-product-list', JSON.stringify(limitGtmArraySize([...existingProducts, product])));
};

export const addCartItemsToGtmProductList = (products: ProductDetails[]) => {
  if (products.length === 0) LocalStorage.removeItem('gtm-product-list');

  LocalStorage.setItem('gtm-product-list', JSON.stringify(limitGtmArraySize(products)));
};

const getProductBasicInfo = (cartItem: CartItem, product: ProductDetails) => ({
  name: product.name,
  id: product.parent_id || product.product_id,
  item_id: product.parent_id || product.product_id,
  item_name: product.name,
  price: String(cartItem.line_total),
  variant: cartItem.variation.attribute_pa_size,
  category: product.category,
  item_category: product.category,
  quantity: cartItem.quantity
});

const getProductDetails = (product: CartItem) => {
  const existingProducts = JSON.parse(LocalStorage.getItem('gtm-product-list') || '[]');

  return existingProducts.find((prdct: ProductDetails) => ((prdct.parent_id == product.parent_id) || (prdct.product_id || product.product_id)));
};

export const getProductFromCartItem = (cartItem: CartItem, attributesData?: ProductInfo) => {
  try {
    const product = getProductDetails(cartItem);
    const productAttributes = getProductAttributes(product, attributesData, cartItem?.variation?.attribute_pa_size);
    const productbasicInfo = getProductBasicInfo(cartItem, product);

    if (product) return { ...productAttributes, ...productbasicInfo };
  } catch (e) {
    return null;
  }
};

export const getProductsFromCart = (cart: Map, attributesData?: ProductInfo) => {
  const cartItems: CartItem[] = Object.keys(cart)?.map((key: string) => cart[key]);
  const products: Map[] = [];

  cartItems.forEach((item: CartItem) => {
    const product = getProductFromCartItem(item, attributesData);

    if (product) products.push(product);
  });

  return products;
};

const getProductAttributes = (product?: ProductType, attributesData?: ProductInfo, selectedSize?: string) => {
  const productInfoStrings: ProductInfo = {
    bust_size: 'dimension1',
    color: 'dimension2',
    fabric: 'dimension3',
    front_length: 'dimension4',
    lining: 'dimension5',
    neckline: 'dimension6',
    occasion: 'dimension7',
    print_type: 'dimension8',
    size: 'dimension9',
    sleeve_length: 'dimension10',
    sleeves: 'dimension11',
    transparency: 'dimension12',
    vendor_name: 'dimension13',
    waist_size: 'dimension14',
    washcare: 'dimension15',
    live_date: 'dimension16',
    hip_size: 'dimension17',
    length: 'dimension18',
    style_type: 'dimension19'
  };

  const keys = Object.keys(product?.product_info ?? {});
  const attributes: { [key: string]: string } = {};
  let sizes: string[] = [];
  const attributesList = attributesData ?? productInfoStrings;

  keys.map((key) => {
    if (product?.product_info && Object.keys(attributesList).includes(key))
      attributes[attributesList[key]] = product?.product_info[key];
  });

  if (product?.variations && product?.variations?.length > 0) {
    sizes = product?.variations?.map((size) => size.size);
    attributes['dimension9'] = sizes.join(',');
  }

  if (product?.live_date && product?.live_date !== '') attributes['dimension16'] = product?.live_date;

  if (product?.vendor_name && product?.vendor_name !== '') attributes['dimension13'] = product?.vendor_name;

  if (selectedSize && product?.size_guide?.body_size_guide?.[selectedSize]?.bust)
    attributes['dimension1'] = String(product?.size_guide?.body_size_guide?.[selectedSize]?.bust);

  return attributes;
};

export default GTM;

export { getProductAttributes };
