import { colors } from '@Constants/common';

const ISSERVER = typeof window === "undefined";

const styles = {
  container: {
    backgroundColor: colors.WHITE_SMOKE_2,
    padding: '16px'
  },
  sectionContainer: {
    marginBottom: '32px',
    marginTop: '8px'
  },
  header: {
    color: colors.BLACK,
    fontWeight: 600,
    fontSize: '14px'
  },
  content: {
    color: colors.DIM_GRAY,
    fontWeight: 400,
    fontSize: '12px'
  },
  link: {
    color: colors.DIM_GRAY,
    textDecoration: 'none',
    display: 'inline-block'
  },
  linkSeparator: {
    display: 'inline-block',
    margin: 'auto 5px'
  },
  subheader: {
    color: colors.BLACK,
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '18px'
  },
  subContent: {
    color: colors.DIM_GRAY,
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px',
    marginTop: '12px'
  },
  subSectionContainer: {
    marginTop: '16px',
    marginBottom: '16px'
  },
  paragraph: {
    marginTop: '16px',
    fontSize: '12px',
    fontWeight: 600
  },
  listStyle: {
    paddingInlineStart: '20px'
  },
  arrow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: !ISSERVER && window.innerWidth >= 450 ? '30px' : '16px',
    height: '30px',
    transform: 'rotate(90deg)'
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  hide: {
    display: 'none'
  }
};

export default styles;
