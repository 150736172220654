import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Storage } from '@Constants/common';
import LocalStorage from '@Utils/storage';
import { RootState } from '@Store/store';

export interface Wishlist {
  productIds: Array<number>;
}

const initialState: Wishlist = {
  productIds:
    LocalStorage.getItem(Storage.WISHLIST) !== null ? JSON.parse(LocalStorage.getItem(Storage.WISHLIST) ?? '') : []
};

export const wishlistSlice = createSlice({
  name: 'wishlist',
  initialState,
  reducers: {
    setWishListData: (state, action: PayloadAction<Array<number>>) => {
      state.productIds = action.payload;
    },
    addItemToWishlist: (state, action: PayloadAction<number>) => {
      state.productIds = [...state.productIds, action.payload];
      LocalStorage.setItem(Storage.WISHLIST, JSON.stringify(state.productIds));
    },
    removeItemToWishlist: (state, action: PayloadAction<number>) => {
      const { productIds } = state;
      const removedProductId = action.payload;
      const updatedProductIds = productIds.filter((productId) => Number(productId) !== Number(removedProductId));

      state.productIds = updatedProductIds;
      LocalStorage.setItem(Storage.WISHLIST, JSON.stringify(state.productIds));
    },
    resetWishlistSlice: () => initialState
  }
});

export const selectWishListState: (state: RootState) => {
  productIds: Array<number>;
} = ({ wishlist }) => ({
  productIds: wishlist.productIds
});

export const { setWishListData, addItemToWishlist, removeItemToWishlist, resetWishlistSlice } = wishlistSlice.actions;
export default wishlistSlice.reducer;
