import ArrowRightIcon from '@assets/icons/ArrowRight.svg';
import { ROUTES } from '@Constants/routes';
import { Box } from '@mui/material';
import Link from 'next/link';
import { forwardRef, Ref, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './styles';

interface SectionProps {
  title: string;
  description: string;
}

interface LinkSectionProps {
  title: string;
  links: Array<Record<string, string>>;
  redirect: (e: { preventDefault: () => void }, page: string) => void;
}

interface SectionData {
  title: string;
  type?: string;
  description?: string;
  bullets: Array<SectionProps>;
}

interface CategoryDataProps {
  title: string;
  description: string;
  sections: Array<SectionData>;
}

const linkData = [
  {
    title: 'Shop For',
    links: [
      {
        name: 'Denim',
        path: '/collection/denim-products'
      },
      {
        name: 'Bottoms',
        path: `${ROUTES.WOMENS_COLLECTION}/bottoms`
      },
      {
        name: 'Tops',
        path: `${ROUTES.WOMENS_COLLECTION}/tops`
      },
      {
        name: 'T-Shirts',
        path: `${ROUTES.WOMENS_COLLECTION}/tshirts`
      },
      {
        name: 'Dresses',
        path: `${ROUTES.WOMENS_COLLECTION}/dresses`
      },
      {
        name: 'Co-ords',
        path: `${ROUTES.WOMENS_COLLECTION}/co-ords`
      },
      {
        name: 'Jumpsuits Bodysuits',
        path: `${ROUTES.WOMENS_COLLECTION}/jumpsuits%2Fbodysuits`
      },
      {
        name: 'Winterwear',
        path: `${ROUTES.WOMENS_COLLECTION}/winterwear`
      }
    ]
  },
  {
    title: 'Customer Policies',
    links: [
      {
        name: 'Terms & Conditions',
        path: ROUTES.TERMS_AND_CONDITIONS.substring(0, ROUTES.TERMS_AND_CONDITIONS.length - 1)
      },
      {
        name: 'About us',
        path: ROUTES.ABOUT_US.substring(0, ROUTES.ABOUT_US.length - 1)
      },
      {
        name: 'Stores',
        path: ROUTES.STORES.substring(0, ROUTES.ABOUT_US.length - 1)
      },
      {
        name: 'Privacy Policy',
        path: ROUTES.PRIVACY_POLICY.substring(0, ROUTES.PRIVACY_POLICY.length - 1)
      },
      {
        name: 'Refund Policy',
        path: ROUTES.RETURNS_AND_EXCHANGE.substring(0, ROUTES.RETURNS_AND_EXCHANGE.length - 1)
      }
    ]
  }
];

const data: Array<SectionProps> = [];

const Section = ({ title, description }: SectionProps) => {
  return (
    <Box sx={styles.sectionContainer}>
      <Box sx={styles.header}>{title}</Box>
      <Box sx={styles.content}>{description}</Box>
    </Box>
  );
};

const LinkSection = ({ title, links, redirect }: LinkSectionProps) => {
  return (
    <Box sx={styles.sectionContainer}>
      <Box sx={styles.header}>{title}</Box>
      <Box sx={styles.content}>
        {links.map((item, index) => {
          const link = item.path.substring(1);
          return (
            <>
              {index !== 0 && <Box sx={styles.linkSeparator}>{` | `}</Box>}
              <Link href={link} key={item.name} style={styles.link} onClick={(e) => redirect(e, item.path)} prefetch={false}>
                {item.name}
              </Link>
            </>
          );
        })}
      </Box>
    </Box>
  );
};

const RenderParagraph = ({ sectionData }: { sectionData: SectionData }) => {
  const { title, bullets, description } = sectionData;
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    setIsOpen(false);
  },[]);

  return (
    <Box sx={styles.subSectionContainer}>
      <Box sx={styles.titleContainer} onClick={() => setIsOpen((s) => !s)}>
        <Box sx={styles.header}>{title}</Box>
        <Box sx={{ ...styles.arrow, ...(isOpen && { transform: 'rotate(-90deg)' }) }}>
          <ArrowRightIcon strokeWidth={3} />
        </Box>
      </Box>
      {description && <Box sx={{ ...styles.subContent, ...(!isOpen && styles.hide)}}>{description}</Box>}
      <Box sx={isOpen ? {} : styles.hide}>
        {bullets?.map((item: SectionProps) => {
          return (
            <Box key={item.title} sx={styles.paragraph}>
              <Box sx={styles.subheader}>{item.title}</Box>
              <Box sx={styles.subContent}>{item.description}</Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

const RenderOrderedList = ({ sectionData }: { sectionData: SectionData }) => {
  const { title, bullets, description } = sectionData;
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    setIsOpen(false);
  },[]);

  return (
    <Box sx={styles.subSectionContainer}>
      <Box sx={styles.titleContainer} onClick={() => setIsOpen((s) => !s)}>
        <Box sx={styles.header}>{title}</Box>
        <Box sx={{ ...styles.arrow, ...(isOpen && { transform: 'rotate(-90deg)' }) }}>
          <ArrowRightIcon strokeWidth={3} />
        </Box>
      </Box>
      {description && <Box sx={{ ...styles.subContent, ...(!isOpen && styles.hide)}}>{description}</Box>}
      <ol style={{ ...styles.listStyle, ...(!isOpen && styles.hide) }}>
        {bullets?.map((item: SectionProps) => {
          return (
            <li key={item.title} style={styles.paragraph}>
              <Box sx={styles.subheader}>{item.title}</Box>
              <Box sx={styles.subContent}>{item.description}</Box>
            </li>
          );
        })}
      </ol>
    </Box>
  );
};

const RenderUnorderedList = ({ sectionData }: { sectionData: SectionData }) => {
  const { title, bullets, description } = sectionData;
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    setIsOpen(false);
  },[]);

  return (
    <Box sx={styles.subSectionContainer}>
      <Box sx={styles.titleContainer} onClick={() => setIsOpen((s) => !s)}>
        <h2 style={styles.header}>{title}</h2>
        <Box sx={{ ...styles.arrow, ...(isOpen && { transform: 'rotate(-90deg)' }) }}>
          <ArrowRightIcon strokeWidth={3} />
        </Box>
      </Box>  
      {description && <Box sx={{ ...styles.subContent, ...(!isOpen && styles.hide)}}>{description}</Box>}
      <ul style={{ ...styles.listStyle, ...(!isOpen && styles.hide) }}>
        {bullets?.map((item: SectionProps) => {
          return (
            <li key={item.title} style={styles.paragraph}>
              <h3 style={styles.subheader}>{item.title}</h3>
              <Box sx={styles.subContent}>{item.description}</Box>
            </li>
          );
        })}
      </ul>
    </Box>
  );
};

const renderSection = (sectionData: SectionData) => {
  switch (sectionData?.type) {
    case 'paragraph':
      return <RenderParagraph sectionData={sectionData} />;
    case 'unordered_list':
      return <RenderUnorderedList sectionData={sectionData} />;
    case 'ordered_list':
      return <RenderOrderedList sectionData={sectionData} />;
    default:
      return <RenderParagraph sectionData={sectionData} />;
  }
};

const CategoryInfo = ({ categoryInfo }: { categoryInfo: CategoryDataProps }) => {
  const { title, description, sections } = categoryInfo ?? {};

  return (
    <Box>
      <h1 style={styles.header}>{title}</h1>
      <Box sx={styles.subContent}>{description}</Box>
      {sections?.map((item: SectionData) => {
        return renderSection(item);
      })}
    </Box>
  );
};

const SeoFooter = forwardRef(({ seoData, onLinkPress }: { seoData: any, onLinkPress?: () => void }, ref: Ref<HTMLDivElement>) => {
  const navigate = useNavigate();

  const redirect = (e: { preventDefault: () => void }, page: string) => {
    e.preventDefault();
    navigate(page);
    setTimeout(() => window.scrollTo(0, 0), 1000);
    if (onLinkPress) onLinkPress();
  };

  return (
    <Box sx={styles.container} ref={ref}>
      {data.map(({ title, description }) => (
        <Section key={title} title={title} description={description} />
      ))}
      {linkData.map(({ title, links }) => (
        <LinkSection title={title} links={links} redirect={redirect} />
      ))}
      <CategoryInfo categoryInfo={seoData} />
    </Box>
  );
});

export default SeoFooter;
