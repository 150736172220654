import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState = {
  signup_sheet_open: '',
  signup_success_popup_open: false,
  signup_failure_popup_open: false,
  signup_sheet_closed_count: 1, 
};

export const signupSlice = createSlice({
  name: 'signupSheet',
  initialState,
  reducers: {
    setSignupSheetOpen: (state, action: PayloadAction<string>) => {
      state.signup_sheet_open = action.payload;
    },
    setSignupSuccessPopupOpen: (state, action: PayloadAction<boolean>) => {
      state.signup_success_popup_open = action.payload;
    },
    setSignupFailurePopupOpen: (state, action: PayloadAction<boolean>) => {
      state.signup_failure_popup_open = action.payload;
    },
    setSignupSheetClosedCount: (state, action: PayloadAction<number>) => {
        state.signup_sheet_closed_count = action.payload;
    },
    resetSignupSlice: () => initialState
  }
});

export const { setSignupSheetOpen, setSignupSuccessPopupOpen, setSignupFailurePopupOpen, setSignupSheetClosedCount, resetSignupSlice } = signupSlice.actions;
export default signupSlice.reducer;
