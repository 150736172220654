export const colors = {
  PRIMARY: '#39B277',
  PRIMARY_DARK: '#1D6B46',
  PRIMARY_LIGHT: '#90E1BA',
  PRIMARY_LIGHT_2: '#EBFFF5',
  PRIMARY_LIGHTER: '#F5FFFA',
  SECONDARY: '#FFFF',
  FONT_SECONDARY: '#1F1F1F',
  BLACK: '#000000',
  WHITE: '#FFFFFF',
  WHITE_SMOKE: '#F7F7F7',
  WHITE_SMOKE_2: '#F2F2F2',
  LIGHT_GRAY: '#D6D6D6',
  NEUTRAL_GRAY: '#E6E6E6',
  DIM_GRAY: '#707070',
  DARK_GRAY: '#ABABAB',
  SILVER_GRAY: '#C4C4C4',
  GREYED_OUT: '#D9D9D9',
  FONT_PRIMARY: '#880746',
  LIGHT_RED: '#F6BDC0',
  RED: '#DA1E28',
  GREEN: '#14664B',
  LIGHT_SILVER: '#D6D6D6',
  LIGHT_GREEN: 'rgb(144, 225, 186, 0.02)',
  DARK_GREEN: '#31B375',
  YELLOW: "#FFE249",
  LIGHT_GREY: '#DEDEDE',
};

export const RESEND_OTP_TIME = 15;
export const OTP_LENGTH = 6;
export const EMPTY_STRING = '';
export const WIDTH = '450px';
export const MAX_WIDTH = 450;
export const MAX_QUANTITY = 10;
export const POST_CODE_LENGTH = 6;
export const MAX_CART_QUANTITY = 10;
export const MAX_IMAGE_SIZE = 5000000;

export enum STOCK_STATUS {
  inStock = 'instock',
  outOfStock = 'outofstock'
}

export enum Pages {
  HOME = 'home',
  PROFILE = 'my-account',
  SHOP = 'shop',
  WOMENS_COLLECTION = 'womens-collection',
  COLLECTION = 'collection',
  WISHLIST = 'wishlist',
  CATEGORY = 'category',
}

export enum Tokens {
  ACCESS_TOKEN = 'access_token',
  REFRESH_TOKEN = 'refresh_token'
}

export enum Analytics{
  SESSION_ID = 'session_id',
}

export enum LastSeen{
  LAST_SEEN = 'last_seen'
}

export enum WebPopup{
  WEB_POPUP = 'firstPopup',
  APP_DOWNLOAD_POPUP = 'appDownloadPopup',
}

export enum PrimaryButtons {
  BUY_NOW = 'buy_now',
  ADD_TO_BAG = 'add_to_bag',
  GO_TO_BAG = 'go_to_bag',
  UPDATE = 'update'
}

export enum MeasurementUnits {
  CM = 'cm',
  INCH = 'inch'
}

export enum ProductCartType {
  SHOP = 'shop',
  WISHLIST = 'wishlist',
  SIMILAR_PRODUCTS = 'similarProducts',
  HOME = 'home',
  RECOMMENDED_PRODUCTS = 'recommendedProducts',
}

export type Map = Record<string, any>;
export type StringMap = Record<string, string>;

export const OUT_OF_STOCK = 'outofstock';

export enum Storage {
  WISHLIST = 'wishlist'
}
export const RETURN_INITIATED = 'Return Initiated';
export const PENDING_PAYMENT = 'Pending Payment';

export const PAYMENT_METHODS = {
  razorpay: 'Paid by Razorpay',
  cod: 'Cash on Delivery'
};

export const CANCEL = 'cancel';
export const RETURN = 'return';
export const DELIVERED = 'Delivered';
export const ORDER_PLACED = 'Placed';
export const CANCELLED = 'Cancelled';

export const LINGERIE = 'lingerie';

export const NEWME_INSTGRAM = 'https://www.instagram.com/newme.asia';

// Product details has dynamic title, hence is omitted here
export const enum TitleBarTexts {
  HOME = "newme | Fresh Drip Delivery! Women's Latest Clothing Styles Online",
  LOGIN = 'Register / Login - NewMe - newme',
  SHOP = 'Shop - newme',
  COLLECTIONS = 'Collections - newme',
  WISHLIST = 'Wishlist - newme',
  ME = 'My account - newme',
  CART = 'Shopping Bag - newme',
  CHECKOUT = 'Checkout - newme',
  PAYMENT_SUCCESS = 'Payment Success - newme',
  REFER_AND_EARN = 'Refer and Earn - newme',
  SEARCH = 'Search - newme'
}

export const SUPPORT_CONTACT = '+918904469606';

export const enum DescriptionTexts {
  HOME = 'Latest trends in clothing for women at Newme. Find new arrivals, fashion catalogs, collections. Start shopping now🛒'
}

export const CatgegoryTitleTexts: Record<string, string> = {
  SHOP: '',
  HOME: 'NEWME - Freshest Fashion Fastest',
  DRESSES: 'Womens Dresses online - Latest trendy dresses on Newme',
  BODYCON: 'Bodycon Dress - Buy trendy Bodycon Dresses Online | Newme',
  MAXI: 'Maxi Dresses - Buy Maxi Long Dress Online for Women |Newme',
  MIDI: 'Midi Dresses - Buy Midi Dresses online|Newme',
  TOPS: "Women's Tops Online - Trending collections| Newme",
  BLOUSES: "Women's Blouses Online |Newme",
  TSHIRTS: "Women's Tshirts Online: Latest trendy collection|Newme",
  TANKTOPS: 'Buy Tank Tops for Women Online | Newme',
  BOTTOMS: 'Women Bottomwear - Buy Women Bottomwear online|Newme',
  SHORTS: "Women's Shorts - Buy Shorts for Women Online",
  SKIRTS: 'Skirts - Buy Short, Mini & Long Skirts Online|Newme',
  JUMPSUITS: "Women's Jumpsuits Online| Newme"
};

export const CategoryDescriptionTexts: Record<string, string> = {
  SHOP: 'sss',
  HOME: 'Latest trends in clothing for women at Newme. Find new arrivals, fashion catalogs, collections. Start shopping now🛒',
  DRESSES:
    'Buy womens Dresses online. Choose from wide variety of latest trending fashion style. Enjoy free shipping ✔️ exclusive 20% off on first purchase✔️ ',
  BODYCON:
    'Shop the trendy collection of bodycon dresses for women and girls online. Find the perfect bodycon dress. Elevate your style with our fashionable bodycon dresses 👗',
  MAXI: 'Shop from a vast collection of Maxi Dresses at best prices. Choose from the latest trendy collections of Maxi dresses 👗',
  MIDI: 'Buy online at Midi Dresses. Huge collection range. Order now and avail 20% Discounts✔️  Cash on Delivery✔️  Easy Returns✔️  ',
  TOPS: 'Women Top - Shop for Ladies Tops in India. Pick Tops from wide colour range and different patterns from our latest collection 👚',
  BLOUSES:
    'Shop online for latest women blouses at best price. Buy blouses in various prints, patterns, fabrics, styles.Shop now 🛒',
  TSHIRTS:
    "Buy Tshirts for Women. Shop from latest collection of women's tshirts. Discounts✔️ Easy Returns✔️ Order Now✔️",
  TANKTOPS:
    'Discover a wide range of branded tank tops for women in India at the best prices. Shop online and explore our collection of stylish and trendy ladies tank tops, perfect for any occasion🎽',
  BOTTOMS: 'Women Bottomwear - Shopping from the Women Bottomwear collection. 🛒Buy Women Bottomwear 🛒 Free Shipping',
  SHORTS:
    "Buy Shorts for Women. Huge collection of women's shorts  with different patterns and style. Shop now ✔️  20% discount on first purchase✔️ ",
  SKIRTS: 'Skirts Online - Check wide range of short & long skirts for Ladies online. Order Now👗',
  JUMPSUITS:
    'Shop from trendy collection of jumpsuit Online for women. Available in various sizes & colors. Order now✔️ '
};

export const getMetaTitle = (categoryName: string, title: string) => {
  switch(categoryName?.toLowerCase()){
    case 'tops':
    case 'blouses':
    case 'tank tops':
      return `Womens ${title} Online - Trending collections| Newme`
    case 'tshirts':
      return `Women's ${title} Online: Latest trendy collection|Newme`
    case 'bottoms':
      return `Women ${title} - Buy Women ${title} online|Newme`
    case 'shirts':
      return `Womens ${title} - Shop trendy shirts online |Newme `
    case 'shorts':
    case 'skirts':
    case 'jeans':
      return `Buy Stylish women's ${title} - Best deals await |Newme`
    case 'trousers':
      return `Buy trendy women's ${title} online |Newme `
    case 'jumpsuits/bodysuits':
    case 'playsuits':
      return `Buy women's ${title} - Explore our new arrivals |Newme`
    case 'bodysuits':
      return `${title}- Buy bodysuits for women online |Newme `
    case 'jumpsuits':
      return `Womens ${title} Online| Newme`
    case 'dresses':
    case 'maxi':
    case 'midi':
    case 'bodycon':
      return `Womens ${title} online - Latest trendy dresses on Newme`
    case 'mini':
      return `${title} Dresses -Buy latest women's short dresses online|Newme`
    case 'sweaters':
      return `${title} for women - shop latest trends online|Newme`
    case 'sweatshirts':
      return `Buy ${title} for women at best prices | Newme`
    case 'coats':
      return `Buy Women ${title} online`
    case 'winterwear':
    case 'jackets':
    case 'lingerie':
    default:
      return `NEWME - Freshest Fashion Fastest`
  }
}

export const getMetaDescription = (categoryName: string, title: string) => {
  switch(categoryName?.toLowerCase()){
    case 'tops':
    case 'blouses':
    case 'tank tops':
      return `Women Top - Shop for Ladies ${title} in India. Pick ${title} from wide colour range and different patterns from our latest collection 👚`
    case 'tshirts':
      return `Buy ${title} for Women. Shop from latest collection of women's ${title}. Discounts✔️ Easy Returns✔️ Order Now✔️ `
    case 'shirts':
      return `Shop online for comfortable and trendy 👕 ${title}to elevate your wardrobe effortlessly. 🛒Buy now!`
    case 'bottoms':
      return `Women ${title} - Shopping from the Women ${title} collection. 🛒Buy Women ${title} 🛒 Free Shipping`
    case 'shorts':
    case 'skirts':
    case 'jeans':
      return `Elevate your wardrobe with the latest 👖${title} collection for women.🛒 Shop stylish and comfortable 👖jeans online today for a fashionable look!`
    case 'trousers':
      return `Upgrade your style with versatile 👖${title} for women. Discover comfortable and chic options online.  🛒Shop now for the perfect fit!`
    case 'jumpsuits/bodysuits':
    case 'playsuits':
      return `Shop the latest ${title} online for a fashionable and comfortable style upgrade. Explore trendy options and shop now for a fresh look!Order now✔️ `
    case 'bodysuits':
      return ` Explore our stylish range of ${title} online. Elevate your fashion game with comfortable and chic options.🛒 Shop now for the perfect fit!`
    case 'jumpsuits':
      return `Shop from trendy collection of ${title} Online for women. Available in various sizes & colors. Order now ✔️ `
    case 'dresses':
    case 'maxi':
    case 'midi':
    case 'bodycon':
      return `Buy womens ${title} online. Choose from wide variety of latest trending fashion style. Enjoy free shipping ✔️ exclusive 20% off on first purchase ✔️ `
    case 'mini':
      return `Discover a stunning selection of ${title} online. Get ready to turn heads with our trendy and elegant options. 🛒 Shop now! `
    case 'sweaters':
      return `Stay cozy and chic with our collection of womens ${title}. Explore trendy and comfortable options online and upgrade your winter wardrobe.🛍️Shop now!`
    case 'sweatshirts':
      return `Buy womens ${title} online to stay warm and stylish with our cozy ${title}. Explore trendy options online and upgrade your casual wardrobe today.🛍️ Shop now!`
    case 'coat':
      return `Bundle up in style with 🧥${title}. Discover trendy and cozy options online for a chic winter look. 🛍️Shop now for warmth and fashion!`
    case 'winterwear':
    case 'jackets':
    case 'lingerie':
    default:
      return `Latest trends in clothing for women at Newme. Find new arrivals, fashion catalogs, collections. Start shopping now 🛒`
  }
}

export const categoryMapper = {
  'jumpsuits-bodysuits': 'jumpsuits/bodysuits',
  'tank-tops': 'tank tops'
}

const productDescriptionTemplate = {
  1: (productName) => `Embrace comfort and style with our 👗 ${productName}. Explore our stunning range of ${productName} for women at Newme with easy returns`,
  2: (productName) => `Discover Elegance and Comfort with Our ${productName} | Shop Now for Your Perfect Look Enjoy an ✔️ exclusive 20% off on your first purchase!`,
  3: (productName) => `Elevate Your Style with Our ${productName} | Easy Returns | Find Your Ideal Length and Design! 🛒 Start Shopping Now!`,
  4: (productName) => `Stay Stylish and Comfortable with Our 👗${productName} | Cash on Delivery Available | 🛍️Shop Now for Bohemian Chic!`,
  5: (productName) => `${productName} Your Go-To Choice for Casual and Formal Events |🛒 Shop Now and Elevate Your Wardrobe Today!`,
  6: (productName) => `Elevate your style with our 👗 ${productName} featuring a stunning print. Perfect for any occasion, 🛍️Shop now to get comfort and elegance look`,
  7: (productName) => `Casual meets chic with our ${productName} . Designed for everyday comfort and style, this ${productName} is a wardrobe essential. ✔️ Get yours now!`
}

export const getProductDescription = (productName) => {
  const i = (productName.length % 7) + 1;
  return productName ? productDescriptionTemplate[i]?.(productName) : "newme | Fresh Weekly Drips! Women's Latest Clothing Styles Online";
}

export const UtmKeys = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content', 'utm_nm', 'utm_nm1', 'utm_nm2'];

export const DEEPLINKS = {
  PRIMARY: 'https://newmeapp.app.link/'
}

export const TOAST_STATES = {
  DEAFULT: {
    message: '',
    type: undefined,
    show: false
  }
}

export const APP_DOWNLOAD_LINK = 'https://newmeapp.app.link/download_app_home_top';
