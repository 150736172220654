import React, { useCallback, useContext, useEffect } from 'react';
import CustomDialog from '@Components/CustomModal';
import FilterSheetCloseIcon from '@assets/icons/FilterSheetCloseIcon.svg';
import { RootState, useAppDispatch, useAppSelector } from '@Store/store';
import { setSignupFailurePopupOpen } from '@Containers/SignupBottomSheet/signupBottomSheetSlice';
import { useRouter } from 'next/router';
import { TrackerContext } from '_app';
import { getTrackingEventObject, getTrackEventMetadata } from '@Utils/common';
import { GLOBAL_ACTION_TYPES, SIGNUP_SHEET_EVENTS } from '@Constants/events';
import { isNonEmptyObject } from '@Utils/checks';

const FailureModal = ({ failureModalConfig }) => {
  const track = useContext(TrackerContext),
    { asPath } = useRouter(),
    dispatch = useAppDispatch(),
    { signup_failure_popup_open: signupFailurePopupOpen } = useAppSelector((state: RootState) => state.signupSlice),
    { heading, error_msg: errorMsg } = failureModalConfig ?? {};

  useEffect(() => {
    if (isNonEmptyObject(failureModalConfig) && signupFailurePopupOpen)
      track(getTrackingEventObject(-1, SIGNUP_SHEET_EVENTS.FEED_TYPE, GLOBAL_ACTION_TYPES.VIEW, SIGNUP_SHEET_EVENTS.FAILURE_POPUP_RENDER, asPath, { meta: getTrackEventMetadata()}));
  }, [failureModalConfig, signupFailurePopupOpen]);

  const handlePopupClose = useCallback(() => {
    track(getTrackingEventObject(-1, SIGNUP_SHEET_EVENTS.FEED_TYPE, GLOBAL_ACTION_TYPES.CLICK, SIGNUP_SHEET_EVENTS.FAILURE_POPUP_CLOSE, asPath, { meta: getTrackEventMetadata()}));
    dispatch(setSignupFailurePopupOpen(false));
  }, []);

  if (!isNonEmptyObject(failureModalConfig)) return <></>;

  return (
    <CustomDialog
      open={signupFailurePopupOpen}
      onClose={handlePopupClose}
      setOpen={handlePopupClose}
      isBottomSheet
      showCloseButton
      customCloseIcon={<FilterSheetCloseIcon />}
    >
      <div className="w-[100vw] max-w-[450px] box-border bg-nm_white rounded-t-[16px] text-left">
        {heading && (
          <div className="p-[16px] text-[16px] font-[700] leading-[24px] text-nm_black border-solid border-0 border-b-[1px] border-b-nm_light_gray_9">
            {heading}
          </div>
        )}
        {errorMsg && <div className="p-[16px] text-[14px] font-[500] leading-[20px] text-nm_black_2">{errorMsg}</div>}
      </div>
    </CustomDialog>
  );
};

export default FailureModal;
