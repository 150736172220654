import useScrollDetectionVertical from '@Hooks/useScrollDetection';
import { handleScrollToTop } from '@Utils/common';
import ArrowRightIcon from '@assets/icons/ArrowRight.svg';
import styles from './styles.module.css';

interface IScrollToTopButtonProps {
  customStyles?: Object;
  customScrollTo?: () => void;
  hideButton?: boolean;
  onScrollCallback?: () => void;
}

const ScrollToTopLogo = () => (
  <div className={`${styles.ArrowIcon} absolute text-center w-[16px] h-[16px] flex justify-center items-center mt-[10px]`}>
    <ArrowRightIcon style={{ filter: 'invert(100%)' }} />
  </div>
);

const ScrollToTopButton = ({ customStyles, customScrollTo, hideButton, onScrollCallback }: IScrollToTopButtonProps) => {
  const { isScrollingUp } = useScrollDetectionVertical({ onScrollCallback: onScrollCallback });

  return (
    isScrollingUp &&
    !hideButton && (
      <div className="fixed w-full max-w-[450px] z-[3000] flex justify-center box-border" style={customStyles ?? {}}>
        <button
          onClick={customScrollTo ? customScrollTo : handleScrollToTop}
          className="customButton customButton--noScale rounded-[24px] px-[12px] py-[6px] text-[12px] text-nm_white font-medium leading-[16px] bg-nm_black flex items-center justify-center"
        >
          Back to top
          <div className="relative  w-[16px] h-[16px] left-[5px]">
            <ScrollToTopLogo />
            <ScrollToTopLogo />
          </div>
        </button>
      </div>
    )
  );
};

export default ScrollToTopButton;
