import React, { createContext, memo, useContext } from 'react';
import { RootState, useAppSelector } from '@Store/store';
import BuyNowSection from '../BuyNowSection';
import { PdpContext } from '@Containers/Product-Details';
import dynamic from 'next/dynamic';

export const PdpFooterContext = createContext({});

const DynamicNotifyMeFooter = dynamic(() => import('../NotifyMeFooter'), { ssr: false });

const PdpFooter = ({
  product,
  id,
  setOpenSizeSelectorSheet,
  selectedSize,
  handleBuyNow,
  handleAddToCart,
  sizesInCart,
  isBottomSheet,
  wishList,
  handleWishListClick,
  selectedVariant
}) => {
  const { notifyOosFlag, isOutOfStock } = useContext(PdpContext) ?? {},
    { selectedProductOutOfStock } = useAppSelector((state: RootState) => state.productDetails);
    
  return (
    <PdpFooterContext.Provider value={{ product: product, selectedSize: selectedSize, selectedVariant: selectedVariant }}>
      {notifyOosFlag && (isOutOfStock || selectedProductOutOfStock) ? (
        <DynamicNotifyMeFooter />
      ) : (
        <BuyNowSection
          product={product}
          productId={id}
          setOpen={setOpenSizeSelectorSheet}
          selectedSize={selectedSize}
          handleBuyNow={handleBuyNow}
          handleAddToCart={handleAddToCart}
          sizesInCart={sizesInCart}
          isBottomSheet={isBottomSheet}
          isInWishList={wishList && wishList?.includes(id)}
          onWishListPress={() => handleWishListClick('bottom-sheet')}
        />
      )}
    </PdpFooterContext.Provider>
  );
};

export default memo(PdpFooter);
