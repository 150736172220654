import { CSSProperties } from 'react';
import { Box, Drawer } from '@mui/material';

import { WIDTH } from '@Constants/common';
import CloseButton from '@assets/icons/CloseButton.svg';

import styles from './styles';
import { primaryFont } from '@Utils/fonts';

interface BottomSheetProps {
  children?: JSX.Element;
  handleClose: () => void;
  open: boolean;
  bottomSheetStyle?: CSSProperties;
  backgroundColor?: string;
  showCloseButton?: boolean;
  fullScreen?: boolean;
  overflow?: string;
}

const BottomSheet = ({
  children,
  handleClose,
  open,
  bottomSheetStyle,
  backgroundColor,
  showCloseButton = false,
  fullScreen = false,
  overflow= 'visible'
}: BottomSheetProps) => {
  return (
    <Drawer
      anchor='bottom'
      open={open}
      onClose={() => {}}
      onBackdropClick={handleClose}
      PaperProps={{
        style: {
          maxWidth: WIDTH,
          overflow: overflow,
          margin: 'auto',
          ...(!fullScreen ? { borderTopRightRadius: '16px', borderTopLeftRadius: '16px' } : { height: '100%' }),
          backgroundColor: 'transparent',
          boxShadow: '0px 0px 0px'
        }
      }}
    >
      {showCloseButton && !fullScreen && (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'end' }} onClick={handleClose}>
            <CloseButton style={{ margin: '16px', cursor: 'pointer' }} />
          </Box>
        </>
      )}
      <Box
        className={primaryFont.className}
        sx={[
          bottomSheetStyle ?? styles.wrapper,
          { backgroundColor: backgroundColor ?? 'white', ...(!fullScreen ? { borderRadius: '16px 16px 0px 0px' } : { height: '100%' }) }
        ]}
      >
        {children}
      </Box>
    </Drawer>
  );
};

export default BottomSheet;
