import { getPriceRange } from '@Utils/format';
import WishListIcon from '@assets/icons/WishList.svg';
import { useRouter } from 'next/router';
import WishListActiveIcon from '@assets/icons/WishlistActive.svg';
import { Amount, SimpleInstallments, ProductTag } from '@Components/index';
import { colors } from '@Constants/colors';
import styles from './styles';
import { ProductPriceInfoProps } from './types';
import { useInView } from 'react-intersection-observer';
import useTranslation from '@Utils/useTranslation';
import { CSSProperties, memo, useEffect, useContext } from 'react';
import { TrackerContext } from '_app';
import { getTrackingEventObject } from '@Utils/common';
import { PDP_PAGE_EVENTS } from '@Constants/events';
import { PdpContext } from '@Containers/Product-Details';
import { RootState, useAppSelector } from '@Store/store';

const disabledTag = {
  textColor: '#FFF',
  color: '#F83849',
  name: 'OUT OF STOCK'
}

const ProductPriceInfo = ({
  title,
  variations,
  selectedSize,
  onWishListPress,
  isInWishList,
  styleId = '',
  showSku = false,
  productId = 0,
  tag,
  isBottomSheet,
  selectedVariant
}: ProductPriceInfoProps) => {
  const priceDetails = getPriceRange(variations);
  const router = useRouter();
  const { t: translate } = useTranslation();
  const track = useContext(TrackerContext);

  const { ref, inView } = useInView({ threshold: 0.5, triggerOnce: false, trackVisibility: false, delay: 0 }),
        { notifyOosFlag, isOutOfStock } = useContext(PdpContext) ?? {},
        { selectedProductOutOfStock } = useAppSelector((state: RootState) => state.productDetails),
        disabled = notifyOosFlag && (isOutOfStock || selectedProductOutOfStock),
        disabledStyles = disabled ? ' opacity-[0.4] ' : '';

  useEffect(() => {
    if (inView) {
      track(getTrackingEventObject(0, PDP_PAGE_EVENTS.PDP_FEED_TYPE, PDP_PAGE_EVENTS.PRICE_VIEW, productId, router.asPath, { meta: { price_visible: inView } }));
    }
  }, [inView]);


  return (
    <>
      <div style={{...styles.containerWishlist as CSSProperties, ...(isBottomSheet && { paddingLeft: '16px'})}}>
        <div className={`${ !isBottomSheet? 'pl-[16px]' : ''}`}>
          <ProductTag tag={disabled ? disabledTag : tag} />
          <h1 style={!isBottomSheet ? styles.productTitle : styles.bottomSheetpProductTitle} className={disabledStyles} >{title}</h1>
        </div>
      {!isBottomSheet && <div>
        {isInWishList ? (
          <WishListActiveIcon
            style={{ paddingRight: '4px', cursor: 'pointer', width: 34 }}
            onClick={onWishListPress}
            fill={colors.PRIMARY}
          />
        ) : (
          <WishListIcon style={{ paddingRight: '4px', cursor: 'pointer' , width: 34}} onClick={onWishListPress} />
        )}
        </div> }
      </div>
      <div className={disabledStyles}>
        {showSku ? (
          <div style={styles.visibleProductSku}>{`${styleId} | ${productId ? productId : ''}`}</div>
        ) : (
          <div style={styles.hiddenProductSku}>{`${styleId} | ${productId ? productId : ''}`}</div>
        )}
      </div>
      <div ref={ref} style={styles.productPrice as CSSProperties} className={disabledStyles}>
        {priceDetails.discount !== 0 && (
          <div style={styles.flex}>
            <div style={styles.offerTag}>{`-${priceDetails.discount}%`}</div>
            <div style={styles.offerTagRibbon}></div>
          </div>
        )}

        <div style={styles.productNewPrice}>
          {selectedSize === '' ? (
            <div>
              {priceDetails.shouldShowPriceRange ? (
                <div style={{ display: 'flex', flexDirection: 'row', gap: '1px' }}>
                  <Amount
                    amount={priceDetails.minSalePrice}
                    style={styles.productNewPrice}
                    currencyStyle={{ height: '13px', width: '13px' }}
                    strokeWidth={2}
                  />
                  -
                  <Amount
                    amount={priceDetails.maxSalePrice}
                    style={styles.productNewPrice}
                    currencyStyle={{ height: '13px', width: '13px' }}
                    strokeWidth={2}
                  />
                </div>
              ) : (
                <Amount
                  amount={priceDetails.minSalePrice}
                  style={styles.productNewPrice}
                  currencyStyle={{ height: '13px', width: '13px' }}
                  strokeWidth={2}
                />
              )}
            </div>
          ) : (
            <Amount
              amount={selectedVariant?.[0]?.sale_price}
              style={styles.productNewPrice}
              currencyStyle={{ height: '10.5px', width: '10.5px' }}
              strokeWidth={3}
            />
          )}
        </div>
        {priceDetails.discount !== 0 && (
          <>
            <Amount
              amount={priceDetails.regularPrice}
              style={styles.productOldPrice}
              currencyStyle={{ height: '10.5px', width: '10.5px' }}
              oldPrice
            />
          </>
        )}
      </div>
      <div className={`mt-[2px] ${!isBottomSheet ? 'px-[16px]': 'pl-[16px] text-left'} text-[14px] leading-[20px] text-nm_light_gray_3 ${disabledStyles} `}>{translate('inclusive_of_all_taxes')}</div>
      <div style={styles.simpleInstallments} className={disabledStyles}>
        <SimpleInstallments
          isVisible={false}
          installmentPrice={
            selectedSize === ''
              ? Math.round(priceDetails?.minSalePrice || 0 / 3)
              : Math.round(Number(selectedVariant?.[0]?.sale_price || 0) / 3)
          }
        />
      </div>
    </>
  );
};

export default memo(ProductPriceInfo);
