import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState = {
  bottom_sheet_open: '',
};

export const recommendationSheetSlice = createSlice({
  name: 'recommendationSheet',
  initialState,
  reducers: {
    setRecommendationSheet: (state, action: PayloadAction<string>) => {
      state.bottom_sheet_open = action.payload;
    },
    resetRecommendationSheetSlice: () => initialState
  }
});

export const { setRecommendationSheet, resetRecommendationSheetSlice } = recommendationSheetSlice.actions;
export default recommendationSheetSlice.reducer;
