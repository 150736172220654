import { colors } from '@Constants/colors';

const styles = {
  container: {
    width: '100%',
    padding: '12px',
    background: 'linear-gradient(123.22deg, #DFFFF0 -0.47%, #FFFFFF 43.16%)',
    display: 'flex',
    flexDirection: 'column'
  },
  bogoContent: {
    padding: '12px 16px 0px 16px',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px'
  },
  bogoProducts: {
    marginTop: '7px',
    marginLeft: '16px',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px'
  },
  getDiscount: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    color: colors.DIM_GRAY
  }
};

export default styles;
