import { useState } from 'react';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useTranslation from '@Utils/useTranslation';

import { SelectBox } from '@Components/index';
import { PrimaryButtons, STOCK_STATUS } from '@Constants/common';
import { useAppSelector } from '@Store/store';
import { isNonEmptyObject, isNonEmptyString } from '@Utils/checks';
import FastDeliveryIcon from '@assets/icons/FastDelivery.svg';
import ReturnIcon from '@assets/icons/ReturnIcon.svg';
import DeleteWishlist from '@assets/icons/DeleteWishlist.svg';
import { useLoggedIn } from '@Utils/common';
import { getDateRangeInText } from '@Utils/format';
import SizeSelectorBottomSheet from '@Components/SizeSelectorBottomSheet';
import ConfirmationPopup from '@Components/ConfirmationPopup';
import Amount from '@Components/Amount';
import NoReturns from '@Components/NoReturns';
import CustomDialog from '@Components/CustomModal';
import { CartItemCardProps } from './types';
import styles from './styles';

const CartItemCard = ({
  index,
  cartItem,
  handleProductUpdate,
  returnWindow,
  handleRemoveFromCart 
}: CartItemCardProps) => {
  const productVariations = useAppSelector((state) => state.cart.cart_data.product_variations);
  const {
    thumb_url,
    title,
    line_subtotal,
    line_discount,
    variation_id,
    parent_variation_id,
    product_id,
    parent_id,
    slug,
    variation,
    quantity,
    is_returnable
  } = cartItem;

  const { t: translate } = useTranslation();
  const [openSizeSelectorSheet, setOpenSizeSelectorSheet] = useState('');
  const [selectedSize, setSelectedSize] = useState(variation?.attribute_pa_size ?? '');
  const [selectedQuantity, setSelectedQuantity] = useState(quantity ?? 1);
  const navigate = useNavigate();
  const { isUserLoggedIn } = useLoggedIn();

  const variations =
    productVariations && Object.keys(productVariations).length > 0
      ? productVariations[product_id]
      : cartItem?.variations;

  const { fast_delivery, stock_status } =
    variations?.find((item: any) => {
      return item.variation_id === variation_id;
    }) || {};

  const deliveryDate = fast_delivery ? cartItem.fast_delivery_date : cartItem.normal_delivery_date;

  const onCardPress = () => {
    navigate(`/product/${slug || product_id}/`);
  };

  const handleBottomSheetOpen = () => {
    setOpenSizeSelectorSheet(PrimaryButtons.UPDATE);
  };

  const findSizeDetails = (size: string) =>
    variations?.find((item) => {
      const value = isNonEmptyObject(productVariations) ? item.variation.attribute_pa_size : item.size;

      return value === size;
    });

  const handlePrimaryButtonClick = () => {
    const size = findSizeDetails(selectedSize);

    handleProductUpdate(
      {
        finalSize: selectedSize,
        finalQuantity: selectedQuantity,
        size,
        variation,
        variation_id: parent_variation_id || variation_id,
        product_id,
        quantity,
        index
      },
      cartItem
    );

    setOpenSizeSelectorSheet('');
  };

  const handleRemove = () => {
    handleRemoveFromCart?.({product_id, variation_id: parent_variation_id || variation_id, variation, index, title, line_subtotal, quantity} , true, cartItem)
  }

  return (
    <Box sx={styles.container}>
      {/* Product Image */}
      <Box onClick={onCardPress} sx={styles.imageContainer}>
        {stock_status === STOCK_STATUS.outOfStock && (
          <>
            <Box sx={styles.outOfStockOverlay} />
            <Box sx={styles.outOfStock}>{translate('out_of_stock')}</Box>
          </>
        )}

        <img
          width='100%'
          height='100%'
          alt='cart_item'
          src={thumb_url}
          style={{ objectFit: 'cover', position: 'absolute', left: 0, top: 0, objectPosition: 'top' }}
        />
      </Box>

      {/* Details */}
      <Box sx={styles.details}>
        <Box sx={styles.title}>{title}</Box>

        {/* Size and quantity */}
        <Box sx={styles.selectContainer}>
          <SelectBox
            label={'Size'}
            value={variation.attribute_pa_size ?? ''}
            onSelectBoxPress={handleBottomSheetOpen}
          />
          <Box sx={styles.quantitySelectContainer}>
            <SelectBox label={'Qty'} value={quantity?.toString() || '1'} onSelectBoxPress={handleBottomSheetOpen} />
          </Box>
        </Box>

        {/* Price container */}
        <Box sx={styles.priceContainer}>
          <Amount amount={line_subtotal} style={styles.discountPrice} />
          {line_discount !== 0 && (
            <>
              <Amount
                amount={line_subtotal + line_discount}
                style={styles.price}
                currencyStyle={{ height: '9px', width: '9px' }}
                oldPrice
              />
            </>
          )}
        </Box>

        <Box sx={styles.divider} />

        {/* Footer */}
        <Box>
          <Box sx={styles.footer}>
            <Box>
              {fast_delivery && !isUserLoggedIn && (
                <Box sx={styles.fastDelivery}>
                  <FastDeliveryIcon />
                  {translate('fast_delivery')}
                </Box>
              )}
              {isUserLoggedIn && isNonEmptyString(deliveryDate) && (
                <Box style={styles.delivery}>{`${translate('expected_delivery')}: ${getDateRangeInText(
                  deliveryDate || ''
                )}`}</Box>
              )}
            </Box>
            <Box onClick={handleRemove} sx={styles.removeButton}>
              <DeleteWishlist style={{ marginTop: '5px' }} color='#14664B' />
            </Box>
          </Box>

          {cartItem.hasOwnProperty.call(cartItem, 'is_returnable') && !is_returnable ? (
            <NoReturns />
          ) : (
            <Box mt={1} sx={styles.returnContainer}>
              <ReturnIcon style={{ marginRight: '6px' }} />
              <Box sx={styles.returnText}>{`${returnWindow || 0} ${translate('return_window')}`}</Box>
            </Box>
          )}
        </Box>
      </Box>
      <SizeSelectorBottomSheet
        open={openSizeSelectorSheet}
        setOpen={setOpenSizeSelectorSheet}
        variations={variations ?? []}
        showQuantitySelector
        selectedSize={selectedSize}
        setSelectedSize={setSelectedSize}
        selectedQuantity={selectedQuantity}
        setSelectedQuantity={setSelectedQuantity}
        id={product_id?.toString() ?? ''}
        handlePrimaryButtonClick={handlePrimaryButtonClick}
        isOutOfStock={stock_status === STOCK_STATUS.outOfStock}
      />
    </Box>
  );
};

export default CartItemCard;
