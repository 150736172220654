import { CSSProperties, useState } from 'react';
import { CarouselDataItem } from '@Components/CustomCarousel/types';
import CloseIconWhite from '@assets/icons/FilterSheetCloseIcon.svg';
import { FullScreenImageCarouselProps } from './types';
import styles from './styles';
import useTranslation from '@Utils/useTranslation';
import GreenTick from '@assets/icons/GreenTick.svg';
import Ellipse from '@assets/icons/EllipseWhite.svg';
import { formatDateString } from '@Utils/format';
import { getFilledArray, getUnFilledArray } from '../RatingReviewSheet/RatingUtlis';
import Rating from '@assets/icons/star.svg';
import RatingNew from '@assets/icons/RatingNew.svg';
import RightArrowButton from '@assets/icons/RightArrowButton.svg';

const isMobileView = () => window.innerWidth < 640;

const FullScreenImageCarousel = ({ carouselData, onClose, selectedIndex, newReview, review, handleCarouselForwardChange, handleCarouselBackwardChange, imgCount, showForwardClick = true, showBackwardClick = true}: FullScreenImageCarouselProps) => {

  const { t: translate } = useTranslation();

  const [isZoomed, setIsZoomed] = useState(false);
  // const [activeIndex, setActiveIndex] = useState(selectedIndex);
  const [transformStyle, setTransformStyle] = useState({
    transform: 'scale(1.5) translateY(0%)'
  });

  const handleFullScreenImageClose = (e) => {
    const { id } = e.target;

    if(id.includes('fullscreen-img'))
      return;

    onClose();
  };

  const fullScreenImagePress = (e: any) => {
    const clickedY = e.clientY;
    const containerHeight = e.currentTarget.clientHeight;

    const third = containerHeight / 3;

    setIsZoomed(!isZoomed);

    if (clickedY <= third)
      setTransformStyle({
        transform: 'scale(1.5) translateY(25%)'
      });
    else if (clickedY <= third * 2)
      setTransformStyle({
        transform: 'scale(1.5) translateY(0%)'
      });
    else
      setTransformStyle({
        transform: 'scale(1.5) translateY(-25%)'
      });
  };

  const handleCarouselClick = (index: number, e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, click) => {
    e.stopPropagation();
    if(click)
      handleCarouselForwardChange(index);
    else handleCarouselBackwardChange(index);
  };

  const fullScreenImageRenderItem = (item: CarouselDataItem, index: number) => (
    <div key={index}>
       <div 
      //  onClick={fullScreenImagePress} 
       style={{
        // margin: '0 100%',
        height: isZoomed ? '100%' : '80%',
        ...(isZoomed ? transformStyle : {}),
        ...(!newReview && {display:'flex'})
      }}>
      <img id={`fullscreen-img-${index}`} src={item?.url} alt='carousel-images' style={{...styles.image, 
        // cursor: isZoomed ? 'zoom-out' : 'zoom-in'
        } as CSSProperties} />
      </div>
    </div>
  );

  return (
    
    <div style={{...styles.fullScreenImage, ...(newReview && {backgroundColor:'#1A1A1A', maxWidth:'450px', boxSizing:'border-box', display:'block', overflowX: 'hidden', overflowY: 'scroll', scrollbarWidth: 'none'}) }} onClick={handleFullScreenImageClose}>
      <div style={{...styles.closeButton, ...(newReview && {top:5}),
        // ...(isMobileView() && {top: 20})
        } as CSSProperties}>
        <CloseIconWhite/>
      </div>
      {fullScreenImageRenderItem(carouselData?.[selectedIndex], selectedIndex)}
      {newReview && imgCount > 1 && showForwardClick && <RightArrowButton onClick={(e) => handleCarouselClick(carouselData?.[selectedIndex],e, true)} style={{position: 'absolute', right: '14px', bottom: '60%', cursor: 'pointer'}}/>}
      {newReview && imgCount > 1 && showBackwardClick && <RightArrowButton onClick={(e) => handleCarouselClick(carouselData?.[selectedIndex],e, false)} style={{position: 'absolute', left: '14px', bottom: '60%', cursor: 'pointer', transform : 'rotate(180deg)'}}/>}
      {newReview && <div className='text-nm_white text-start mx-4 mb-4'>

        <div className='my-2'>
          {getFilledArray(review.rating).map((item: Key | null | undefined) => (
          <Rating key={item} />
          ))}
          {getUnFilledArray(review.rating).map((item) => (
          <RatingNew key={item} />
          ))}
        </div>

        <div className='text-[14px] mb-2'>{review?.review_content}</div>

        <div className='text-[12px] mb-1'>{review?.review_author}</div>

        <div className='flex items-center gap-2'>
          <GreenTick />
          <div className='text-[12px]'>{translate('verified_buyer')}</div>
          <Ellipse />
          <div className='text-[12px]'>{formatDateString(review.review_date)}</div>
        </div> 

        <div className='mt-3'>
        <div className={`text-nm_black_2 border-solid border-nm_light_gray_7 bg-nm_white_smoke rounded-lg text-[12px] px-[8px] py-[6px] w-fit ${!review.size ? 'hidden' : ''} `}>Size bought: {review?.size.toUpperCase()}</div>
        </div>
      </div>}
    </div>
  );
};

export default FullScreenImageCarousel;