import Link from 'next/link';
import React, { memo, useEffect, useState } from 'react';
import styles from '../../styles';
import { getHoardingURL } from '@Utils/common';

const BannerImage = ({ appConfigData }) => {
  const [imageUrl, setImageUrl] = useState('');
  const [redirectUrl, setRedirectUrl] = useState('');

  useEffect(() => {
    const hoardings = appConfigData?.data?.additional_meta_data?.hoardings ?? {};
    const [url, redirect] = getHoardingURL(hoardings);

    if (url) setImageUrl(url);
    if (redirect) setRedirectUrl(redirect);
  }, [appConfigData]);

  return (
    imageUrl && (
      <Link href={redirectUrl ?? ''} style={redirectUrl ? styles.emptyLinkStyle : styles.linkStyle} target="_blank">
        <img style={styles.imageStyle} src={imageUrl} alt="Banner text here" />
      </Link>
    )
  );
};

export default memo(BannerImage);
