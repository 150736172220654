const ISSERVER = typeof window === "undefined";

const LocalStorage = {
  setItem: (key: string, value: string) => {
    if(!ISSERVER) {
      try{
        localStorage.setItem(key, value);
      }
      catch(e){
        console.log(e);
      }
    }
  },

  getItem: (key: string) => {
    const value = ISSERVER ? null : localStorage.getItem(key);
    return value;
  },

  removeItem: (key: string) => {
    if(!ISSERVER) {
      localStorage.removeItem(key);
    }
  }
};

export default LocalStorage;

export const SessionStorage = {
  setItem: (key: string, value: string) => {
    if(!ISSERVER) {
      sessionStorage.setItem(key, value);
    }
  },

  getItem: (key: string) => {
    const value = ISSERVER ? null : sessionStorage.getItem(key);
    return value;
  },

  removeItem: (key: string) => {
    if(!ISSERVER) {
      sessionStorage.removeItem(key);
    }
  },

  clear: () => {
    if(!ISSERVER) {
      sessionStorage.clear();
    }
  }
};

