import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useContext, useMemo, useCallback, createContext } from 'react';
import { useInView } from 'react-intersection-observer';
import useTranslation from '@Utils/useTranslation';
import LeftArrow from '@assets/icons/LeftArrow.svg';
import AppBar from '@Components/AppBar';
import TitleBarText from '@Components/TitleBarText';
import { Breadcrumb } from '@Components/index';
import Schema from '@Components/Schema';
import ProductRatingSchema from '@Components/Schema/productRatingSchema';
import { BannerImage, ProductDetailsCarousel, ProductHeroImg, ProductInfoWrapper, ReferAndEarn, SimilarProducts, TickerModule } from './components';
import { TrackerContext } from '_app';
import styles from './styles';
import LocalStorage from '@Utils/storage';
import { ROUTES } from '@Constants/routes';
import { RootState, useAppSelector, useAppDispatch } from '@Store/store';
import { getTrackingEventObject, scrollToContainer } from '@Utils/common';
import { isNonEmptyArray, isNonEmptyString } from '@Utils/checks';
import RatingModule from './components/RatingModule';
import ViewSimilarProducts from './components/ViewSimilarProducts';
import { resetShopScroll } from '@Containers/ScrollRestoration/scrollSlice';
import { CONTAINER_IDS } from './constants';
import { setSelectedProductInStock, setSelectedProductOutOfStock } from './productDetailsSlice';

export const PdpContext = createContext({}),
       randomSort = (arr) => arr?.sort(() => Math.random() - 0.5);

const getParsedCarousalData = (data: any[]) =>
  data?.map((item: any) => ({
    url: item,
    type: 'image'
  }));

const getParsedVideoCarousalData = (data: any[]) =>
  data?.map((item: any) => ({
    ...item,
    type: 'video'
  }));

const getVariationInfo= (variations) => {
  const fewItemsLeft: Array<string> = [];
  let isInStock = false;

  variations.forEach((variation)=>{
    if(isNonEmptyString(variation.variation_disclaimer))
      fewItemsLeft.push(variation.size);
    if(!isInStock && variation.stock_status === 'instock')
      isInStock = true;
  })

  return { isInStock, fewItemsLeft };
}

const ProductDetails = ({ bannerHeight, isOutOfStock, notifyOosProduct, productData = [], similarProductsData = {}, bogoTimeLeft, bogoMetaData, isWebAppConfigLoading, isBottomSheet = false, showNewReviewUi }) => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const { t: translate } = useTranslation();
  const track = useContext(TrackerContext);
  const { user } = useAppSelector((state: RootState) => state.profile),
  { ref, inView } = useInView({ threshold: 0.1, triggerOnce: false, trackVisibility: false, delay: 0 });

  const { product_details: productDetails } = { product_details: productData };
  const product = productDetails?.[0];

  const price = product?.variations?.[0]?.sale_price;

  const breadcrumbs = productDetails?.[0]?.breadcrumbs;
  const id = product?.product_id ?? 0;

  const { app_config_data: appConfigData } = useAppSelector((state: RootState) => state.layout);
  const { isInStock, fewItemsLeft } = useMemo(()=> getVariationInfo(product?.variations ?? []), [product?.variations]);
  const { bottom_sheet_open: configKey } = useAppSelector((state: RootState) => state.recommedationSheet);

  useEffect(() => {
    try {
      if (product == undefined) return;
      const id = product?.product_id;
      const slug = product?.slug;
      const thumb_url = product?.image_url;
      const title = product?.name;
      const sale_price = product?.variations?.[0]?.sale_price;

      if(isOutOfStock)
        dispatch(setSelectedProductOutOfStock());

      let all_viewed_products = JSON.parse(LocalStorage.getItem('viewedProducts'));
      let all_viewed_products_data = JSON.parse(LocalStorage.getItem('viewedProductsData'));

      if (!user?.id) {
        if (!all_viewed_products) {
          LocalStorage.setItem('viewedProducts', JSON.stringify([id]));
          LocalStorage.setItem('viewedProductsData', JSON.stringify({ [id]: { id, slug, thumb_url, title, sale_price } }));
        } else {
          const index = all_viewed_products.indexOf(id);
          if (index > -1) all_viewed_products.splice(index, 1);
          all_viewed_products.unshift(id);
          LocalStorage.setItem('viewedProducts', JSON.stringify(all_viewed_products));
          LocalStorage.setItem(
            'viewedProductsData',
            JSON.stringify({
              ...all_viewed_products_data,
              ...{ [id]: { id, slug, thumb_url, title, sale_price } }
            })
          );
        }
      }
    } catch (error) {
      console.error('-----PDP Adding PID in local Store Error Start----');
      console.error(error);
      console.error('-----PDP Adding PID in local Store Error End----');
    }
    const obj = getTrackingEventObject(-1, 'pdp', 'product-viewed', product?.product_id, router.asPath, { meta: { price: price ,...(isNonEmptyArray(fewItemsLeft) && {few_item_left: fewItemsLeft}), ...(product?.ticker_data && {tickerData: product?.ticker_data})}});
    track(obj);

    return () => { 
      dispatch(setSelectedProductInStock()); 
    }
  }, [product]);

  const scrollToSimilarProducts = useCallback((meta) => {
    scrollToContainer(`#${CONTAINER_IDS.REFER_AND_EARN_CONTAINER}`);
    track(getTrackingEventObject(-1, 'pdp', 'view-similar-products-cta-click', id, router.asPath, meta ? { meta: meta } : {}));
  }, [id, track]);

  const renderUI = () => {
    const isCarousel = product?.gallery && product?.gallery?.length + product?.video_gallery?.length > 1;
    return (
    <PdpContext.Provider value={{ scrollToSimilarProducts: scrollToSimilarProducts, notifyOosFlag: notifyOosProduct, isOutOfStock: isOutOfStock, showNewReviewUi: showNewReviewUi }}>
    <div style={ isBottomSheet ? styles.bottomSheetContentWrapper : styles.contentWrapper }>
      <BannerImage appConfigData={appConfigData} />
      <div ref={ref}>
        {((isBottomSheet && configKey === 'initial') || !isBottomSheet) && <div style={{ position: 'relative' }}>
          {isCarousel? (
            <ProductDetailsCarousel
              carouselData={getParsedCarousalData(product?.gallery)}
              carouselVideoData={getParsedVideoCarousalData(product?.video_gallery)}
              imageMetadata={product?.image_gallery_metadata}
              productId={product?.product_id}
            />
          ) : (
            <ProductHeroImg product={product} />
          )}
          <div className={`w-full absolute flex items-center ${ !isBottomSheet ? 'justify-between' : 'justify-end'}` + (isCarousel ? ' bottom-[34px]' : ' bottom-[10px]') }>
            {!isBottomSheet && <ViewSimilarProducts productId={product?.product_id}/>}
            <RatingModule id={id} rating={product?.rating} ratingCount={product?.rating_count} showNewReviewUi={showNewReviewUi} />
          </div>
          <TickerModule id={id} product={product} />
        </div>}
        <ProductInfoWrapper appConfigData={appConfigData} product={product} productDetails={productDetails} isBottomSheet = {isBottomSheet} configKey={configKey}/>
      </div>
      {!isBottomSheet &&<hr style={styles.divider} />}
      {!isBottomSheet && <ReferAndEarn type = {'pdp'} />}
      {!isBottomSheet &&<hr style={styles.divider} />}
      {!isBottomSheet && <SimilarProducts productName={product?.name ?? ''} id={String(id) ?? ''} similarProductsData={similarProductsData} bannerHeight={bannerHeight} productInfoVisible={inView} />}

      {!isBottomSheet &&<hr style={styles.divider} />}
      {!isBottomSheet && <Link href={`${ROUTES.SHOP}?product_cat=${product?.category || ''}`} onClick={() => dispatch(resetShopScroll())} className='customButton customButton--noScale customButton--transparent' style={styles.viewAll}>
        <div>{`${translate('all')} ${product?.category}`}</div>
        <LeftArrow style={{ transform: 'rotate(180deg)' }} stroke="#1D6B46" />
      </Link>}
      {breadcrumbs && !isBottomSheet && <Breadcrumb callback={() => {}} breadcrumbs={breadcrumbs} />}
    </div>
    </PdpContext.Provider>)
  };

  return (
    <div style={ configKey == 'initial' ? styles.bottomSheetRoot : styles.root}>
      {!!breadcrumbs?.length && isInStock && <Schema breadcrumbs={breadcrumbs} />}
      {productDetails?.length && isInStock && <ProductRatingSchema productDetails={product} />}
      <TitleBarText text={product?.name ? `${product?.name} - newme` : 'newme'} productName={product?.name} isTitle />
      { !isBottomSheet && <AppBar showBackButton bogoSaleTimeLeft={bogoTimeLeft} bogoMetaData={bogoMetaData} isWebAppConfigLoading={isWebAppConfigLoading} />}
      {!!id && renderUI()}
    </div>
  );
};

export default ProductDetails;
