import { baseApi } from '@Services/api';
import { ProductPayload, ProductResponse, SimilarProductPayload, SimilarProductResponse } from './types';
import { setBottomSheetProductDetails } from './productDetailsSlice';
import { setRecommendationSheet } from '@Containers/RecommendationSheet/recommendationSheetSlice';

const productDetailsApis = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getProductDetails: builder.mutation<ProductResponse, ProductPayload>({
      query: (payload) => ({
        url: `products/details?gallery=true&reviews=true&variations=true&features=true&description=true&product_info=true&size_guide=true`,
        method: 'POST',
        body: payload
      })
    }),
    getSimilarProducts: builder.mutation<SimilarProductResponse, SimilarProductPayload>({
      query: (payload) => ({
        url: 'similar_products',
        method: 'POST',
        body: payload
      })
    }),
    getBottomSheetProductDetail: builder.mutation<ProductResponse, ProductPayload>({
      query: (payload) => ({
        url: `products/details?gallery=true&reviews=true&variations=true&features=true&description=true&product_info=true&size_guide=true`,
        method: 'POST',
        body: payload
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if(data){
            dispatch(setBottomSheetProductDetails(data));
          }
        } 
        catch (err) {
          dispatch(setRecommendationSheet(''));
          console.log(err);
        }
      }
    }),
  })
});

export const { useGetProductDetailsMutation, useGetSimilarProductsMutation, useGetBottomSheetProductDetailMutation } = productDetailsApis;
