import { colors } from '@Constants/colors';

const styles = {
  ordersDetails: {
    paddingTop: '0px'
  },
  titleText: {
    color: colors.FONT_SECONDARY,
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px'
  },
  priceInclusiveTax: {
    color: colors.DARK_GRAY,
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    paddingBottom: '20px'
  },
  orderKey: {
    color: colors.DIM_GRAY,
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '26px'
  },
  orderDetailsRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  messageContainer: {
    backgroundColor: colors.LIGHT_GREEN_3,
    padding: '10px',
    borderRadius: '4px',
    marginTop: '15px',
    display: 'flex',
    flexDirection: 'row'
  },
  messageText: {
    color: colors.FONT_SECONDARY,
    fontWeight: 500,
    fontSize: '11px',
    marginRight: '3px'
  },
  amountDetails: {
    borderStyle: 'dotted none none none',
    borderColor: colors.SILVER,
    marginTop: '16px',
    paddingTop: '16px'
  },
  amount: {
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '26px'
  },
  boldText: {
    color: colors.FONT_SECONDARY,
    fontWeight: 600,
    fontSize: '12px'
  },
  footer: {
    marginTop: '20px',
    backgroundColor: colors.LIGHT_GREEN_3,
    borderRadius: '4px',
    padding: '7px'
  },
  footerText: {
    color: colors.FONT_SECONDARY,
    fontWeight: 400,
    fontSize: '11px'
  }
};

export default styles;
