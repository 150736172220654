import { Box } from '@mui/material';

import CurrencyIcon from '@assets/icons/CurrencyIcon.svg';

interface PriceTextProps {
  text: string;
}

const PriceText = ({ text }: PriceTextProps) => {
  const getParsedText = (text: string) => {
    return text.split('₹');
  };

  const formatPriceText = (text: string[]) => (
    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
      {text.map((data, index) => (
        <Box sx={{ display: 'flex', alignItems: 'center' }} key={data}>
          <div>{data}</div>
          {index + 1 !== text.length && (
            <CurrencyIcon style={{ height: '10px', width: '10px', overflow: 'visible', marginLeft: '2px' }} />
          )}
        </Box>
      ))}
    </Box>
  );

  return formatPriceText(getParsedText(text));
};

export default PriceText;
