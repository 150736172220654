import { colors } from '@Constants/colors';

const styles = {
  productCard: {
    width: '48.5%',
    marginBottom: '17px',
    position: 'relative',
  },
  discountTag: {
    top: '8px',
    left: '8px',
    borderRadius: '23px',
    width: '26px',
    height: '26px',
    backgroundColor: colors.PRIMARY_DARK,
    color: 'white',
    fontWeight: 700,
    fontSize: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  ratingPill: {
    bottom: '15px',
    right: '8px',
    borderRadius: '23px',
    height: '20px',
    backgroundColor: colors.WHITE,
    color: 'black',
    fontWeight: 600,
    fontSize: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px 5px',
    opacity: '.75'
  },
  ratingstar: {
    height: '12px',
    width: '12px',
    marginLeft: '2px',
    fill: colors.ORANGE
  },
  priceContainerWishlist: {
    paddingLeft: '10px',
    minHeight: '38px'
  },
  priceContainer: {
    paddingLeft: '6px',
    display: 'flex',
    alignItems: 'center'
  },
  productPrice: {
    paddingLeft: '8px',
    paddingTop: '8px',
    display: 'flex',
    flexDirection: 'row',
    gap: '4px',
    alignItems: 'center'
  },
  productPriceRange: {
    paddingLeft: '8px',
    paddingTop: '8px',
    display: 'flex',
    flexDirection: 'row',
    gap: '4px',
    alignItems: 'center',
    color: colors.PRIMARY_DARK
  },
  productName: {
    paddingLeft: '8px',
    gap: '4px',
    alignItems: 'center',
    color: colors.DIM_GRAY,
    fontSize: '12px',
    textDecoration: 'none',
    display: 'block',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  recommendedProductsName: {
    marginBottom: '10px',
    color: '#6E7079',
    textOverflow: 'unset',
    overflow: 'unset',
    whiteSpace: 'unset',
  },
  recommendedProductsName: {
    marginBottom: 0,
    color: '#6E7079',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    display: 'block',
    height: 'auto',
  },
  price: {
    color: colors.PRIMARY_DARK,
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px'
  },
  productNewPrice: {
    color: colors.PRIMARY_DARK,
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    margin: '0px 1.5px'
  },
  productOldPrice: {
    color: colors.DIM_GRAY,
    fontWeight: 400,
    fontSize: '12px',
  },
  sizeCards: {
    // marginTop: '15px',
    paddingBottom: '2px',
    paddingLeft: '8px',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '4px'
  },
  deliveryDays: {
    paddingTop: '12px',
    paddingLeft: '2px',
    fontSize: '10px',
    fontWeight: 400,
    color: colors.DARK_GRAY,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '4px'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  addToBagButton: {
    width: '100%',
    height: '32px',
    backgroundColor: colors.PRIMARY,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    color: 'white',
    marginTop: '10px',
    textTransform: 'none',
    cursor: 'pointer',
    '&:hover': { backgroundColor: colors.PRIMARY },
    position: 'absolute',
    bottom: 0,
    borderRadius: '6px',
  },
  dummyBagButton: {
    height: '32px',
    marginTop: '10px'
  },
  videoIcon: {
    position: 'absolute',
    top: '50px',
    left: '8px',
  },
  disabledButton: {
    opacity: '50%',
    backgroundColor: colors.GREY,
    cursor: 'not-allowed',
  },
  flex: {
    display: 'flex',
  },
  offerTag: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#31B275',
    color: "#FFF",
    fontSize: "9px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    paddingLeft: '2px',
    paddingRight: '2px'
  },
  offerTagRibbon: {
    borderTop: '8px solid #31B27599',
    borderLeft: '2.5px solid #31B275',
    borderBottom: '8px solid #31B27599',
    borderRight: '2.5px solid white',
    marginRight: '3px'
  }
};

export default styles;
