import { getMetaDescription } from "@Constants/common";
import Head from 'next/head';

const DescriptionText = ({ text, pageNum }: { text: string, pageNum?: number }) => {
  return (
    <Head>
      <meta name='description' content={`${pageNum && pageNum != 1 ? 'Pg ' + pageNum + ' | ' : ''}${getMetaDescription(text, text)}`} />
    </Head>
  );
};

export default DescriptionText;
