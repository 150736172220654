import React, { useCallback, useEffect, useRef, useState, useContext } from 'react';
import TagManager from 'react-gtm-module';
import { GTM_ID } from '../constants/config';
import { getHoardingURL, parseMilliSeconds, redirectToApp, useLoggedIn, verifyUtmParamsChange } from '@Utils/common';
import { useLazyGetCartItemsQuery } from '@Containers/Cart/apiSlice';
import { useGetProfileMutation } from '@Containers/Profile/apiSlice';
import {
  useGetSystemConfigQuery,
  useLazyGetSystemConfigQuery,
  useLazyGetPageConfigQuery,
  useSendUserLocationMutation
} from '@Containers/Onboarding/apiSlice';
import { RootState, useAppDispatch, useAppSelector } from '@Store/store';
import { getProductIdsFromCart } from '@Utils/cart';
import { useGetProductDetailsMutation } from '@Containers/Product-Details/apiSlice';
import { addCartItemsToGtmProductList } from '@Utils/gtm';
import { hasAuthToken, isNonEmptyObject } from '@Utils/checks';
import { setShowLoginBottomSheet } from '@Containers/Login/loginSlice';
import { Loader, Footer } from '@Components/index';
import TopHeader from '@Components/TopBanner'; // old banner component changed to app banner
import AppBanner from '@Components/AppBanner';
import LoginBottomSheet from '@Components/LoginBottomSheet';
import LocalStorage, { SessionStorage } from '@Utils/storage';
import styles from './styles';
import { useRouter } from 'next/router';
import CanonicalHead from '@Reducers/CanonicalHead';
import { generate_deep_link } from '@Utils/deeplinkgenerator';
import { Pages, WebPopup } from '@Constants/common';
import PopupBanner from './components/PopupBanner';
import { SHOW_TRANSPARENT_GLOBAL_LOADER } from '@Constants/storageKeys';
import { useCountdownTimer } from '@Hooks/useCountdownTimer';
import SignupBottomSheet from '@Containers/SignupBottomSheet';
import { SIGNUP_SHEET_MODAL_SHOWN, SHOW_SIGN_UP_SUCCESS_OR_FAILURE_MODAL } from '@Constants/storageKeys';
import { setSignupFailurePopupOpen, setSignupSuccessPopupOpen } from '@Containers/SignupBottomSheet/signupBottomSheetSlice';
import { HandleContext } from '_app';
const tagManagerArgs = {
  gtmId: GTM_ID
};

const excludePopupPaths = ['/valentine', '/login'],
  POPUP_TIME_DELAY = 700,
  bannerPaths = ['/checkout', '/black-friday-sale', '/popup','/shop', '/collection'],
  bannerUrlSubstring = ['new-store', 'store-register', '/womens-collection/'],
  topHeaderPaths = ['/black-friday-sale', '/popup', '/checkout','/shop','/collection'],
  topHeaderUrlSubstring = ['new-store', 'store-register', '/womens-collection/'];

const showComponentByPath = (type, pathname, asPath) => {
  let pathnames = null,
    urlSubstrings = null;

  switch (type) {
    case 'banner':
      {
        pathnames = bannerPaths;
        urlSubstrings = bannerUrlSubstring;
      }
      break;
    case 'topHeader':
      {
        pathnames = topHeaderPaths;
        urlSubstrings = topHeaderUrlSubstring;
      }
      break;
    default:
      break;
  }

  return !(pathnames && pathnames.some((path) => pathname.includes(path))) && !(urlSubstrings && urlSubstrings.some((subString) => asPath.includes(subString)));
};

const getDefaultPopupData = (appConfig = {}) => {
  const { popup } = appConfig ?? {},
    { imageUrl } = popup?.default ?? {};

  return imageUrl ? { imageUrl: imageUrl } : {};
};

export const getActiveFooterOption = (pathname: string) => {
  if (pathname.includes(Pages.SHOP) || pathname.includes(Pages.WOMENS_COLLECTION) || pathname.includes(Pages.COLLECTION)) return Pages.SHOP;
  else if (pathname.includes(Pages.WISHLIST)) return Pages.WISHLIST;
  else if (pathname.includes(Pages.CATEGORY)) return Pages.CATEGORY;
  else if (pathname.includes(Pages.PROFILE)) return Pages.PROFILE;
  else return Pages.HOME;
};

export const pagesWithFooter = ['/home', '/womens-collection', '/wishlist'];

export const isFooterActiveForCurrentPage = (pathname: string) =>
  pathname === '/' || pathname === '/my-account' || pagesWithFooter.some((path) => pathname == path);

export const Layout = ({ children }) => {
  const handleFirstLoad = useContext(HandleContext)
  const [getProfile] = useGetProfileMutation();
  const [getCartData, { data: getCartDataResponse }] = useLazyGetCartItemsQuery();
  const [getProductDetails, { data: getProductDetailsResponse }] = useGetProductDetailsMutation();
  // const [getWishlistData] = useLazyGetWishlistQuery();
  const [getPageConfig, { isLoading: isWebAppConfigLoading }] = useLazyGetPageConfigQuery();
  const [getSystemConfig] = useLazyGetSystemConfigQuery();
  const { app_config_data: webAppDefaultConfig } = useAppSelector((state: RootState) => state.layout);

  const appBarRef = useRef(null);
  const appDownloadBarRef = useRef(null);

  const [height, setHeight] = useState<string>('0');
  const [bannerData, setBannerData] = useState<Record<string, string>>({});
  const [open, setOpen] = useState<boolean>(false);
  const [popupData, setPopupData] = useState<Record<string, string>>({});
  const [loginImageUrl, setLoginImageUrl] = useState<string>('');
  const [loginRedirectUrl, setLoginRedirectUrl] = useState<string>('');

  const [isLoading, setIsLoading] = useState(false);
  const [sendUserLocation] = useSendUserLocationMutation();

  const router = useRouter();

  const handleRouteChange = useCallback((url, { shallow }) => {
    if (!shallow) setIsLoading(true);
  }, []);

  const handleRouteChangeComplete = useCallback((url, { shallow }) => {
    setIsLoading(false);

    // remove show transparent loader sessionkey on route change compeletion - only once allowed per route change
    if (SessionStorage.getItem(SHOW_TRANSPARENT_GLOBAL_LOADER)) SessionStorage.removeItem(SHOW_TRANSPARENT_GLOBAL_LOADER);

    const showSignupModalSuccessOrFailureModal = SessionStorage.getItem(SHOW_SIGN_UP_SUCCESS_OR_FAILURE_MODAL);
    if(showSignupModalSuccessOrFailureModal){
      SessionStorage.setItem(SIGNUP_SHEET_MODAL_SHOWN, JSON.stringify(true));

      if(showSignupModalSuccessOrFailureModal === 'success')
        dispatch(setSignupSuccessPopupOpen(true));
      else if(showSignupModalSuccessOrFailureModal === 'failure')
        dispatch(setSignupFailurePopupOpen(true));

      SessionStorage.removeItem(SHOW_SIGN_UP_SUCCESS_OR_FAILURE_MODAL);
    }
  }, []);
  const handleLayoutFirstLoad = async () =>{
    await handleFirstLoad();
    TagManager.initialize(tagManagerArgs);
    if (hasToken) {
      getCartData({});
      getProfile({});
      // getWishlistData({});
    }

    // const redirectToAppTried = SessionStorage.getItem('redirect-to-app');

    // if(!redirectToAppTried){
    //   redirectToApp({ searchParams: router.query });
    //   SessionStorage.setItem('redirect-to-app', true);
    // }

    const utmString = verifyUtmParamsChange({ searchParams: router.query });
    getPageConfig({ page_type: 'default', utmString: utmString !== '' ? utmString : null });
    getSystemConfig({});
  }
  
  useEffect(() => {
    handleLayoutFirstLoad();

    router.events.on('routeChangeStart', handleRouteChange);
    router.events.on('routeChangeComplete', handleRouteChangeComplete);
    router.events.on('routeChangeError', handleRouteChangeComplete);
    // document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
      router.events.off('routeChangeError', handleRouteChangeComplete);
      // document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    if (router.pathname !== '/') {
      handleHeightChange(0);
    }
  }, [router.pathname]);

  // useGetSystemConfigQuery({});


  const { user, isUserfetched } = useAppSelector((state: RootState) => state.profile);
  const { isUserLoggedIn } = useLoggedIn();
  const { showLoginBottomSheet } = useAppSelector((state: RootState) => state.login);
  const dispatch = useAppDispatch();
  const hasToken = hasAuthToken();
  const isLoginBottomSheetOpen = showLoginBottomSheet && !isUserLoggedIn;
  const [bogoMilliSecLeft, setBogoMilliSecLeft] = useState(0),
    [bogoMetaData, setBogoMetaData] = useState({}),
    bogoCountDownTime = parseMilliSeconds(bogoMilliSecLeft);

  useCountdownTimer(
    () => {
      if (!bogoMilliSecLeft && bogoMilliSecLeft <= 0) return;

      setBogoMilliSecLeft((prevMilliSecLeft) => prevMilliSecLeft - 1000);
    },
    bogoMilliSecLeft && bogoMilliSecLeft > 0 ? 1000 : null,
    bogoMilliSecLeft,
    setBogoMilliSecLeft
  );

  // Fetching product details of ids from cart response (for gtm events)
  useEffect(() => {
    if (isUserLoggedIn && getCartDataResponse) {
      const gtmProductListIds = getProductIdsFromCart(getCartDataResponse.cart);

      if (gtmProductListIds && gtmProductListIds?.length > 0) getProductDetails({ product_ids: gtmProductListIds });
    }
  }, [getCartDataResponse, isUserLoggedIn]);

  // Adding product details to gtm product list
  useEffect(() => {
    const { product_details: productDetails } = getProductDetailsResponse || {};

    if (productDetails && productDetails.length > 0) addCartItemsToGtmProductList(productDetails);
  }, [getProductDetailsResponse]);

  useEffect(() => {
    const value = (appBarRef?.current && appBarRef?.current['clientHeight']) ?? 0;
    const downloadAppValue = (appDownloadBarRef?.current && appDownloadBarRef?.current['clientHeight']) ?? 0;
    let totalValue = value + downloadAppValue;
    setHeight(`${totalValue}px`);
  });

  let popupRedirectArray = webAppDefaultConfig?.additional_meta_data?.popup ?? {};

  const getPopupDeepLinkPath = useCallback(() => process.env.NEXT_PUBLIC_REACT_APP_WEB_URL + router.asPath, [router.asPath]);

  useEffect(() => {
    let banner = {},
      popupData = null,
      popupTimer;

    const data = webAppDefaultConfig?.additional_meta_data?.top_header ?? {};
    const { font_size: defaultFontSize, bg_color: bgColor, text_color: textColor } = data;
    popupRedirectArray = webAppDefaultConfig?.additional_meta_data?.popup ?? {};
    const pagePopupArray = webAppDefaultConfig?.additional_meta_data?.page_popup ?? {};

    if (Object.keys(data).includes(sessionStorage.getItem('utm_source') ?? '')) banner = data[sessionStorage.getItem('utm_source') ?? 'default'];
    else banner = data['default'] ?? {};

    if (Object.keys(popupRedirectArray).includes(sessionStorage.getItem('utm_source') ?? ''))
      popupData = popupRedirectArray[sessionStorage.getItem('utm_source') ?? 'default'];
    else popupData = popupRedirectArray['default'] ?? {};

    const hoardings = webAppDefaultConfig?.additional_meta_data?.hoardings ?? {};

    const [url, redirect] = getHoardingURL(hoardings);

    setLoginImageUrl(url);
    setLoginRedirectUrl(redirect);

    if (banner) setBannerData({ ...banner, defaultFontSize, bgColor, textColor });

    if (router.pathname.includes('/black-friday-sale') || router.pathname.includes('/popup') || router?.asPath?.includes('/login/?callback=')) return;

    clearTimeout(popupTimer);

    if (Object.keys(pagePopupArray).includes(router.pathname)) {
      setPopupData(pagePopupArray[router.pathname]);
      setTimeout(
        () => {
          setOpen(true);
        },
        pagePopupArray?.timeout ? parseInt(pagePopupArray?.timeout) : 10
      );
    } else if (
      user &&
      user?.download_app_nudge &&
      popupRedirectArray?.purchased_user &&
      !SessionStorage.getItem(WebPopup.APP_DOWNLOAD_POPUP) &&
      !excludePopupPaths.some((path) => router.pathname.includes(path))
    ) {
      let temp_popup_data = { ...popupRedirectArray?.purchased_user };
      let starting_deeplink = temp_popup_data?.deeplink;

      const deeplink_url = getPopupDeepLinkPath();
      const redirect_deeplink = generate_deep_link(deeplink_url, starting_deeplink);

      if (redirect_deeplink) temp_popup_data.redirectUrl = redirect_deeplink;

      setPopupData(temp_popup_data);
      popupTimer = setTimeout(() => setOpen(true), temp_popup_data?.timeout ? parseInt(temp_popup_data?.timeout) : POPUP_TIME_DELAY);
    } else if (
      !SessionStorage.getItem(WebPopup.WEB_POPUP) &&
      popupData?.imageUrl &&
      !SessionStorage.getItem(WebPopup.APP_DOWNLOAD_POPUP) &&
      !excludePopupPaths.some((path) => router.pathname.includes(path))
    ) {
      setPopupData(popupData);
      popupTimer = setTimeout(() => setOpen(true), popupData?.timeout ? parseInt(popupData?.timeout) : POPUP_TIME_DELAY);
    }

    if (webAppDefaultConfig) {
      const { is_bogo_live_for_user, bogo_metadata } = webAppDefaultConfig ?? {},
        { bogo_ms_left } = bogo_metadata ?? {};

      setBogoMetaData(bogo_metadata);

      // if (is_bogo_live_for_user && bogo_ms_left && bogo_ms_left > 0) setBogoMilliSecLeft(bogo_ms_left ?? 0);
      if (bogo_ms_left && bogo_ms_left > 0) setBogoMilliSecLeft(bogo_ms_left ?? 0);
      else setBogoMilliSecLeft(0);
    } else setBogoMilliSecLeft(0);

    // payment success page banner
    // if (webAppDefaultConfig?.additional_meta_data?.paymentSuccessBanner)
    //   setSuccessPageBanner(webAppDefaultConfig?.additional_meta_data?.paymentSuccessBanner);

    return () => clearTimeout(popupTimer);
  }, [webAppDefaultConfig, router.pathname, isUserfetched]);

  const handleClose = useCallback(() => setOpen(false), [setOpen]);
  const handleLoginBottomSheetClose = useCallback(() => dispatch(setShowLoginBottomSheet(false)), [setShowLoginBottomSheet]);

  const loginBannerImaqe = LocalStorage.getItem('loginBanner');

  const updatedChildren = React.Children.map(children, (child) => {
    return React.cloneElement(child, {
      ...{
        bannerHeight: height,
        isUserLoggedIn: isUserLoggedIn,
        is_bogo_live_for_user: webAppDefaultConfig?.is_bogo_live_for_user,
        ...(bogoMilliSecLeft && { bogoTimeLeft: bogoCountDownTime }),
        ...(bogoMetaData && { bogoMetaData: bogoMetaData }),
        isWebAppConfigLoading: isWebAppConfigLoading,
        skipReferAndEarn: webAppDefaultConfig?.additional_meta_data?.skip_refer_and_earn ?? false
      }
    });
  });

  const handleHeightChange = useCallback(
    (height: number) => {
      setHeight(`${height}px`);
    },
    [setHeight]
  );

  const handleTopHeaderClick = useCallback(() => {
    const popupData = getDefaultPopupData(webAppDefaultConfig?.additional_meta_data);

    if (isNonEmptyObject(popupData)) {
      setOpen(true);
      setPopupData(popupData);
    }
  }, [webAppDefaultConfig?.additional_meta_data]);

  useEffect(() => {
    try {
      if (navigator?.permissions && navigator?.permissions?.query) {
        hasAuthToken() &&
          navigator.permissions.query({ name: 'geolocation' }).then((value) => {
            if (value?.state === 'granted') {
              navigator.geolocation.getCurrentPosition((position) => {
                sendUserLocation({ latitude: position.coords.latitude, longitude: position.coords.longitude });
              });
            } else if (value?.state === 'prompt') {
              navigator.geolocation.getCurrentPosition((position) => {
                sendUserLocation({ latitude: position.coords.latitude, longitude: position.coords.longitude });
              });
            } else if (value?.state === 'denied') {
              // do nothing
            }
          });
      }
    } catch (e) {}
  }, [isUserLoggedIn]);

  return (
    <div id="root" style={styles.root}>
      <CanonicalHead />
        <>
          {showComponentByPath('banner', router.pathname, router.asPath) && (
            <AppBanner
              ref={appDownloadBarRef}
            />)
          }
          {showComponentByPath('topHeader', router.pathname, router.asPath) && (
            <TopHeader
              ref={appBarRef}
              bannerData={bannerData}
              onClick={handleTopHeaderClick}
              customStyles={{
                ...(isNonEmptyObject(popupData) && {cursor: 'pointer'})
              }}
              top={(appDownloadBarRef?.current !== null && appDownloadBarRef?.current['clientHeight']) ? `${appDownloadBarRef?.current['clientHeight']}px` : '0px'}
            />
          )}
          <div style={{ position: 'relative', height: `calc(100vh - ${height}`, paddingTop: height }}>
            <Loader isLoading={isLoading} isWhiteBackground={!SessionStorage.getItem(SHOW_TRANSPARENT_GLOBAL_LOADER)} customStyles={{zIndex: 3000}}/>
            {updatedChildren}
          </div>
          {isFooterActiveForCurrentPage(router.pathname) && <Footer />}
          {showComponentByPath('topHeader', router.pathname, router.asPath) && <PopupBanner open={open} handleClose={handleClose} popupData={popupData} handleWebPopUpClose={handleClose} />}
          {isLoginBottomSheetOpen && (
            <LoginBottomSheet
              open={isLoginBottomSheetOpen}
              onClose={handleLoginBottomSheetClose}
              imageUrl={loginImageUrl}
              redirectUrl={loginRedirectUrl}
            />
          )}
          <SignupBottomSheet />
          {/* To load image in background */}
          <div style={{ display: 'none' }}>
            <img src={loginBannerImaqe || ''} alt='login-banner' />
          </div>
        </>
    </div>
  );
};

