export const sizeChartData = {
  size_guide_info: {
    title: 'Measure your body',
    data: [
      {
        title: 'Bust',
        description: 'Measure your chest over the fullest part of your bust while wearing a bra that fits.'
      },
      {
        title: 'Waist',
        description: 'Measure your waist at the narrowest point, keeping the tape bitloose.'
      },
      {
        title: 'Hips',
        description: 'Stand with your feet together and measure around the fullest part of the hips.'
      }
    ]
  }
};

export const sizeChartDataLingerie = {
  size_guide_info: {
    title: 'How to measure?',
    data: [
      {
        title: 'Under Bust',
        description: 'Measure your chest over the fullest part of your bust while wearing a bra that fits.'
      },
      {
        title: 'Over Bust',
        description: 'Measure your waist at the narrowest point, keeping the tape bitloose.'
      }
    ]
  }
};
