import React, { CSSProperties, memo } from 'react';
import WishListActiveIcon from '@assets/icons/WishlistActive.svg';
import WishListIcon from '@assets/icons/WishList.svg';
import DeleteWishlist from '@assets/icons/DeleteWishlist.svg';
import Amount from '@Components/Amount';
import styles from '../../styles';
import { ProductCartType, colors } from '@Constants/common';

const ProductInfo = ({
  productId,
  type,
  discount,
  oldPrice,
  minSalePrice,
  maxSalePrice,
  shouldShowPriceRange,
  showWishList,
  wishList,
  handleWishListClick,
  handleDeleteWishlist,
  isRecommendedProducts
}) => {
  return (
    <div className='flex justify-between'>
      <div className={' mr-[2px] ' + (isRecommendedProducts ? ' items-baseline ' : ' flex-wrap ')} style={type === ProductCartType.WISHLIST ? styles.priceContainerWishlist : styles.priceContainer}>
        {discount > 0 && (
          <div style={styles.flex}>
            <div style={styles.offerTag}>{`-${discount}%`}</div>
            <div style={styles.offerTagRibbon}></div>
          </div>
        )}
        {oldPrice === minSalePrice ? (
          <Amount amount={minSalePrice} style={{...styles.price, ...(isRecommendedProducts && { color: '#000'})}} currencyStyle={{ height: '10px', width: '10px' }} />
        ) : (
          <div className={`flex ${isRecommendedProducts ? ' mt-0 gap-[2px] items-baseline ' : ' mt-[5px] flex-wrap '} mr-[4px]`}>
            {shouldShowPriceRange ? (
              <div style={styles.row as CSSProperties}>
                <Amount amount={minSalePrice} style={styles.productNewPrice} currencyStyle={{ height: '10.5px', width: '10.5px' }} strokeWidth={3} />
                <div style={styles.productNewPrice}>-</div>
                <Amount amount={maxSalePrice} style={styles.productNewPrice} currencyStyle={{ height: '10.5px', width: '10.5px' }} strokeWidth={3} />
              </div>
            ) : (
              <Amount amount={minSalePrice} style={{...styles.productNewPrice,  ...(isRecommendedProducts && { color: '#000'})}} currencyStyle={{ height: '10.5px', width: '10.5px' }} strokeWidth={3} />
            )}
            <Amount amount={oldPrice} style={styles.productOldPrice} currencyStyle={{ height: '9px', width: '9px' }} oldPrice />
          </div>
        )}
      </div>
      {showWishList &&
        wishList &&
        (wishList?.includes(Number(productId)) ? (
          <WishListActiveIcon style={{ cursor: 'pointer', width: 25 }} onClick={handleWishListClick} fill={colors.PRIMARY} />
        ) : (
          <WishListIcon style={{ cursor: 'pointer', width: 25}} onClick={handleWishListClick} />
        ))}
      {type && type === ProductCartType.WISHLIST && <DeleteWishlist style={{ paddingRight: '8px', cursor: 'pointer' }} onClick={handleDeleteWishlist} />}
    </div>
  );
};

export default ProductInfo;
