import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState = {
   recommended_products: {},
   buy_now_cart_items: [],
};

export const recommendedProductsSlice = createSlice({
  name: 'recommendedProducts',
  initialState,
  reducers: {
    setRecommendedProductsData: (state, action: PayloadAction<any>) => {
      state.recommended_products = action.payload;
    },
    resetRecommendedProductsData: (state) => {
      state.recommended_products = {};
    },
    setBuyNowCart: (state, action: PayloadAction<any>) => {
      state.buy_now_cart_items = action.payload;
    },
    resetRecommendedProductsSlice: () => initialState
  }
});

export const { setRecommendedProductsData, resetRecommendedProductsData, setBuyNowCart, resetRecommendedProductsSlice } = recommendedProductsSlice.actions;
export default recommendedProductsSlice.reducer;
