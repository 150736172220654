import { useState, useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';

const handleScroll = (setScrollDirection, setPreviousScrollY) => {
  const currentScrollY = window.scrollY;

  setPreviousScrollY((prevScroll) => {
    // Scroll Up
    if (currentScrollY < prevScroll) {
      setScrollDirection(-1);
    }
    // Scroll Down
    else if (currentScrollY > prevScroll) {
      setScrollDirection(1);
    }
    // No Scroll
    else {
      // Add a setTimeout to execute - TO BE HANDLED
    }

    return currentScrollY;
  });
};

const useScrollDetectionVertical = ({ onScrollCallback, skipAddingScrollListener = false }: { onScrollCallback?: () => void, skipAddingScrollListener?: boolean }) => {
  const [previousScrollY, setPreviousScrollY] = useState(0),
    [scrollDirection, setScrollDirection] = useState(0);
  /*
    Scroll Direction Mapping: 
    --> -1 is upwards scroll
    --> 0 is no scroll yet
    --> 1 is downwards scroll
  */

  const debounceScroll = useDebouncedCallback(
    () => {
      handleScroll(setScrollDirection, setPreviousScrollY);
    },
    30,
    { leading: true }
  );

  useEffect(() => {
    const handleUserScroll = () => {
      debounceScroll();
      onScrollCallback?.();
    }

    !skipAddingScrollListener &&
    window.addEventListener('scroll', handleUserScroll);

    return () =>
    !skipAddingScrollListener &&
      window.removeEventListener('scroll', handleUserScroll);
  }, []);

  return { isScrollingUp: scrollDirection === -1, isScrollingDown: scrollDirection === 1, debounceScroll: debounceScroll };
};

export default useScrollDetectionVertical;
