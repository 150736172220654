import { colors } from '@Constants/colors';

const styles = {
  productInfoTitle: {
    padding: '24px 16px',
    fontWeight: 600,
    fontSize: '14px',
    color: colors.FONT_SECONDARY
  },
  otherInfoTitle: {
    margin: '16px 16px 0px 16px'
  },
  productInfo: {
    paddingLeft: '17px',
    paddingRight: '16px',
    paddingTop: '16px',
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: 400,
    fontSize: '12px',
    textAlign: 'right'
  },
  productInfoItem: {
    color: colors.DIM_GRAY,
  },
  productInfoDivider: {
    margine: 0,
    marginLeft: '17px',
    marginRight: '16px',
    marginTop: '16px',
    color: '#F5F5F5',
    backgroundColor: '#F5F5F5',
    height: '2px',
    border: 'none',
  },
  moreButton: {
    display: 'flex',
    justifyContent: 'end',
    margin: '16px',
    fontWeight: 500,
    fontSize: '12px',
    cursor: 'pointer',
    color: colors.PRIMARY_DARK
  }
};

export default styles;
