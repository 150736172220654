import { memo, useContext } from 'react';
import { useRouter } from 'next/router';
import useTranslation from '@Utils/useTranslation';
import useLoginNavigation from '@Hooks/useLoginNavigation';
import Refer from '@assets/icons/Refer.svg';
import Amount from '@Components/Amount';
import { colors } from '@Constants/colors';
import { ROUTES } from '@Constants/routes';
import { CONTAINER_IDS } from '../../constants';
import styles from './styles';
import { TrackerContext } from '_app';
import { getTrackingEventObject } from '@Utils/common';
import  { PDP_PAGE_EVENTS, REFERRAL_EVENTS, GLOBAL_ACTION_TYPES } from '@Constants/events';

const ReferAndEarn = ({type}) => {
  const { t: translate } = useTranslation();
  const { navigateAfterLogin } = useLoginNavigation();
  const track = useContext(TrackerContext);
  const { asPath } = useRouter();

  const navigateToReferAndEarn = () => {
    navigateAfterLogin(ROUTES.REFERANDEARN)
    if(type === 'pdp')
      track(getTrackingEventObject(0, PDP_PAGE_EVENTS.PDP_FEED_TYPE, GLOBAL_ACTION_TYPES.CLICK, REFERRAL_EVENTS.REFER_AND_EARN_PAGE_FEED_TYPE, asPath));

  }
  return (
    <div id={CONTAINER_IDS.REFER_AND_EARN_CONTAINER} style={styles.referAnEarnLayout}>
      <div>
        <div style={styles.referTitle}>{translate('refer_and_earn')}</div>
        <div style={styles.referContent}>
          {translate('get_off')}
          <div style={{ display: 'inline-block' }}>
            <Amount
              amount={500}
              style={{
                fontWeight: 400,
                fontSize: '12px',
                lineHeight: '16px',
                color: colors.DIM_GRAY,
                margin: '0px 4px'
              }}
              currencyStyle={{ width: '9px', height: '9px' }}
            />
          </div>
          {translate('for_every_friend')}
        </div>
        <div style={styles.referNow} onClick={navigateToReferAndEarn}>
          {translate('refer_now')}
        </div>
      </div>
      <Refer style={{ paddingRight: '16px' }} />
    </div>
  );
};

export default memo(ReferAndEarn);
