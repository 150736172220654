import { ReviewType } from "@Containers/Product-Details/types";
import { VariationType } from "@Types/common";
import Script from "next/script";

interface SchemaProps {
  productDetails: {
    name: string;
    slug: string;
    image_url: string;
    variations: Array<VariationType>;
    rating?: string;
    product_info?: Record<string, string>;
    reviews?: Array<ReviewType>;
  };
}

const ProductRatingSchema = ({ productDetails }: SchemaProps) => {
  const { name, slug, image_url, variations, product_info, rating, reviews } =
    productDetails;
  const sale_price = variations?.reduce(
    (acc, curr) => (curr?.sale_price < acc ? curr?.sale_price : acc),
    variations?.[0]?.sale_price ?? variations?.[0]?.sale_price
  );
  const stock_status = variations?.reduce(
    (acc, curr) => (acc === "instock" ? acc : curr?.stock_status),
    "outofstock"
  );

  const json = {
    "@context": "http://schema.org/",
    "@type": "Product",
    name: name,
    // "description": "Step out in style with our chic denim mini dress, perfect for casual outings and events.",
    url: `https://newme.asia/product/${slug}/`,
    image: image_url,
    brand: {
      "@type": "Brand",
      "@id": "newme.asia",
      name: "NewMe Asia",
    },
    offers: {
      "@type": "Offer",
      price: sale_price,
      priceCurrency: "INR",
      // "availability": "http://schema.org/InStock",
      availability: `http://schema.org/${stock_status}`,
      seller: {
        "@type": "Organization",
        name: "NewMe Asia",
      },
    },
    ...(rating &&
      reviews?.length && {
        aggregateRating: {
          "@type": "AggregateRating",
          ratingValue: rating,
          ...(reviews && { reviewCount: reviews?.length }),
        },
      }),
    additionalProperty: Object.keys(product_info)?.map((key) => {
      return {
        "@type": "PropertyValue",
        name: key,
        value: product_info?.[key],
      };
    }),
    itemCondition: "http://schema.org/NewCondition",
    ...(reviews?.length && {
      review: reviews
        ?.slice(0, Math.min(reviews.length - 1, 5))
        ?.map((item) => {
          return {
            "@type": "Review",
            author: {
              "@type": "Person",
              name: item?.review_author,
            },
            datePublished: item?.review_date?.split(",")?.[0],
            description: item?.review_content,
            reviewRating: {
              "@type": "Rating",
              ratingValue: item?.rating,
            },
          };
        }),
    }),
  };

  return (
    <>
      {/* <script type="application/ld+json">{JSON.stringify(json)}</script> */}
      <Script
        dangerouslySetInnerHTML={{ __html: JSON.stringify(json) }}
        type="application/ld+json"
      ></Script>
    </>
  );
};

export default ProductRatingSchema;
