import { baseApi } from "@Services/api";
import { RequestTypes } from "@Constants/api";

const searchApis = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    search: builder.query({
      query: (payload) => ({
        url: `search?s=${payload.searchTerm}`,
        method: RequestTypes.GET,
      }),
    }),
    getTrending: builder.query({
      query: () => ({
        url: "trending",
        method: RequestTypes.GET,
      }),
    }),
    getHistoricalTrending: builder.query({
      query: () => ({
        url: "history_trend",
        method: RequestTypes.GET,
      }),
    }),
    updateHistoricalTrending: builder.mutation({
      query: (payload) => ({
        url: "history_trend",
        method: "POST",
        body: payload,
      }),
    }),
    getBBVisualSearch: builder.mutation({
      query: (payload) => ({
        url: "image_search/bounds",
        method: "POST",
        body: payload,
      }),
    }),
    getOffersVisualSearch: builder.mutation({
      query: (payload) => ({
        url: "image_search/offers",
        method: "POST",
        body: payload,
      }),
    })
  }),
});

export const {
  useLazyGetTrendingQuery,
  useLazySearchQuery,
  useGetHistoricalTrendingQuery,
  useUpdateHistoricalTrendingMutation,
  useGetBBVisualSearchMutation,
  useGetOffersVisualSearchMutation,
} = searchApis;
