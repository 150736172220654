import React, { CSSProperties, useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useInView } from 'react-intersection-observer';
import VideoIcon from '@assets/icons/videoIcon.svg';
import RatingStar from '@assets/icons/RatingStar.svg';
import { ProductTag, ProgressiveImage } from '@Components/index';
import styles from '../../styles';
import { getImageWidth, getProductImageSizeSet, getProductImageSrcSet } from '@Components/ProductCard/utils';
import { isEmptyString } from '@Utils/checks';
import { TrackerContext } from '_app';
import { getTrackingEventObject } from '@Utils/common';
import { IMG_LOAD_FAILURE_EVENTS } from '@Constants/events';


const ProductImgWrapper = ({
  onIntersection,
  productImage,
  encodedFallbackImg,
  productImagesSet,
  productImageStyle,
  tag,
  isVideoAvailable,
  rating,
  handleProductCardClick,
  alt = 'product-img',
  feedPosition = 0,
  productId,
  type
}) => {
  const [loadProductImg, setLoadProductImg] = useState(false),
    { ref, inView } = useInView({ threshold: 0.3, triggerOnce: false, trackVisibility: false, delay: 0 }),
    imgSrcSet = getProductImageSrcSet(productImagesSet || []),
    imgSizeSet = getProductImageSizeSet(productImagesSet || []),
    track = useContext(TrackerContext),
    router = useRouter();

  const handleImgLoadFailure = () => {
    track(
      getTrackingEventObject(feedPosition, type, IMG_LOAD_FAILURE_EVENTS.PRODUCT_CARD, productId, router.asPath, { meta: { src: productImage, srcSet: imgSrcSet } })
    );
  };

  useEffect(() => {
    if (inView) {
      onIntersection(inView);
      setLoadProductImg(true);
    }
  }, [inView, onIntersection]);

  return (
    <div className="relative" ref={ref}>
      <ProgressiveImage
        src={
          loadProductImg ? (isEmptyString(imgSrcSet) || isEmptyString(imgSizeSet) ? productImage : undefined) : `data:image/png;base64,${encodedFallbackImg}`
        }
        srcSet={loadProductImg ? imgSrcSet : getProductImageSrcSet([])}
        sizes={loadProductImg ? imgSizeSet : getProductImageSizeSet([])}
        alt={alt}
        draggable={false}
        style={{
          objectFit: 'cover',
          objectPosition: 'top',
          cursor: 'pointer',
          ...(productImageStyle ?? {
            width: getImageWidth(),
            aspectRatio: 2 / 3
          })
        }}
        href={productImage}
        placeholder={`data:image/png;base64,${encodedFallbackImg}`}
        onClick={handleProductCardClick}
        handleImgLoadFailure={handleImgLoadFailure}
        loading={'eager'}
        decoding="async"
      />
      <ProductTag tag={tag} isProductCard />
      {isVideoAvailable && (
        <div style={styles.videoIcon as CSSProperties}>
          {' '}
          <VideoIcon />{' '}
        </div>
      )}
      {rating && parseFloat(rating) >= 3.0 && (
        <div className="absolute" style={styles.ratingPill}>
          {rating}
          <RatingStar style={styles.ratingstar} fill="#90E1BA" />
        </div>
      )}
    </div>
  );
};

export default ProductImgWrapper;
