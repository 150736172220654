import { useRef, useEffect, useState } from 'react';
import { Box, Divider } from '@mui/material';

import FastDeliveryIcon from '@assets/icons/FastDelivery.svg';
import { STOCK_STATUS } from '@Constants/common';
import { SizeCardProps } from './type';
import styles from './styles';

const SizeCard = ({ size, fastDelivery, stockStatus }: SizeCardProps) => {
  const ref = useRef<HTMLInputElement>(null);

  const [diagonalHeight, setDiagonalHeight] = useState(0);
  const [diagonalAngle, setDiagonalAngle] = useState(0);

  useEffect(() => {
    if (ref?.current) {
      // To find the height and angle of diagonal to strike off unavailable variants
      setDiagonalHeight(Math.sqrt(ref?.current?.clientHeight ** 2 + ref?.current?.clientWidth ** 2) - 4);
      setDiagonalAngle((Math.atan2(ref?.current?.clientHeight, ref?.current?.clientWidth) * 180) / Math.PI);
    }
  }, [ref]);

  return (
    <Box ref={ref} sx={styles.sizeCard} position='relative'>
      {stockStatus === STOCK_STATUS.outOfStock && (
        <Divider
          sx={[styles.sizeCardCross, { width: diagonalHeight, transform: `rotate(-${diagonalAngle}deg)` }]}
          orientation='vertical'
        />
      )}
      {fastDelivery && <FastDeliveryIcon />}
      <div>{size}</div>
    </Box>
  );
};

export default SizeCard;
