import { colors } from '@Constants/colors';
import { LoaderProps } from './type';
import moduleStyles from './styles.module.css';
import styles from './styles';
import { CSSProperties } from 'react';

const Loader = ({ isLoading, isWhiteBackground = false, color='white', customStyles = {} }: LoaderProps) => {
  if (!isLoading) return null;

  return (
    <div style={{position: 'fixed', ...styles.loaderWrapper, ...(isWhiteBackground && { backgroundColor: colors.WHITE }), ...(customStyles && customStyles)} as CSSProperties}>
      <div style={{ width: 350 }}>
      <div className={moduleStyles.loader}>
        <span style={{background: '#333333'}}></span>
        <span style={{background: '#333333'}}></span>
        <span style={{background: '#333333'}}></span>
        <span style={{background: '#333333'}}></span>
      </div>
      </div>
    </div>
  );
};

export default Loader;
