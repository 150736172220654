import {memo, useCallback, useContext, useEffect, useState} from 'react';
import { TrackerContext } from '_app';
import useTranslation from '@Utils/useTranslation';
import Rating from '@assets/icons/star.svg';
import RatingNew from '@assets/icons/RatingNew.svg';
import GreenTick from '@assets/icons/GreenTickCircle.svg';
import { formatDateString } from '@Utils/format';
import { isNonEmptyArray, isNonEmptyString } from '@Utils/checks';
import { getTrackingEventObject } from '@Utils/common';
import { GLOBAL_ACTION_TYPES, PDP_PAGE_EVENTS} from '@Constants/events';
import { useInView } from 'react-intersection-observer';
import { getFilledArray, getUnFilledArray } from '../RatingReviewSheet/RatingUtlis';
import {RootState, useAppSelector, useAppDispatch } from '@Store/store'
import { setRatingsData, setImgIndex, setImgClickSrc } from '../RatingsV2/ratingReviewSheetSlice'

const ReviewContent = ({review, handleImageClick, translate, onInteration}) => {

    const { ref, inView } = useInView({ threshold: 0.5, triggerOnce: false, trackVisibility: false, delay: 0 });

    useEffect(() => {
        if (inView) 
            onInteration?.();
      }, [inView]);

    return (
        <div ref={ref} className="p-[12px] mr-[12px] border mb-1 border-solid w-[292px] h-[122px] border-nm_light_gray_7 rounded-lg ">
                <div className="flex gap-3 h-[82px] ">
                   { isNonEmptyArray(review.review_photos) && <div className='relative' onClick={() => handleImageClick(review.review_photos, review)}>
                        <img src={review.review_photos[0]} alt="" className='rounded h-[82px] w-[58px] cursor-pointer' />
                        {review.review_photos.length > 1 && <div className='bg-nm_black_2 text-nm_white text-[10px] leading-[16px] font-[500] text-center absolute top-[-6px] right-[-6px] rounded-full h-[16px] w-[16px] '>{review.review_photos.length}</div>}
                    </div>}
    
                    <div className=''>
                        <div className='flex gap-1.5 items-center'>
                            <div>{getFilledArray(review.rating).map((item) => (
                                <Rating key={item} style={{marginRight:'4px'}} />
                                ))}
                                {getUnFilledArray(review.rating).map((item) => (
                                <RatingNew key={item} />
                                ))}
                            </div>

                            <div className='text-nm_dim_gray text-[12px] leading-4 '>{formatDateString(review.review_date)}</div>
                        </div>
                        
                        <div className=''>
                            <div className='text-nm_font_secondary text-[12px] leading-4 mt-[6px] line-clamp-3'>{review.review_content}</div>
                        </div>
    
                    </div>
                </div>
    
                <div>
                    <div className='flex justify-between items-end mt-2'>
                        <div className=''>
                            <div className='text-nm_dim_gray text-[10px] leading-3 font-medium mb-1'>{review.review_author}</div>
                            <div className='flex items-center gap-1'>
                            <GreenTick />
                            <div className='text-nm_dim_gray text-[10px] leading-3 font-normal'>{translate('verified_buyer')}</div>
                            </div>
                        </div>
                        <div>
                            {isNonEmptyString(review?.size) && <div className={`text-nm_black_2 border border-solid border-nm_light_gray_7 bg-nm_white_smoke rounded-lg text-[12px] leading-4 px-[8px] py-[6px]`}>Size bought: {review.size.toUpperCase()}</div>}
                        </div>
                    </div>
                </div>
            </div>
    )
}

const ReviewCaraousel = ({reviews}) => {
    const { t: translate } = useTranslation();
    const track = useContext(TrackerContext);
    const dispatch = useAppDispatch();

    const {new_ratings_review : newRatingsReview } = useAppSelector((state: RootState) => state.rating)

    const reviewsWithPictures = reviews?.filter(review => review.review_photos.length > 0);

    const filteredReview = reviewsWithPictures.flatMap(review => 
        review.review_photos.map(eachPhoto => ({
          author: review.review_author,
          content: review.review_content,
          photo: eachPhoto,
          id : review.product_id,
          rating: review.rating,
          date: review.review_date,
          size: review.size
        }))
      );


    const handleOnInteration = (index) => {
    track(getTrackingEventObject(0, PDP_PAGE_EVENTS.PDP_REVIEW_FEED_TYPE, PDP_PAGE_EVENTS.REVIEW_CAROUSEL, 
        reviews[0].parent_product_id, GLOBAL_ACTION_TYPES.SCROLL, {meta_data: {index: index}})) 
    }

    const handleImageClick = useCallback((reviewPhotos, review) => {
        const imageObjectIndex = filteredReview.findIndex( img => img.photo == reviewPhotos[0])
          dispatch(setRatingsData(filteredReview))
          dispatch(setImgIndex(imageObjectIndex))
          dispatch(setImgClickSrc('Carousel'))
        track(getTrackingEventObject(0, PDP_PAGE_EVENTS.PDP_FEED_TYPE, PDP_PAGE_EVENTS.IMAGE_CAROUSEL_OPEN, reviews[0].parent_product_id, GLOBAL_ACTION_TYPES.CLICK , { meta_data: { index: 0} }));
    },[ newRatingsReview])


    return (
        <div className={`flex box-border mx-[17px] overflow-auto hideScrollBar ${reviews.length == 1 ? 'mb-[17px]' : '' }`}>
            {reviews?.map((review, index) => (
                <div>
                    <ReviewContent review={review} handleImageClick={handleImageClick} translate={translate} onInteration={() => handleOnInteration(index)} />
            </div>))}
        </div>
    )
}

export default memo(ReviewCaraousel);
