import { useCallback, useEffect, useState, useContext, CSSProperties } from 'react';
import ReactSimplyCarousel from 'react-simply-carousel';
import CustomDialog from '@Components/CustomModal';
import {RootState, useAppSelector, useAppDispatch } from '@Store/store'
import { setRatingsData, setImgIndex } from '../RatingsV2/ratingReviewSheetSlice';
import FullScreenImageCarouselV2 from '../FullScreenImageCarouselV2';
import FilterSheetCloseIcon from '@assets/icons/FilterSheetCloseIcon.svg';
import { PDP_PAGE_EVENTS, GLOBAL_ACTION_TYPES } from "@Constants/events";
import { TrackerContext } from '_app';
import { getTrackingEventObject } from '@Utils/common';

const handleTrackEvents = (key, track, id, index, imgUrl) => {
  const triggerEvent = (key) => track(getTrackingEventObject(0, PDP_PAGE_EVENTS.PDP_FEED_TYPE, key, id, GLOBAL_ACTION_TYPES.CLICK , { meta_data: { index: index, imgUrl: imgUrl} }));
  
  switch(key){
    case 'gallery':
      triggerEvent(PDP_PAGE_EVENTS.IMG_GALLERY_NEXT);
      break;
    case 'sheet':
      triggerEvent(PDP_PAGE_EVENTS.ALL_REVIEWS_SHEET_IMAGE_NEXT);
      break;
    case 'Carousel':
      triggerEvent(PDP_PAGE_EVENTS.IMAGE_CAROUSEL_NEXT);
      break;
  }
}

const ImageCarousel = () => {
    const dispatch = useAppDispatch();
    const track = useContext(TrackerContext);

  const {img_index: imgIndex,
        ratings_data: reviewsData,
        img_click_src: imgClickSrc} = useAppSelector((state: RootState) => state.rating)
  
  const handleClose = useCallback(() => {
    dispatch(setRatingsData([]))
    dispatch(setImgIndex(''))
  }, [])

  const handleChange = () => {
    if(reviewsData[0]?.id){
      handleTrackEvents(imgClickSrc, track, reviewsData[0]?.id, imgIndex, reviewsData[imgIndex]?.photo)
    }
  }

  const style = {
    caraouselNavBtnContainer:{
      alignSelf: 'center',
      position:'absolute',
      cursor: 'pointer',
      background: 'white',
      border: 'none',
      bottom: '56%',
      borderRadius: '50%',
      color: '#1A1A1A',
      opacity: '70%',
      fontSize: '20px',
      height: 30,
      lineHeight: 1,
      textAlign: 'center',
      width: 30,
      display: 'flex',  
      padding: '5px',     
      justifyContent: 'center',
      alignItems: 'center', 
      zIndex: 1,
    }
  }

  const disableScrollByMouse = () => {
    if(reviewsData?.length > 1) return false
    else return true
  }

  const setActiveSlideIndex = (ind) => {
    dispatch(setImgIndex(ind));
  }
 
    return (
      <div className='max-h-full h-full'>
        <CustomDialog 
          open={reviewsData?.length > 0} 
          onClose={handleClose} 
          setOpen={handleClose}
          showCloseButton
          customCloseIcon={<FilterSheetCloseIcon />}
          customStyles={{maxHeight:'100%', height: '100%', backgroundColor:'#1A1A1A', overflow: 'scroll', scrollbarWidth: 'none', borderRadius: '0', paddingTop:'20px' }}
          >
          <div className='w-[100vw] relative max-h-full h-full flex-nowrap max-w-[450px] w-full box-border pb-[34px] pt-2  '>
        <ReactSimplyCarousel
          activeSlideIndex={imgIndex}
          onRequestChange={setActiveSlideIndex}
          touchSwipeTreshold={10}
          disableSwipeByMouse={disableScrollByMouse()}
          disableSwipeByTouch = {disableScrollByMouse()}
          itemsToShow={1}
          itemsToScroll={1}
          forwardBtnProps={{
            style: reviewsData?.length > 1 ? {
              ...(style.caraouselNavBtnContainer as CSSProperties),
              right: '10px',
            } : { display: 'none'},
            children: 
              <span>{`>`}</span>
          }}
          backwardBtnProps={{
            style: reviewsData?.length > 1 ? {
              ...(style.caraouselNavBtnContainer as CSSProperties),
              left: '10px',
            } : {display: 'none'},
            children: <span>{`<`}</span>,
          }}
          speed={400}
          easing="linear"
          onAfterChange={handleChange}
        >
          {
            reviewsData?.map((item, index) => (
              <div className='max-h-full h-full'>
                <FullScreenImageCarouselV2 index={index} data={item} />
               </div>
            ))
          }          
          
        </ReactSimplyCarousel>
    </div>
        </CustomDialog>
      </div>
    );
  }

export default ImageCarousel;