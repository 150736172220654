import { useEffect, useCallback, useContext, useState } from 'react';
import { useRouter } from 'next/router';
import { TrackerContext } from '_app';
import GreyInfoCircle from '@assets/icons/greyInfoCircle.svg';
import FastDeliveryIcon from '@assets/icons/FastDelivery.svg';
import LeftArrow from '@assets/icons/ArrowLeftBlack.svg';
import { STOCK_STATUS } from '@Constants/common';
import { SizeSelectorProps } from './types';
import { VariationType } from '@Containers/Shop/type';
import { getSizeColorVariantEventData, getTrackingEventObject } from '@Utils/common';
import { PRODUCT_SIZE_SELECTOR, RECOMMENDED_PRODUCT_EVENTS } from '@Constants/events';
import { RootState, useAppSelector, } from '@Store/store';
import { useAppDispatch } from '@Store/store';
import { setRecommendationSheet } from '@Containers/RecommendationSheet/recommendationSheetSlice';
import SizeGuideSheet from '@Containers/Product-Details/components/SizeGuideSheet';
import { setSelectedProductInStock, setSelectedProductOutOfStock } from '@Containers/Product-Details/productDetailsSlice';
import { PdpContext } from '@Containers/Product-Details';
import { fetchDefaultProductSize } from '@Containers/Product-Details/utils';
import { CONTAINER_IDS } from '@Containers/Product-Details/constants';

let deliveryStatus = 'slow-delivery';
const SizeSelectorSheet = "sizeSelector"

const SizeSelector = ({
  variations,
  selectedSize,
  setSelectedSize,
  sizeRemarks,
  productId,
  disableDefaultSizeSelect = false,
  secondaryButton,
  isSizeSelectorSheet = false,
  handleSecondaryButtonClick = () => {},
  product,
  isBottomSheet
}: SizeSelectorProps) => {
  const track = useContext(TrackerContext)
  const [sizeGuideOpen, setSizeGuideOpen] = useState(false)
  const [backPressed, setBackPressed] = useState(true),
    { notifyOosFlag } = useContext(PdpContext) ?? {},
    router = useRouter(),
    dispatch = useAppDispatch(),

    handleSelectSizeCardClick = useCallback(
      (size: string, status: string, delivery: boolean) => {

        if(delivery) deliveryStatus = 'fast-delivery'
        
        if (status === STOCK_STATUS.inStock || (notifyOosFlag && status === STOCK_STATUS.outOfStock)) {
          setSelectedSize(size);

          if(status === STOCK_STATUS.inStock )
            dispatch(setSelectedProductInStock());
          else
            dispatch(setSelectedProductOutOfStock());

          track(
            getTrackingEventObject(
              0,
              isBottomSheet ? RECOMMENDED_PRODUCT_EVENTS.FEED_TYPE : getSizeColorVariantEventData({ isSizeSelectorBottomSheet: isSizeSelectorSheet }).feedType,
              PRODUCT_SIZE_SELECTOR.SIZE_SELECT_ACTION_TYPE,
              productId ?? '',
              router.pathname,
              { meta: { selected_size: size, delivery_status : deliveryStatus } }
            )
          );
        }
      },
      [setSelectedSize]
    ),
    getVariationSize = useCallback((variation: VariationType) => variation?.size ?? variation?.variation?.attribute_pa_size, []);
  // setting default selected size based on fast delivery and out of stock conditions
  useEffect(() => {
    if (variations.length > 0 && !disableDefaultSizeSelect) {
      const { defaultSize, isOutOfStock } = fetchDefaultProductSize({ variations: variations });

      if (defaultSize !== null && defaultSize !== undefined) {
        setSelectedSize(defaultSize);
        dispatch(setSelectedProductInStock());
      }
      if (isOutOfStock) {
        if(notifyOosFlag)
          dispatch(setSelectedProductOutOfStock());
        else
          setSelectedSize('');
      }
    }
  }, [productId]);

  const { bottom_sheet_open: configKey } = useAppSelector((state: RootState) => state.recommedationSheet);

  const handleBottomSheetSizeGuideOpen = useCallback(() => {
    setSizeGuideOpen(true);
    setBackPressed(false);
    router.push(router.asPath + '#sizeChartOpen');
  }, [router, router.asPath])

  return (        
    <div id={CONTAINER_IDS.RATING_FRAME} className="py-[24px]">
      <div className="flex justify-between items-center">

        { configKey == SizeSelectorSheet ?  (
        <div className='flex pl-[16px]'>
          <div onClick={() => dispatch(setRecommendationSheet('initial'))} > <LeftArrow /></div> 
          <div className="pl-[8px] font-semibold text-[14px] leading-[20px] text-nm_font_secondary">Select Size</div>
        </div>) : (<div className="pl-[17px] font-semibold text-[14px] leading-[20px] text-nm_font_secondary">Select Size</div>)
      }

        {secondaryButton && (
          <button className="customButton customButton--transparent customButton--noScale" onClick={configKey == SizeSelectorSheet ? handleBottomSheetSizeGuideOpen : handleSecondaryButtonClick}>
            {secondaryButton}
          </button>
        )}
      </div>
      {sizeRemarks && (
        <div className="flex mt-4 mr-8 mb-4 ml-8 items-center gap-[8px] cursor-pointer">
          <GreyInfoCircle />
          <div className="text-[10px] text-nm_dim_gray font-medium">{sizeRemarks}</div>
        </div>
      )}
      <div className={`pl-[16px] pt-[20px] ${configKey === SizeSelectorSheet ? 'pr-[16px]' : ''} flex flex-wrap gap-[20px]`}>
        {variations.map((variation, index) => {
          const size = getVariationSize(variation),
            { id, stock_status, fast_delivery, variation_disclaimer } = variation,
            isOutOfStock = stock_status === STOCK_STATUS.outOfStock;

          return (
            <div className="flex flex-col items-center" key={`${id}${index}`}>
              <div
                className={`relative w-[40px] h-[40px] rounded-full flex gap-[2px] justify-center items-center cursor-pointer  ${
                  size === selectedSize ? (isOutOfStock ? 'bg-nm_grey_2' : 'bg-nm_primary_dark') : 'bg-nm_light_gray'
                }`}
                onClick={() => handleSelectSizeCardClick(size, stock_status, fast_delivery)}
              >
                {isOutOfStock && <div className="bg-nm_silver w-full h-[1px] rotate-[-45deg] absolute" />}
                {fast_delivery && <FastDeliveryIcon />}
                <div
                  className={`font-medium text-[12px] text-nm_font_secondary ${isOutOfStock ? ' text-nm_light_silver' : ''} ${
                    size === selectedSize ? 'text-nm_white' : ''
                  }`}
                >
                  {getVariationSize(variation)}
                </div>
              </div>
              {variation_disclaimer && <div className="mt-[4px] text-[12px] leading-[16px] font-medium text-nm_error">{variation_disclaimer}</div>}
            </div>
          );
        })}
      </div>

        {configKey == SizeSelectorSheet && 
          <SizeGuideSheet
          sizeGuideOpen={sizeGuideOpen}
          setSizeGuideOpen={setSizeGuideOpen}
          handleSizeGuideOpen={handleBottomSheetSizeGuideOpen}
          product={product}
          backPressed={backPressed}
          setBackPressed={setBackPressed}
        />
        }

    </div>
  );
};

export default SizeSelector;
