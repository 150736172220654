import { useEffect, useRef } from "react";
import { useTrackData } from "./useTrackData";
import { useSendTrackData } from "./useSendTrackData";
import { useLocation } from "react-router-dom";
import { useRouter } from "next/router";
import { Analytics } from "@Constants/common";
import { getCookie, setCookie, deleteCookie } from "cookies-next";
import { getTrackingEventObject, setServerTimeDiff } from "@Utils/common";

export const useTracker = () => {
  const { trackObj, addEvent, hasEvent, clearEventData } = useTrackData();
  const { sendTrackData } = useSendTrackData();
  const lastRequestTime = useRef(0);
  const location = useLocation();
  const router = useRouter();
  const TIME_INTERVAL = 10 * 1000; // 10 seconds
  const BUFFER_SIZE = 100;

  const handleFirstLoad = async () => {
    const sessionId = getCookie(Analytics.SESSION_ID);

    await setServerTimeDiff();
    
    await track(getTrackingEventObject(-1, "session", "session-started", "user", router.asPath, {
      sessionId: sessionId,
      window_width: window?.innerWidth,
      window_height: window?.innerHeight 
    }));

    await sendTrackData(trackObj.current);
    clearEventData();
  };

  // useEffect(()=>{
  //   setTimeout(()=>{
  //     handleFirstLoad();
  //   },300)
  // },[])

  useEffect(() => {
    let hiddenTime: number | null = null;

    const handleVisibilityChange = async () => {
      setTimeout(async()=>{
        if (document.hidden) {
          setCookie('last_seen_v2', true, { maxAge: 30 * 60 });
        } else {
          let hasSessionEnded = getCookie('last_seen_v2')
          if (!hasSessionEnded) { // 30 minutes as in GA
            deleteCookie(Analytics.SESSION_ID);
          }
          hiddenTime = null;
        }
      }, 0);
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  // useEffect(()=>{
  //   console.log(location)

  // }, [location.pathname])

  useEffect(() => {
    let name = router.asPath.split("/")[1];
   
    if(router.asPath.includes('/referrals/'))
      name = '/referrals/';

    router.asPath === "/" && (name = "home");

    track(getTrackingEventObject(-1, name, "viewed_screen", name, router.asPath));

    return () => {
      track(getTrackingEventObject(-1, name, "screen_exit", name, router.asPath));
    };
  }, [router.asPath]);

  const updateLastRequestTimeAndSendData = async () => {
    const timeout = setTimeout(async () => {
      if (
        Date.now() - lastRequestTime.current > TIME_INTERVAL &&
        Object.keys(trackObj.current).length > 0
      ) {
        let copy = Object.values(trackObj.current);
        clearEventData();
        await sendTrackData(copy);
        lastRequestTime.current = Date.now();
      }
      await updateLastRequestTimeAndSendData();
    }, TIME_INTERVAL);

    // Return a function that clears the timeout
    return () => {
      clearTimeout(timeout);
    };
  };
  const clear = updateLastRequestTimeAndSendData();

  const validateEvent = (event) => {
    const requiredKeys = [
      "feed_position",
      "feed_type",
      "time",
      "action_type",
      "id",
      "url",
      "absolute_url",
    ];
    const missingKeys = requiredKeys.filter((key) => !event[key]);
    if (missingKeys.length > 0) {
      return { isValid: false, missingKeys };
    }
    return { isValid: true };
  };

  const track = async (event) => {
    const { action_type, id } = event;
    const { isValid, missingKeys } = await validateEvent(event);

    if (!isValid) {
      // console.log(
      //   `Event is missing following keys: ${missingKeys.join(", ")}`,
      //   event
      // );
    }
    let key = `${id}-${action_type}`; // 1-scroll, 1-click
    switch (action_type) {
      case "scroll":
        if (!hasEvent(key)) {
          addEvent(key, event);
        }
        break;
      case "click":
        key = key + Date.now();
        addEvent(key, event);
        break;
      default:
        key = key + Date.now();
        addEvent(key, event);
        break;
    }
    if (Object.keys(trackObj.current).length >= BUFFER_SIZE) {
      let copy = Object.values(trackObj.current);
      clearEventData();
      await sendTrackData(copy);
      lastRequestTime.current = Date.now();
      updateLastRequestTimeAndSendData();
    }
  };

  return { track, handleFirstLoad };
};
