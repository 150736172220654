import { APITags } from '@Constants/api';
import { baseApi } from '@Services/api';
import { setGetCartValue } from './cartSlice';

const cartApis = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    updateCart: builder.mutation({
      query: (body) => ({
        url: `cart/update`,
        method: 'POST',
        body
      }),
      invalidatesTags: [APITags.GET_CART],
      // TODO figure out why getCartItems is not invalidated after login
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;

        if (data) dispatch(setGetCartValue(data?.cart_data));
      }
    }),
    getCartItems: builder.query({
      query: () => ({
        url: 'cart'
      }),
      providesTags: [APITags.GET_CART],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;

        if (data) dispatch(setGetCartValue(data));
      }
    }),
    checkCartOffer: builder.mutation({
      query: (body) => ({
        url: `cart/offer`,
        method: 'POST',
        body
      })
    }),
    removeCartOffer: builder.mutation({
      query: (code) => ({
        url: `cart/offer/remove`,
        method: 'POST',
        body: {
          offer: {
            coupon_code: code
          }
        }
      })
    })
  })
});

export const {
  useLazyGetCartItemsQuery,
  useCheckCartOfferMutation,
  useUpdateCartMutation,
  useRemoveCartOfferMutation
} = cartApis;
