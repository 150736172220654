import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
    rating_review_sheet_open: false,
    img_index : 0,
    ratings_data: [],
    img_click_src: ''
}
export const ratingReviewSheetSlice = createSlice({
    name: 'ratingReviewSheet',
    initialState,
    reducers: {
        setRatingReviewSheetOpen: (state, action: PayloadAction<boolean>) => {
            state.rating_review_sheet_open = action.payload;
        },
        setImgIndex: (state, action: PayloadAction<any>) => {
            state.img_index = action.payload;
        },
        setRatingsData : (state, action: PayloadAction<any>) => {
            state.ratings_data = action.payload;
        },
        setImgClickSrc : (state, action: PayloadAction<any>) => {
            state.img_click_src = action.payload;
        },
        resetRatingReviewSheetSlice: () => initialState,
    }
})

export const { setRatingReviewSheetOpen, resetRatingReviewSheetSlice, setImgIndex, setRatingsData, setImgClickSrc} = ratingReviewSheetSlice.actions;
export default ratingReviewSheetSlice.reducer;