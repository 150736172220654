import { format, parse } from 'date-fns';
import { SUPPORT_CONTACT, Tokens, UtmKeys } from '@Constants/common';
import { VariationType } from '@Types/common';
import { RootState, useAppSelector } from '@Store/store';
import { uuid } from 'uuidv4';

import LocalStorage, { SessionStorage } from './storage';
import { deleteCookie, getCookie, setCookie } from 'cookies-next';
import { WEB_URL } from '../constants/config';
import { isNonEmptyString, isNumber, isServer, sessionStorageIsSupported } from './checks';
import { PDP_PAGE_EVENTS, SIZE_COLOR_SELECTOR_BOTTOM_SHEET } from '@Constants/events';
import { TIME_IN_SECS, timeUnitMap } from '@Constants/time';
import { SERVER_TIME_DIFF_COOKIE_KEY } from '@Constants/storageKeys';

export const parseMilliSeconds = (millisecs: number) => {
  let remainingSecs = millisecs / 1000;
  const hours = Math.floor(remainingSecs / 3600)
    .toString()
    .padStart(2, '0');
  remainingSecs = remainingSecs % 3600;
  const minutes = Math.floor(remainingSecs / 60)
    .toString()
    .padStart(2, '0');
  const seconds = formatToTwoDigit(remainingSecs % 60)
    .toString()
    .padStart(2, '0');

  return { hours, minutes, seconds };
};

export const parseMilliSecondsV2 = ({milliSecs, maxTimeUnit} : { milliSecs: number, maxTimeUnit: string }) => {
  let remainingSecs = milliSecs / 1000,
    days = '00',
    hours = '00',
    minutes = '00',
    seconds = '00';
  const mappedMaxTimeUnit = timeUnitMap[maxTimeUnit] ?? 1;

  if (mappedMaxTimeUnit >= 4) {
    days = Math.floor(remainingSecs / TIME_IN_SECS['24Hrs'])
      .toString()
      .padStart(2, '0');
    remainingSecs = remainingSecs % TIME_IN_SECS['24Hrs'];
  }
  if (mappedMaxTimeUnit >= 3) {
    hours = Math.floor(remainingSecs / TIME_IN_SECS['1Hr'])
      .toString()
      .padStart(2, '0');
    remainingSecs = remainingSecs % TIME_IN_SECS['1Hr'];
  }
  if (mappedMaxTimeUnit >= 3) {
    minutes = Math.floor(remainingSecs / TIME_IN_SECS['1Min'])
      .toString()
      .padStart(2, '0');
    remainingSecs = remainingSecs % TIME_IN_SECS['1Min'];
  }
  if (mappedMaxTimeUnit >= 3) {
    seconds = Math.floor(remainingSecs).toString().padStart(2, '0');
  }

  return { days, hours, minutes, seconds };
};

export const formatSecondsToDisplayTime = (secs: number) => {
  const minutes = Math.floor(secs / 60);
  const seconds = formatToTwoDigit(secs % 60);

  return `${minutes}:${seconds}`;
};

const formatToTwoDigit = (digit: number) => (digit > 9 ? digit : `0${digit}`);

export const validatePhoneNumber = (value: string | number) => +value == value && `${value}`.length === 10;
export const validateOTPNumber = (value: string | number) => +value == value && `${value}`.length === 6;

export const parseErrorMessage = (obj?: { data: { error: string } } | any) =>
  obj?.data?.error || 'Something went wrong. Please try again.';

export const getInitialsFromName = (name: string): string => {
  return name
    .split(' ')
    .map((n: string) => n[0])
    .join('');
};

export const onLogout = async () => {
  // LocalStorage.removeItem(Tokens.ACCESS_TOKEN);
  // LocalStorage.removeItem(Tokens.REFRESH_TOKEN);
  deleteCookie(Tokens.ACCESS_TOKEN);
  deleteCookie(Tokens.REFRESH_TOKEN);
};

export const getDiscountPercentage = (regularPrice: number, salePrice: number) => {
  const regular = Number(regularPrice);
  const sale = Number(salePrice);
  const discount = ((regular - sale) / regular) * 100;

  return Math.ceil(discount);
};

export const getPriceRange = (variations: Array<VariationType>) => {
  if (!variations || variations?.length === 0)
    return {
      minSalePrice: 0,
      maxSalePrice: 0,
      regularPrice: 0,
      discount: 0,
      shouldShowPriceRange: false
    };

  const minSalePrice = variations.reduce(
    (min, variation) => (Number(variation.sale_price) < min ? Number(variation.sale_price) : min),
    Number(variations[0].sale_price)
  );
  const maxSalePrice = variations.reduce(
    (max, variation) => (Number(variation.sale_price) > max ? Number(variation.sale_price) : max),
    Number(variations[0].sale_price)
  );

  const regularPrice = Number(variations[0].regular_price);

  const discount = getDiscountPercentage(regularPrice, minSalePrice);

  const shouldShowPriceRange = minSalePrice !== maxSalePrice;

  return {
    minSalePrice: Math.ceil(minSalePrice),
    maxSalePrice: Math.ceil(maxSalePrice),
    regularPrice: Math.ceil(regularPrice),
    discount,
    shouldShowPriceRange
  };
};

export const convertDateString = (date: string, oldFormat = 'dd/MM/yyyy, HH:mm:ss', newFormat = 'dd MMM yyyy') => {
  try {
    const formatOld = parse(date, oldFormat, new Date());
    const updatedDate = format(new Date(formatOld), newFormat);

    return updatedDate;
  } catch (e) {
    return '';
  }
};

export const useLoggedIn = () => {
  const { user } = useAppSelector((state: RootState) => state.profile);

  return { isUserLoggedIn: !!user?.id };
};

export const copyToClipboard = (value: string, id: string) => {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(value);
  } else if ((window as any).clipboardData && (window as any).clipboardData.setData) {
    return (window as any).clipboardData.setData('Text', value);
  } else if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
    const textarea = document.createElement('textarea');

    textarea.innerText = value;
    const parentElement = document.getElementById(id);

    if (!parentElement) return;

    parentElement.appendChild(textarea);
    textarea.select();
    try {
      document.execCommand(id); // Security exception may be thrown by some browsers.
    } catch (ex) {
      console.log('Copy to clipboard failed.', ex);

      return false;
    } finally {
      parentElement.removeChild(textarea);
    }
  }
};

export const convertBytesToMB = ({ bytes }: { bytes: number }) => {
  const megaBytes = (bytes / 1000000).toFixed(3);

  return megaBytes;
};

export const getHoardingURL = (hoardingObj: Record<string, Record<string, string>>) => {
  let url, redirect;

  if (typeof window !== "undefined" && Object.keys(hoardingObj).includes((window as any)?.sessionStorage?.getItem('utm_source') ?? '')) {
    url = hoardingObj[(window as any)?.sessionStorage?.getItem('utm_source') ?? 'default']?.image_url ?? '';
    redirect = hoardingObj[(window as any)?.sessionStorage?.getItem('utm_source') ?? 'default']?.redirect_url ?? '';
  } else {
    url = hoardingObj['default']?.image_url ?? '';
    redirect = hoardingObj['default']?.redirect_url ?? '';
  }

  return [url, redirect];
};

const isMobileDevice = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};

export const getWhatsAppLink = (message: string) => {
  const phoneNumber = SUPPORT_CONTACT;
  const encodedMessage = encodeURIComponent(message);
  const baseURL = 'https://api.whatsapp.com';

  if (isMobileDevice()) return `${baseURL}/send?phone=${phoneNumber}&text=${encodedMessage}`;
  else return `${baseURL}/send/?phone=${phoneNumber}&text=${encodedMessage}&type=phone_number&app_absent=0`;
};

export const showHelpButton = () => JSON.parse(LocalStorage.getItem('haveActiveOrder') || 'false');

export const getUniqueSizes = (variations: Array<VariationType>): Array<string> => {
  const sizes = variations.map((variation) => variation.size);

  const uniqueNumbers = new Set();
  const regex = /(?=.*[a-zA-Z])(?=.*[0-9])/;

  for (const size of sizes) uniqueNumbers.add(regex.test(size) ? size?.substring(0, 2) : size);

  const stringArray: string[] = Array.from(uniqueNumbers) as string[];

  return stringArray;
};

export const convertNumberRange = (range: string) => {
  const sizes = range.split('-');

  const cms = sizes.map((size) => Math.round(Number(size) * 2.54));

  return cms.join(' - ');
};

export const appDownloadLink = () => {
  const UtmKeys = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_nm'];
  const UtmDict = {};
  UtmKeys.forEach((utmKey) => {
    if ((window as any)?.sessionStorage?.getItem(utmKey)) {
      UtmDict[utmKey] = (window as any)?.sessionStorage?.getItem(utmKey) ?? '';
    } 
  });
  let utmString = '';
  if (Object.keys(UtmDict).length > 0) {
    const utmSearchParams = new URLSearchParams();
    for (const key in UtmDict) {
      utmSearchParams.append(key, UtmDict[key]);
    }
    utmString = utmSearchParams.toString()
  }
  if (/Android/i.test(navigator.userAgent)) {
    return `https://play.google.com/store/apps/details?id=com.newme.mobile&${utmString}`;
  } 
  else if (/iPhone|iPad/i.test(navigator.userAgent)) {
    return `https://apps.apple.com/app/id6445953273?${utmString}`;
  } 
  return `https://play.google.com/store/apps/details?id=com.newme.mobile&${utmString}`;
  
} 

// Converts hexcode to RGB values
const hexToRgb = (hex: string) => {
  // Remove the hash if it exists
  hex = hex.replace(/^#/, '');

  // Parse the hex values
  const bigint = parseInt(hex, 16);

  // Extract RGB components
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  // Return the RGB values as an object
  return { r, g, b };
};

/* 
  - Checks whether a given shade of color in hexcode is a variant of white
  - Alter whiteThreshold value for R, G & B values
  */
export const isWhiteColorVariant = (color: string, whiteThreshold: number = 250) => {
  const { r, g, b } = hexToRgb(color);

  // Check if the color is white or a variant of white
  const isWhite = r >= whiteThreshold && g >= whiteThreshold && b >= whiteThreshold;

  return isWhite;
};

export const validateCookie = (cookie: string | null, validityCookie: string | null): string | null => {
  if (cookie) {
    
    if (!validityCookie) {
      // If 'validity' cookie is not present, return null
      return null;
    }

    const currentTime = new Date();
    const validityTimestamp = new Date(validityCookie);

    if (validityTimestamp && validityTimestamp > currentTime) {
      // If 'validity' is present and not expired, return the cookie value
      return cookie;
    }
  }

  // Return null if the cookie is not present or validity check fails
  return null;
}

export const preloadImgs = (imgSrc: string | string[] | undefined, onImgLoad = () => {}) => {
  if(!imgSrc)
    return;

  const loadImg = (src: string) => {
    const img = new Image();
    img.src = src;
    
    if(typeof onImgLoad === 'function')
      img.onload = onImgLoad;
  }

  if(typeof imgSrc === 'string' && imgSrc.length)
    loadImg(imgSrc);
  else if(typeof imgSrc !== 'string')
    imgSrc?.forEach(src => loadImg(src));
}

export const generateTrackEventMetadata = (metadata) => { 
  const {imageUrl, clickEvent} = metadata;
  
  return {
  ...(imageUrl && {image_url: imageUrl}),
  ...(clickEvent?.params && {params: clickEvent.params})
  }
}

export const enableScrollOnBody = () => {
  if(document?.getElementsByTagName('body')?.[0])
      document.getElementsByTagName('body')[0].style.overflow = 'auto';
}

export const disableScrollOnBody = () => {
  if(document?.getElementsByTagName('body')?.[0])
      document.getElementsByTagName('body')[0].style.overflow = 'hidden';
}

export const getProductCanonicalUrl = (productName: string) => {
  if(typeof productName === 'string')
      productName = productName.toLowerCase().split(' ').join('-');

  return `${WEB_URL}product/${productName}`
};

export const setServerTimeDiff = async () => {
  if(isServer())
    return; 

  if(!getCookie(SERVER_TIME_DIFF_COOKIE_KEY)) {
    const apiRequestTimestamp = Date.now(),
          res = await fetch('/api/server-time');

    if(res?.ok){
      const data = await res.json(),
            { serverDate } = data?.message ?? {};
    
      const serverTime = (new Date(serverDate)).getTime(),
            clientTime = (new Date()).getTime(),
            // Considering server time to lag by 1/4 of api latency
            timeDiff = (serverTime + (clientTime - apiRequestTimestamp)/2) - clientTime ;

      console.log('first load: ', data, serverTime, clientTime, clientTime, apiRequestTimestamp, timeDiff);
    
      // store time diff in browser cookie 
      setCookie(SERVER_TIME_DIFF_COOKIE_KEY, JSON.stringify(timeDiff), { maxAge: TIME_IN_SECS['6Hrs'] });
    }
  }
}

export const getAccurateTimeStamp = ({ timestamp } : { timestamp?: number }) => {
  const serverTimeDiffCookie = getCookie(SERVER_TIME_DIFF_COOKIE_KEY),
        parsedTimeDiff = serverTimeDiffCookie ? JSON.parse(serverTimeDiffCookie) : 0,
        currTimeStamp = (timestamp ?? Date.now()) + (isNumber(parsedTimeDiff) ? parsedTimeDiff : 0);

  return { currTimeStamp: currTimeStamp, serverTimeDiff: serverTimeDiffCookie };
}

export const getTrackingEventObject =  (idx: string | number, feedType: string, type: string, id: string | number, pathname: string, additionalData = {}) => {
  const { currTimeStamp, serverTimeDiff } = getAccurateTimeStamp({});

  return {
    ...(serverTimeDiff && { server_time_diff: JSON.parse(serverTimeDiff)}),
    feed_position: idx,
    feed_type: feedType,
    time: currTimeStamp,
    action_type: type,
    id: id ?? uuid(),
    url: pathname,
    absolute_url: window.location.href,
    ...additionalData
  };
};

export const removeTrailSlash = (str: string) => (str.endsWith('/') ? str.slice(0, -1) : str);

export const navigatorVibrate = (pattern) => {
  if (!isServer() && window.navigator.vibrate) window.navigator.vibrate(pattern);
}

export const getSizeColorVariantEventData = ({ isSizeSelectorBottomSheet }) => {
  if(isSizeSelectorBottomSheet)
    return {
      feedType: SIZE_COLOR_SELECTOR_BOTTOM_SHEET.FEED_TYPE
    }
  else
    return {
      feedType: PDP_PAGE_EVENTS.FEED_TYPE
    }
}

export const scrollToContainer = (id) => id &&
  document.querySelector(id)?.scrollIntoView({ behavior: 'smooth', block: 'start', inline:'start'  });

export const handleScrollToTop = () => window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });

export const verifyUtmParamsChange = ({searchParams}) => {
  const UtmDict = {}
    let utmParamsChanged = false;
    UtmKeys.forEach((utmKey) => {
      if (searchParams[utmKey] && searchParams[utmKey] !== '') {
        if (!(window as any)?.sessionStorage || !sessionStorage.getItem(utmKey) || sessionStorage.getItem(utmKey) !== searchParams[utmKey]) {
          utmParamsChanged = true;
        }
        sessionStorage.setItem(utmKey, searchParams[utmKey] ?? '');
        UtmDict[utmKey] = searchParams[utmKey] ?? '';
      } 
    });
    if (!utmParamsChanged) return;
    if (Object.keys(UtmDict).length > 0) {
      const utmSearchParams = new URLSearchParams();
      for (const key in UtmDict) {
        utmSearchParams.append(key, UtmDict[key]);
      }
      return utmSearchParams.toString();
    }
}

export const handleSessionStorage = ({ key, data, storeInRedux = false, dispatch, action}) => {
    if(sessionStorageIsSupported())
      SessionStorage.setItem(key ,JSON.stringify(data ?? {}));
    else if(storeInRedux && dispatch && action)
      dispatch(action);
  }

export const capitalizeFirst = (str) => {
  // Check if the input is not empty
  if (str.length === 0) {
      return str;
  }
  
  // Capitalize the first character and concatenate with the rest of the string
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const getTrackEventMetadata = () => {
  let relPath = location.pathname; 
  if(location.pathname === '/')
    relPath = '/home';

  return { source: relPath };
}

export const isInstagramWebView = () => {
  let ua = navigator.userAgent || navigator.vendor || window.opera;
  return (ua.indexOf('Instagram') > -1);
}

export const isAndroid = () => {
  return /Android/i.test(navigator.userAgent);
}

export const redirectToApp = ({ searchParams }) => {
  if (!isServer()) {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
          validUtmSrcs = ['ig', 'google', 'facebook'],
          instagramWebView = isInstagramWebView();

    if (isMobile || instagramWebView) {
      const { utm_source } = searchParams ?? {};
      const userComingFromRefLink = validUtmSrcs.includes(utm_source);
      if (userComingFromRefLink || instagramWebView) {

        if(isAndroid()){
          window.location.href = 'intent://mobile/#Intent;scheme=newme-asia;package=com.newme.mobile;end';
        }
        else {
          const url = window.location.href.replace('https://stage.newme.asia', 'newme-asia://mobile');
          window.location = url;
        }
      }
    }
  }
};

export const getTimeDiff = ({ time } : { time: number}) => {
  if(isNumber(time))
    return ((time * 1000) - Date.now())/1000;
    // Expecting time to be in seconds - converting to ms 

  return time;
}



