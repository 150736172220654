import { CSSProperties } from 'react';
import styles from './styles';
import useTranslation from '@Utils/useTranslation';
import GreenTick from '@assets/icons/GreenTick.svg';
import Ellipse from '@assets/icons/EllipseWhite.svg';
import { formatDateString } from '@Utils/format';
import { getFilledArray, getUnFilledArray } from '../RatingReviewSheet/RatingUtlis';
import Rating from '@assets/icons/star.svg';
import RatingNew from '@assets/icons/RatingNew.svg';
import { isNonEmptyString } from '@Utils/checks';

const FullScreenImageCarouselV2 = ({index, data,}) => {

  const { t: translate } = useTranslation();

  return (
    
    <div style={{...styles.fullScreenImage}}>
      <img
      draggable={false}
      src={data?.photo} alt='carousel-images' style={{...styles.image } as CSSProperties} />
      <div className='text-nm_white text-start mx-4 mb-4'>
 
        <div className='my-2'>
          {getFilledArray(data.rating).map((item: Key | null | undefined) => (
          <Rating key={item} />
          ))}
          {getUnFilledArray(data.rating).map((item) => (
          <RatingNew key={item} />
          ))}
        </div>

        <div className='text-[14px] line-clamp-2 mb-2'>{data?.content}</div>

        <div className='text-[12px] mb-1'>{data?.author}</div>

        <div className='flex items-center gap-2'>
          <GreenTick />
          <div className='text-[12px]'>{translate('verified_buyer')}</div>
          <Ellipse />
          <div className='text-[12px]'>{formatDateString(data?.date)}</div>
        </div> 

        {isNonEmptyString(data?.size) && <div className={`mt-3 text-nm_black_2 border-solid border-nm_light_gray_7 bg-nm_white_smoke rounded-lg text-[12px] px-[8px] py-[6px] w-fit `}>Size bought: {data?.size?.toUpperCase()}</div>}
      </div>
    </div>
  );
};

export default FullScreenImageCarouselV2;
