import useTranslation from '@Utils/useTranslation';
import { Box, Button } from '@mui/material';

import getApplyCouponStyles from '@Utils/coupons';
import { isEmptyString, isNonEmptyString } from '@Utils/checks';
// import { PriceText } from '@Components/index';
// import { CouponApplyStatus } from '@Constants/coupon';
// import CouponIcon from '@assets/icons/Coupon.svg';
// import CrossCircleIcon from '@assets/icons/CrossCircle.svg';
// import TickCircleIcon from '@assets/icons/TickCircle.svg';

import { styleDefault, styleAllCouponsVariant } from './styles';
import { ApplyCouponProps } from './types';
import { CSSProperties } from 'react';
import { CouponApplyStatus } from '@Constants/coupon';

const ApplyCoupon: React.FC<ApplyCouponProps> = ({
  couponCode,
  setCouponCode,
  handleApplyCoupon,
  handleRemoveCoupon,
  couponApplyStatus,
  couponMessage,
  allCouponsPage = false,
  isVersion2 = false,
  style = {}
}) => {
  const { t: translate } = useTranslation();
  const applyCouponStyles = getApplyCouponStyles(couponApplyStatus, undefined, allCouponsPage, isVersion2);

  const handleChangeInput = (e: any) => {
    setCouponCode(e.target.value);
  };

  const styles = {...styleDefault, ...(allCouponsPage && styleAllCouponsVariant)};

  const gradientStyle = {
    ...styles.gradientContainer,
    ...applyCouponStyles.gradientStyle
  };

  const inputTextStyle = {
    ...(!isVersion2 ? {...styles.inputField, ...applyCouponStyles.inputTextStyle} : { ...styles.inputFieldV2 }),
  };

  const inputContainerStyle = {
    ...(isVersion2 ? styles.inputFieldContainerV2 : styles.inputFieldContainer),
    ...(isNonEmptyString(couponCode) ? styles.inputFieldNotEmpty : null)
  };

  return (
  <>
    <Box sx={{ ...styles.wrapper, ...style, ...(isVersion2 && { padding: 0, minWidth: '20px', overflow: 'hidden' }) }}>
      <Box mt={2} sx={{...styles.container, ...(isVersion2 && { padding: '16px', margin: 0, borderRadius: '12px', justifyContent: 'space-between' })}} >
        { isVersion2 ? 
        <div style={{...inputContainerStyle,...(isVersion2 && { padding: 0, width: '100%'})}} >
          <input
            type='text'
            disabled={couponApplyStatus}
            value={couponCode}
            style={{...inputTextStyle, ...(isVersion2 && { width: '100%', padding: 0, whiteSpace: 'normal', wordWrap: 'break-word' })}}
            onChange={handleChangeInput}
            placeholder={translate('coupon_placeholder') || ''}
          />
          {/* {applyCouponStyles.couponAppliedStatus === CouponApplyStatus.SUCCESS && <TickCircleIcon />}
          {applyCouponStyles.couponAppliedStatus === CouponApplyStatus.FAILURE && (
            <CrossCircleIcon onClick={handleRemoveCoupon} style={{ cursor: 'pointer' }} />
          )} */}
        </div> :
         <Box p={1} sx={{...inputContainerStyle,...(isVersion2 ? { padding: 0, flex: 1 } : {})}} >
         <input
           type='text'
           disabled={couponApplyStatus}
           value={couponCode}
           style={{...inputTextStyle, ...(isVersion2 ? { padding: 0, whiteSpace: 'normal', wordWrap: 'break-word', flex: 1 } : {})}}
           onChange={handleChangeInput}
           placeholder={translate('coupon_placeholder') || ''}
         />
         {/* {applyCouponStyles.couponAppliedStatus === CouponApplyStatus.SUCCESS && <TickCircleIcon />}
         {applyCouponStyles.couponAppliedStatus === CouponApplyStatus.FAILURE && (
           <CrossCircleIcon onClick={handleRemoveCoupon} style={{ cursor: 'pointer' }} />
         )} */}
       </Box>
        }
        <button
          className={`customButton customButton--transparent ${isEmptyString(couponCode)?"customButton--disable":""}`}
          disabled={isEmptyString(couponCode)}
          onClick={() => {
            if (isEmptyString(couponCode)) return;
            if (couponApplyStatus) handleRemoveCoupon();
            else handleApplyCoupon();
          }}
          style={{...styles.applyButton, ...(!isEmptyString(couponCode)? {...styles.enableApplyBtn, ...(isVersion2 && { color: '#078B3E' })} : {}), ...(isVersion2 && { margin: 0, width: 68, display: 'flex', justifyContent: 'end' }),...(isVersion2 && (applyCouponStyles.buttonText === 'Remove' ? { color: '#B20100' } : {}))} as CSSProperties}
        >
          {applyCouponStyles.buttonText}
        </button>
      </Box>
      {/* {applyCouponStyles.couponAppliedStatus && (
        <Box mt={2} sx={gradientStyle}>
          {couponMessage?.length && couponMessage !== 'Invalid Coupon' ? (
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '4px' }}>
              <PriceText text={couponMessage} />
            </Box>
          ) : (
            <div>{applyCouponStyles.couponMessage}</div>
          )}
        </Box>
      )} */}
    </Box>
    {isVersion2 ? <div className={'mt-[4px] text-[12px] font-[500] leading-[18px] ' + (couponApplyStatus === CouponApplyStatus.SUCCESS ? ' text-nm_dark_mystique_green' : ' text-nm_danger_red_2')}>{couponMessage}</div> : <></>}
  </>
  );
};

export default ApplyCoupon;
