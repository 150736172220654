import { colors } from '@Constants/common';

const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    backgroundColor: colors.WHITE,
    marginTop: '1px'
  },
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  title: {
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '24px',
    color: colors.BLACK,
    paddingLeft: '60px',
    paddingRight: '60px',
    textAlign: 'center'
  },
  subtitle: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '24px',
    color: colors.DIM_GRAY,
    textAlign: 'center'
  },
  buttonContainer: {
    width: '100%',
    paddingBottom: '24px',
    paddingX: '16px',
    boxSizing: 'border-box',
    cursor: 'pointer'
  },
  homeButton: {
    width: '100%',
    textTransform: 'none',
    borderRadius: '8px',
    background: colors.PRIMARY,
    color: colors.WHITE,
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    padding: '14px 32px',
    boxShadow: 'none',
    '&:hover': { backgroundColor: colors.PRIMARY, boxShadow: 'none' }
  }
};

export default styles;
