import { colors } from '@Constants/colors';

const styles = {
  referAndEarnContainer: {
    position: 'fixed',
    top: 0,
    zIndex: 999,
    width: '100%',
    maxWidth: '450px'
  },
  referAndEarn: {
    position: 'absolute',
    top: '330px',
    right: '-10px',
    color: colors.WHITE,
    cursor: 'pointer',
    padding: '0 10px'
  }
};

export default styles;
