import { CSSProperties, Key, memo, useContext, useEffect, useRef, useState, useCallback, createContext } from 'react';
import { useInView } from 'react-intersection-observer';
import { TrackerContext } from '_app';
import useTranslation from '@Utils/useTranslation';

import Ellipse from '@assets/icons/Ellipse.svg';
import GreenTick from '@assets/icons/GreenTick.svg';
import Rating from '@assets/icons/Rating.svg'
import { ReviewType } from '@Containers/Product-Details/types';
import { formatDateString } from '@Utils/format';
import { RootState, useAppDispatch, useAppSelector } from '@Store/store';

import styles from './styles';
import FullScreenImageCarousel from '../FullScreenImageCarousel';
import CustomDialog from '@Components/CustomModal';
import useModalLocationHash from '@Hooks/useLocationModalHash';
import { colors } from '@Constants/colors';
import { useRouter } from 'next/router';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { isNonEmptyString } from '@Utils/checks';
import { getTrackingEventObject } from '@Utils/common';
import { GLOBAL_ACTION_TYPES, PDP_PAGE_EVENTS, RECOMMENDED_PRODUCT_EVENTS } from '@Constants/events';
import RatingReviewSheet from '../RatingReviewSheet/index';
import FilterSheetCloseIcon from '@assets/icons/FilterSheetCloseIcon.svg';
import { getFilledArray, getUnFilledArray, getParsedCarousalData } from '../RatingReviewSheet/RatingUtlis';
import { setRatingReviewSheetOpen } from '../RatingsV2/ratingReviewSheetSlice'

const isServer = typeof window === 'undefined';
export const RatingReviewsContext = createContext({});


const ReviewImage = ({ loading = 'lazy', customStyles = {}, src = '', alt = 'review-img', onIntersection = () => {} }) => {
  const { ref, inView } = useInView({ threshold: 0.5, triggerOnce: false, trackVisibility: false, delay: 0 });

  useEffect(() => {
    if (inView) onIntersection?.();
  }, [inView]);

  if (!isNonEmptyString(src)) return <></>;

  return <img ref={ref} loading={loading} style={customStyles} src={src} alt={alt} />;
};

const ReviewContent = ({review, index, getFilledArray, getUnFilledArray, renderReviewGallery, reviews, translate, onIntersection = () => {}}) => {
  const { ref, inView } = useInView({ threshold: 0.5, triggerOnce: false, trackVisibility: false, delay: 0 });

  useEffect(() => {
    if (inView) onIntersection?.();
  }, [inView]);

  return <div ref={ref} key={`${review.review_date}${index}`}>
  {review?.review_photos && review?.review_photos.length > 0 && renderReviewGallery(review?.review_photos, index, false, review?.product_id)}
  <div style={styles.ratingIcon as CSSProperties}>
    {getFilledArray(review.rating).map((item: Key | null | undefined) => (
      <Rating key={item} fill={colors.ORANGE} />
    ))}
    {getUnFilledArray(review.rating).map((item) => (
      <Rating key={item} fill={colors.BORDER_GRAY} />
    ))}
  </div>
  <div style={styles.reviewContent}>{review.review_content}</div>
  <div style={styles.reviewAuthor}>{review.review_author}</div>
  <div style={styles.verifiedBuyer as CSSProperties}>
    <GreenTick />
    <div style={styles.verifiedBuyerText}>{translate('verified_buyer')}</div>
    <Ellipse style={styles.ellipse} />
    <div>{formatDateString(review.review_date)}</div>
  </div>
  {index + 1 !== reviews.length ? (
    <hr className=" opacity-[0.3]" style={styles.divider} />
  ) : (
    <div style={{ marginTop: '16px', marginBottom: '16px' }}></div>
  )}
</div>

}

const Ratings = ({reviewV2, reviews }: { reviews: ReviewType[], reviewV2:ReviewType[] }) => {
  const { t: translate } = useTranslation(),
    router = useRouter(),
    track = useContext(TrackerContext),
    dispatch = useAppDispatch();

  const INITIAL_REVIEW_COUNT = 2;
  const MAX_IMAGE_COUNT = 25;

  const imgCount = reviewV2?.all_images?.length || 0

  const { bottom_sheet_open: configKey } = useAppSelector((state: RootState) => state.recommedationSheet);
  const {rating_review_sheet_open : ratingReviewSheetOpen} = useAppSelector((state: RootState) => state.rating)

  const isConfigInitial = configKey === 'initial';

  const [showMore, setShowMore] = useState(false);
  const [showFullScreenImage, setShowFullScreenImage] = useState(false);
  const [showFullScreenImageIndex, setShowFullScreenImageIndex] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isCustomerImage, setIsCustomerImage] = useState(false);

  const initialReview = reviews?.slice(0, INITIAL_REVIEW_COUNT);
  const showMoreReview = reviews?.slice(INITIAL_REVIEW_COUNT);
  const firstImages = reviews?.reduce((acc, curr) => {
    if( curr?.review_photos?.length) {
      return [...acc, ...curr?.review_photos];
    }
    return acc
  },[])?.slice(0,MAX_IMAGE_COUNT);

  const handleShowAllReviewClick = (reviews) => {
    if(isConfigInitial){
      track(getTrackingEventObject(0, RECOMMENDED_PRODUCT_EVENTS.FEED_TYPE,  RECOMMENDED_PRODUCT_EVENTS.VIEW_ALL_REVIEWS_CLICK, reviews[0]?.product_id,router.asPath));
    }
    else 
      track(getTrackingEventObject(0, PDP_PAGE_EVENTS.PDP_FEED_TYPE,  PDP_PAGE_EVENTS.VIEW_ALL_REVIEWS, reviews[0]?.product_id,router.asPath));

    setShowMore(!showMore);
  };

  const handleReviewSectionImpressions = ({ idx, id, src, review_id, type = GLOBAL_ACTION_TYPES.SCROLL, feedType = PDP_PAGE_EVENTS.PDP_REVIEW_FEED_TYPE }) => {
      track(getTrackingEventObject(idx, feedType, type, id, router.asPath, { meta_data: { review_id: review_id, img_url: src } }));
  };

  const { handleLocationHashOnOpen, handleLocationHashOnClose } = useModalLocationHash({
    router: router,
    pathname: router.pathname,
    hash: '#fullScreenImg',
    openModal: () => {},
    closeModal: () => setShowFullScreenImage(false)
  });

  const handleFullScreenImageClose = () => {
    setShowFullScreenImage(false);
    handleLocationHashOnClose?.();
  };

  const setFullScreenImage = (idx: number, index: number, isCustomerImage = false) => {
    setSelectedIndex(idx);
    setShowFullScreenImageIndex(index);
    setShowFullScreenImage(true);
    setIsCustomerImage(isCustomerImage);
    handleLocationHashOnOpen?.();
  };

  const handleReviewModal = useCallback(() => {
    dispatch(setRatingReviewSheetOpen(true));
    track(getTrackingEventObject(0, PDP_PAGE_EVENTS.PDP_FEED_TYPE, PDP_PAGE_EVENTS.VIEW_ALL_REVIEWS_BOTTOM_SHEET_OPEN, reviewV2?.reviews[0].parent_product_id, router.asPath));
  }, []);

  const handleClose = useCallback(()=>{
    dispatch(setRatingReviewSheetOpen(false));
  }, []);

  function renderReviewGallery(photos: Array<string>, index, checkCustomerImage = false, productId) {
    const image_arr = photos;

    return (
      <div style={styles.reviewGallery as CSSProperties}>
        <div style={{ display: 'flex', position: 'relative' }}>
          {image_arr && image_arr?.length > 1 ? (
            image_arr.map((img, idx) => {
              const handleOnClick = () => {
                setFullScreenImage(idx, index, checkCustomerImage);
                handleReviewSectionImpressions({ idx: idx, id: productId, src: img, review_id: index, type: GLOBAL_ACTION_TYPES.CLICK });
              };

              const handleOnIntersection = () => {
                handleReviewSectionImpressions({ idx: idx, id: productId, src: img, review_id: index });
              }

              return idx < 2 || checkCustomerImage ? (
                <div key={idx} onClick={handleOnClick} style={{ height: '100px', margin: '0px 2px' }}>
                  <ReviewImage
                    loading="lazy"
                    src={img}
                    alt="product"
                    customStyles={{ maxWidth: '100%', maxHeight: '100px', borderRadius: '5px' }}
                    idx={idx}
                    productId={productId}
                    reviewId={index}
                    onIntersection={handleOnIntersection}
                  />
                </div>
              ) : idx == 2 ? (
                <div onClick={handleOnClick} style={styles.reviewImage as CSSProperties}>
                  <ReviewImage
                    loading="lazy"
                    src={img}
                    alt="product"
                    customStyles={{ maxWidth: '100%', maxHeight: '100px', borderRadius: '5px', opacity: image_arr.length > 3 ? '0.6' : 1 }}
                    idx={idx}
                    productId={productId}
                    reviewId={index}
                    onIntersection={handleOnIntersection}
                  />
                  {image_arr.length > 3 && (
                    <div
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        fontSize: '30px'
                      }}
                    >{`+${image_arr.length - 2}`}</div>
                  )}
                </div>
              ) : (
                <></>
              );
            })
          ) : (
            <div
              onClick={() => {
                setFullScreenImage(0, index, checkCustomerImage);
                handleReviewSectionImpressions({ idx: 0, id: productId, src: image_arr?.[0], review_id: index, type: GLOBAL_ACTION_TYPES.CLICK });
              }}
              style={{ display: 'flex', alignItems: 'middle', justifyContent: 'center', maxHeight: '100px' }}
            >
              <ReviewImage
                loading="lazy"
                src={image_arr?.[0]}
                alt="product"
                customStyles={{ maxWidth: '100%', maxHeight: '100px', borderRadius: '5px' }}
                idx={0}
                productId={productId}
                reviewId={index}
                onIntersection={() => handleReviewSectionImpressions({ idx: 0, id: productId, src: image_arr?.[0], review_id: index })}
              />
            </div>
          )}
          {showFullScreenImage && checkCustomerImage === isCustomerImage && (showFullScreenImageIndex === index) && (
            <CustomDialog open={showFullScreenImage} onClose={handleFullScreenImageClose} setOpen={handleFullScreenImageClose}>
              <FullScreenImageCarousel
               carouselData={getParsedCarousalData(image_arr)}
               onClose={handleFullScreenImageClose}
               selectedIndex={selectedIndex}
              />
            </CustomDialog>
          )}
        </div>
      </div>
    );
  }

  return (
    <>
      <div id="rating-container">
        {firstImages?.length > 0 && (
          <>
            <div style={{ ...styles.ratingsTitle, paddingBottom: '0' }}>{translate('customer_images_1')}</div>
             <div
              style={{
                width: '100%',
                maxWidth: '100%',
                paddingTop: '16px'
              }}
            >
              <Swiper
                spaceBetween={16}
                slidesPerView={isServer ? 5 : (Math.min(window.innerWidth, 450) - 16) / 77}
                style={{
                  paddingLeft: '16px',
                  zIndex: 0
                }}
              >
                {firstImages.map((item, index) => (
                  <SwiperSlide
                    style={{ width: 'auto' }}
                    key={index}
                    onClick={() => {
                      setFullScreenImage(index, 0, true);
                    }}
                  >
                    <ReviewImage
                      loading="lazy"
                      customStyles={{ objectFit: 'cover', objectPosition: 'bottom', width: '75px', borderRadius: '5px', height: '100px' }}
                      src={item}
                      alt="carousel-images"
                      idx={index}
                      onIntersection={() =>
                        handleReviewSectionImpressions({
                          idx: 0,
                          id: index,
                          src: item,
                          review_id: index,
                          feedType: PDP_PAGE_EVENTS.PDP_REVIEW_CAROUSEL_FEED_TYPE
                        })
                      }
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div> 
          </>
        )}
        {showFullScreenImage && true === isCustomerImage && showFullScreenImageIndex === 0 && (
          <CustomDialog open={showFullScreenImage} onClose={handleFullScreenImageClose} setOpen={handleFullScreenImageClose}>
            <FullScreenImageCarousel carouselData={getParsedCarousalData(firstImages)} onClose={handleFullScreenImageClose} selectedIndex={selectedIndex} />
          </CustomDialog>
        )}
      </div>
      <div className="break-words overflow-x-hidden">
        <div style={styles.ratingsTitle}>{translate('ratings_and_review')}</div>

        <CustomDialog
        open={ratingReviewSheetOpen}
        onClose={handleClose} 
        setOpen={handleClose} 
        isBottomSheet 
        showCloseButton
        customCloseIcon={<FilterSheetCloseIcon />}
          >
        <div className={`max-w-[450px] w-[100vw] box-border rounded-t-[16px] bg-nm_white`}>
        <RatingReviewsContext.Provider value={{reviewsData: reviewV2?.reviews, imgCount: imgCount}}>
          <RatingReviewSheet/>
          </RatingReviewsContext.Provider>
        </div>
    </CustomDialog>
  
        { [...initialReview, ...(showMore ? showMoreReview : [])].map((review, index) => (

           <ReviewContent 
           review={review} 
           index={index} 
           getFilledArray={getFilledArray} 
           getUnFilledArray={getUnFilledArray} 
           renderReviewGallery={renderReviewGallery} 
           reviews={reviews}
           translate={translate}
           onIntersection={() => {
            handleReviewSectionImpressions({
            idx: 0, 
            id: review?.product_id, 
            src: review?.review_photos, 
            review_id: index,
            feedType: PDP_PAGE_EVENTS.PDP_REVIEW_FEED_TYPE
          })}}
            />
        ))}
        
      </div>

      {reviews?.length > 2 &&
        (showMore ? (
          <div style={styles.viewAllReview} onClick={() => handleShowAllReviewClick(reviews)}>
            {translate('view_less')}
          </div>
        ) : (
          <div
            style={styles.viewAllReview}
            onClick={() => handleShowAllReviewClick(reviews)}
          >{`view all ${reviews.length} reviews`}</div>
        ))}
    </>
  );
};

export default memo(Ratings);
