import { Box } from '@mui/material';

import styles from './styles';

interface ToolTipProps {
  text: string;
}

const ToolTip = ({ text }: ToolTipProps) => {
  return (
    <Box sx={styles.tooltip}>
      <Box sx={styles.tooltipContent}>{text}</Box>
      <Box sx={styles.tooltipArrow} />
    </Box>
  );
};

export default ToolTip;
