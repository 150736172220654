import React, { useEffect, createContext } from "react";
import Script from 'next/script'
// import Head from 'next/head';
// import { I18nextProvider } from 'react-i18next';
import { Provider } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";
import { Layout } from "../layouts";
import { store } from "../store/store";
// import i18n from '../i18n';
import LocalStorage from '../utils/storage';
import { onLogout } from '@Utils/common';
import { useLoggedIn } from '@Utils/common';
import { getCookie, hasCookie, setCookie } from 'cookies-next';
import { useTracker } from '@Hooks/useTracker';
// css imports
import "../styles/globals.css";
// import '../styles/App.css';
import theme from '../styles/theme';
import { nextFetch } from "@Services/api";
import { useRouter } from "next/router";
import { primaryFont } from "@Utils/fonts";

export const TrackerContext = createContext();
export const HandleContext = createContext();

async function callBackendToInformLogout() {
  await nextFetch.post(null, `logout_users`, {}, null, {
      'Content-Type': 'application/json',
      'caller': 'web_app',
      'timestamp': new Date().getTime(),
      'Authorization': `Bearer ${getCookie('access_token')}`
    }).then((response) => {
    console.log("Logged out successfully")
  }).catch((err) => {
    console.log("Error in logging out")
  })
}

function getRemainingSecondsInToday() {
  const now = new Date();
  const midnight = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() + 1
  );
  const remainingSeconds = Math.floor((midnight - now) / 1000);
  return remainingSeconds;
}

const clairtyCode = ` (function(c,l,a,r,i,t,y){
  c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
  t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
  y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
})(window, document, "clarity", "script", "lf0qa3tsdh");`

function MyApp({ Component, pageProps }) {
  const { track, handleFirstLoad } = useTracker(),
        { pathname } = useRouter();
  
  const handleAppFirstLoad = async ()=>{
    // await handleFirstLoad();
    document.getElementById("__next").style.width = '100%';
    document.getElementById("__next").style.maxWidth = '450px';
    const isRefreshFlag = LocalStorage.getItem('isRefreshFlag') || false;
    if (useLoggedIn && !isRefreshFlag) { // do not change this line without consulting with backend team
      // send an api call to /logout_users
      if(getCookie('access_token'))
        callBackendToInformLogout();
      LocalStorage.setItem('isRefreshFlag', true);
      LocalStorage.setItem('lastForcedLogout', new Date().getTime());
      onLogout();
    }
    // let timer = LocalStorage.getItem('timer') 
    console.log(getCookie('new_cookie'))
    let hasTimerCookie = hasCookie('timer')
    if (!hasTimerCookie) {
      setCookie("timer", Date.now(), {
        maxAge: getRemainingSecondsInToday(),
      });
    }
  }
  useEffect(() => {
    handleAppFirstLoad();
  }, []);
  return (
    <>
    <main className={primaryFont.className}>
    {pathname === '/redirect-to-app' ? <Component {...pageProps} /> :
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          {/* <I18nextProvider i18n={i18n}> */}
                <TrackerContext.Provider value={track}>
                  <HandleContext.Provider value={handleFirstLoad}>
                    <Layout>
                        <Component {...pageProps}/>
                    </Layout>
              </HandleContext.Provider>
                </TrackerContext.Provider>
          {/* </I18nextProvider> */}
        </ThemeProvider>
      </Provider>}
    </main>
    <Script id="ms-clarity" strategy="afterInteractive">
        {clairtyCode}
      </Script>
      <Script src="https://checkout.gokwik.co/integration.js" strategy="lazyOnload"/>
    </>
  );
}

export default MyApp;
