import { getProductImageSizeSet, getProductImageSrcSet } from "@Components/ProductCard/utils";
import React, { memo, useState } from 'react';
import FullScreenImageCarousel from '../FullScreenImageCarousel';
import CustomDialog from '@Components/CustomModal';

const ProductHeroImg = ({ product }) => {
  const [showFullScreenImage, setShowFullScreenImage] = useState(false);

  return (
    <>
      <div
        onClick={() => setShowFullScreenImage(true)}
        style={{
          display: 'flex',
          alignItems: 'middle',
          justifyContent: 'center',
          height: '390px',
          width: '100%'
        }}
      >
        <img
          src={product?.image_url}
          alt="product"
          style={{ maxWidth: '100%', height: '100%' }}
          srcSet={getProductImageSrcSet(product?.image_gallery_urls)}
          sizes={getProductImageSizeSet(product?.image_gallery_urls)}
        />
      </div>
      {showFullScreenImage && !!product?.image_url && (
        <CustomDialog open={showFullScreenImage} onClose={() => setShowFullScreenImage(false)} setOpen={setShowFullScreenImage}>
          <FullScreenImageCarousel carouselData={[{ url: product?.image_url, type: 'image' }]} onClose={() => setShowFullScreenImage(false)} selectedIndex={0} />
        </CustomDialog>
      )}
    </>
  );
};

export default memo(ProductHeroImg);
