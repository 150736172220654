import { APITags, RequestTypes } from '@Constants/api';
import { baseApi } from '@Services/api';
import { setWishListData } from './wishlistSlice';

const wishListApis = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getWishlist: builder.query({
      query: () => ({
        url: 'wishlist',
        method: RequestTypes.GET
      }),
      providesTags: [APITags.GET_WISHLIST],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;

        if (data) dispatch(setWishListData(data?.wishlist));
      }
    }),
    getWishlistPageData: builder.mutation({
      query: (payload) => ({
        url: `wishlist?page_number=${payload}&get_details=${true}`,
        method: RequestTypes.GET
      }),
    }),
    addToWishlist: builder.mutation({
      query: (payload) => ({
        url: 'wishlist/add',
        method: RequestTypes.POST,
        body: payload
      }),
      invalidatesTags: [APITags.GET_WISHLIST]
    }),
    removeFromWishlist: builder.mutation({
      query: (payload) => ({
        url: 'wishlist/remove',
        method: RequestTypes.POST,
        body: payload
      }),
      invalidatesTags: [APITags.GET_WISHLIST]
    })
  })
});

export const { useGetWishlistQuery, useGetWishlistPageDataMutation, useAddToWishlistMutation, useRemoveFromWishlistMutation, useLazyGetWishlistQuery } =
  wishListApis;
