import { colors } from '@Constants/common';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '16px',
  },
  headerIcon: {
    filter: 'contrast(110%) saturate(110%)'
  },
  title: {
    fontSize: '20px',
    lineHeight: '24px',
    fontWeight: 600,
    color: colors.FONT_SECONDARY,
    textAlign: 'center',
    paddingLeft :'14px',
    paddingRight :'14px',
  },
  description: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 400,
    color: colors.DIM_GRAY,
    textAlign: 'center',
    margin: '0 15px',
    marginTop: '20px',
    paddingLeft :'14px',
    paddingRight :'14px',
  },
  readPolicy: {
    fontSize: '12px',
    lineHeight: '16px',
    color: colors.PRIMARY_DARK,
    cursor: 'pointer'
  },
  gradient: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '16px',
    background: 'linear-gradient(to bottom, #D1FBDB, #FFFFFF);',
    borderTopLeftRadius: '10px', 
    borderTopRightRadius: '10px'
  },
  inputContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    marginBottom: '5px',
    marginTop: '10px'
  },
  input: {
    marginBottom: '20px',
    backgroundColor: colors.WHITE_SMOKE, 
    '& .MuiFilledInput-underline:after': {
      borderBottomColor: colors.SILVER_GRAY,
    },
  },
  saveBtn: {
    height: '48px',
    textTransform: 'none',
    fontWeight: 600,
    width: '100%',
    cursor: 'pointer',
    boxShadow: 'none',
    color: colors.WHITE,
    marginBottom: '0px',
    '&:hover': { backgroundColor: colors.PRIMARY, boxShadow: 'none' }
  },
};

export default styles;
