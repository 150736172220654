import { ROUTES } from "@Constants/routes";
import { BRANCH_DEEPLINK_URL } from "@Constants/urls";
import { DeepLinkParams } from "@Types/common";

export const generate_deep_link = (link : string, deeplink = BRANCH_DEEPLINK_URL) => {
   let web_url = new URL(link),
       data_obj : DeepLinkParams = {}

   if (web_url.pathname.includes(ROUTES.COLLECTION_PATH)) {
      let tag = web_url.pathname.split(ROUTES.COLLECTION_PATH)
      const tag_name = tag[1].split("/")[0]
      data_obj['tag'] = tag_name
   }
      
   let searchParams = web_url.searchParams,
       keysIterator = searchParams.keys();
   
   for (let nextKey = keysIterator.next(); !nextKey.done; nextKey = keysIterator.next()) {
     let x = nextKey.value,
         tempKey = x,
         tempValue = searchParams.get(tempKey);
   
     // Check and modify keys if needed
     if (x === 'product_cat') tempKey = 'category';
     if (x === 'subCategory') tempKey = 'subcategory';
     if (x === 'orderby') tempKey = 'sort_by';
   
     data_obj[tempKey] = tempValue;
   }   

   let after_link = ""

   if (link.includes(ROUTES.WISHLIST)) {
      after_link += ROUTES.WISHLIST;
   }
   else if (link.includes(ROUTES.COLLECTION_PATH) || link.includes(ROUTES.SHOP_PATH)) {
      after_link += `${ROUTES.SHOP_PATH}?${data_obj?.tag ? 'tag=' + data_obj?.tag : ''}${data_obj?.category ? '&category=' + data_obj?.category : ''}${data_obj?.sort_by ? '&sort_by=' + data_obj?.sort_by : ''}${data_obj?.subcategory ? '&subcategory=' + data_obj?.subcategory : ''}`;
   }
   else if(link.includes(ROUTES. WOMENS_COLLECTION)){
      after_link += '/category-page'
   }
   else if (link.includes(ROUTES.PRODUCT)) {
      let slug = link.split('/product/')[1]
      after_link += `/product/?product_id=${slug}`
   }
   else if (link.includes(ROUTES.CART)) {
      after_link += ROUTES.CART
   }
   else if (link.includes(ROUTES.REELS_PATH)) {
      after_link += ROUTES.REELS_PATH;
   }
   else if (link.includes(ROUTES.REFERANDEARN_PATH)) {
      after_link += ROUTES.REFERANDEARN_PATH
   }
   else if (link.includes(ROUTES.WALLET_PATH)) {
      if (link.includes(ROUTES.REFERRALS_PATH)) {
         after_link += ROUTES.REFERRALS_PATH;
      }
      else {
         after_link += ROUTES.WALLET_PATH
      }
   }
   else if (link.includes(ROUTES.PROFILE)){
      if (link.includes(ROUTES.ORDER_DETAILS_PATH)) {
         Error('no return in order-details')
      }
      else if (link.includes(ROUTES.ORDERS_PATH)) {
         after_link += ROUTES.ORDERS_PATH
      }
      else{
         after_link += '/my-account';
      }
   }
   else {
      after_link += '';
   }

   for (let nextKey = keysIterator.next(); !nextKey.done; nextKey = keysIterator.next()) {
      let x = nextKey.value;
      
      if (!x.includes("utm_")) continue
      let tempValue = web_url.searchParams.get(x);

      if(!after_link.includes('?')) {
        after_link += `?${x}=${tempValue}`
      } else {
        if (!after_link.endsWith('&')){
         after_link+='&'
        }
        after_link += `${x}=${tempValue}`
      }
   }

   deeplink = deeplink + after_link
   return deeplink
};