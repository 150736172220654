export interface TagType {
  name: string;
  color: string;
  textColor: string;
}

interface IProductTagProps {
  tag?: TagType;
  isProductCard?: boolean;
}

const PRODUCT_CARD_TAG_BORDER_RADIUS = 'rounded-br-[4px] rounded-tr-[4px]',
      PDP_TAG_BORDER_RADIUS = 'rounded-[4px]';

const ProductTag = ({ tag, isProductCard = false }: IProductTagProps) => {
  return (
    tag && (
      <div
        className={`px-[8px] py-[2px] text-[10px] leading-[14px] text-center font-medium uppercase ${
          isProductCard
            ? `absolute bottom-[20px] left-0 ${PRODUCT_CARD_TAG_BORDER_RADIUS} bg-nm_mask`
            : `${PDP_TAG_BORDER_RADIUS} h-fit w-fit mb-[10px]`
        }`}
        style={{ color: tag.textColor ?? 'white', ...(!isProductCard ? { background: tag.color } : {}) }}
      >
        {isProductCard && (
          <div
            className={`w-full h-full top-0 left-0 absolute opacity-55 ${PRODUCT_CARD_TAG_BORDER_RADIUS} z-0`}
            style={{ background: tag.color }}
          />
        )}
        <div className={`${isProductCard ? 'relative z-10' : ''}`}>{tag.name}</div>
      </div>
    )
  );
};

export default ProductTag;