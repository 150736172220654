import useTranslation from '@Utils/useTranslation';
import { useNavigate } from 'react-router-dom';
import { CSSProperties, memo, useCallback, useContext, useEffect, useState } from 'react';
import { PrimaryButtons } from '@Constants/common';
import { ROUTES } from '@Constants/routes';
import { RootState, useAppSelector, useAppDispatch } from '@Store/store';
import WishListIcon from '@assets/icons/heart.svg';
import WishListActiveIcon from '@assets/icons/WishlistActive.svg';
import { colors } from '@Constants/colors';
import { BuyNowProps } from './types';
import styles from './styles';
import { TrackerContext } from '_app';
import { getTrackingEventObject } from '@Utils/common';
import { PDP_PAGE_EVENTS, RECOMMENDED_PRODUCT_EVENTS, GLOBAL_ACTION_TYPES } from '@Constants/events';
import { useRouter } from 'next/router';
import { setRecommendationSheet } from '@Containers/RecommendationSheet/recommendationSheetSlice';
import { Popover, PopoverContent, PopoverTrigger } from '@Reducers/shadcn/ui/popover';
import { setBuyNowCart } from '@Components/RecommendedProducts/recommendedProductsSlice';
import { isNonEmptyArray } from '@Utils/checks';
import { setPdpToast } from '@Containers/Cart/cartSlice';
import {addToBuyNowCart} from '@Containers/RecommendationSheet/recomendationUtlis'

const AddToWishListPopover = () => {
  return (
      <div className="relative pointer-events-auto z-[10000] w-[135px] bottom-[80px] left-[-50px]">
      <div className="px-[8px] py-[12px] rounded-[12px] bg-nm_black_2 flex flex-col gap-[8px] z-10">
          <div className="text-[12px] font-[500] leading-[14px] text-nm_white"> Add item to wishlist </div>
      </div>
    <div className="w-[9px] h-[9px] bg-nm_black_2 rotate-45 absolute left-[15px] bottom-[-4px] " />
  </div>
  )
}

const BuyNowSection = ({ setOpen, selectedSize, handleBuyNow, handleAddToCart, sizesInCart, productId, isBottomSheet, isInWishList, onWishListPress, product }: BuyNowProps) => {
  const { t: translate } = useTranslation(),
    navigate = useNavigate(),
    track = useContext(TrackerContext),
    router = useRouter(),
    { app_config_data: webAppDefaultConfig } = useAppSelector((state: RootState) => state.layout),
    { remove_buy_now: rmBuyNow } = webAppDefaultConfig ?? {},
    dispatch = useAppDispatch();
    
  const { buy_now_cart_items } = useAppSelector((state: RootState) => state.recommededProducts)

  const getVariation = useCallback(() => product?.variations?.filter((variation) => variation.size === selectedSize), [product?.variations, selectedSize]);

  const [openPopover, setOpenPopover] = useState(false);

  const handleSecondaryButtonClick = () => {
    if (rmBuyNow === 1) {
      onWishListPress?.();
      track(getTrackingEventObject(0, PDP_PAGE_EVENTS.FEED_TYPE, PDP_PAGE_EVENTS.FOOTER_WISHLIST_CLICK_ACTION_TYPE, productId, router.asPath));
    } 
    else {
      track(getTrackingEventObject(0, PDP_PAGE_EVENTS.FEED_TYPE, PDP_PAGE_EVENTS.BUY_NOW_CLICK_ACTION_TYPE, productId, router.asPath));
      if (selectedSize === '') handleOpenSizeSelector(PrimaryButtons.BUY_NOW);
      else if(rmBuyNow === 2) handleAddToCart(ROUTES.CART) 
      else handleBuyNow();
    }
  };

  const handlePrimaryButtonClick = () => {
    if (!sizesInCart.includes(selectedSize))
      track(getTrackingEventObject(1, PDP_PAGE_EVENTS.FEED_TYPE, PDP_PAGE_EVENTS.ADD_TO_BAG_CLICK_ACTION_TYPE, productId, router.asPath));
    if (sizesInCart.includes(selectedSize)) {
      navigate(ROUTES.CART);

      return;
    }
    if (selectedSize === '') handleOpenSizeSelector(PrimaryButtons.ADD_TO_BAG);
    else handleAddToCart();
  };

  const handleBottomSheetAddToBag = () => {
    if (selectedSize === ''){
      dispatch(setRecommendationSheet('sizeSelector'))
      track(getTrackingEventObject(0, RECOMMENDED_PRODUCT_EVENTS.FEED_TYPE, RECOMMENDED_PRODUCT_EVENTS.ADD_TO_BAG_CLICK, productId, router.asPath));

    }
    else {
      if(router.pathname === '/checkout'){
        let variationID = getVariation()?.[0].id
        
        addToBuyNowCart(product, selectedSize, buy_now_cart_items, dispatch, variationID)

        dispatch(setRecommendationSheet(''))
        dispatch(setPdpToast(true))
      }
      else
        handleAddToCart();
    } 
  }

  const handleOpenSizeSelector = (title: string) => {
    setOpen(title);
  };

  useEffect(() => {
    let openTimer;
    let closeTimer;

    if (isBottomSheet) {
      openTimer = setTimeout(() => {
        setOpenPopover(true);
        closeTimer = setTimeout(() => {
          setOpenPopover(false);
        }, 2000);
      }, 5000);
    } else {
      clearTimeout(openTimer);
      clearTimeout(closeTimer);
      setOpenPopover(false);
    }

    return () => {
      clearTimeout(openTimer);
      clearTimeout(closeTimer);
    };
  }, [isBottomSheet]);

  return (
    <div style={!isBottomSheet ? styles.buyNowLayout : styles.bottomSheetBuyNowLayout as CSSProperties}>
      {
        isBottomSheet ? (isInWishList ? <div>
          <WishListActiveIcon
          className="p-2 w-fit cursor-pointer w-8" onClick={onWishListPress}
        />
        </div>  : 
        <div className='relative z-10'>
          <WishListIcon className="p-2 w-fit cursor-pointer w-8" onClick={onWishListPress}/>
          <Popover open={openPopover} >
              <PopoverTrigger asChild>
              <button className="customButton customButton--noScale customButton--transparent p-0" ></button>
              </PopoverTrigger>
              <PopoverContent alignOffset side='top' align='end' className='absolute z-[5000]'>
                  <AddToWishListPopover />
              </PopoverContent>
          </Popover>
      </div>
        
      ):(
      <button
        id={'buy_now'}
        className="customButton customButton--noScale"
        style={(isBottomSheet ? styles.bottomSheetBuyNowButton : rmBuyNow === 1 ? styles.addToBagButton : styles.buyNowButton) as CSSProperties}
        onClick={handleSecondaryButtonClick}
      >
        {rmBuyNow === 1 ? (
          isInWishList ? (
            <>
              <WishListActiveIcon
                style={{ paddingRight: '4px', cursor: 'pointer', width: 'fit-content', transform: 'scale(0.77)' }}
                onClick={onWishListPress}
                fill={colors.PRIMARY}
              />
              {'Wishlisted'}
            </>
          ) : (
            <>
              <WishListIcon style={{ paddingRight: '5px', cursor: 'pointer', width: 'fit-content', transform: 'scale(0.72)' }} onClick={onWishListPress} />
              {'Wishlist'}
            </>
          )
        ) : (
          translate(PrimaryButtons.BUY_NOW)
        )}
      </button>
      )}
      <button
        className="customButton customButton--noScale"
        style={(isBottomSheet ? styles.bottomSheetBuyNowButton : rmBuyNow === 1 ? styles.buyNowButton : styles.addToBagButton ) as CSSProperties}
        onClick={isBottomSheet ? handleBottomSheetAddToBag : handlePrimaryButtonClick}
      >
        {sizesInCart.includes(selectedSize) ? translate(PrimaryButtons.GO_TO_BAG) : translate(PrimaryButtons.ADD_TO_BAG)}
      </button>
      
    </div>
  );
};

export default memo(BuyNowSection);