import { Box } from '@mui/material';
import useTranslation from '@Utils/useTranslation';

import AddIcon from '@assets/icons/AddIcon.svg';
import MinusIcon from '@assets/icons/MinusIcon.svg';
import { MAX_QUANTITY } from '@Constants/common';
import { colors } from '@Constants/colors';

import styles from './styles';
import { QuantitySelectorProps } from './types';

const QuantitySelector = ({ quantity = 1, setQuantity, isOutOfStock }: QuantitySelectorProps) => {
  const { t: translate } = useTranslation();

  const onAddClick = () => {
    if (quantity >= MAX_QUANTITY) return;
    setQuantity(quantity + 1);
  };

  const onMinusClick = () => {
    if (quantity <= 1) return;
    setQuantity(quantity - 1);
  };

  const iconColor = isOutOfStock ? colors.LIGHT_SILVER : colors.DARK_BLUE;

  return (
    <Box sx={{ padding: '24px 0' }}>
      <div style={styles.quantitySelectorTitle}>{translate('select_quantity')}</div>
      <Box sx={styles.quantityCards}>
        <Box sx={{ cursor: 'pointer' }} onClick={!isOutOfStock ? onMinusClick : undefined}>
          <MinusIcon stroke={iconColor} />
        </Box>
        <Box sx={styles.quantityCard} position='relative'>
          <Box style={{ ...styles.quantityCardText, ...(isOutOfStock && styles.disabled) }}>{quantity}</Box>
        </Box>
        <Box sx={{ cursor: 'pointer' }} onClick={!isOutOfStock ? onAddClick : undefined}>
          <AddIcon stroke={iconColor} />
        </Box>
      </Box>
    </Box>
  );
};

export default QuantitySelector;
