import { colors } from '@Constants/colors';

const styles = {
  text: {
    fontSize: '10px',
    lineHeight: '12px',
    color: colors.DIM_GRAY,
    marginLeft: '5px',
    textDecorationLine: 'underline',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '10px'
  },
  heading: {
    fontSize: '16px',
    lineHeight: '24px',
    color: colors.BLACK,
    marginBottom: '5px',
    fontWeight: 500
  },
  subText: {
    fontSize: '12px',
    lineHeight: '16px',
    color: colors.BLACK,
    fontWeight: 400
  },
  boldText: {
    fontSize: '12px',
    lineHeight: '16px',
    color: colors.BLACK,
    fontWeight: 600
  }
};

export default styles;
