import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@Store/store';

export interface SearchState {
  searchTerm: string;
}

const initialState: SearchState = {
  searchTerm: ''
};

export const searchKeySlice = createSlice({
  name: 'searchKey',
  initialState,
  reducers: {
    updateSearch: (state, action: PayloadAction<string>) => ({
      ...state,
      searchTerm: action.payload
    }),
    clearSearch: (state) => ({
      ...state,
      searchTerm: ''
    })
  }
});

export const selectSearchTermState: (state: RootState) => {
  searchTerm: string;
} = ({ searchKey }) => ({
  searchTerm: searchKey.searchTerm
});

export const { updateSearch, clearSearch } = searchKeySlice.actions;

export default searchKeySlice.reducer;
