import React, { Fragment, useCallback, useContext, useEffect, useRef, useState } from 'react';
import CustomDialog from '@Components/CustomModal';
import FilterSheetCloseIcon from '@assets/icons/FilterSheetCloseIcon.svg';
import SignupSheetWalletIcon from '@assets/icons/SignupSheetWalletIcon.svg';
import SignupSheetCouponIcon from '@assets/icons/SignupSheetCouponIcon.svg';
import SignupSheetSeparatorIcon from '@assets/icons/SignupSheetSeparatorIcon.svg';
import { RootState, useAppDispatch, useAppSelector } from '@Store/store';
import { isNonEmptyArray, isNonEmptyObject, isNonEmptyString } from '@Utils/checks';
import LoginBottomSheet from '@Components/LoginBottomSheet';
import SuccessModal from './Components/SuccessModal';
import FailureModal from './Components/FailureModal';
import { setSignupSheetClosedCount, setSignupSheetOpen } from './signupBottomSheetSlice';
import { SessionStorage } from '@Utils/storage';
import { useRouter } from 'next/router';
import { setPrevPath } from '@Containers/Login/loginSlice';
import { TrackerContext } from '_app';
import { getTrackEventMetadata, getTrackingEventObject } from '@Utils/common';
import { SIGNUP_SHEET_EVENTS, GLOBAL_ACTION_TYPES } from '@Constants/events';
import { SIGNUP_SHEET_MODAL_SHOWN } from '@Constants/storageKeys';

const DEFAULT_MAX_RENDERS = 3;

const SignupNotification = ({ signupNotificationConfig, setActiveContent }) => {
  const track = useContext(TrackerContext),
    { asPath } = useRouter(),
    {
      title,
      sub_title: subTitle,
      sign_up_button_label: signupButtonLabel,
      referral_offer_msg: referralOfferMsg,
      icon_config: iconConfig
    } = signupNotificationConfig;

  const handleSignupButtonClick = useCallback(() => {
    track(getTrackingEventObject(-1, SIGNUP_SHEET_EVENTS.FEED_TYPE, GLOBAL_ACTION_TYPES.CLICK, SIGNUP_SHEET_EVENTS.SIGN_UP_BTN_CLICK, asPath, { meta: getTrackEventMetadata()}));
    setActiveContent('login');
  }, []);

  return (
    <div
      className="py-[24px] px-[16px] rounded-t-[24px] overflow-y-auto hideScrollBar"
      style={{
        background: 'linear-gradient(180deg, #FEFFFE 0%, #EDFFE8 100%)'
      }}
    >
      <div className="flex flex-col gap-[28px] items-center">
        {(title || subTitle) && (
          <div>
            {title && <h1 className="m-0 mb-[8px] p-0 text-[20px] font-[800] leading-[28px] text-nm_black text-center">{title}</h1>}
            {subTitle && (
              <div className="flex items-center gap-[13px] w-full box-border px-[10px]">
                <span className="w-full h-[1px] bg-nm_light_gray_10" />
                <h6 className="m-0 p-0 text-[14px] font-[500] leading-[20px] text-nm_light_gray_10 text-center w-full min-w-max">{subTitle}</h6>
                <span className="w-full h-[1px] bg-nm_light_gray_10" />
              </div>
            )}
          </div>
        )}
        {isNonEmptyArray(iconConfig) && (
          <div className="text-center w-fit">
            <div className="flex items-start justify-center">
              {iconConfig.map((icon, idx) => {
                const { title } = icon ?? {};

                return (
                  <Fragment key={title + idx}>
                    <div key={title + idx} className={'max-w-[29%] min-w-[15%] flex flex-col items-center justify-start flex-1 '}>
                      {title && (
                        <>
                          <div className={'rounded-full aspect-square flex items-center justify-center bg-nm_light_mystique_green p-[16px]'}>
                            <div className="w-[52px] flex items-center justify-center">
                              {idx % 2 === 0 ? <SignupSheetWalletIcon /> : <SignupSheetCouponIcon />}
                            </div>
                          </div>
                          {title && <p className="p-0 m-0 mt-[6px] text-[12px] font-[500] leading-[16px] text-nm_black_2 text-center">{title}</p>}
                        </>
                      )}
                    </div>
                    {idx < iconConfig.length - 1 && (
                      <div className={'w-[34px] flex flex-col items-center justify-start'}>
                        <div className={'rounded-full w-[34px] aspect-square flex items-center justify-center py-[20px]'}>
                          <div className="h-[40px] flex items-center justify-center">
                            <SignupSheetSeparatorIcon />
                          </div>
                        </div>
                      </div>
                    )}
                  </Fragment>
                );
              })}
            </div>
          </div>
        )}
        {referralOfferMsg && (
          <p className="m-0 w-full bg-nm_light_mystique_green rounded-[4px] px-[4px] py-[6px] text-[12px] text-nm_dark_mystique_green font-[600] leading-[16px] text-center box-border">
            {referralOfferMsg}
          </p>
        )}
      </div>
      {signupButtonLabel && (
        <button
          onClick={handleSignupButtonClick}
          className="customButton customButton--noScale w-full rounded-[100px] bg-nm_primary px-[12px] py-[14px] text-center text-[14px] font-[600] leading-[20px] text-nm_white mt-[12px]"
        >
          {signupButtonLabel}
        </button>
      )}
    </div>
  );
};

const expelledPaths = ['/login', '/store-register', '/new-store'],
  checkIfValidPath = ({ pathname }) => !expelledPaths.includes(pathname);

const renderSignupSheetContent = ({ key, sign_up_bottom_sheet, setActiveContent, loginBannerUrl, setFullScreen }) => {
  switch (key) {
    case 'initial':
      return <SignupNotification signupNotificationConfig={sign_up_bottom_sheet} setActiveContent={setActiveContent} />;
    case 'login':
      return (
        <LoginBottomSheet
          disableModal
          isVersion2
          setOtpSession={setFullScreen}
          {...(isNonEmptyString(loginBannerUrl) ? { imageUrl: loginBannerUrl, source: { via_wallet_nudge: true } } : {})}
        />
      );
    default:
      return <></>;
  }
};

const SignupBottomSheet = () => {
  const track = useContext(TrackerContext),
    { pathname, asPath } = useRouter(),
    dispatch = useAppDispatch(),
    { user } = useAppSelector((state: RootState) => state.profile),
    { signup_sheet_open: signupSheetOpen, signup_sheet_closed_count: signupSheetClosedCount } = useAppSelector((state: RootState) => state.signupSlice),
    { app_config_data: webAppDefaultConfig } = useAppSelector((state: RootState) => state.layout),
    { wallet_signup_bottom_sheet: signupSheetConfig } = webAppDefaultConfig ?? {},
    {
      sign_up_bottom_sheet,
      referral_sign_up_success_modal: successModalConfig,
      referral_sign_up_failure_modal: failureModalConfig,
      login_bottom_sheet_banner: loginSheetBanner
    } = signupSheetConfig ?? {},
    { delay, delay_revised, max_renders: maxRenders } = sign_up_bottom_sheet ?? {},
    { image_url: imageUrl, render } = loginSheetBanner ?? {},
    timerRef = useRef(null),
    [fullScreen, setFullScreen] = useState(false),
    modalStorageFlag = SessionStorage.getItem(SIGNUP_SHEET_MODAL_SHOWN),
    modalShownFlag = modalStorageFlag ? JSON.parse(modalStorageFlag) : false,
    isValidPath = checkIfValidPath({ pathname: pathname });

  const timeModalOpen = ({ delay }) => {
    clearTimeout(timerRef.current);

    timerRef.current = setTimeout(() => {
      if (isNonEmptyObject(sign_up_bottom_sheet) && !modalShownFlag && !user?.id && isValidPath) {
        delay && dispatch(setSignupSheetOpen('initial'));
        dispatch(setPrevPath(asPath));
      }
    }, delay);
  };

  useEffect(() => {
    if (delay) timeModalOpen({ delay: delay });

    if(!fullScreen)
      setFullScreen(false);

    return () => {
      clearTimeout(timerRef.current);
    };
  }, [webAppDefaultConfig]);

  useEffect(() => {
    if (isNonEmptyObject(sign_up_bottom_sheet) && signupSheetOpen === 'initial' && !modalShownFlag && !user?.id && isValidPath)
      track(getTrackingEventObject(-1, SIGNUP_SHEET_EVENTS.FEED_TYPE, GLOBAL_ACTION_TYPES.VIEW, SIGNUP_SHEET_EVENTS.SIGN_UP_SHEET_RENDER, asPath, { meta: getTrackEventMetadata()}));

    if(isNonEmptyString(signupSheetOpen))
      clearTimeout(timerRef.current);
  }, [signupSheetOpen]);

  const handleSheetClose = () => {
    dispatch(setSignupSheetClosedCount(signupSheetClosedCount + 1));

    track(getTrackingEventObject(-1, SIGNUP_SHEET_EVENTS.FEED_TYPE, GLOBAL_ACTION_TYPES.CLICK, SIGNUP_SHEET_EVENTS.SIGN_UP_SHEET_CLOSE, asPath, { meta: getTrackEventMetadata()}));
    dispatch(setSignupSheetOpen(''));

    if (delay_revised && signupSheetClosedCount < (maxRenders ?? DEFAULT_MAX_RENDERS)) timeModalOpen({ delay: delay_revised });
  };

  return (
    <>
      {isNonEmptyObject(sign_up_bottom_sheet) && (
        <CustomDialog
          open={isNonEmptyString(signupSheetOpen) && !user?.id && isValidPath}
          onClose={handleSheetClose}
          setOpen={handleSheetClose}
          isBottomSheet
          showCloseButton
          customCloseIcon={<FilterSheetCloseIcon />}
          customStyles={fullScreen ? { maxHeight: 'calc(100% + 40px)' } : {}}
        >
          <div className="w-[100vw] max-w-[450px] box-border">
            {renderSignupSheetContent({
              key: signupSheetOpen,
              setActiveContent: (activeKey) => dispatch(setSignupSheetOpen(activeKey)),
              sign_up_bottom_sheet: sign_up_bottom_sheet,
              loginBannerUrl: render && imageUrl,
              setFullScreen: setFullScreen,
            })}
          </div>
        </CustomDialog>
      )}
      <SuccessModal successModalConfig={successModalConfig} />
      <FailureModal failureModalConfig={failureModalConfig} />
    </>
  );
};

export default SignupBottomSheet;
