import { colors } from '@Constants/colors';

const styles = {
  appbarWrapper: {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    maxWidth: '450px',
    zIndex: 100
  },
  appbar: {
    backgroundColor: colors.SECONDARY,
    top: 0,
    left: 0,
    maxWidth: 450,
    width: '100%'
  },
  actionWrapper: { display: 'flex', gap: '8px' },
  iconButton: {  position: 'relative' },
  toolbar: { display: 'flex', justifyContent: 'space-between' },
  appBarTitle: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'inline-grid',
    whiteSpace: 'nowrap'
  },
  titleText: {
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '22px',
    color: colors.FONT_SECONDARY,
    textWrap: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  countText: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '18px',
    color: colors.FONT_SECONDARY,
  },
  cartCount: {
    position: 'absolute',
    top: '-3px',
    left: '19px',
    width: '20px',
    height: '20px',
    backgroundColor: colors.PRIMARY,
    color: colors.WHITE,
    fontSize: '12px',
    borderRadius: '10px',
    paddingTop: '2px',
    boxSizing: 'border-box'
  }
};

export default styles;
