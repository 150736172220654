import ProgressBar from "@Components/ProgressBar"
import { memo } from "react";

const ProductExpSheetItem = ({feature}) => {

    const percentages = feature.percentages;
    const highestPercentage = Math.max(...Object.values(percentages));

    return <>
        <div className='flex items-end'>
            <div className='w-full'>
                <div className='mx-4'>
                    <p className='my-2 text-nm_light_gray_11 text-start text-[12px] font-[500] leading-[16px]'>{feature.key}</p>
                    {Object.keys(feature?.percentages).map(key => {
                        const isHighest = percentages[key] === highestPercentage;
                        return <div className='my-4'>
                        <div className='flex items-center gap-[4px]'>
                            <div className='w-[70%]'>
                                <ProgressBar mainBg={'nm_white_smoke'} progressBg={isHighest ? 'bg-nm_dark_green_2' : 'bg-nm_bumble_bee_yellow_default' } progress = {feature.percentages[key]}/>
                            </div>
                                <div className='w-[30%] text-[12px] font-[500] leading-[16px] text-end'>{key} {`(${feature.percentages[key]}%)`}</div>
                        </div>
                    </div>
                    })}
                </div>
            </div>
        </div>
    </>
}

export default memo(ProductExpSheetItem);