import { CSSProperties, useCallback, useState } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import useTranslation from '@Utils/useTranslation';
import { colors } from '@Constants/colors';
import CategoryIcon from '@assets/icons/Category.svg';
import ShopActiveIcon from '@assets/icons/ShopActive.svg';
import WishListIcon from '@assets/icons/WishList.svg';
import ProfileIcon from '@assets/icons/Profile.svg';
import HomeIcon from '@assets/icons/Home.svg';
import CategoryActiveIcon from '@assets/icons/CategoryActive.svg';
import ShopIcon from '@assets/icons/Shop.svg';
import WishListActiveIcon from '@assets/icons/WishlistActive.svg';
import ProfileActiveIcon from '@assets/icons/ProfileActive.svg';
import HomeActiveIcon from '@assets/icons/HomeActive.svg';
import { useAppDispatch } from '@Store/store';
import { resetShopScroll } from '@Containers/ScrollRestoration/scrollSlice';
import { ROUTES } from '@Constants/routes';
import styles from './styles';
import { FOOTER } from './types';
import { Pages } from '@Constants/common';

interface FooterItem {
  key: string;
  label: string;
  icon: JSX.Element;
  path: string[];
  activeIcon: JSX.Element;
}

const { t: translate } = useTranslation();

const footerItems: FooterItem[] = [
  {
    key: FOOTER.HOME,
    label: translate('footer.home'),
    icon: <HomeIcon />,
    path: [ROUTES.HOME],
    activeIcon: <HomeActiveIcon />
  },
  {
    key: FOOTER.SHOP,
    label: translate('footer.shop'),
    icon: <ShopIcon />,
    path: [`${ROUTES.SHOP}?p=1`, ROUTES.CATEGORY],
    activeIcon: <ShopActiveIcon />
  },
  {
    key: FOOTER.CATEGORY,
    label: translate('footer.category'),
    icon: <CategoryIcon />,
    path: [ROUTES.WOMENS_COLLECTION],
    activeIcon: <CategoryActiveIcon />
  },

  {
    key: FOOTER.WISHLIST,
    label: translate('footer.wishlist'),
    icon: <WishListIcon />,
    path: [ROUTES.WISHLIST],
    activeIcon: <WishListActiveIcon />
  },
  {
    key: FOOTER.ME,
    label: translate('footer.me'),
    icon: <ProfileIcon />,
    path: [ROUTES.PROFILE],
    activeIcon: <ProfileActiveIcon />
  }
];

export const getActiveFooterOption = (pathname: string) => {
  if(pathname === ROUTES.WOMENS_COLLECTION || pathname === ROUTES.COLLECTION_PATH)
    return Pages.CATEGORY;
  else if(pathname.includes(Pages.SHOP) || pathname.includes(Pages.WOMENS_COLLECTION) || pathname.includes(Pages.COLLECTION))
    return Pages.SHOP;
  else if(pathname.includes(Pages.WISHLIST))
    return Pages.WISHLIST;
  else if(pathname.includes(Pages.PROFILE))
    return Pages.PROFILE;
  else  
    return Pages.HOME;
}

const Footer: React.FC<{ currentPage?: string }> = ({ currentPage = '' }) => {
  // const [selectedTab, setSelectedTab] = useState<string>(currentPage);
  const { pathname } = useRouter();
  const dispatch = useAppDispatch();

  const handleTabChange = useCallback((key: string) => {
    if (key === FOOTER.SHOP || key === FOOTER.CATEGORY || key === FOOTER.HOME) dispatch(resetShopScroll());
    // setSelectedTab(key);
  }, []);

  const selectedTab = getActiveFooterOption(pathname);

  return (
    <nav style={styles.footerWrapper as CSSProperties}>
          <div style={styles.actionWrapper}>
            {footerItems.map((item, idx) => (
              <div style={styles.tabWrapper as CSSProperties} key={item.key+idx}>
                <Link href={item.path[0] ?? ''}>
                  <div onClick={() => handleTabChange(item.key)}>
                    {
                      (selectedTab === item.key || item.path.includes(pathname)) ? item.activeIcon : item.icon
                    }
                  </div>
                  <div
                    style={{
                      ...styles.label,
                      ...((selectedTab === item.key || item.path.includes(pathname)) && {
                        color: colors.PRIMARY
                      })
                   }}
                  >
                    {item.label}
                  </div>
                </Link>
              </div>
            ))}
          </div>
    </nav>
  );
};

export default Footer;
