import { colors } from '@Constants/colors';

const styles = {
  addressSection: {
    width: '100%',
    backgroundColor: colors.DARK_BLUE,
    color: colors.WHITE,
    padding: '12px 18px 18px',
    boxSizing: 'border-box',
    textAlign: 'center'
  },
  addressTitle: {
    width: 'max-content',
    margin: 'auto',
    marginBottom: '2px',
    fontSize: '10px'
  },
  address: {
    maxWidth: '300px',
    margin: 'auto',
    color: colors.DARK_GRAY,
    fontSize: '8px'
  }
};

export default styles;
