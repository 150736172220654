import { Carousel } from "react-responsive-carousel";
import { Box } from "@mui/material";

import { CustomCarouselProps } from "./types";

import "react-responsive-carousel/lib/styles/carousel.min.css";

const CustomCarousel = ({
  renderIndicator,
  renderItem,
  carouselData,
  autoPlay,
  infiniteLoop,
  containerStyle,
  autoPlayInterval,
  centerMode = false,
  showIndicator = true,
  selectedItem,
  showArrows = false,
  swipeable = true,
  onChange,
}: CustomCarouselProps): JSX.Element => (
  <Box sx={containerStyle}>
    {carouselData.length > 0 ? (
      <Carousel
        key={carouselData.length}
        autoPlay={autoPlay}
        selectedItem={selectedItem}
        renderIndicator={renderIndicator}
        axis="horizontal"
        swipeable={swipeable}
        showThumbs={false}
        showArrows={showArrows}
        showStatus={false}
        emulateTouch
        infiniteLoop={infiniteLoop}
        interval={autoPlayInterval}
        showIndicators={showIndicator}
        centerMode={centerMode}
        preventMovementUntilSwipeScrollTolerance={true}
        swipeScrollTolerance={50}
        stopOnHover={false}
        onChange={onChange}
      >
        {carouselData?.map((item, index) => (
          <div key={index}>{renderItem(item, index)}</div>
        ))}
      </Carousel>
    ) : null}
  </Box>
);

export default CustomCarousel;
