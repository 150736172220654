import { Box } from '@mui/material';

import ArrowDownIcon from '@assets/icons/ArrowDownIcon.svg';

import { SelectBoxProps } from './types';
import styles from './styles';

const SelectBox = ({ label, value, onSelectBoxPress }: SelectBoxProps) => {
  return (
    <Box sx={styles.container} onClick={onSelectBoxPress}>
      <span>{`${label}:`}</span>
      <span style={styles.select}>{value}</span>
      <ArrowDownIcon />
    </Box>
  );
};

export default SelectBox;
