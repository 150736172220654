import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRouter } from 'next/router';
import useTranslation from '@Utils/useTranslation';
import { CSSProperties, memo } from 'react';
import NoReturns from '@assets/icons/NoReturns.svg';
import CashOnDelivery from '@assets/icons/CashOnDelivery.svg';
import EasyReturn from '@assets/icons/EasyReturn.svg';
import FreeShipping from '@assets/icons/FreeShipping.svg';
import { ROUTES } from '@Constants/routes';
import { colors } from '@Constants/colors';
import { PDP_PAGE_EVENTS, GLOBAL_ACTION_TYPES } from '@Constants/events';
import styles from './styles';
import { DeliveryDetailsProps } from './types';
import { isNonEmptyObject } from '@Utils/checks';
import { TrackerContext } from '_app';
import { getTrackingEventObject } from '@Utils/common';


const DeliveryDetails = ({ deliveryInfo }: DeliveryDetailsProps) => {
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const { is_visible: deliveryDetailsVisible, cod, shipping, return: returnable } = deliveryInfo ?? {};
  const isNoReturn = !returnable?.is_not_returnable ?? false;
  const router = useRouter(),
        track = useContext(TrackerContext);

  const onValuePropClick = () => {
    navigate(ROUTES.RETURN_POLICY);
    track(getTrackingEventObject(0, PDP_PAGE_EVENTS.PDP_FEED_TYPE, PDP_PAGE_EVENTS.RETURN_POLICY_CLICK, GLOBAL_ACTION_TYPES.CLICK,router.asPath));
  };

  return (
    deliveryDetailsVisible && (
      <div onClick={onValuePropClick} style={styles.deliveryDetails as CSSProperties}>
        {returnable && isNonEmptyObject(returnable) && (
          <div style={styles.deliveryDetailsContent as CSSProperties}>
            {isNoReturn ? <NoReturns color={colors.BLACK} /> : <EasyReturn />}
            <div>
              <div style={styles.text as CSSProperties}>{returnable.title ?? (isNoReturn ? translate('no_returns') : translate('easy_returns'))}</div>
              <div style={styles.descriptionText as CSSProperties}>{returnable.description ?? (isNoReturn ? translate('available') : translate('pick_up'))}</div>
            </div>
          </div>
        )}
        {shipping && isNonEmptyObject(shipping) && (
          <div style={styles.deliveryDetailsContent as CSSProperties}>
            <FreeShipping />
            <div>
              <div style={styles.text as CSSProperties}>{shipping.title ?? translate('free_shipping')}</div>
              <div style={styles.descriptionText as CSSProperties}>{shipping.description ?? translate('above_799')}</div>
            </div>
          </div>
        )}
        {cod && isNonEmptyObject(cod) && (
          <div style={styles.deliveryDetailsContent as CSSProperties}>
            <CashOnDelivery />
            <div>
              <div style={styles.text as CSSProperties}>{cod.title ?? translate('freeCOD')}</div>
              <div style={styles.descriptionText as CSSProperties}>{cod.description ?? translate('free_cod_orders')}</div>
            </div>
          </div>
        )}
      </div>
    )
  );
};

export default memo(DeliveryDetails);
