import { StringMap, Tokens } from '@Constants/common';
import LocalStorage from './storage';
import { getCookie } from 'cookies-next';
import { MAX_WIDTH } from '@Constants/common';
import { ROUTES } from '@Constants/routes';

export const isArray = (obj: any): boolean => Boolean(isObject(obj) && obj instanceof Array);

export const isEmptyArray = (obj: any): boolean => Boolean(isArray(obj) && obj.length === 0);

export const isNonEmptyArray = (arr: any) => Array.isArray(arr) && arr.length > 0;

export const isObject = (obj: any): boolean => Boolean(obj && typeof obj === 'object');

export const isNonEmptyObject = (obj: any) => obj && Object.keys(obj).length > 0;

export const isEmptyObject = (obj: any) => obj && Object.keys(obj).length === 0;

export const isNonEmptySet = (set: any) => set && set.size > 0;

export const isBoolean = (val: any): boolean => typeof val === 'boolean';

export const isNonEmptyString = (str: any) => typeof str === 'string' && str?.trim().length > 0;

export const isEmptyString = (str: any): boolean => Boolean(typeof str === 'string' && str.length === 0);

export const getNonEmptyString = (data: any): string => {
  if (isNonEmptyString(data)) return data;

  return '';
};

export const isUndefinedOrNull = (value: any) => {
  if (value == null) return true;

  return false;
};

export const isNumber = (value) => {
  return typeof value === "number" && !Number.isNaN(value);
}

export const hasEmptyFields = (object: StringMap) => Object.values(object).some((field) => isEmptyString(field));

// export const hasAuthToken = () => LocalStorage.getItem(Tokens.ACCESS_TOKEN);
export const hasAuthToken = () => getCookie(Tokens.ACCESS_TOKEN);

export const isServer = () =>  typeof window === 'undefined';

export const isMobileScreen = () =>  !isServer() && window?.innerWidth <= MAX_WIDTH;

export const sessionStorageIsSupported = () => (typeof(Storage) !== "undefined");

export const validateRedirection = (url: string, pathname: string) => {
  if (url.includes(ROUTES.SHOP_PATH))
    return !(pathname.includes(ROUTES.SHOP_PATH) || pathname.includes(ROUTES.WOMENS_COLLECTION + '/') || pathname.includes(ROUTES.COLLECTION_PATH));
  else return !url.includes(pathname);
};

export const isDesktop = () => {
  const userAgent = window.navigator.userAgent,
        platform = window.navigator.platform,
        macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms = ['iPhone', 'iPad', 'iPod'];
  
  let os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'Mac OS';
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows';
  } else if (/Android/.test(userAgent)) {
    os = 'Android';
  } else if (!os && /Linux/.test(platform)) {
    os = 'Linux';
  }

  return os !== 'Android' && os !== 'iOS';
}


 
