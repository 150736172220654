import { isNonEmptyArray } from '@Utils/checks';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState = {
  shop_config_data: {},
  appliedFilters: {},
  latest_selected_filter: '',
  filter_sheet_open: '',
  recent_temp_category_bottom_filter: '',
  size_selector_config: {},
  user_selected_sizes: null,
  select_size_modal_shown: false,
  activeQuickFilters: []
};

export const shopSlice = createSlice({
  name: 'shop',
  initialState,
  reducers: {
    setShopConfigData: (state, action: PayloadAction<any>) => {
      state.shop_config_data = action.payload;
    },
    setAppliedFilters: (state, action: PayloadAction<any>) => {
      state.appliedFilters = action.payload;
    },
    setLatestSelectedFilter: (state, action: PayloadAction<any>) => {
      state.latest_selected_filter = action.payload;
    },
    setFilterSheetOpen: (state, action: PayloadAction<any>) => {
      state.filter_sheet_open = action.payload;
    },
    setRecentTempCategoryBottomFilter: (state, action: PayloadAction<any>) => {
      state.recent_temp_category_bottom_filter = action.payload;
    },
    setSizeSelectorConfig: (state, action: PayloadAction<any>) => {
      state.size_selector_config = action.payload;
    },
    setUserSelectedSizes: (state, action: PayloadAction<any>) => {
      state.user_selected_sizes = action.payload;
    },
    addActiveQuickFilters: (state, action: PayloadAction<any>) => {
      const quickFilterArr = state.activeQuickFilters;
      state.activeQuickFilters = [...(isNonEmptyArray(quickFilterArr) ? quickFilterArr : []), action.payload]
    },
    removeActiveQuickFilters: (state, action: PayloadAction<any>) => {
      state.activeQuickFilters = (state.activeQuickFilters ?? []).filter(item => item != action.payload);
    },
    setSelectSizeModalShown: (state, action: PayloadAction<any>) => {
      state.select_size_modal_shown = action.payload;
    },
    resetShopSlice: () => initialState
  }
});

export const { setShopConfigData, setAppliedFilters, setLatestSelectedFilter, setFilterSheetOpen, setRecentTempCategoryBottomFilter, setSizeSelectorConfig, setUserSelectedSizes, setSelectSizeModalShown, addActiveQuickFilters, removeActiveQuickFilters, resetShopSlice } = shopSlice.actions;