import { colors } from '@Constants/colors';

const styles = {
  buyNowButton: {
    width: '100%',
    height: '48px',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    color: 'white',
    textTransform: 'none',
    cursor: 'pointer',
    backgroundColor: colors.PRIMARY,
    '&:hover': { backgroundColor: colors.PRIMARY }
  },
  disabledButton: {
    backgroundColor: colors.LIGHT_GRAY
  },
  selectSizeToast: {
    padding: '10px 16px',
    fontWeight: 500,
    fontSize: '12px',
    backgroundColor: 'black',
    color: 'white',
    borderRadius: '100px'
  }
};

export default styles;
