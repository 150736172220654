// import { Helmet } from 'react-helmet';
import Head from "next/head";

import {
  getMetaTitle,
  getProductDescription,
  TitleBarTexts,
} from "@Constants/common";

interface TextBarProps {
  text: string;
  isTitle?: boolean;
  productName?: string;
  pageNum?: number;
}

const TitleBarText = ({ text, isTitle, productName, pageNum }: TextBarProps) => {
  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <link rel="icon" href="/assets/icons/favicon.png?v=1" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        {productName ? 
          <meta name="description" content={getProductDescription(productName)} /> : 
          <meta name="description" content="newme | Fresh Weekly Drips! Women's Latest Clothing Styles Online" />}
      {/* <link rel="preconnect" href="https://fonts.googleapis.com" /> */}
      {/* <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" /> */}
      {/* <link rel="apple-touch-icon" href="%PUBLIC_URL%/logo192.png" /> */}
      {/* <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" /> */}
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <meta property="og:url" content="https://newme.asia/" />
        <meta name="google-site-verification" content="cbqCC-lxaNxjgw5xJSDKHaPJaxxn6bpABTSNZalNavk" />
        <title>{ isTitle ? text : (getMetaTitle(text,text) || TitleBarTexts.HOME) + (pageNum && pageNum != 1 ? ' | ' + 'Pg ' + pageNum : '')}</title>
        {/* <script async defer src="https://checkout.gokwik.co/integration.js"></script> */}
      </Head> 
    </>
  );
};

export default TitleBarText;
