import { Box, Snackbar } from '@mui/material';

import { ToastProps, ToastTypes } from './type';
import ErrorIcon from '@assets/icons/ErrorIcon.svg';
import ErrorCloseIcon from '@assets/icons/CloseError.svg';
import TickCircleIcon from '@assets/icons/TickCircle.svg';
import Close from '@assets/icons/CloseIconWhite.svg';
import CloseIcon from '@assets/icons/CloseIconLightV2.svg';
import { Positions } from './type';

import styles from './styles';

const handleAutoHideDuration = (type) => {
  switch (type) {
    case ToastTypes.CHIP:
      return 2000;
    case ToastTypes.REMOVE:
      return 2000;
    default:
      return 6000;
  }
}

export const defaultToastState = {
  message:'',
  type: undefined,
  show: false
}

const renderNotificationToast = ({ message, onClose }) => <div className='px-[16px] py-[8px] rounded-[8px] bg-nm_black flex gap-[8px] justify-between items-center text-nm_white text-[14px] font-[500] leading-[20px]'>
  {message}
  <CloseIcon onClick={onClose} style={{ cursor: 'pointer'}} />
</div>;

const Toast = ({ message, type, open, onClose, position = Positions.TOP, customStyle }: ToastProps) => {
  const toastStyle = customStyle ? customStyle : position === Positions.TOP ? { top: '40px' } : { bottom: '120px' };

  const renderErrorToast = () => (
    <Box sx={[styles.toastContent, styles.errorToastWrapper]}>
      <div style={styles.iconWrapper}>
        <ErrorIcon />
        <div style={styles.errorMessage}>{message}</div>
      </div>
      <ErrorCloseIcon onClick={onClose} style={{ cursor: 'pointer' }} />
    </Box>
  );

  const renderSuccessToast = () => (
    <Box sx={[styles.toastContent, styles.successToastWrapper]}>
      <div style={styles.iconWrapper}>
        <TickCircleIcon />
        <div style={styles.successMessage}>{message}</div>
      </div>
      <ErrorCloseIcon onClick={onClose} style={{ cursor: 'pointer' }} />
    </Box>
  );

  const renderBlackChip = () => (
    <Box sx={styles.chipContainer}>
      <div style={styles.chipText}>{message}</div>
    </Box>
  );

  const renderRemoveToast = () => (
    <div className='flex items-center justify-between p-2 bg-nm_black rounded-lg'>
      <div className='text-nm_white text-sm'>{message}</div>
      <Close onClick={onClose} style={{ cursor: 'pointer'}} />
    </div>
  )

  const renderToastBody = () => {
    switch (type) {
      case ToastTypes.ERROR:
        return renderErrorToast();
      case ToastTypes.SUCCESS:
        return renderSuccessToast();
      case ToastTypes.CHIP:
        return renderBlackChip();
      case ToastTypes.REMOVE:
        return renderRemoveToast();
      case ToastTypes.NOTIFICATION:
        return renderNotificationToast({ message: message, onClose: onClose });
      default:
        return null;
    }
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={handleAutoHideDuration(type)}
      onClose={onClose}
      anchorOrigin={{ vertical: position, horizontal: 'center' }}
      sx={toastStyle}
      ClickAwayListenerProps={{ onClickAway: () => null }}
    >
      <Box sx={styles.toastWrapper}>{renderToastBody()}</Box>
    </Snackbar>
  );
};

export default Toast;
export { Positions };
