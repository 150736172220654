import { useRouter } from "next/router";

export const convertParsedUrlQueryToObject = (query): object => {
  const jsonString = JSON.stringify(query);
  const jsonObject = JSON.parse(jsonString);
  return jsonObject;
};

export const createSearchParams = (params) => {
  const queryString = new URLSearchParams(params || {}).toString();
  return queryString;
};

export function addPrefixToKeys(state, prefix) {
  if (typeof state !== "object" || state === null || !state) {
    return state; // If state is not an object or is null, return it as is
  }

  const newState = {};
  for (const key in state) {
    if (Object.prototype.hasOwnProperty.call(state, key)) {
      const newKey = prefix + key;
      newState[newKey] = state[key];
    }
  }

  return newState;
}

export function stripTrailingQuestion(str) {
  if (str.endsWith("?")) {
    return str.slice(0, -1);
  }
  return str;
}

export function appendQueryString(url, queryString) {
  const separator = url.includes("?") ? "&" : "?";
  return url + separator + queryString;
}

export function filterKeysWithPrefix(obj, prefix) {
  const keysWithPrefix = {};
  const keysWithoutPrefix = {};
  if (obj && typeof obj === "object") {
    for (const key in obj) {
      if (key.startsWith(prefix)) {
        const newKey = key.slice(prefix.length);
        keysWithPrefix[newKey] = obj[key];
      } else {
        keysWithoutPrefix[key] = obj[key];
      }
    }
  }
  return { keysWithPrefix, keysWithoutPrefix };
}

export const useNavigate = () => {
  const router = useRouter();
  const navigate = (path, replace = false, options = {}, state = {}) => {
    state = state || {};
    if (path === "-1") {
      router.back();
      return;
    }
    const newState = addPrefixToKeys(state, "state_");
    const stateQueryString = createSearchParams(newState);
    const newUrl = stripTrailingQuestion(
      appendQueryString(path, stateQueryString)
    );
    if (replace) {
      router.replace(newUrl, path, options);
    } else {
      router.push(newUrl, path, options);
    }
  };

  return navigate;
};

export const useParams = () => {
  const router = useRouter();
  const { query } = router;
  return query || {};
};

export const useLocation = () => {
  const router = useRouter();
  const { pathname, query } = router;
  const { keysWithPrefix, keysWithoutPrefix } = filterKeysWithPrefix(
    query,
    "state_"
  );
  return {
    pathname,
    search: `?${createSearchParams(keysWithoutPrefix)}`,
    state: keysWithPrefix,
  };
};

export const Navigate = ({ to, replace = false }) => {
  const router = useRouter();
  if (replace) {
    router.replace(to);
  } else {
    router.push(to);
  }
  return null;
};

export const useSearchParams = () => {
  const router = useRouter();
  const { query } = router;
  const { keysWithPrefix, keysWithoutPrefix } = filterKeysWithPrefix(
    query,
    "state_"
  );
  // Return the query parameters object
  return keysWithoutPrefix;
};
