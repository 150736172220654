import { colors } from '@Constants/common';

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    margin: '16px',
    marginTop: '0'
  },
  imageContainer: {
    width: '32%',
    border: `1px solid ${colors.LIGHT_GRAY}`,
    borderRadius: '10px 0 0 10px',
    borderRight: 'none',
    overflow: 'hidden',
    position: 'relative',
    cursor: 'pointer'
  },
  outOfStockOverlay: {
    position: 'absolute',
    backgroundColor: colors.GREYED_OUT,
    opacity: 0.35,
    height: '100%',
    width: '100%',
    top: 0,
    left: 0,
    zIndex: 100
  },
  outOfStock: {
    position: 'absolute',
    backgroundColor: colors.LIGHT_RED,
    padding: '6px 24px',
    color: colors.RED,
    width: '100%',
    bottom: 0,
    left: 0,
    boxSizing: 'border-box',
    textAlign: 'center',
    fontSize: '12px',
    zIndex: 100
  },
  details: {
    width: '68%',
    padding: '16px 12px',
    borderRadius: '0 10px 10px 0',
    border: `1px solid ${colors.LIGHT_GRAY}`,
    borderLeft: 'none',
    background: colors.WHITE
  },
  title: {
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '16px',
    color: colors.DIM_GRAY
  },
  selectContainer: {
    display: 'flex',
    marginTop: '14px'
  },
  quantitySelectContainer: {
    marginLeft: '8px'
  },
  priceContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '14px'
  },
  discountPrice: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    color: colors.FONT_SECONDARY
  },
  price: {
    fontSize: '12px',
    lineHeight: '16px',
    marginLeft: '8px',
    color: colors.DIM_GRAY
  },
  discountPercent: {
    fontSize: '12px',
    lineHeight: '16px',
    marginLeft: '8px',
    color: colors.GREEN
  },
  divider: {
    height: '1px',
    marginTop: '16px',
    backgroundColor: colors.LIGHT_GRAY
  },
  fastDelivery: {
    fontSize: '10px',
    fontWeight: 400,
    display: 'flex',
    marginTop: '9px',
    color: colors.DARK_GRAY,
    gap: '4px'
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'end'
  },
  removeButton: {
    color: colors.PRIMARY_DARK,
    fontWeight: 500,
    fontSize: '12px',
    marginTop: '9px',
    cursor: 'pointer'
  },
  returnContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  returnText: {
    color: colors.DIM_GRAY,
    fontSize: '10px',
    lineHeight: '12px'
  },
  sizeQtyWrapper: {
    display: 'flex',
    alignItem: 'center',
    marginTop: '8px'
  },
  delivery: {
    fontWeight: 400,
    fontSize: '12px',
    color: colors.DIM_GRAY,
    lineHeight: '20px'
  }
};

export default styles;
