import { memo, useCallback, useContext } from 'react';
import { useRouter } from 'next/router';
import { TrackerContext } from '_app';
import { getTrackingEventObject } from '@Utils/common';
import { PDP_PAGE_EVENTS } from '@Constants/events';
import { setImgIndex, setRatingsData, setImgClickSrc } from '../RatingsV2/ratingReviewSheetSlice';
import { useAppDispatch } from '@Store/store';

const ImageGallery = ({reviewss, imgCount}) => {

    const track = useContext(TrackerContext),
        router = useRouter(),
        dispatch = useAppDispatch();

    const reviewsWithPictures = reviewss?.filter(review => review.review_photos.length > 0);

    const filteredReview = reviewsWithPictures.flatMap(review => 
        review.review_photos.map(eachPhoto => ({
          author: review.review_author,
          content: review.review_content,
          photo: eachPhoto,
          id : review.product_id,
          rating: review.rating,
          date: review.review_date,
          size: review.size
        }))
      );

    const handleImageClick = ((index, photoIndex, review, imageUrlArray) => {
        const imageObjectIndex = filteredReview.findIndex( img => img.photo == imageUrlArray[photoIndex])

        dispatch(setImgIndex(imageObjectIndex))
        dispatch(setRatingsData(filteredReview))
        dispatch(setImgClickSrc('gallery'))
        track(getTrackingEventObject(0, PDP_PAGE_EVENTS.PDP_FEED_TYPE, PDP_PAGE_EVENTS.IMG_GALLERY_CLICK, reviewss[0].parent_product_id, router.asPath , { meta_data: { index: index} }));
    })

    let count = 0;

    return (
        <>
            <div className='flex mx-[17px] pt-[16px]'> 
            {reviewsWithPictures.map((review, index) => {
                let images: JSX.Element[] = [];
                if (review.review_photos.length > 0) {
                    review.review_photos.forEach((reviewPhoto, photoIndex) => {
                        if (count < 4) {
                            count++;
                            images.push(
                                <div className={`${count === 4 ? 'bg-nm_black w-[76px] h-[100px] rounded object-cover object-bottom ' : ''} relative `}>
                                    <div className={`${ count === 4 && 'flex flex-col justify-center items-center'}`}>
                                    <img onClick={() => handleImageClick(index, photoIndex, review, review.review_photos)} className={`w-[76px] h-[100px] rounded object-cover object-bottom mr-[4px] cursor-pointer ${count === 4 ? 'opacity-75 ml-[4px]' : ''} `}  src={reviewPhoto} alt="review-img" key={photoIndex} />
                                    {count === 4 && imgCount-3 > 0 && <div className='absolute text-nm_white text-[14px] font-bold '>+{imgCount-3}</div>}
                                    </div>
                                </div>
                            );
                        }
                    });
                }
                return images;
            })}
            </div>
        </>
    )
}

export default memo(ImageGallery);
