import Script from 'next/script';

interface SchemaProps {
  breadcrumbs: Array<Record<string, string>>;
}

const Schema = ({ breadcrumbs }: SchemaProps) => {
  const json = {
    '@context': 'https://schema.org/',
    '@type': 'BreadcrumbList',
    itemListElement: breadcrumbs?.map((crumb, index) => {
      return {
        '@type': 'ListItem',
        position: crumb?.level ?? index + 1,
        item: {
          '@id': crumb?.url,
          name: crumb?.name
        }
      };
    })
  };

  return (
    <>
      {/* <script type="application/ld+json">{JSON.stringify(json)}</script> */}
      <Script dangerouslySetInnerHTML={{__html: JSON.stringify(json)}} type="application/ld+json"></Script>
    </>
  );
};

export default Schema;
