import { memo } from "react";

const ProgressBar = ({mainBg, progressBg, progress }) => {

    return (
    <div className={`w-full bg-${mainBg} rounded-full h-[4px]`}>
      <div className={`${progressBg} h-[4px] rounded-full`}
        style={{ width: `${progress ? progress : 2}%` }}
      >
      </div>
    </div>

    )
}

export default memo(ProgressBar);
