import { CSSProperties, useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRouter } from 'next/router';
import { AppBar, Toolbar, Box, IconButton } from '@mui/material';
import { isEmptyObject } from '@Utils/checks';

import { ROUTES } from '@Constants/routes';
import SearchIcon from '@assets/icons/Search.svg';
import BagIconV2 from '@assets/icons/BagIconV2.svg';
import SearchIconV2 from '@assets/icons/SearchIconV2.svg';
import NewmeLogo from '@assets/icons/NewMeLogo.svg';
import BogoSaleAppBarLogo from '@assets/icons/BogoSaleAppBarLogo.svg';
import BogoSaleNewmeLogo from '@assets/icons/BogoSaleNewmeLogo.svg';
import CartIcon from '@assets/icons/Cart.svg';
import WalletIcon from '@assets/icons/Wallet.svg';
import LeftArrow from '@assets/icons/LeftArrow.svg';
import BackArrowIconV2 from '@assets/icons/BackArrowIconV2.svg';

import { isEmptyString } from '@Utils/checks';
import { colors } from '@Constants/colors';
import { useAppDispatch, useAppSelector, RootState } from '@Store/store';
import { clearSearch } from '@Containers/Search/searchKeySlice';
import { getTrackingEventObject, useLoggedIn } from '@Utils/common';

import styles from './styles';
import styleModule from './style.module.css';
import { TrackerContext } from '_app';
import { APP_HEADER_NAV_EVENTS, GLOBAL_ACTION_TYPES, HEADER_EVENTS } from '@Constants/events';

interface AppBarProps {
  showBackButton?: boolean;
  hideSearch?: boolean;
  hideCart?: boolean;
  hideWallet?: boolean;
  hasShadow?: boolean;
  hasBorder?: boolean;
  logoTouchDisabled?: boolean;
  title?: string;
  children?: React.ReactNode;
  backButtonPress?: () => void;
  count?: number;
  bogoSaleTimeLeft?: string;
  bogoMetaData?: Object;
  isWebAppConfigLoading?: boolean;
  v2?: boolean;
}

const getTimerTitle = (titleType: string) => {
  switch (titleType) {
    case 'start':
      return 'SALE STARTS IN';
    case 'end':
      return 'SALE ENDS IN';
    default:
      return '';
  }
};

const getProductsCount = ({ count }) => {
  if(count >= 5000) {
    count = count / 1000;

    return Math.trunc(count) + 'k+';
  }

  return count;
}

const renderTimer = (time: string, width: number, charLength: number, isLoading?: boolean, separator?: boolean) => {
  return (
    <>
      <div className={'flex mx-[0.5px] ' + ''} style={{ width: width * charLength }}>
        {time.split('').map((item) => (
          <div className={' text-center '} style={{ minWidth: width }}>
            {item}
          </div>
        ))}
      </div>
      {separator && <span style={{ width: width / 2 }}>{!isLoading && ':'}</span>}
    </>
  );
};

const Header = ({
  showBackButton = false,
  hideSearch = false,
  hideCart = false,
  hideWallet = false,
  logoTouchDisabled = false,
  title = '',
  children = null,
  hasShadow = false,
  hasBorder = false,
  backButtonPress,
  count = 0,
  bogoSaleTimeLeft,
  bogoMetaData,
  isWebAppConfigLoading,
  v2,
}: AppBarProps) => {
  const track = useContext(TrackerContext);
  const navigate = useNavigate();
  const { asPath, query } = useRouter();
  const dispatch = useAppDispatch();
  const { isUserLoggedIn } = useLoggedIn();
  const guestCartCount = useAppSelector((state) => state.guestCart.cart_data.cart_item_count);
  const loggedInCartCount = useAppSelector((state) => state.cart.cart_count);
  const { app_config_data: webAppDefaultConfig } = useAppSelector((state: RootState) => state.layout);

  const cartCount = isUserLoggedIn ? loggedInCartCount : guestCartCount;
  const [showAppBar, setShowAppBar] = useState(false);
  const { hours, minutes, seconds } = bogoSaleTimeLeft ?? {},
    { text } = bogoMetaData ?? {},
    enableBogoTimer = !(
      (isEmptyObject(bogoMetaData) || !hours || !minutes || !seconds || (hours === '00' && minutes === '00' && seconds === '00')) &&
      !isWebAppConfigLoading
    );

  const getTimerCharacter = useCallback(
    (type: string) => {
      if (isWebAppConfigLoading) return '';

      switch (type) {
        case 'hours':
          return hours;
        case 'mins':
          return minutes;
        case 'secs':
          return seconds;
        default:
          return '';
      }
    },
    [hours, minutes, seconds, isWebAppConfigLoading]
  );

  const handleBackClick = () => {
    track(getTrackingEventObject(-1, APP_HEADER_NAV_EVENTS.BACK_BTN_CLICK, GLOBAL_ACTION_TYPES.CLICK, -1, asPath));

    if (query['utm_source']) {
      navigate(ROUTES.HOME);
      return;
    }

    if (backButtonPress) backButtonPress();
    else navigate('-1');
  };

  const handleLogoClick = () => {
    // MIGHT NEED TO ENABLE IT LATER
    if (logoTouchDisabled) return;
    else navigate(ROUTES.HOME);
  };

  const handleSearchNavigation = () => {
    track(getTrackingEventObject(-1, HEADER_EVENTS.HEADER_ID, GLOBAL_ACTION_TYPES.CLICK, HEADER_EVENTS.SEARCH_ICON_ID, asPath));
    dispatch(clearSearch());
    navigate(ROUTES.SEARCH);
  };

  useEffect(() => {
    setShowAppBar(true);
  }, []);

  if (!showAppBar) {
    return;
  }

  return (
    <Box sx={[styles.appbarWrapper, hasShadow ? { boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.06)' } : {}, hasBorder ? v2 ? { borderBottom: '1px solid #EFEFEF'} : { borderBottom: '1px solid #DDD'} : {}]}>
      <AppBar position="static" sx={styles.appbar} elevation={0}>
        <Toolbar sx={styles.toolbar}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: enableBogoTimer ? '8px' : '16px',
              alignItems: 'center'
            }}
          >
            {showBackButton && (v2 ? <BackArrowIconV2 style={{ width: '24px', height: '24px', cursor: 'pointer' }} onClick={() => handleBackClick()}/> : <LeftArrow style={{ width: '24px', height: '24px', cursor: 'pointer' }} onClick={() => handleBackClick()} />)}
            {isEmptyString(title) ? (
              webAppDefaultConfig?.is_bogo_live_for_user ? (
                <div className="relative cursor-pointer" onClick={handleLogoClick}>
                  <BogoSaleAppBarLogo className={'absolute flex items-center justify-center ' + styleModule['bogoLogo_animate']} />
                  <BogoSaleNewmeLogo className={'flex items-center justify-center ' + styleModule['bogoLogo_animate']} />
                </div>
              ) : (
                <NewmeLogo onClick={handleLogoClick} style={{ width: '34px', cursor: 'pointer' }} />
              )
            ) : (
              <div style={styles.appBarTitle as CSSProperties}>
                <span style={styles.titleText} className={v2 ? 'capitalize' : ''}>{title}</span>
                {count > 0 && (v2 ? <div className='text-[12px] font-[500] leading-[16px] text-nm_light_gray_11 mt-[2px]'>{getProductsCount({ count: count })} items</div> : <div style={styles.countText}>{count} results</div>)}
              </div>
            )}
            {enableBogoTimer && (
              <div className="text-left">
                <div className="text-[12px] font-[700] leading-[16px] text-nm_flamingo">{getTimerTitle(text)}</div>
                <div className="flex justify-start items-center text-[14px] leading-[120%] font-[800] tracking-[10%] text-nm_black w-full mt-[1.5px]">
                  {renderTimer(getTimerCharacter('hours'), 10, getTimerCharacter('hours').split('').length, isWebAppConfigLoading, true)}
                  {renderTimer(getTimerCharacter('mins'), 10, 2, isWebAppConfigLoading, true)}
                  {renderTimer(getTimerCharacter('secs'), 10, 2, isWebAppConfigLoading, false)}
                </div>
              </div>
            )}
          </Box>
          <Box sx={{...styles.actionWrapper, ...(v2 ? {gap: '24px', marginLeft: '5px' } : {})}}>
            {v2 ? 
            <>
              {!hideCart && (
              <button
                className='customButton customButton--noScale customButton--transparent relative p-0'
                onClick={() => {
                  navigate(ROUTES.CART);
                }}
              >
                <BagIconV2 /> 
                {!!cartCount && <div className='w-full flex justify-center absolute top-[8px]'><div className='bg-nm_white w-[14px] text-[10px] font-[600] text-nm_black leading-[12px] '>{cartCount > 99 ? 'C+' : cartCount}</div></div>}
              </button>
            )}
              {!hideSearch && (
              <button
                className='customButton customButton--noScale customButton--transparent p-0'
                onClick={() => {
                  handleSearchNavigation();
                }}
              >
               <SearchIconV2 />
              </button>
            )}
            </> : 
            <>
            {!hideSearch && (
              <IconButton
                onClick={() => {
                  handleSearchNavigation();
                }}
                disableRipple
              >
               <SearchIcon />
              </IconButton>
            )}
            {!hideCart && (
              <button
                className='customButton customButton--transparent customButton--noScale active:opacity-100'
                style={styles.iconButton as CSSProperties}
                onClick={() => {
                  navigate(ROUTES.CART);
                }}
              >
                <CartIcon color={colors.BLACK} />
                {!!cartCount && <Box sx={styles.cartCount}>{cartCount}</Box>}
              </button>
            )}
            {!hideWallet && (
              <IconButton
                sx={styles.iconButton}
                disableRipple
                onClick={() => {
                  navigate(ROUTES.WALLET);
                }}
              >
                <WalletIcon style={{ width: '25px' }} />
              </IconButton>
            )}
            </>}
            {children}
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Header;
