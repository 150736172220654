// import CurrencyIcon from '@assets/icons/CurrencyIcon.svg';
import { AmountType, Currency } from './types';
import { useCallback } from 'react';

const Amount: React.FC<AmountType> = ({
  currency = Currency.INR,
  amount,
  style = {},
  toFixed = false,
  currencyStyle,
  oldPrice = false,
  strokeWidth = 1,
  symbol
}) => {
  const updatedAmount = toFixed ? Number(amount)?.toFixed(2) : amount;

  const getCurrency = useCallback(() => {
    if (!amount) return;
    <div style={style}>{'₹0'}</div>;

    switch (currency) {
      case Currency.INR:
        return `${updatedAmount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
      // TODO: Add currencies as required

      default:
        return null;
    }
  }, [amount, updatedAmount, currency, style]);

  return (
    <div className="flex flex-row items-center relative w-fit z-0" style={style}>
      {symbol && <div>{symbol}</div>}
      {oldPrice && <hr style={{height: '-0.9px'}} className="w-full bg-nm_dim_gray absolute" />}
      {/* <CurrencyIcon
        style={{
          ...(currencyStyle ?? { height: '10px', width: '10px' }),
          ...{ overflow: 'visible' }
        }}
        strokeWidth={strokeWidth}
      /> */}
      <div>{'₹' + (getCurrency() || '0')}</div>
    </div>
  );
};

export default Amount;
