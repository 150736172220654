import { colors } from '@Constants/common';

const styles = {
  wrapper: {
    backgroundColor: colors.WHITE
  },
  orderDetails: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px'
  },
  subHeading: {
    fontSize: '12px',
    lineHeight: '16px',
    color: colors.DARK_GRAY
  },
  orderDetailsItemContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start'
  },
  bagTotalPrice: {
    color: '#000000',
    fontWeight: 500
  },
  orderDetailsItemText: {
    fontSize: '12px',
    lineHeight: '16px',
    color: colors.DIM_GRAY
  },
  deliveryFeePriceContainer: {
    display: 'flex'
  },
  discountPriceStyle: {
    color: colors.GREEN,
    fontWeight: 500
  },
  applyCoupon: {
    color: colors.PRIMARY_DARK,
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    cursor: 'pointer'
  },
  deliveryFee: {
    fontWeight: 500
  },
  dashedLine: {
    borderTop: `1px dashed ${colors.SILVER_GRAY}`
  },
  amountPayable: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '20px',
    color: colors.FONT_SECONDARY
  },
  partnerTextContainer: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'pre'
  },
  partnerPrice: {
    color: colors.FONT_SECONDARY,
    fontWeight: 600
  },
  partnerLogo: {
    marginLeft: '4px'
  },
  loginText: {
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '20px',
    color: colors.PRIMARY_DARK,
    cursor: 'pointer'
  },
  walletBenefitsText: {
    fontSize: '14px',
    lineHeight: '20px'
  },
  currencyStyle: { width: '9px', height: '9px' },
  codCurrencyStyles: { height: '9.5px', width: '9.5px' },
  amountPayableCurrencyStyle: { height: '10.5px', width: '10.5px' },
  disclaimer: {
    fontSize: '10px',
    color: colors.RED
  }
};

export default styles;
