import { BASE64_ENCODED_PDP_FALLBACK_IMG } from '@Constants/base64EncodedImages/pdpFallbackImg';
import React, { useEffect, useState } from 'react';

const encodedProductDetailsFallbackImg = `data:image/png;base64,${BASE64_ENCODED_PDP_FALLBACK_IMG}`;

const ProgressiveImage = ({
  src,
  placeholder = encodedProductDetailsFallbackImg,
  style,
  item,
  fetchPriority,
  loading,
  srcSet,
  sizes,
  setImgLoaded = () => {},
  handleImgLoadFailure = () => {},
  alt = 'image',
  ...props
}) => {
  const [imgSrc, setImgSrc] = useState(placeholder);
  const [imgFailedToLoad, setImgFailedToLoad] = useState(false);

  const handleImageLoadFailure = () => {
    setImgFailedToLoad(true);
    setImgSrc(placeholder);
    handleImgLoadFailure?.();
  };

  useEffect(() => {
    const img = new Image();

    if (item?.type == 'image' || src) {
      img.src = item?.url ?? src;
      img.loading = loading;
      img.onload = () => {
        setImgSrc(item?.url ?? src);
        setImgLoaded?.();
      };
    }
  });

  return (
    <img
      loading={loading ?? 'lazy'}
      src={imgSrc}
      alt={alt}
      style={style}
      srcSet={imgFailedToLoad ? null : srcSet}
      sizes={sizes}
      {...props}
      onError={handleImageLoadFailure}
    />
  );
};

export default ProgressiveImage;
