import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
    bottom_sheet_product_Details : {},
    selectedProductOutOfStock: false,
}

export const productDetailsSlice = createSlice({
    name : 'ProductDetails',
    initialState,
    reducers: {
        setBottomSheetProductDetails: (state, action: PayloadAction<any>) => {
            state.bottom_sheet_product_Details = action.payload;
        },
        setSelectedProductInStock: (state) => {
            state.selectedProductOutOfStock = false;
        },
        setSelectedProductOutOfStock: (state) => {
            state.selectedProductOutOfStock = true;
        },
        resetProductDetailsSlice: () => initialState
    }
});

export const {setBottomSheetProductDetails, setSelectedProductInStock, setSelectedProductOutOfStock, resetProductDetailsSlice} = productDetailsSlice.actions;
export default productDetailsSlice.reducer;