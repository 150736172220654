import BannerImage from './BannerImage';
import BuyNowSection from './BuyNowSection';
import DeliveryDetails from './DeliveryDetails';
import ProductDetailsCarousel from './ProductDetailsCarousel';
import ProductHeroImg from './ProductHeroImg';
import ProductInfo from './ProductInfo';
import ProductInfoWrapper from './ProductInfoWrapper';
import ProductPriceInfo from './ProductPriceInfo';
import Ratings from './Ratings';
import ReferAndEarn from './ReferAndEarn';
import SimilarProducts from './SimilarProduct';
import TickerModule from './TickerModule';

export {
  BannerImage,
  BuyNowSection,
  DeliveryDetails,
  ProductDetailsCarousel,
  ProductHeroImg,
  ProductInfo,
  ProductInfoWrapper,
  ProductPriceInfo,
  Ratings,
  ReferAndEarn,
  SimilarProducts,
  TickerModule,
};
