import { colors } from '@Constants/colors';

export const styles = {
  root: {
    '& .MuiPaper-root': {
      margin: 0,
      borderRadius: '12px'
    }
  },
  dialogTitle: {
    fontSize: '16px',
    display: 'flex',
    alignItems: 'top',
    justifyContent: 'space-between',
    width: '328px',
    boxSizing: 'border-box',
    paddingBottom: 0,
    lineHeight: '24px',
    fontWeight: 500,
    paddingRight: '16px',
    position: 'relative',
    overflow: 'hidden'
  },
  dialogAction: { padding: '16px 16px 32px 16px' },
  yesBtn: {
    height: 48,
    fontWeight: 600,
    textTransform: 'none',
    width: '130px',
    borderRadius: '8px',
    cursor: 'pointer',
    borderColor: colors.PRIMARY_DARK,
    color: colors.PRIMARY_DARK,
    '&:hover': { backgroundColor: colors.WHITE, borderColor: colors.PRIMARY_DARK }
  },
  noBtn: {
    height: 48,
    fontWeight: 600,
    textTransform: 'none',
    width: '156px',
    borderRadius: '8px',
    color: colors.WHITE,
    boxShadow: 'none',
    cursor: 'pointer',
    '&:hover': { backgroundColor: colors.PRIMARY, boxShadow: 'none' }
  }
};

export default styles;
