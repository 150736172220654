import { memo, useContext } from 'react';
import { useRouter } from 'next/router';
import RatingStar from '@assets/icons/RatingStar.svg';
import { CONTAINER_IDS } from '../../constants';
import { scrollToContainer } from '@Utils/common';
import { RootState, useAppSelector } from '@Store/store';
import { TrackerContext } from '_app';
import { RECOMMENDED_PRODUCT_EVENTS, PDP_PAGE_EVENTS, GLOBAL_ACTION_TYPES } from '@Constants/events';
import { getTrackingEventObject } from '@Utils/common';

const RatingModule = ({id, rating, ratingCount, showNewReviewUi }) => {
  const router = useRouter();
  const track = useContext(TrackerContext);

  const { bottom_sheet_open: configKey } = useAppSelector((state: RootState) => state.recommedationSheet);

  const handleRatingsClick = () => {
    showNewReviewUi ? scrollToContainer(`#${CONTAINER_IDS.RATING_FRAME}`) : scrollToContainer(`#${CONTAINER_IDS.RATING_CONTAINER}`)
    
    if(configKey === 'initial')
      track(getTrackingEventObject(0, RECOMMENDED_PRODUCT_EVENTS.FEED_TYPE, RECOMMENDED_PRODUCT_EVENTS.RATINGS_CLICK, id,router.asPath));
    else
      track(getTrackingEventObject(0, PDP_PAGE_EVENTS.PDP_FEED_TYPE, PDP_PAGE_EVENTS.RATINGS, id, router.asPath));
  }

  return (
    rating &&
    parseFloat(rating) >= 3 && (
      <button
        className='mr-[17px] rounded-[23px] bg-nm_white text-nm_black font-extrabold text-[14px] flex justify-center items-center px-[8px] py-[4px] opacity-[0.80] customButton customButton--noScale'
        id={CONTAINER_IDS.RATING_BTN}
        onClick={handleRatingsClick}
      >
        <RatingStar className='h-[18px] w-[18px] mr-[4px] fill-nm_orange' fill='#90E1BA' />
        {rating}
        {ratingCount && (
          <>
            <p className='my-0 font-normal text-nm_light_gray_11 mx-1'>|</p>
            <p className='my-0 font-normal text-nm_light_gray_11'>{ratingCount}</p>
          </>
        )}

      </button>
    )
  );
};

export default memo(RatingModule);
