export const colors = {
  PRIMARY: '#39B277',
  PRIMARY_DARK: '#1D6B46',
  PRIMARY_LIGHT: '#90E1BA',
  PRIMARY_LIGHT_TRANSPARENT: '#90E1BA33',
  PRIMARY_LIGHT_2: '#EAFFF5',
  PRIMARY_LIGHTER: '#F5FFFA',
  SECONDARY: '#FFFF',
  FONT_SECONDARY: '#1F1F1F',
  SECONDARY_FONT_V2: '#6A6A6A',
  CAROUSEL_INDICATOR: '#EAEAEA',
  WHITE: '#FFFFFF',
  WHITE_SMOKE: '#F7F7F7',
  DIM_GRAY: '#707070',
  DARK_GRAY: '#ABABAB',
  FONT_PRIMARY: '#880746',
  LIGHT_RED: '#F6BDC0',
  LIGHT_RED_TRANSPARENT: '#F6BDC033',
  RED: '#DA1E28',
  GREEN_CYAN: '#14664B',
  LIGHT_GRAY: '#F2F2F2',
  LIGHT_SILVER: '#D6D6D6',
  SILVER: '#C4C4C4',
  LIGHT_PINK: '#FDD8EA',
  BORDER_GRAY: '#C4C4C4',
  BORDER_GRAY_2: '#D6D6D6',
  DARK_GREEN: '#14664B',
  LIGHT_GREEN: '#AAEED7',
  BLACK: '#000000',
  DARK_BLUE: '#292D32',
  ORANGE: '#EB9E24',
  GREY: '#E6E6E6',
  GREYED_OUT: '#D9D9D9',
  SNOW: '#FFF8FB',
  ERROR: '#D32F2F',
  PRIMARY_TEXT: '#3A5744',
  PRIMARY_GRAY: '#A0D2C9',
  LIGHT_GREEN_2: '#DFFFF0',
  LIGHT_GREEN_3: '#E4F1EC',
  LIGHT_RED_2: '#F1E4E4',
  DARK_GREEN_2: '#31B375',
  LIGHT_GRAY_2: '#F3F3F3',
  PILL_COLOR: '#F2F3F6',
};
