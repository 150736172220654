import { colors } from '@Constants/colors';

const styles = {
  sizeCard: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: '8px',
    borderRadius: '4px',
    boxShadow: '1px 1px 0px rgba(162, 162, 162, 0.25)',
    backgroundColor: colors.WHITE_SMOKE,
    padding: '4px',
    gap: '2px',
    fontSize: '10px'
  },
  sizeCardCross: {
    color: colors.DARK_GRAY,
    height: '1px',
    backgroundColor: colors.DARK_GRAY,
    position: 'absolute'
  }
};

export default styles;
