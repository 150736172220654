import { CSSProperties, useCallback, useContext, useEffect } from 'react';
import { useRouter } from 'next/router';
import { TrackerContext } from '_app';
import useTranslation from '@Utils/useTranslation';

import BottomSheet from '@Components/BottomSheet';
import QuantitySelector from '@Components/QuantitySelector';
import { ExpectedDelivery } from '@Components/index';

import SizeSelector from '../SizeSelector';
import { SizeSelectorProps } from './type';
import styles from './styles';
import ProductColorVariation from '@Containers/Product-Details/components/ProductColorVariation';
import { isNonEmptyArray } from '@Utils/checks';
import { getTrackingEventObject } from '@Utils/common';
import { SIZE_COLOR_SELECTOR_BOTTOM_SHEET } from '@Constants/events';
import { RootState, useAppSelector, useAppDispatch} from '@Store/store';
import { setPdpToast } from '@Containers/Cart/cartSlice';
import { setRecommendationSheet } from '@Containers/RecommendationSheet/recommendationSheetSlice';
import {addToBuyNowCart} from '@Containers/RecommendationSheet/recomendationUtlis'

export const SIZE_SELECTOR_BOTTOM_SHEET_ID = 'size-selector-sheet';

const SizeSelectorBottomSheet = ({
  id,
  open,
  setOpen,
  variations,
  colorVariants,
  selectedSize,
  setSelectedSize,
  showQuantitySelector,
  selectedQuantity,
  setSelectedQuantity,
  handlePrimaryButtonClick,
  isOutOfStock,
  disableDefaultSizeSelect = false,
  showExpectedDelivery,
  fastDelivery,
  dateRange,
  secondaryButton,
  handleSecondaryButtonClick = () => {},
  disableModal = false,
  product,
  v2,
  isBottomSheet
}: SizeSelectorProps) => {
  const { t: translate } = useTranslation(),
    track = useContext(TrackerContext),
    router = useRouter(),
    dispatch = useAppDispatch();
  
  const { bottom_sheet_open: configKey } = useAppSelector((state: RootState) => state.recommedationSheet);
  const { buy_now_cart_items } = useAppSelector((state: RootState) => state.recommededProducts)

  const getVariation = useCallback(() => product?.variations?.filter((variation) => variation.size === selectedSize), [product?.variations, selectedSize]);

  const handleButtonClick = () => {
    if(router.pathname === '/checkout'){
      let variationID = getVariation()?.[0].id

      addToBuyNowCart(product, selectedSize, buy_now_cart_items, dispatch, variationID)
      
        dispatch(setRecommendationSheet(''))
        dispatch(setPdpToast(true))
    }
    else handlePrimaryButtonClick()
  }

  const handleClose = useCallback(() => {
    setOpen('');
  }, []);

  useEffect(() => {
    if (open)
      track(
        getTrackingEventObject(0, SIZE_COLOR_SELECTOR_BOTTOM_SHEET.FEED_TYPE, SIZE_COLOR_SELECTOR_BOTTOM_SHEET.MODAL_OPEN_ACTION_TYPE, id, router.pathname)
      );
    else
      track(
        getTrackingEventObject(0, SIZE_COLOR_SELECTOR_BOTTOM_SHEET.FEED_TYPE, SIZE_COLOR_SELECTOR_BOTTOM_SHEET.MODAL_CLOSE_ACTION_TYPE, id, router.pathname)
      );
    }, [open]);

  const renderSizeSelector = () => (
    <div id={SIZE_SELECTOR_BOTTOM_SHEET_ID} >

      {isNonEmptyArray(colorVariants) && configKey != 'sizeSelector' && <ProductColorVariation variations={colorVariants} product_id={id} isSizeSelectorSheet />}
      <SizeSelector
        variations={variations ?? []}
        selectedSize={selectedSize}
        setSelectedSize={setSelectedSize}
        disableDefaultSizeSelect={disableDefaultSizeSelect}
        secondaryButton={secondaryButton}
        isSizeSelectorSheet
        handleSecondaryButtonClick={handleSecondaryButtonClick}
        product={product}
        isBottomSheet={isBottomSheet}
      />
      {showExpectedDelivery && <ExpectedDelivery fastDelivery={fastDelivery ?? false} dateRange={dateRange ?? ''} variations={variations ?? []} selectedSize={selectedSize} />}
      {showQuantitySelector && selectedQuantity && setSelectedQuantity && (
        <QuantitySelector isOutOfStock={isOutOfStock} quantity={selectedQuantity} setQuantity={setSelectedQuantity} />
      )}
      <div style={{ padding: '12px 16px' }}>
        <button
          className="customButton customButton--noScale"
          disabled={isOutOfStock}
          style={{ ...styles.buyNowButton, ...(isOutOfStock ? styles.disabledButton : {}) } as CSSProperties}
          onClick={handleButtonClick}
        >
          {translate(open)}
        </button>
      </div>
    </div>
  );

  return (
    <>
      {disableModal ? (
        renderSizeSelector()
      ) : v2 ? (
        <CustomDialog open={open !== ''} onClose={handleClose} setOpen={handleClose} isBottomSheet showCloseButton>
          <div className="w-[100vw] max-w-[450px] box-border rounded-t-[16px] bg-nm_white">{renderSizeSelector()}</div>
        </CustomDialog>
      ) : (
        <BottomSheet
          open={open !== ''}
          handleClose={handleClose}
          bottomSheetStyle={{ paddingTop: 1, paddingBottom: '24px', position: 'relative' }}
          showCloseButton
        >
          {renderSizeSelector()}
        </BottomSheet>
      )}
    </>
  );
};

export default SizeSelectorBottomSheet;
