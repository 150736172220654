import { useRouter } from 'next/router';
import React, { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import ProductColorVariation from '../ProductColorVariation';
import SizeGuideSheet from '../SizeGuideSheet';
import ProductModal from '../ProductModal';
import Toast from '@Components/Toast';
import SizeSelector from '@Components/SizeSelector';
import SizeSelectorBottomSheet, { SIZE_SELECTOR_BOTTOM_SHEET_ID } from '@Components/SizeSelectorBottomSheet';
import { ProductInfo, ProductPriceInfo, Ratings, DeliveryDetails } from '../../components';
import styles from '../../styles';
import { useUpdateCartMutation } from '@Containers/Cart/apiSlice';
import { addProduct } from '@Containers/GuestCart/guestCartSlice';
import { useAddToWishlistMutation, useRemoveFromWishlistMutation } from '@Containers/Wishlist/apiSlice';
import { addItemToWishlist, removeItemToWishlist, selectWishListState } from '@Containers/Wishlist/wishlistSlice';
import { Positions, ToastTypes } from '@Components/Toast/type';
import { ShowToastType } from '@Components/AddressCard/types';
import useLoginNavigation from '@Hooks/useLoginNavigation';
import { getPriceRange, navigatorVibrate, getTrackingEventObject } from '@Utils/common';
import useCartUtils from '@Utils/useCartUtils';
import { isNonEmptyObject, isNonEmptyArray } from '@Utils/checks';
import useTranslation from '@Utils/useTranslation';
import { useParams } from '@Utils/reactRouterUtils';
import GTM, { addToGtmProductList, getProductAttributes } from '@Utils/gtm';
import { RootState, useAppDispatch, useAppSelector } from '@Store/store';
import { PrimaryButtons, STOCK_STATUS } from '@Constants/common';
import { ROUTES } from '@Constants/routes';
import { ANALYTIC_EVENTS, ECOMM_EVENTS, ECOMM_EVENTS_GA4, RECOMMENDED_PRODUCT_EVENTS, GLOBAL_ACTION_TYPES, PDP_PAGE_EVENTS } from '@Constants/events';
import { ExpectedDelivery } from '@Components/index';
import { VIBRATION_PATTERNS } from '@Constants/vibrationPatterns';
import ArrowRightIcon from '@assets/icons/ArrowRight.svg';
import ArrowRightGreen from '@assets/icons/ArrowRightGreen.svg';
import { setRecommendationSheet } from '@Containers/RecommendationSheet/recommendationSheetSlice';
import { setPdpToast } from '@Containers/Cart/cartSlice';
import { TrackerContext } from '_app';
import RatingsFrame from '../RatingsFrame';
import ProductExpSection from '../ProductExpSection';
import RatingsV2 from '../RatingsV2';
import PdpFooter from '../PdpFooter';
import { PdpContext } from '@Containers/Product-Details';
import ImageCarousel from '../ImageCarousel';

const defaultToastState = {
  message: '',
  type: undefined,
  show: false
};

const SizeGuideButton = () => (
  <div className="flex items-center leading-[20px] text-[14px] font-medium mr-[10px]">
    Size Guide <ArrowRightIcon style={{ marginLeft: 5 }} />
  </div>
);

const BottomSheetSizeGuideButton = () => (
  <div className="flex items-center text-nm_dark_mystique_green leading-[20px] underline decoration-nm_dark_mystique_green text-[14px] font-medium mr-[10px]">
    Size Guide <ArrowRightGreen style={{ marginLeft: 5 }} />
  </div>
)

const isInStock = (variations, selectedSize) =>
  isNonEmptyArray(variations) &&
  variations.length > 0 &&
  variations.some((variation) => variation?.size === selectedSize && variation?.stock_status === STOCK_STATUS.inStock);

const ProductInfoWrapper = ({ product, productDetails, appConfigData, isBottomSheet }) => {
  const [selectedSize, setSelectedSize] = useState(''),
    [sizeGuideOpen, setSizeGuideOpen] = useState(false),
    sizeRemarks = product?.size_remarks,
    colorVariants = product?.color_variants,
    selectedSizeIsFastDeliverable =
      !!product?.variations &&
      product?.variations.length > 0 &&
      product?.variations.some((variation) => variation.size === selectedSize && variation.fast_delivery === true),
    expectedDeliveryRange = selectedSizeIsFastDeliverable ? product?.fast_delivery_text ?? '3-5 days' : product?.normal_delivery_text ?? '12-15 days',
    disableDefaultSizeSelect = product?.disable_size_autoselect ?? false,
    { app_config_data: webAppDefaultConfig } = useAppSelector((state: RootState) => state.layout),
    { remove_buy_now: rmBuyNow } = webAppDefaultConfig ?? {};

  const { bottom_sheet_open: configKey } = useAppSelector((state: RootState) => state.recommedationSheet);
  const showPdpToast = useAppSelector((state) => state.cart.show_pdp_add_to_bag_toast);

  const { productIds } = useAppSelector(selectWishListState);
  const { productName } = useParams();
  const { t: translate } = useTranslation();
  const dispatch = useAppDispatch();
  const router = useRouter(),
        track = useContext(TrackerContext),
        { showNewReviewUi } = useContext(PdpContext) ?? {};
  const { checkIfAlreadyInCart } = useCartUtils();
  const [addCartItems, { data: addCartResponse, error: cartError }] = useUpdateCartMutation();
  const [addToWishlist, addWishlistData] = useAddToWishlistMutation();
  const [removeFromWishlist, removeWishlistData] = useRemoveFromWishlistMutation();
  const [openSizeSelectorSheet, setOpenSizeSelectorSheet] = useState('');
  const [showToast, setShowToast] = useState<ShowToastType>(defaultToastState);
  const [sizesInCart, setSizesInCart] = useState<string[]>([]);
  const [wishList, setWishList] = useState(productIds);
  const [backPressed, setBackPressed] = useState(true);
  const { navigateAfterLogin } = useLoginNavigation();
  const { user } = useAppSelector((state: RootState) => state.profile);
  const id = product?.product_id ?? 0;
  const { getUpdatedQuantity } = useCartUtils();

  const imgCount = product?.review_v2?.all_images?.length || 0

  useEffect(()=>{
    if(isBottomSheet && configKey === 'sizeSelector')
      setOpenSizeSelectorSheet('add_to_bag');
  }, [configKey]);

  const getVariation = useCallback(() => product?.variations?.filter((variation) => variation.size === selectedSize), [product?.variations, selectedSize]),
        selectedVariant = useMemo(() => getVariation(), [product?.variations, selectedSize]);

  const handleSizeGuideOpen = useCallback(() => {
    setSizeGuideOpen(true);
    setBackPressed(false);
    router.push(router.asPath + '#sizeChartOpen');
    if(configKey === 'initial' || configKey === 'sizeSelector')
      track(getTrackingEventObject(0, RECOMMENDED_PRODUCT_EVENTS.FEED_TYPE, RECOMMENDED_PRODUCT_EVENTS.SIZE_GUIDE, id, router.asPath));
    else
      track(getTrackingEventObject(0, PDP_PAGE_EVENTS.PDP_FEED_TYPE, PDP_PAGE_EVENTS.SIZE_GUIDE_CLICK, id, router.asPath));

  }, [router, router.asPath]);
  useEffect(() => {
    if (isNonEmptyObject(productDetails)) {
      GTM.sendEcommEvent(ECOMM_EVENTS.PRODUCT_DETAIL, {
        detail: {
          products: gtmGetProductDetails()
        }
      });
      GTM.sendEcommEvent(ECOMM_EVENTS_GA4.PRODUCT_DETAIL, {
        items: [gtmGetProductDetails()]
      });
    }
  }, [productDetails]);

  const gtmGetProductDetails = () => {
    const attributes = getProductAttributes(product, appConfigData?.data?.ga_attribute_map, selectedSize);
    const priceDetails = getPriceRange(product?.variations ?? []);
    const pr: {
      name?: string;
      id?: string;
      price?: string;
      category?: string;
      quantity: number;
      variant?: string;
      item_id?: string;
      item_category?: string;
      item_name?: string;
    } = {
      name: product?.name,
      id: product?.parent_style_id || product?.style_id,
      price:
        priceDetails.minSalePrice === priceDetails.maxSalePrice
          ? String(priceDetails?.minSalePrice)
          : `${priceDetails.minSalePrice}-${priceDetails.maxSalePrice}`,
      category: product?.category,
      quantity: 1,
      item_id: product?.parent_style_id || product?.style_id,
      item_name: product?.name,
      item_category: product?.category
    };

    if (selectedSize !== '') {
      pr.variant = selectedSize;
      pr.price = String(getVariation()?.[0]?.sale_price);
    }

    return { ...pr, ...attributes };
  };
  useEffect(() => {
    if (addCartResponse && configKey === '' ) {
      setShowToast({
        message: translate('added_to_bag'),
        type: ToastTypes.CHIP,
        show: true
      });

      setOpenSizeSelectorSheet('');
    }
    if (addCartResponse && (configKey === 'initial' || configKey === 'sizeSelector') ) {
        dispatch(setPdpToast(true))
        dispatch(setRecommendationSheet(''))
    }
    if (cartError)
      setShowToast({
        message: translate('app_error_title'),
        type: ToastTypes.CHIP,
        show: true
      });
  }, [addCartResponse, cartError]);

  useEffect(() => {
    if (addWishlistData?.data)
      setShowToast({
        message: translate('added_to_wishlist'),
        type: ToastTypes.CHIP,
        show: true
      });
  }, [addWishlistData]);

  useEffect(() => {
    if (removeWishlistData?.data)
      setShowToast({
        message: translate('removed_from_wishlist'),
        type: ToastTypes.CHIP,
        show: true
      });
  }, [removeWishlistData]);

  useEffect(() => {
    if (disableDefaultSizeSelect && !(openSizeSelectorSheet && isInStock(product?.variations ?? [], selectedSize))) setSelectedSize('');

    setSizesInCart([]);
  }, [productName]);

  useEffect(() => {
    router.beforePopState(({ as }) => {
      if (as !== router.asPath) {
        setBackPressed(true);
      }
      return true;
    });

    return () => router.beforePopState(() => true);
  }, [router]);

  const handlePrimaryButtonClick = () => {
    if (selectedSize === '') {
      navigatorVibrate(VIBRATION_PATTERNS.ERROR);

      const sizeSelectorBottomSheet = document.getElementById(SIZE_SELECTOR_BOTTOM_SHEET_ID);
      sizeSelectorBottomSheet?.animate([{ transform: 'rotate(0.7deg)' }], { duration: 80, iterations: 2 });

      setShowToast({
        message: translate('select_size_toast'),
        type: ToastTypes.CHIP,
        show: true
      });
    } else if (openSizeSelectorSheet === PrimaryButtons.BUY_NOW) rmBuyNow === 2 ? handleAddToCart(ROUTES.CART) : handleBuyNow();
    else if (openSizeSelectorSheet === PrimaryButtons.ADD_TO_BAG) handleAddToCart();
  };

  const handleWishListClick = (type) => {
    if (productDetails) addToGtmProductList(productDetails[0]);
    if (user?.id) {
      if (wishList?.includes(id)) {
        setWishList(wishList.filter((item: number) => item !== id));
        removeFromWishlist([id]);
        dispatch(removeItemToWishlist(id));
      } else {
        setWishList([...wishList, id]);
        addToWishlist([id]);
        dispatch(addItemToWishlist(id));
      }
    } else if (wishList?.includes(id)) {
      setWishList(wishList.filter((item: number) => item !== id));
      setShowToast({
        message: translate('removed_from_wishlist'),
        type: ToastTypes.CHIP,
        show: true
      });
      dispatch(removeItemToWishlist(id));
    } else {
      setWishList([...wishList, id]);
      setShowToast({
        message: translate('added_to_wishlist'),
        type: ToastTypes.CHIP,
        show: true
      });
      dispatch(addItemToWishlist(id));
    }

    if(type === 'bottom-sheet'){
      track(getTrackingEventObject(0, RECOMMENDED_PRODUCT_EVENTS.FEED_TYPE, RECOMMENDED_PRODUCT_EVENTS.BOTTOM_SHEET_WISHLIST_CLICK, id, router.asPath));
    }
    else if(type === 'pdp'){
      track(getTrackingEventObject(0, PDP_PAGE_EVENTS.PDP_FEED_TYPE, PDP_PAGE_EVENTS.WISHLIST_CLICK, id, router.asPath));
    }
  };

  // GTM events
  const handleAddEvent = (event: string) => {
    const details = {
      user_id: user?.id || -1,
      amount: getVariation()?.[0]?.sale_price,
      product_name: product?.name,
      product_id: product?.parent_id || product?.product_id,
      quantity: getUpdatedQuantity(`${id}${getVariation()?.[0].id}`, 1),
      variation_id: getVariation()?.[0]?.parent_variation_id || getVariation()?.[0]?.id
    };

    GTM.sendEvent(event, {
      ...details
    });
  };

  const handleBuyNow = () => {
    // GTM events
    handleAddEvent(ANALYTIC_EVENTS.BUY_NOW);
    if (productDetails) addToGtmProductList(productDetails[0]);

    navigateAfterLogin(`${ROUTES.CHECKOUT}?id=${id}&variation-id=${getVariation()?.[0]?.id}`);
  };

  const handleAddToCart = async (redirectTo?:string) => {
    if (!user?.id) {
      navigateAfterLogin(`/product/${productName}`);
      return;
    }
    // GTM utils
    if (productDetails) addToGtmProductList(productDetails[0]);

    setSizesInCart([...sizesInCart, selectedSize]);
    if (!user?.id) {
      dispatch(
        addProduct({
          data: {
            product_id: id,
            variation_id: getVariation()?.[0].id as number,
            variation: { attribute_pa_size: selectedSize },
            quantity: 1,
            ...(product?.parent_id ? { parent_id: product?.parent_id } : {}),
            is_returnable: product?.is_returnable ?? false
          },
          index: checkIfAlreadyInCart(id, Number(getVariation()?.[0].id))
        })
      );
      setOpenSizeSelectorSheet('');
    } else {
      const quantity = getUpdatedQuantity(`${id}${getVariation()?.[0].id}`, 1);

      if (quantity === -1) {
        setShowToast({
          message: translate('max_cart_limit'),
          type: ToastTypes.CHIP,
          show: true
        });

        return;
      }

      if(redirectTo) {
        const res = await addCartItems([
          {
            product_id: id,
            variation_id: getVariation()?.[0].id,
            variation: { attribute_pa_size: selectedSize },
            quantity: quantity
          }
        ]);
  
        if(res?.data?.cart_data)
          router.push(redirectTo ?? ROUTES.CART);
      }
      else
        addCartItems([
          {
            product_id: id,
            variation_id: getVariation()?.[0].id,
            variation: { attribute_pa_size: selectedSize },
            quantity: quantity
          }
        ]);

    }
    // GTM events
    handleAddEvent(ANALYTIC_EVENTS.ADD_TO_CART);
    GTM.sendEcommEvent(ECOMM_EVENTS.ADD_TO_CART, {
      add: {
        products: [gtmGetProductDetails()]
      }
    });
    GTM.sendEcommEvent(ECOMM_EVENTS_GA4.ADD_TO_CART, {
      items: [gtmGetProductDetails()]
    });
  };

  return (
    <>
    {((isBottomSheet && configKey === 'initial') || !isBottomSheet) && <>
      <ProductPriceInfo
        selectedVariant={selectedVariant}
        variations={product?.variations ?? []}
        selectedSize={selectedSize}
        title={product?.name ?? ''}
        onWishListPress={() => handleWishListClick('pdp')}
        isInWishList={wishList && wishList?.includes(id)}
        showSku={productDetails?.[0]?.show_sku ?? false}
        styleId={productDetails?.[0]?.style_id ?? ''}
        productId={productDetails?.[0]?.product_id ?? 0}
        tag={product?.display_tag}
        isBottomSheet = {isBottomSheet}
      />
      <hr style={styles.divider} />
      {colorVariants && <ProductColorVariation variations={colorVariants} product_id={id} isBottomSheet = {isBottomSheet} />}
      <SizeSelector
        variations={product?.variations ?? []}
        selectedSize={selectedSize}
        setSelectedSize={setSelectedSize}
        sizeRemarks={sizeRemarks}
        productId={id}
        isBottomSheet={isBottomSheet}
        disableDefaultSizeSelect={disableDefaultSizeSelect}
      />
      {selectedSize !== '' && (
        <ExpectedDelivery
          fastDelivery={selectedSizeIsFastDeliverable}
          dateRange={expectedDeliveryRange}
          variations={product?.variations ?? []}
          selectedSize={selectedSize}
        />
      )}
      <SizeGuideSheet
        sizeGuideOpen={sizeGuideOpen}
        setSizeGuideOpen={setSizeGuideOpen}
        handleSizeGuideOpen={handleSizeGuideOpen}
        product={product}
        backPressed={backPressed}
        setBackPressed={setBackPressed}
        isBottomSheet = {isBottomSheet}
      />
      <ProductModal product={product} />
      {/* {!appConfigData?.data?.is_bogo_live_for_user && ( */}
     
      <hr style={styles.divider} />
      {product?.reviews && product?.reviews?.length > 0 && (
        <>
          {showNewReviewUi ? <div className={`${isBottomSheet ? 'text-left' : ''}`}>
            {!isBottomSheet && <RatingsFrame reviewCount={product?.reviews?.length} rating = {product?.rating} ratingCount={product?.rating_count}/>}
            {!isBottomSheet && product?.features && <ProductExpSection features={product?.features} id={id}/>}
            <RatingsV2 reviewV2 = {product?.review_v2} reviews={product?.reviews ?? []} backPressed={backPressed} setBackPressed={setBackPressed} />
            <hr style={styles.divider} />
          </div>
        :
        <div className={`${isBottomSheet ? 'text-left' : ''}`}>
          <Ratings reviewV2 = {product?.review_v2} reviews={product?.reviews ?? []} backPressed={backPressed} setBackPressed={setBackPressed} />
          <hr style={styles.divider} />
        </div>}
        </>
      )}
       {!isBottomSheet && (
        <>
          <hr style={styles.divider} />
          <DeliveryDetails deliveryInfo={product?.delivery_info} />
        </>
      )}
      {/* <Hoarding /> */}
      <hr style={styles.divider} />
      <ProductInfo
        productInfo={product?.product_info ?? {}}
        marketerInfo={{
          ...(product?.marketer_info && { marketerAddress: product.marketer_info }),
          ...(product?.country_of_origin && { countryOfOrigin: product.country_of_origin })
        }}
      />
      <PdpFooter
        selectedVariant={selectedVariant}
        product={product}
        id={id}
        setOpenSizeSelectorSheet={setOpenSizeSelectorSheet}
        selectedSize={selectedSize}
        handleBuyNow={handleBuyNow}
        handleAddToCart={handleAddToCart}
        sizesInCart={sizesInCart}
        isBottomSheet={isBottomSheet}
        wishList={wishList}
        handleWishListClick={handleWishListClick}
      />
      </>}
      {((isBottomSheet && configKey === 'sizeSelector') || !isBottomSheet) && <SizeSelectorBottomSheet
        open={openSizeSelectorSheet}
        disableModal={configKey === 'sizeSelector'}
        setOpen={setOpenSizeSelectorSheet}
        variations={product?.variations ?? []}
        colorVariants={colorVariants}
        selectedSize={selectedSize}
        setSelectedSize={setSelectedSize}
        id={id}
        secondaryButton={isBottomSheet ? <BottomSheetSizeGuideButton/> :<SizeGuideButton />}
        handlePrimaryButtonClick={handlePrimaryButtonClick}
        handleSecondaryButtonClick={handleSizeGuideOpen}
        disableDefaultSizeSelect={disableDefaultSizeSelect}
        showExpectedDelivery={selectedSize !== ''}
        fastDelivery={selectedSizeIsFastDeliverable}
        dateRange={expectedDeliveryRange}
        product={product}
        isBottomSheet={isBottomSheet}
      />
      }
      <Toast
        message={showToast.message}
        open={showToast.show}
        type={showToast.type}
        customStyle={openSizeSelectorSheet ? { bottom: '40%' } : undefined}
        position={Positions.BOTTOM}
        onClose={() => setShowToast(defaultToastState)}
      />
      
      <ImageCarousel/>

    </>
  );
};

export default memo(ProductInfoWrapper);