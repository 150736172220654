import React, { FC, forwardRef, memo, Ref, useCallback, useContext } from 'react';
import { useAppDispatch, useAppSelector } from '@Store/store';
import { closeAppDownloadBanner } from '@Layouts/layoutSlice';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { TrackerContext } from '_app';
import { getTrackingEventObject } from '@Utils/common';
import { DOWNLOAD_EVENTS, GLOBAL_ACTION_TYPES } from '@Constants/events';
import CloseIconLight from '@assets/icons/CloseIconLightV2.svg';
import BannerText from './components/BannerText';
import { APP_DOWNLOAD_LINK } from '@Constants/common';
import { generate_deep_link } from '@Utils/deeplinkgenerator';

export const handleAppDownloadClick = (track, pathname, position = 'header') => {
  track(getTrackingEventObject(-1, DOWNLOAD_EVENTS.FEED_TYPE, GLOBAL_ACTION_TYPES.CLICK, position, pathname));
};

interface AppBannerProps {
  onClose?: () => void; // Add a prop for the callback function
  ref?: Ref<any>;
  customStyles?: Object;
  showCloseIcon?: boolean;
}

const AppBanner: FC<AppBannerProps> = forwardRef(({ onClose, customStyles = {}, showCloseIcon = false}, ref) => {
  // Destructure the onClose prop
  const dispatch = useAppDispatch(),
        { showAppDownloadBanner } = useAppSelector(state => state.layout),
    track = useContext(TrackerContext),
    router = useRouter(),
    getPopupDeepLinkPath = useCallback(() => process.env.NEXT_PUBLIC_REACT_APP_WEB_URL + router.asPath, [router.asPath]),
    handleCloseBanner = useCallback(() => {
      dispatch(closeAppDownloadBanner());
      onClose?.(); // Call the onClose callback function
    }, []);

  return (
    <>
      {showAppDownloadBanner && (
        <div
          ref={ref}
          className="fixed top-0 z-[110] w-full max-w-[450px] flex justify-between items-center gap-[10px] text-nm_white bg-nm_white text-[14px] p-[12px] mb-[0px] text-center box-border border-solid border-0 border-b-[1px] border-nm_light_gray_7"
          style={customStyles}
        >
            <BannerText />
            <div>
            <Link href={generate_deep_link(getPopupDeepLinkPath()) ?? APP_DOWNLOAD_LINK} target="_blank">
              <button
                onClick={() => handleAppDownloadClick(track, router.pathname)}
                className="customButton customButton--noScale rounded-[8px] border-none bg-nm_black text-nm_white text-[14px] font-[600] leading-[20px] px-[12px] py-[6px]"
                style={{ textDecoration: 'none' }}
              >
                Install
              </button>
            </Link>
            {showCloseIcon && <CloseIconLight style={{ cursor: 'pointer', marginLeft: '8px', filter: 'invert(100%)', transform: 'scale(1.25)' }} onClick={handleCloseBanner} />}
            </div>
        </div>
      )}
    </>
  );
});

export default memo(AppBanner);
