import { Box } from '@mui/material';
import useTranslation from '@Utils/useTranslation';

import Amount from '@Components/Amount';
import useLoginNavigation from '@Hooks/useLoginNavigation';
import SimplLogo from '@assets/icons/SimplLogo.svg';

import styles from './styles';
import { OrderDetailsProps } from './types';

const OrderDetails = ({
  total = 0,
  bagTotal,
  bagDiscount = 0,
  couponDiscount = 0,
  // deliveryFee = 0,
  customFeeLines,
  walletMoney,
  walletMessage,
  prepaidDiscount = 0,
  // codCharge,
  // shippingDiscount = 0,
  isGuest,
  bogoSaleDiscount = 0,
  ignoreCouponIfBogoLive,
  couponName,
  onApplyCouponClick
}: OrderDetailsProps) => {
  const { t: translate } = useTranslation();

  const { navigateAfterLogin } = useLoginNavigation();

  const showPaymentPartnerText = false;

  const navigateToLogin = () => navigateAfterLogin(location.pathname);

  return (
    <Box p={2} pb={4} sx={styles.wrapper}>
      {isGuest && (
        <Box mb={2}>
          <Box sx={{ display: 'inline-block', ...styles.loginText, cursor: 'pointer' }} onClick={navigateToLogin}>
            {translate('guest_cart_login.1')}
          </Box>
          <span style={styles.walletBenefitsText}>{translate('guest_cart_login.2')}</span>
        </Box>
      )}
      <Box sx={styles.orderDetails}>{translate('orderDetails')}</Box>
      <Box sx={styles.subHeading} mt={0.5}>
        {translate('inclusiveOfAllTaxes')}
      </Box>

      <Box sx={styles.orderDetailsItemText}>
        <Box mt={2} sx={styles.orderDetailsItemContainer}>
          <Box>{translate('bagSubtotal')}</Box>
          <Amount style={styles.bagTotalPrice} amount={bagTotal} />
        </Box>

        {!!bagDiscount && (
          <Box mt={1.5} sx={styles.orderDetailsItemContainer}>
            <Box>{translate('bagDiscount')}</Box>
            <Amount
              style={styles.discountPriceStyle}
              amount={bagDiscount}
              symbol='-'
              currencyStyle={styles.currencyStyle}
            />
          </Box>
        )}

        {bogoSaleDiscount > 0 && (
          <Box mt={1.5} sx={styles.orderDetailsItemContainer}>
            <Box>{translate('bogo_discount')}</Box>
            <Amount style={styles.discountPriceStyle} amount={bogoSaleDiscount} symbol='-' />
          </Box>
        )}

        {(bogoSaleDiscount <= 0 || !ignoreCouponIfBogoLive) && (
          <Box mt={1.5} sx={styles.orderDetailsItemContainer}>
            <Box>{translate('couponDiscount')}</Box>
            {couponDiscount ? (
              <Amount style={styles.discountPriceStyle} amount={couponDiscount} symbol='-' />
            ) : (
              <Box sx={styles.applyCoupon} onClick={onApplyCouponClick} role='presentation'>
                {!isGuest ? translate('apply_coupon') : translate('login_apply_coupon')}
              </Box>
            )}
          </Box>
        )}

        {(bogoSaleDiscount <= 0 || !ignoreCouponIfBogoLive) && !!couponDiscount && !!couponName && (
          <Box mt={1.5} sx={styles.orderDetailsItemContainer}>
            <Box>{translate('coupon_name')}</Box>
            <Box style={styles.discountPriceStyle}>{couponName}</Box>
          </Box>
        )}

        {!isGuest && (
          <Box mt={1.5} sx={styles.orderDetailsItemContainer}>
            <Box>{translate('walletMoney')}</Box>
            <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
              <Amount
                style={styles.discountPriceStyle}
                amount={walletMoney || 0}
                symbol='-'
                currencyStyle={styles.currencyStyle}
              />
              {!!walletMoney && !!walletMessage && <Box sx={{ fontSize: '10px' }}> ({walletMessage})</Box>}
            </Box>
          </Box>
        )}

        {!isGuest && !!prepaidDiscount && (
          <Box mt={1.5} sx={styles.orderDetailsItemContainer}>
            <Box>{translate('Prepaid Discount')}</Box>
            <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
              <Amount
                style={styles.discountPriceStyle}
                amount={prepaidDiscount || 0}
                symbol='-'
                currencyStyle={styles.currencyStyle}
              />
            </Box>
          </Box>
        )}
        

        {/* {!!codCharge && (
          <Box mt={1.5} sx={styles.orderDetailsItemContainer}>
            <Box>
              <Box>{translate('cod_charges')}</Box>
              <Box sx={styles.disclaimer}>({translate('non_refundable')})</Box>
            </Box>
            <Amount style={styles.bagTotalPrice} amount={codCharge} currencyStyle={styles.codCurrencyStyles} />
          </Box>
        )}

        {!codCharge && (
          <Box mt={1.5} sx={styles.orderDetailsItemContainer}>
            <Box>{translate('deliveryFee')}</Box>
            <Box sx={styles.deliveryFeePriceContainer}>
              <Box sx={styles.discountPriceStyle}>{deliveryFee || translate('free')}</Box>
              {(!!shippingDiscount || !!Number(deliveryFee)) && (
                <Box sx={styles.deliveryFee} ml={0.5}>
                  <Amount amount={shippingDiscount + Number(deliveryFee)} style={styles.deliveryFee} oldPrice />
                </Box>
              )}
            </Box>
          </Box>
        )} */}

        {/* Custom Fee Lines */}
        {customFeeLines && (
          <Box>
            {customFeeLines?.map((customFeeLine, index) => {
              return (
                <Box key={index} mt={1.5} sx={styles.orderDetailsItemContainer}>
                  <Box>
                    {customFeeLine.fee_title}
                    {['True', true].includes(customFeeLine.fee_show_disclaimer) && (
                      <Box sx={styles.disclaimer}>{`(${customFeeLine.fee_disclaimer})`}</Box>
                    )}
                  </Box>
                  <Box sx={styles.deliveryFee} ml={0.5}>
                    <Amount amount={Number(customFeeLine.fee_total)} style={styles.deliveryFee} />
                  </Box>
                </Box>
              );
            })}
          </Box>
        )}

        <Box mt={2} sx={styles.dashedLine} />

        <Box mt={2} sx={styles.amountPayable}>
          <Box>{translate('amount_payable')}</Box>
          <Amount amount={total} style={styles.amountPayable} currencyStyle={styles.amountPayableCurrencyStyle} />
        </Box>

        {showPaymentPartnerText && (
          <Box sx={{ ...styles.orderDetailsItemText, ...styles.partnerTextContainer }} mt={1.5}>
            <span>{translate('installments')}</span>
            <Amount amount={1960} style={styles.partnerPrice} strokeWidth={2} />
            <span> {translate('with')}</span>
            <SimplLogo style={styles.partnerLogo} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default OrderDetails;
