import { colors } from '@Constants/common';

const styles = {
  container: {
    padding: '16px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  link: {
    color: colors.FONT_SECONDARY,
    fontWeight: 400,
    textDecoration: 'none',
    display: 'inline',
    fontSize: '12px',
    lineHeight: '18px',
    cursor: 'pointer'
  },
  linkSeparator: {
    color: colors.FONT_SECONDARY,
    display: 'inline',
    margin: 'auto 2px',
    fontSize: '12px'
  },
  selectedLink: {
    fontWeight: 600
  }
};

export default styles;
