import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { useRouter } from 'next/navigation';
import { API_DOMAIN } from '@Constants/config';
import { Backdrop } from '@mui/material';
import styles from './styles'
import YellowInfoCircle from '@assets/icons/yellowInfoCircle.svg';
import useTranslation from '@Utils/useTranslation';
import { nextFetch } from '@Services/api';

interface ModalProps {
    isOpen: boolean;
    onClose: (
        isSuccess: Boolean,
        data: any,
    ) => void;
    phone: string;
}

const isMobileDevice = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};

const SuspiciousLoginModal = ({ isOpen, onClose, phone }: ModalProps) => {
    const [countdown, setCountdown] = useState(179);
    const router = useRouter();
    const { t: translate } = useTranslation();
    useEffect(() => {
        let interval: NodeJS.Timeout;
        if (isOpen) {
            interval = setInterval(async () => {
                setCountdown((prevCountdown) => prevCountdown - 1);
                if (countdown % 10 === 0) {
                    const data =   await nextFetch.post(null, `allow_login`, { "mobile_number":phone, }, null, {
                            'Content-Type': 'application/json',
                            'timestamp': Date.now().toString(),
                            'caller': 'web_app'
                    });
                    if(data?.msg === 'WAIT'){
                        // do nothing
                    }
                    else if(data?.msg === 'NO'){
                        onClose(false, null)
                    }
                    else if(data?.access_token){
                        onClose(true, data)
                    }                    
                }
                if(countdown === 0){
                    clearInterval(interval);
                    onClose(false, null);
                    router.refresh()
                }
            }, 1000);
        }
        return () => clearInterval(interval);
    }, [isOpen, countdown]);

    const handleClose = () => {
        // onClose();
        // setCountdown(180);
    };

    const handleWhatsAppClick = () => {
        const baseURL = 'https://api.whatsapp.com';
        const phoneNumber = '+918904448606'
        let link = ''

        if (isMobileDevice()) link = `${baseURL}/send?phone=${phoneNumber}`;
        else link = `${baseURL}/send/?phone=${phoneNumber}&type=phone_number&app_absent=0`;

        window.open(link, '_blank');
    }

    const minutes = Math.floor(countdown / 60);
    const seconds = countdown % 60;

    return (
        <>
             <Modal
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={styles.modalContainer}
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                      sx: { left: 'unset', right: 'unset', width: '450px' },
                      timeout: 500
                    }
                  }}
            >
                <Box sx={styles.modal}>
                    <div style={styles.titleContainer}>
                        <YellowInfoCircle strokeWidth={0.4} stroke={'#ffab36'}/>
                        <h3 style={styles.title}>{translate('verify_session_title')}</h3>
                    </div>
                    <div style={styles.content}>{translate('verify_session_desc')}</div>
                    <div style={styles.content}>{translate('verify_session_desc_2')}</div>
                    <button style={styles.modalButton} onClick={handleWhatsAppClick}>{translate('open_whatsapp')}</button>
                    <div style={styles.timer}>{`${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`}</div>
                </Box>
            </Modal>
        </>
    );
};

export default SuspiciousLoginModal;