import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState = {
    order_delay_sheet_open: true,
    order_delay_text: {},
    is_new_pincode: false,
};

export const orderDelaySlice = createSlice({
    name: 'orderDelaySheet',
    initialState,
    reducers: {
        setOrderDelaySheetOpen: (state, action: PayloadAction<boolean>) => {
            state.order_delay_sheet_open = action.payload;
        },
        setOrderDelayText: (state, action: PayloadAction<string>) => {
            state.order_delay_text = action.payload;
        },
        setNewPincode: (state, action: PayloadAction<string>) => {
            state.is_new_pincode = action.payload;
        },
        resetOrderDelaySlice: () => initialState
    }
});

export const { setOrderDelaySheetOpen, resetOrderDelaySlice, setOrderDelayText, setNewPincode } = orderDelaySlice.actions;
export default orderDelaySlice.reducer;