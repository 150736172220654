import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { deliveryPolicyData, homeData } from './types';

const initialState: homeData = {
  deliveryPolicy: {
    deliveryInfo: {},
  }
};

export const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    setDeliveryInfo: (state, action: PayloadAction<deliveryPolicyData>) => {
      state.deliveryPolicy = action.payload;
    },
    resetHomeSlice: () => initialState
  }
});

export const { setDeliveryInfo, resetHomeSlice } = homeSlice.actions;
export default homeSlice.reducer;
