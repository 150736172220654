import { useNavigate } from 'react-router-dom';

import { ROUTES } from '@Constants/routes';
import { setPrevPath, setShowLoginBottomSheet } from '@Containers/Login/loginSlice';
import { setSignupSheetOpen } from '@Containers/SignupBottomSheet/signupBottomSheetSlice';
import { useAppDispatch } from '@Store/store';
import { useLoggedIn } from '@Utils/common';

/**
 * This hook is used to navigate to protected routes after login from bottom sheet
 */
const useLoginNavigation = () => {
  const dispatch = useAppDispatch();
  const { isUserLoggedIn } = useLoggedIn();
  const navigate = useNavigate();

  /**
   *
   * @param route - route to navigate to after login from bottomsheet
   */
  const navigateAfterLogin = (route: ROUTES | string, v2: boolean | null) => {
    if (isUserLoggedIn) {
      navigate(route);
    } else {
      dispatch(setPrevPath(route));
      
      if(v2)
        dispatch(setSignupSheetOpen('initial'));
      else 
        dispatch(setShowLoginBottomSheet(true));
    }
  };

  return {
    navigateAfterLogin
  };
};

export default useLoginNavigation;
