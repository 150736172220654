import { useEffect, useState, useContext } from 'react';
import { useRouter } from 'next/router';
import { Box, Button, FilledInput, FormControl, InputAdornment, InputLabel } from '@mui/material';
import useTranslation from '@Utils/useTranslation';
import OtpInput from 'react-otp-input';
import { LoadingButton } from '@mui/lab';

import BottomSheet from '@Components/BottomSheet';
import CountryCodeIcon from '@assets/icons/CountryCode.svg';
import InputClearIcon from '@assets/icons/InputClear.svg';
import LeftArrow from '@assets/icons/LeftArrow.svg';
import { EMPTY_STRING, OTP_LENGTH } from '@Constants/common';
import { PHONE_NUMBER_INPUT_REGEX } from '@Constants/regex';
import useLogin from '@Hooks/useLogin';
import Toast from '@Components/Toast';
import { ToastTypes } from '@Components/Toast/type';
import Loader from '@Components/Loader';
import { formatSecondsToDisplayTime, validatePhoneNumber, getTrackingEventObject, getTrackEventMetadata } from '@Utils/common';

import { LoginBottomSheetProps } from './types';
import styles from './styles';
import Link from 'next/link';
import SuspiciousLoginModal from '@Components/SuspiciousLoginModal';
import { parsePhoneNumber } from '@Utils/format';
import { componentConfig } from '@Constants/componentConfigs';
import { isNonEmptyObject } from '@Utils/checks';
import { TrackerContext } from '_app';
import { GLOBAL_ACTION_TYPES, LOGIN_BOTTOM_SHEET_EVENTS } from '@Constants/events';

const LoginBottomSheet = ({ open, onClose, imageUrl, redirectUrl = '', disableModal = false, source = {}, isVersion2, setOtpSession = () => {} }: LoginBottomSheetProps) => {
  const track = useContext(TrackerContext);
  const { asPath } = useRouter();
  // states
  const [phone, setPhone] = useState<number | string>('');
  const [otp, setOtp] = useState<string | undefined>();
  const [hideBanner, setHideBanner] = useState(false);

  // hooks
  const { t: translate } = useTranslation();
  const {
    requestOtp,
    isLoading,
    showResendBtn,
    resendTime,
    OTPSession,
    showError,
    setShowError,
    verifyLoading,
    resetLoginStates,
    isSuspicious,
    setIsSuspicious,
    setLoginCreds
  } = useLogin(otp, source);

  useEffect(() => {
    track(getTrackingEventObject(-1, LOGIN_BOTTOM_SHEET_EVENTS.FEED_TYPE, GLOBAL_ACTION_TYPES.VIEW, OTPSession ? LOGIN_BOTTOM_SHEET_EVENTS.OTP_SCREEN : LOGIN_BOTTOM_SHEET_EVENTS.PHONE_NUM_SCREEN, asPath, { meta: {...getTrackEventMetadata(), ...(isNonEmptyObject(source) && source)}}));
    setOtpSession?.(OTPSession);
  }, [OTPSession]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.value === EMPTY_STRING || PHONE_NUMBER_INPUT_REGEX.test(e.target.value)) setPhone(parsePhoneNumber(e.target.value));
  };

  const onKeyDown = (e: React.KeyboardEvent) => {
    if (e?.key === 'Enter' && validatePhoneNumber(phone)) requestOtp({ mobile_number: phone, ...(isNonEmptyObject(source) ? source : {})});
  };

  const renderLoginPage = () => (
    <Box>
      <Box mt={2}>
        {isVersion2 ? <div className='text-nm_font_secondary text-left mt-[2px] mb-[4px]'>
          <div className='text-[20px] font-[600] leading-[28px]'>{'What’s your mobile number?'}</div>
          <div className='text-[14px] font-[400] leading-[20px] mt-[8px]'>{'A verification code will be sent to this number.'}</div>
        </div>:<><span style={{ fontWeight: '500' }}>{translate('guest_cart_login.1')}</span>
        <span>{` ${translate('or')} `}</span>
        <span style={{ fontWeight: '500' }}>{translate('signup')}</span></>}
      </Box>
      <Box sx={{ position: 'relative' }}>
        <Box sx={styles.countryIconWrapper}>
          <CountryCodeIcon />
        </Box>
        <FormControl sx={{ width: '100%' }} variant='filled'>
          <InputLabel htmlFor='login-phone-number' sx={styles.inputLabel}>
            {translate('your_ten_digit_phone_number')}
          </InputLabel>
          <FilledInput
            value={phone}
            inputProps={componentConfig.phoneNumberInput.defaultProps}
            onFocus={(event) => setHideBanner(true)}
            onBlur={(event) => setHideBanner(false)}
            onChange={handleChange}
            onKeyDown={onKeyDown}
            id='login-phone-number'
            sx={styles.phoneNumberInput}
            endAdornment={
              <InputAdornment position='end'>
                <InputClearIcon onClick={() => setPhone('')} />
              </InputAdornment>
            }
          />
        </FormControl>
      </Box>
      <LoadingButton
        loading={isLoading}
        variant='contained'
        color='primary'
        disabled={!validatePhoneNumber(phone)}
        sx={{...styles.sendOTPButton, ...(isVersion2 ? { borderRadius: 100, marginTop: '20%' } : {})}}
        onClick={() => requestOtp({ mobile_number: phone, ...(isNonEmptyObject(source) ? source : {}) })}
      >
        {translate('send_otp')}
      </LoadingButton>
    </Box>
  );

  const renderOTPPage = () => (
    <>
      <div style={styles.loginRegisterText}>{translate('login_register')}</div>
      <div style={styles.title}>{translate('verify_phone_number')}</div>
      <div style={styles.subTitle}>
        {translate('enter_otp').replace('{digit}', OTP_LENGTH.toString()).replace('{phone}', `91 ${phone.toString()}`)}
      </div>
      <Box sx={{ position: 'relative' }}>
        <OtpInput
          value={otp}
          onChange={setOtp}
          numInputs={6}
          inputType='number'
          renderInput={(props, index) => <input {...props} {...(index === 0 && { autoFocus: true })} />}
          containerStyle={styles.otpInput}
          inputStyle='otp-input'
        />
      </Box>
      <div style={styles.resendWrapper}>
        {showResendBtn ? (
          <Button
            variant='text'
            sx={styles.resendButton}
            onClick={() => requestOtp({ mobile_number: phone, ...(isNonEmptyObject(source) ? source : {}) })}
            disableRipple
          >
            {translate('resend_code')}
          </Button>
        ) : (
          <div style={{ marginTop: '32px' }}>
            {translate('resend_code_in')}
            <span style={styles.resendLabel}> {formatSecondsToDisplayTime(resendTime)}</span>
          </div>
        )}
      </div>
    </>
  );

  const resetBottomSheet = () => {
    resetLoginStates();
    setPhone('');
  };

  const renderLoginSheetContent = () =>       <>
  {OTPSession && <LeftArrow style={{...styles.backIcon, ...(isVersion2 ? { marginBottom: 24 } : {})}} onClick={() => resetBottomSheet()} />}
  {(imageUrl && !hideBanner) && (
    <Box sx={styles.loginBannerContainer}>
      <Link href={redirectUrl ?? ''} style={redirectUrl ? styles.linkStyle : styles.disabledLink} target='_blank' prefetch={false}>
        <img style={styles.imageStyle} src={imageUrl} alt='Banner text here' />
      </Link>
    </Box>
  )}
  <Box>{OTPSession ? renderOTPPage() : renderLoginPage()}</Box>
  <Toast message={showError} open={!!showError} type={ToastTypes.ERROR} onClose={() => setShowError('')} />
  {verifyLoading && <Loader isLoading={true} />}
  <SuspiciousLoginModal phone={OTPSession?.mobile_number.toString() || ''} isOpen={isSuspicious} onClose={(isSuccess: boolean, creds:any) => {
    if(isSuccess){
      setIsSuspicious(false);
      setLoginCreds(creds)
    }
    else{
      // router.refresh();
    }
  }}/>
</>;

  return (
    disableModal ? 
    <div className={"py-[24px] px-[16px] bg-nm_white text-left overflow-y-auto hideScrollBar " + (!!OTPSession ? " h-[100vh]" : " rounded-t-[24px]")}>
      {renderLoginSheetContent()}
    </div>
    :<BottomSheet
      open={open}
      handleClose={onClose}
      bottomSheetStyle={{ padding: '32px 32px', position: 'relative' }}
      showCloseButton
      fullScreen={!!OTPSession}
    >
      {renderLoginSheetContent()}
    </BottomSheet>
  );
};

export default LoginBottomSheet;
