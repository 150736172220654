import { colors } from '@Constants/colors';

const styles = {
  buyNowLayout: {
    borderTop: '1px solid #F2F2F2',
    padding: '24px 12px',
    display: 'flex',
    flexDiection: 'row',
    gap: '8px',
    position: 'sticky',
    bottom: '0',
    backgroundColor: 'white'
  },
  bottomSheetBuyNowLayout: {
    borderTop: '1px solid #F2F2F2',
    padding: '24px 12px',
    display: 'flex',
    flexDiection: 'row',
    position: 'fixed',
    width: '100%',
    maxWidth: '450px',
    boxSizing: 'border-box',
    bottom: '0px',
    backgroundColor: 'white',
    justifyContent:'space-between',
    alignItems: 'end',
  },

  buyNowButton: {
    width: '50%',
    height: '48px',
    backgroundColor: colors.PRIMARY,
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    color: 'white',
    cursor: 'pointer',
    textTransform: 'none',
    '&:hover': { backgroundColor: colors.PRIMARY }
  },
  bottomSheetBuyNowButton: {
    width: '90%',
    height: '48px',
    backgroundColor: colors.PRIMARY,
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    color: 'white',
    cursor: 'pointer',
    textTransform: 'none',
    '&:hover': { backgroundColor: colors.PRIMARY }
  },
  addToBagButton: {
    width: '50%',
    height: '48px',
    backgroundColor: colors.WHITE,
    border: '1px solid #1D6B46',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    color: colors.PRIMARY_DARK,
    textTransform: 'none',
    cursor: 'pointer',
    '&:hover': { backgroundColor: colors.WHITE }
  },
  toast: {
    padding: '10px 16px',
    fontWeight: 500,
    fontSize: '12px',
    backgroundColor: 'black',
    color: 'white',
    borderRadius: '100px'
  }
};

export default styles;
