import { colors } from "@Constants/colors";

const styles = {
  modalContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    outline: '0',
    borderWidth: 0
  },
  modal: {
    maxWidth: 400,
    boxSizing: 'border-box',
    bgcolor: 'background.paper',
    borderRadius: '5px',
    boxShadow: 24,
    p: 4,
    zIndex: 1,
    margin: '16px',
    outline: '0'
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '10px',
  },
  title: {
    fontSize: '20px',
    fontWeight: 600,
    margin: 0
  },
  modalButton: {
    display: 'block',
    borderRadius: '2px',
    backgroundColor: colors.PRIMARY,
    color: colors.WHITE,
    padding: '8px 16px',
    fontSize: '16px',
    margin: 'auto',
    marginTop: '32px',
    fontWeight: 600,
    border: 0
  },
  content: {
    paddingTop: '8px',
    fontSize: '14px',
    fontWeight: 400
    // textAlign: 'justify'
  },
  timer: {
    display: 'block',
    fontWeight: 500,
    margin: 'auto',
    marginTop: '12px',
    width: 'fit-content'
  }
};

export default styles;
