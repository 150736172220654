import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface RecentSearchItem {
  id: number;
  type: string;
  title: string;
  thumb_url?: string;
}

export interface Search {
  recentSearches: Array<RecentSearchItem>;
  searchSuggestions: Array<any>;
  visualSearchCurrentImage: any;
  visualSearchSelectedIdx: number;
  visualSearchEnabed: boolean;
}

const initialState: Search = {
  recentSearches: [],
  searchSuggestions: [],
  visualSearchCurrentImage: {},
  visualSearchSelectedIdx: 0,
  visualSearchEnabed: false
};

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setRecentSearches: (state, action: PayloadAction<Array<RecentSearchItem>>) => {
      state.recentSearches = action.payload;
    },
    setSearchSuggestions: (state, action: PayloadAction<Array<any>>) => {
      state.searchSuggestions = action.payload;
    },
    setVisualSearchCurrentImage: (state, action: PayloadAction<any>) => {
      state.visualSearchCurrentImage = action.payload;
    },
    setVisualSearchSelectedIdx: (state, action: PayloadAction<number>) => {
      state.visualSearchSelectedIdx = action.payload;
    },
    resetVisualSearchCurrentImage: (state, action: PayloadAction<any>) => {
      state.visualSearchCurrentImage = {};
      state.visualSearchSelectedIdx = 0;
    },
    setVisualSearchEnabled: (state, action: PayloadAction<boolean>) => {
      state.visualSearchEnabed = action.payload;
    },
    resetSearchSlice: () => initialState
  }
});

export const { setRecentSearches, setSearchSuggestions, setVisualSearchCurrentImage, setVisualSearchSelectedIdx, resetVisualSearchCurrentImage, setVisualSearchEnabled, resetSearchSlice } = searchSlice.actions;
export default searchSlice.reducer;
