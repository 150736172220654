import { useEffect, useRef } from 'react';

export const useCountdownTimer = (callback: () => void, delay: number | null, bogoMsLeft: number, setBogoMsLeft: (value) => void) => {
  const savedCallback = useRef(),
    pageHiddenTimeStamp = useRef<number>(),
    pageHiddenBogoTimeStamp = useRef<number>();

  useEffect(() => {
    savedCallback.current = callback;

    let id;
    const tick = () => savedCallback?.current?.();

    if (delay !== null) id = setInterval(tick, delay);
    else clearInterval(id);

    return () => clearInterval(id);
  }, [delay, callback]);

  const handleVisibilityChange = () => {
    if (document.hidden) {
      pageHiddenTimeStamp.current = Date.now();
      pageHiddenBogoTimeStamp.current = bogoMsLeft;
    } else {
      const timeElapsed = Date.now() - (pageHiddenTimeStamp.current ?? 0),
      remainingTime = (pageHiddenBogoTimeStamp.current ?? 0) - timeElapsed;
      setBogoMsLeft(remainingTime > 0 ? (Math.round(remainingTime / 1000) * 1000) : 0);
    }
  };

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [bogoMsLeft, setBogoMsLeft]);
};
