export enum ROUTES {
  ALL = '*',
  HOME = '/',
  ABOUT_US = '/about-us/',
  ADD_ADDRESS = '/my-account/add-address/',
  ADDRESS = '/my-account/address/',
  CART = '/cart/',
  COLLECTIONS = '/collections/',
  COLLECTION = '/collection/:tag/',
  COLLECTION_PATH = '/collection/',
  CHECKOUT = '/checkout/',
  CHECKOUT_WITHOUT_TRAIL_SLASH = '/checkout',
  CHECKOUT_ADDRESSES = '/address/:isFromCheckout/',
  EDIT_ADDRESS = '/my-account/edit-address/:id/',
  LOGIN = '/login/',
  ORDER_ADDRESSES = '/address/change/:id/',
  ORDER_CANCEL = '/my-account/orders/cancel/:orderId/:productId/',
  ORDER_DETAILS = `/my-account/view-order/:id/`,
  ORDER_DETAILS_PATH = `/order-details`,
  ITEM_DETAILS = `my-account/view-order-item/:id/`,
  ORDER_RETURN = '/my-account/orders/return/:orderId/:productId/',
  ORDERS = '/my-account/orders/',
  ORDERS_PATH = '/orders',
  PAYMENT_FAILED = '/payment-failure/',
  PAYMENT_SUCCESS = `/payment-success/`,
  JUSPAY_PAYMENT_SUCCESS = `/juspay-payment-success/`,
  PRIVACY_POLICY = '/privacy-policy/',
  PRODUCT = `/product/`,
  PRODUCT_DETAILS = `/product/:productName/`,
  PROFILE = '/my-account/',
  PROFILE_EDIT = '/my-account/edit/',
  RATE_THIS_PRODUCT = '/my-account/orders/rate-this-product/:orderId/:itemId/:starRating',
  REELS_PATH = `/reels`,
  REFERANDEARN = '/refer-and-earn/',
  REFERANDEARN_PATH = 'refer-and-earn',
  REFERRALS = '/my-account/wallet/referrals/',
  REFERRALS_PATH = '/referrals',
  RETURN_POLICY = `/return-policy/`,
  SEARCH = '/search/',
  SITEMAP = '/sitemap',
  SHOP = '/shop/',
  SHOP_PATH = '/shop',
  STORES = '/stores/',
  TERMS_AND_CONDITIONS = '/terms-and-conditions/',
  TRACK = '/my-account/orders/track/:id/',
  WALLET = '/my-account/wallet/',
  WALLET_PATH = '/wallet',
  WISHLIST = '/wishlist/',
  JOIN = '/join/',
  BLOCKS = '/blocks/',
  TRACK_ORDER = '/track-order/',
  SIZE_CHART = '/size-chart/',
  COMING_SOON = '/coming-soon/',
  OAUTH_PROXY = '/oauth-proxy/',
  AFFILIATE_DASHBOARD = '/affiliate-dashboard/',
  AFFILIATE = '/affiliate/',
  WISH_LIST = '/wish-list/',
  MAINTENANCE = '/maintenance/',
  RETURNS_AND_EXCHANGE = '/returns-and-exchange/',
  LETS_KEEP_IN_TOUCH = '/lets-keep-in-touch/',
  VIBES = '/vibes',
  NOT_FOUND = '/not-found/',
  IMAGE_VERIFICATION = '/verify-image/',
  SUPPORT = '/my-account/support/',
  FAQS = '/my-account/support/faqs/',
  CATEGORY = '/womens-collection/:categoryName',
  PATTERN_CATEGORY = '/womens-collection/:categoryName/:sub',
  WOMENS_COLLECTION = '/womens-collection',
  WOMENS = '/womens'
}

export const PUBLIC_ROUTES_REDIRECT = [
  {
    path: ROUTES.BLOCKS,
    to: ROUTES.HOME
  },
  {
    path: ROUTES.SIZE_CHART,
    to: ROUTES.HOME
  },
  {
    path: ROUTES.COMING_SOON,
    to: ROUTES.HOME
  },
  {
    path: ROUTES.OAUTH_PROXY,
    to: ROUTES.HOME
  },
  {
    path: ROUTES.AFFILIATE_DASHBOARD,
    to: ROUTES.PROFILE
  },
  {
    path: ROUTES.AFFILIATE,
    to: ROUTES.PROFILE
  },
  {
    path: ROUTES.WISH_LIST,
    to: ROUTES.WISHLIST
  },
  {
    path: ROUTES.MAINTENANCE,
    to: ROUTES.HOME
  },
  {
    path: ROUTES.LETS_KEEP_IN_TOUCH,
    to: ROUTES.HOME
  },
  {
    path: ROUTES.VIBES,
    to: ROUTES.COLLECTIONS
  },
  {
    path: ROUTES.TRACK_ORDER,
    to: ROUTES.ORDERS
  }
];
