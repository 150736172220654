import useTranslation from '@Utils/useTranslation';
import { useEffect, useState } from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import BottomSheet from '@Components/BottomSheet';
import { MeasurementUnits, colors } from '@Constants/common';
import { convertNumberRange } from '@Utils/common';
import SizeChartImage from '@assets/images/Size.svg';
import SizeChartImageLingerie from '@assets/images/sizeChartImageLingerie.svg';
import FilterSheetCloseIcon from '@assets/icons/FilterSheetCloseIcon.svg';

import { SizeChartDataType, SizeChartProps, StepData } from './type';
import { sizeChartData, sizeChartDataLingerie } from './constants';
import styles from './styles';
import { useRouter } from 'next/router';
import CustomDialog from '@Reducers/CustomModal';

const SizeChartBottomSheet = ({ open, setOpen, sizeGuideData, isLingerie = false, backPressed, setBackPressed }: SizeChartProps) => {
  const { t: translate } = useTranslation();
  const router = useRouter();

  const handleClose = () => {
    setOpen(false);
    setBackPressed?.(true);
    router.back();
  };

  const { menu_data } = sizeGuideData;

  const [unit, setUnit] = useState<MeasurementUnits>(MeasurementUnits.INCH);
  const [sizeType, setSizeType] = useState(menu_data[0]?.key);
  const [sizeData, setSizeData] = useState(sizeChartData);
  const [chartData, setChartData] = useState(sizeGuideData[sizeType as keyof SizeChartDataType]);

  useEffect(() => {
    setSizeData(isLingerie ? sizeChartDataLingerie : sizeChartData);
  }, [isLingerie]);

  const onUnitChange = (currentUnit: string) => {
    if (currentUnit === MeasurementUnits.CM) setUnit(MeasurementUnits.INCH);
    else setUnit(MeasurementUnits.CM);
  };

  const onSizeTypeChange = (currentSizeType: string) => {
    if (currentSizeType === menu_data[0].key) {
      setSizeType(menu_data[1].key);
      setChartData(sizeGuideData[menu_data[1].key as keyof SizeChartDataType]);
    } else {
      setSizeType(menu_data[0].key);
      setChartData(sizeGuideData[menu_data[0].key as keyof SizeChartDataType]);
    }
  };

  useEffect(() => {
    if (backPressed) setOpen(false);
  },[backPressed])

  const stepper = (stepData: StepData) => {
    const data = stepData.options;
    const activeData = stepData.data;

    return (
      <div>
        <div style={styles.subTitle}>{stepData.title}</div>
        <div style={{ ...styles.stepContainer, flexDirection: 'row', position: 'relative' }}>
          {data.map((step, index) => {
            let style = styles.stepSelected;

            if (step == activeData)
              if (index == 0) style = { ...styles.step, ...styles.stepSelectedLeft };
              else if (index == data.length - 1) style = { ...styles.step, ...styles.stepSelectedRight };
              else style = { ...styles.step, ...styles.stepSelected };

            return (
              <div key={index} style={style}>
                <div style={step == activeData ? { ...styles.stepActive, position: 'absolute' } : {}}></div>

                <div style={step == activeData ? styles.stepTextActive : styles.stepText}>{step}</div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const objToTableData = (
    obj: Record<string, any>,
    isInches = true
  ): { tableHead: string[]; tableBody: (string | number)[][] } => {
    const sizes = Object.keys(obj);

    const tableHead: string[] = [];
    const tableBody: (string | number)[][] = [];

    if (sizes.length === 0) return { tableHead, tableBody };

    tableHead.push('Sizes');
    const zerothSize = obj[Object.keys(obj)[0]];

    Object.keys(zerothSize).forEach((key) => tableHead.push(key.split('_').join(' ')));

    sizes.map((size) => {
      const row: (string | number)[] = [size];

      Object.keys(obj[size]).map((key) => {
        const modKey = key.split('_').join(' ');

        const isNumberValue = typeof obj[size][key] === 'number' && !isNaN(obj[size][key]);

        if (!isInches && obj[size][key]?.toString()?.includes('-'))
          row[tableHead.indexOf(modKey)] = convertNumberRange(obj[size][key]);
        else if (isInches || !isNumberValue) row[tableHead.indexOf(modKey)] = obj[size][key];
        else row[tableHead.indexOf(modKey)] = Math.round(obj[size][key] * 2.54);
      });
      tableBody.push(row);
    });

    return { tableHead, tableBody };
  };

  const sizeGuideTable = (data: { tableHead: string[]; tableBody: (string | number)[][] }, title = 'Title') => {
    const [windowWidth, setWindowWidth] = useState(0);

    useEffect(() => {
      // Only run this code once after the component has mounted.
      setWindowWidth(window.innerWidth);
  
      // Optionally, you can update the width value whenever the window is resized.
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };
  
      window.addEventListener('resize', handleResize);
  
      // Cleanup function to remove the event listener when the component is unmounted.
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    const widthArr = data.tableHead.map((arr: string) => {
      return Number(arr.length * 8 + 40);
    });
  
    const sum = widthArr.reduce((acc: number, currVal: number) => acc + currVal + 12, 0);
  
    if (sum < windowWidth) {
      const remainingVal = Number(((windowWidth - sum) / widthArr.length).toFixed(2));
  
      widthArr.forEach((val: number, index: number, arr: number[]) => {
        arr[index] = val + remainingVal;
      });
    }

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <h2 style={styles.subTitle}>{title}</h2>
        <TableContainer style={styles.tableContainer}>
          <Table style={styles.borderStyle}>
            <TableHead>
              <TableRow style={styles.tableHead}>
                {data.tableHead.map((head: string, index: number) => (
                  <TableCell
                    key={index}
                    width={widthArr[index]}
                    style={{
                      ...styles.tableHeadText,
                      textTransform: 'capitalize',
                      whiteSpace: 'nowrap',
                      textAlign: 'center'
                    }}
                  >
                    {head}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.tableBody.map((row: (string | number)[], rowIndex: number) => (
                <TableRow key={rowIndex} style={rowIndex % 2 === 0 ? styles.tableDataGrey : styles.tableData}>
                  {row.map((cell: string | number, cellIndex: number) => (
                    <TableCell
                      key={cellIndex}
                      width={widthArr[cellIndex]}
                      style={{
                        ...styles.tableText,
                        textTransform: 'capitalize',
                        whiteSpace: 'nowrap',
                        textAlign: 'center'
                      }}
                    >
                      {cell}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };

  const renderSizeChart = () => (
    <div style={styles.contentWrapper}>
      <div style={styles.headerWrapper}>
        <div style={styles.sizeGuideText}>{translate('size_guide')}</div>
        <div style={styles.switchWrapper}>
          <div style={{ ...styles.switchLabel, ...(unit === MeasurementUnits.CM && { color: colors.BLACK }) }}>
            {translate('cm')}
          </div>
          <div
            style={{
              ...styles.switchOuter,
              position: 'relative',
              ...(unit === MeasurementUnits.INCH
                ? { backgroundColor: colors.PRIMARY }
                : { backgroundColor: colors.NEUTRAL_GRAY })
            }}
            role='presentation'
            onClick={() => onUnitChange(unit)}
          >
            <div
              style={{
                ...styles.slider,
                position: 'absolute',
                ...(unit === MeasurementUnits.INCH ? { right: '2px' } : { left: '2px' })
              }}
            />
          </div>
          <div style={{ ...styles.switchLabel, ...(unit === MeasurementUnits.INCH && { color: colors.BLACK }) }}>
            {translate('inch')}
          </div>
        </div>
      </div>
      <Box sx={styles.divider} />
      {menu_data.length === 2 && (
        <div
          style={{
            ...styles.tabOuter,
            position: 'relative'
          }}
          role='presentation'
          onClick={() => onSizeTypeChange(sizeType)}
        >
          <div
            style={{
              ...styles.tab,
              position: 'absolute',
              ...(sizeType === menu_data[1].key ? { right: '2px' } : { left: '2px' })
            }}
          >
            <div style={{ ...styles.tabLabel, color: colors.WHITE }}>
              {sizeType === menu_data[0].key ? menu_data[0].title : menu_data[1].title}
            </div>
          </div>
          <div
            style={{
              ...styles.inactiveTab,
              position: 'absolute',
              ...(sizeType === menu_data[0].key ? { right: '2px' } : { left: '2px' })
            }}
          >
            <div
              style={{
                ...styles.tabLabel,
                color: colors.DIM_GRAY
              }}
            >
              {sizeType !== menu_data[0].key ? menu_data[0].title : menu_data[1].title}
            </div>
          </div>
        </div>
      )}
      {sizeGuideData?.stretch_fit_data && (
        <>
          <div>{stepper(sizeGuideData.stretch_fit_data.fit_type)}</div>
          <div>{stepper(sizeGuideData.stretch_fit_data.stretch)}</div>
        </>
      )}
      <div>
        {chartData &&
          sizeGuideTable(
            objToTableData(chartData, unit === MeasurementUnits.INCH),
            menu_data.find((data) => data.key === sizeType)?.title
          )}
      </div>
      <div style={styles.subHeaderText}>{sizeData?.size_guide_info && sizeData.size_guide_info.title}</div>
      <div style={styles.sizeChartImageWrapper}>{isLingerie ? <SizeChartImageLingerie /> : <SizeChartImage />}</div>
      {sizeData?.size_guide_info &&
        sizeData?.size_guide_info.data?.map((data, index) => (
          <div key={index}>
            <div style={styles.subHeaderText}>{`${index + 1}.${data.title}`}</div>
            <div style={styles.description}>{data.description}</div>
          </div>
        ))}
    </div>
  );
  return (
    <>
      <CustomDialog
        open={open}
        onClose={handleClose}
        setOpen={handleClose}
        isBottomSheet
        showCloseButton
        customCloseIcon={<FilterSheetCloseIcon />}
      >
        <div className='w-[100vw] max-w-[450px] w-full box-border pb-[34px] pt-2 rounded-t-[16px] bg-nm_white'>
        {renderSizeChart()}
        </div>
      </CustomDialog>
    </>
  );
};

export default SizeChartBottomSheet;
