import useTranslation from '@Utils/useTranslation';

import Amount from '@Components/Amount';
import BogoProductsCarousel from '@Components/BogoProductsCarousel';
import BogoSaleLogo from '@assets/icons/BogoSaleLogo.svg';

import { BogoSaleProps } from './type';
import styles from './styles';
import { useLoggedIn } from '@Utils/common';
import { useAppSelector } from '@Store/store';
import { isNonEmptyObject } from '@Utils/checks';

const BogoSale = ({ totalDiscount, bogoData, cartData, isBuyNow = false }: BogoSaleProps) => {
  const { t: translate } = useTranslation();
  const { isUserLoggedIn } = useLoggedIn();
  const guestCartCount = useAppSelector((state) => state.guestCart.cart_data.cart_item_count);
  const loggedInCartCount = useAppSelector((state) => state.cart.cart_count);

  const cartCount = isBuyNow ? (isNonEmptyObject(cartData) ? Object.keys(cartData).length : 1) : isUserLoggedIn ? loggedInCartCount : guestCartCount;

  return (
    <div className="p-[20px] bg-nm_white">
      <div className="flex flex-col rounded-[8px] bg-nm_peach pb-[12px]" style={cartCount === 1 ? { border: '1px solid #000' } : {}}>
        <div className="flex justify-between pt-[12px] px-[16px] text-nm_black">
          <div className="flex flex-col gap-[4px]">
            {cartCount === 1 ? (
              <>
                <div className="text-[10px] font-[500] leading-[14px]">THIS ITEM IS UNDER</div>
                <div className="text-[16px] font-[700] leading-[24px]">BUY 1 GET 1 Free OFFER</div>
                <div className="text-[12px] font-[500] leading-normal">Add one more item to your cart for free!</div>
              </>
            ) : (
              <>
                <div className="text-[14px] font-[600] leading-[20px]">‘BUY 1 GET 1 FREE’ Applied</div>
                <div className="flex gap-[5px] text-[12px] font-[500] leading-[16px] text-nm_dim_gray">
                  <span className="font-[600] text-nm_primary">
                    <Amount amount={totalDiscount} currencyStyle={{ width: '11px', height: '11px' }} strokeWidth={0.5}></Amount>
                  </span>{' '}
                  Savings
                </div>
              </>
            )}
          </div>
          <BogoSaleLogo className="w-[76px] h-[45px]" />
        </div>
        {cartCount > 1 && (
          <>
            <div style={styles.bogoProducts}>
              <div style={styles.getDiscount}>{translate('OFFER APPLIED ON:')}</div>
              <BogoProductsCarousel bogoData={bogoData} cartData={cartData} />
            </div>
            {cartCount % 2 !== 0 && (
              <div className="mt-[12px] mx-[16px] pt-[12px] text-nm_black" style={{ borderTop: '1px dashed darkgray' }}>
                <div className=" text-[10px] leading-[14px] font-medium">
                  <span className="font-bold">ONE ITEM</span> IS STILL APPLICABLE FOR THE
                </div>
                <div className=" text-[16px] leading-[24px] font-bold">BUY 1 GET 1 Free OFFER</div>
                <div className=" text-[12px] leading-[16px] font-medium">Add one more item to your cart for free!</div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default BogoSale;
