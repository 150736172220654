import data from '../locales/en/translations.json'

export default function useTranslation() {

  const t = (key) => {
    const keysArray = key?.split('.');
    if(keysArray?.length > 1) {
      let value = data
      keysArray.forEach((key) => {
        value = value?.[key]
      })
      return value;
    }
  
    if(data?.[key]) {
      return data[key];
    } else {
      return key
    }
  }

  return {t}; 
}