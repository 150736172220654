import { CouponApplyStatus } from '@Constants/coupon';
import i18n from '../i18n';

import { styleDefault, styleAllCouponsVariant } from '../components/ApplyCoupon/styles';

const translate = i18n.t;

// Util to handle changes in ButtonText, InputStyle, GradientStyle based on action type
const getApplyCouponStyles = (status?: CouponApplyStatus, payload?: string, allCouponsPage?: boolean, isVersion2?: boolean) => {
  const styles = {...styleDefault, ...(allCouponsPage && styleAllCouponsVariant)};

  switch (status) {
    case CouponApplyStatus.SUCCESS:
      return {
        couponAppliedStatus: CouponApplyStatus.SUCCESS,
        buttonText: translate('remove'),
        gradientText: payload,
        gradientStyle: styles.gradientSuccessBackground
      };
    case CouponApplyStatus.FAILURE:
      return {
        couponAppliedStatus: CouponApplyStatus.FAILURE,
        buttonText: isVersion2 ? translate('remove') : translate('try_another'),
        inputTextStyle: styles.inputTextStyleFailure,
        gradientText: translate('invalid_coupon') || 'Invalid Coupon',
        gradientStyle: styles.gradientFailureBackground
      };
    default:
      return {
        couponAppliedStatus: null,
        buttonText: translate('apply')
      };
  }
};

export default getApplyCouponStyles;
