import { colors } from '@Constants/colors';

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: colors.WHITE,
    borderTop: '1px solid rgba(0, 0, 0, 0.13)',
    position: 'fixed',
    bottom: 0,
    maxWidth: '450px',
    width: '100%',
    boxSizing: 'border-box',
    zIndex: 1000
  },
  amountPayable: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 600,
    color: colors.FONT_SECONDARY
  },
  viewDetails: {
    fontSize: '10px',
    lineHeight: '12px',
    color: colors.PRIMARY_DARK,
    cursor: 'pointer'
  },
  proceedToPayment: {
    textTransform: 'none',
    borderRadius: '8px',
    background: colors.PRIMARY,
    color: colors.WHITE,
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    padding: '14px 32px',
    cursor: 'pointer',
    '&:hover': { backgroundColor: colors.PRIMARY }
  }
};

export default styles;
