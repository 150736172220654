import { useEffect, useState, CSSProperties, useContext, } from 'react';
import { useNavigate } from 'react-router-dom';
import useTranslation from '@Utils/useTranslation';
import { addItemToWishlist, removeItemToWishlist, selectWishListState } from '@Containers/Wishlist/wishlistSlice';
import { useAddToWishlistMutation, useRemoveFromWishlistMutation } from '@Containers/Wishlist/apiSlice';
import { useUpdateCartMutation } from '@Containers/Cart/apiSlice';
import  RecommendationSheet  from '@Containers/RecommendationSheet';
import { Positions, ToastTypes } from '@Components/Toast/type';
import { ShowToastType } from '@Components/AddressCard/types';
import { colors } from '@Constants/colors';
import { LINGERIE, ProductCartType, STOCK_STATUS } from '@Constants/common';
import { RootState, useAppDispatch, useAppSelector } from '@Store/store';
import CartIcon from '@assets/icons/Cart.svg';

import FastDeliveryThunder from '@assets/icons/FastDeliveryThunder.svg';

import { isNonEmptyArray, isServer } from '@Utils/checks';

import { addProduct } from '@Containers/GuestCart/guestCartSlice';
import { ANALYTIC_EVENTS, ECOMM_EVENTS, ECOMM_EVENTS_GA4, RECOMMENDED_PRODUCT_EVENTS, GLOBAL_ACTION_TYPES } from '@Constants/events';
import { getPriceRange, getTrackingEventObject, getUniqueSizes } from '@Utils/common';
import GTM, { addToGtmProductList, getProductAttributes } from '@Utils/gtm';
import useCartUtils from '@Utils/useCartUtils';
import Toast from '@Components/Toast';
import Loader from '@Components/Loader';
import ConfirmationPopup from '@Components/ConfirmationPopup';
import SizeSelectorBottomSheet from '@Components/SizeSelectorBottomSheet';

import { ProductCardProps } from './type';
import SizeCard from '../SizeCard';
import styles from './styles';
import style from './styles.module.css';
import Link from 'next/link';
import { BASE64_ENCODED_PRODUCT_LISTING_FALLBACK_IMG } from '@Constants/base64EncodedImages/productListingFallbackImg';
import ProductImgWrapper from './components/ProductImgWrapper';
import ProductInfo from './components/ProductInfo';
import { useRouter } from 'next/router';
import { setBuyNowCart } from '@Components/RecommendedProducts/recommendedProductsSlice';
import { TrackerContext } from '_app';
import { setPdpToast } from '@Containers/Cart/cartSlice';
import { getImageWidth } from '@Components/ProductCard/utils';

export const getTrackingEventMetadata = (type: string, idx?: string| number, router = null, optMetaData = {}) => {
  const pageSource = router ? getPageSource(router) : {};

  switch(type){
    case ProductCartType.HOME:
    case ProductCartType.RECOMMENDED_PRODUCTS:
      return {
        id: idx,
        meta: {
          ...optMetaData,
          ...(pageSource && {source : pageSource})
        }
      };
    default: 
      return {};
  }
}

const defaultToastState = {
  message: '',
  type: undefined,
  show: false
};

const { t: translate } = useTranslation(),
  isVsSearchPage = isServer() ? false : window.location.hash.includes('visual_search');

const addedToCartToastState = {
  message: translate('added_to_bag'),
  type: ToastTypes.CHIP,
  show: true
};

const getPageSource = (router) => {
  switch(router.pathname){
    case '/checkout':
      if(router.asPath.includes('variation-id'))
        return 'isBuyNow';
      else if(router.asPath.includes('pending-order-id'))
        return 'isPendingOrder'
      else
        return 'checkout'
    case '/cart':
        return 'cart';
    default: 
        break;
  }
}

const ProductCard = ({
  product,
  productId,
  parent_id,
  slug,
  productImage,
  discount,
  minSalePrice,
  maxSalePrice,
  shouldShowPriceRange,
  oldPrice,
  sizeCardsData,
  type,
  name,
  productImageStyle,
  showSizeCards = true,
  showWishList = false,
  isVideoAvailable,
  scrolling = false,
  handleListItemClick,
  itemClick,
  showNotifier,
  category,
  styleId,
  parent_style_id,
  attributeMap,
  rating,
  productImagesSet = [],
  isTitleLink = false,
  onDeleteProduct = (productId) => {},
  onAddBagClick, 
  fastDeliveryText,
  isInStock,
  onIntersection = () => {},
  tag,
  encodedFallbackImg = BASE64_ENCODED_PRODUCT_LISTING_FALLBACK_IMG,
  disableDefaultSizeSelect = false,
  feedPosition = 0,
  handleBottomSheetAddToCart,
}: ProductCardProps) => {
  const navigate = useNavigate();
  const { user } = useAppSelector((state: RootState) => state.profile);
  const { productIds } = useAppSelector(selectWishListState);
  const dispatch = useAppDispatch();
  const [addToWishlist, addWishlistData] = useAddToWishlistMutation();
  const [removeFromWishlist, removeWishlistData] = useRemoveFromWishlistMutation();
  const [addCartItems] = useUpdateCartMutation();
  const { checkIfAlreadyInCart, getUpdatedQuantity } = useCartUtils();
  const fastDelivery = sizeCardsData?.some((sizeCard) => sizeCard.fast_delivery === true);
  const [showToast, setShowToast] = useState<ShowToastType>(defaultToastState);
  const [wishList, setWishList] = useState(productIds);
  const [openSizeSelectorSheet, setOpenSizeSelectorSheet] = useState('');
  const [selectedSize, setSelectedSize] = useState('');
  const [showPopUp, setShowPopUp] = useState(false);
  const { app_config_data: appConfigData } = useAppSelector((state: RootState) => state.layout);
  const router = useRouter(),
        searchParams = router.query,
        id = searchParams['id'],
        variationId = searchParams['variation-id'],
        isBuyNow = !!(id && variationId),
        pendingOrderId = searchParams['pending-order-id'],
        isRecommendedProducts = type === ProductCartType.RECOMMENDED_PRODUCTS,
        { buy_now_cart_items } = useAppSelector((state: RootState) => state.recommededProducts),
        track = useContext(TrackerContext),
        pageSource = getPageSource(router, isBuyNow, pendingOrderId);

  // const getVariation = useCallback(() => sizeCardsData?.filter((variation) => variation.size === selectedSize), [sizeCardsData, selectedSize]);
  const getVariation = () => sizeCardsData?.filter((variation) => variation.size === selectedSize);
  useEffect(() => {
    if (addWishlistData?.data)
      setShowToast({
        message: translate('added_to_wishlist'),
        type: ToastTypes.CHIP,
        show: true
      });
  }, [addWishlistData]);

  useEffect(() => {
    if (removeWishlistData?.data)
      setShowToast({
        message: translate('removed_from_wishlist'),
        type: ToastTypes.CHIP,
        show: true
      });
  }, [removeWishlistData]);

  const handleWishListClick = (e) => {
    e?.preventDefault();

    if (user?.id) {
      if (wishList?.includes(productId)) {
        setWishList(wishList.filter((item) => item !== Number(productId)));
        removeFromWishlist([productId]);
        onDeleteProduct(productId);
        dispatch(removeItemToWishlist(productId));
      } else {
        setWishList([...wishList, productId]);
        addToWishlist([productId]);
        dispatch(addItemToWishlist(productId));
      }
    } else if (wishList?.includes(productId)) {
      setWishList(wishList.filter((item) => item !== Number(productId)));
      setShowToast({
        message: translate("removed_from_wishlist"),
        type: ToastTypes.CHIP,
        show: true,
      });
      dispatch(removeItemToWishlist(productId));
    } else {
      setWishList([...wishList, productId]);
      setShowToast({
        message: translate("added_to_wishlist"),
        type: ToastTypes.CHIP,
        show: true,
      });
      dispatch(addItemToWishlist(productId));
    }
  };

  const handleProductCardClick = (e) => {
    if(isRecommendedProducts){
      dispatch(setPdpToast(false));
      handleBottomSheetAddToCart?.(productId)
      track(getTrackingEventObject(feedPosition, RECOMMENDED_PRODUCT_EVENTS.FEED_TYPE, RECOMMENDED_PRODUCT_EVENTS.IMAGE_CLICK, productId, router.asPath));

      // e.preventDefault();
      return;
    }
    // e?.preventDefault();
    // GTM events
    const products = getProductDetails(),
      url = window.location.pathname + window.location.search;

    GTM.sendEcommEvent(ECOMM_EVENTS.PRODUCT_CLICK, {
      click: {
        actionField: {
          list: url
        },
        products: [products]
      }
    });
    GTM.sendEcommEvent(ECOMM_EVENTS_GA4.PRODUCT_CLICK, {
      item_list_id: url,
      items: [products]
    });

    if (handleListItemClick) {
      handleListItemClick();
    } else if (!scrolling) {
      itemClick && itemClick();
      navigate(`/product/${slug || productId}`);
      window.scrollTo(0, 0);
    }
  };

  const handleConfirmDeleteClick = () => {
    setShowPopUp(false);
    setWishList(wishList.filter((item) => item !== productId));
    if (user?.id) {
      removeFromWishlist([productId]);
      dispatch(removeItemToWishlist(productId));
    } else {
      dispatch(removeItemToWishlist(productId));
    }

    onDeleteProduct(productId);
    
    const notifyInfo = {
      message: translate('removed_from_wishlist'),
      type: ToastTypes.CHIP,
      show: true
    };

    if (showNotifier) showNotifier(notifyInfo);
    else setShowToast(notifyInfo);
  };

  const handleDeleteWishlist = (e) => {
    e?.preventDefault();
    setShowPopUp(true);
  };

  const handleAddToCartClick = 
    (e) => {
      e?.preventDefault();
      if (!user?.id) {
        navigate(`/login?callback=${window.location.pathname}`);
        return;
      }
      if(!isRecommendedProducts){
        setOpenSizeSelectorSheet('add_to_bag');
      }
      else{
        dispatch(setPdpToast(false));
        handleBottomSheetAddToCart?.(productId)
        track(getTrackingEventObject(feedPosition, RECOMMENDED_PRODUCT_EVENTS.FEED_TYPE, RECOMMENDED_PRODUCT_EVENTS.ADD_NOW_BTN_CLICK, productId, router.asPath));
      }
    };

  const handlePrimaryButtonClick = async () => {
    if (!selectedSize) {
      setShowToast({
        message: translate('select_size_toast'),
        type: ToastTypes.CHIP,
        show: true
      });
    } else {
      const details = {
        user_id: user?.id || -1,
        amount: getVariation()?.[0]?.sale_price,
        product_name: name,
        product_id: parent_id || productId,
        quantity: 1,
        variation_id: getVariation()?.[0]?.parent_variation_id || getVariation()?.[0]?.id
      };

      // GTM Utils
      if (product) addToGtmProductList(product);

      GTM.sendEvent(ANALYTIC_EVENTS.ADD_TO_CART, {
        ...details
      });

      const products = getProductDetails();

      GTM.sendEcommEvent(ECOMM_EVENTS.ADD_TO_CART, {
        add: {
          products: [products]
        }
      });
      GTM.sendEcommEvent(ECOMM_EVENTS_GA4.ADD_TO_CART, {
        items: [products]
      });

      if (!user?.id) {
        dispatch(
          addProduct({
            data: {
              product_id: productId,
              variation_id: getVariation()?.[0].id,
              variation: { attribute_pa_size: selectedSize },
              quantity: 1
            },
            index: checkIfAlreadyInCart(productId, getVariation()?.[0].id)
          })
        );
        setOpenSizeSelectorSheet('');

        if (showNotifier) showNotifier(addedToCartToastState);
        else setShowToast(addedToCartToastState);

        if(type !== ProductCartType.RECOMMENDED_PRODUCTS){
          setWishList(wishList.filter((item) => item !== productId));
          dispatch(removeItemToWishlist(productId));
        }
      } else {

        if(isRecommendedProducts) 
          track(getTrackingEventObject(feedPosition, RECOMMENDED_PRODUCT_EVENTS.FEED_TYPE, 'add_to_bag', id, router.asPath, getTrackingEventMetadata(type, productId, router, {variation: { attribute_pa_size: selectedSize }})));
        
        const quantity = getUpdatedQuantity(`${productId}${getVariation()?.[0].id}`, 1);

        if(isBuyNow || pendingOrderId){
          const localBuyNowCart =  isNonEmptyArray(buy_now_cart_items) ? buy_now_cart_items : [];

          dispatch(setBuyNowCart([...localBuyNowCart, {
            product_id: productId,
            variation_id: getVariation()?.[0].id,
            variation: { attribute_pa_size: selectedSize },
            quantity: 1,
            thumb_url: product?.image_url,
            title: product?.name
          }]));

          onAddBagClick?.();

          setOpenSizeSelectorSheet('');
          setShowToast(addedToCartToastState);

          return;
        }
        else if (quantity === -1) {
          setShowToast({
            message: translate('max_cart_limit'),
            type: ToastTypes.CHIP,
            show: true
          });

          return;
        }
        const res: any = await addCartItems([
          {
            product_id: productId,
            variation_id: getVariation()?.[0].id,
            variation: { attribute_pa_size: selectedSize },
            quantity: quantity
          }
        ]);

        if (res?.data) {
          if (showNotifier) showNotifier(addedToCartToastState);
          else setShowToast(addedToCartToastState);
          setOpenSizeSelectorSheet('');
        } else {
          setShowToast({
            message: translate('app_error_title'),
            type: ToastTypes.CHIP,
            show: true
          });
        }

        onDeleteProduct(productId);

        if((type !== ProductCartType.RECOMMENDED_PRODUCTS))
        {
          removeFromWishlist([productId]);
          dispatch(removeItemToWishlist(productId));
        }
      }
    }
  };

  const getProductDetails = () => {
    const attributes = getProductAttributes(product, attributeMap, selectedSize);
    const priceDetails = getPriceRange(sizeCardsData ?? []);
    const pr: {
      name?: string;
      id?: string;
      price: string;
      category?: string;
      quantity: number;
      variant?: string;
      item_id?: string;
      item_name?: string;
      item_category?: string;
    } = {
      name: name,
      id: parent_style_id || styleId,
      item_id: parent_style_id || styleId,
      item_name: name,
      price:
        priceDetails.minSalePrice === priceDetails.maxSalePrice
          ? String(priceDetails?.minSalePrice)
          : `${priceDetails.minSalePrice}-${priceDetails.maxSalePrice}`,
      category: category,
      item_category: category,
      quantity: 1
    };

    if (selectedSize !== '') {
      pr.variant = selectedSize;
      pr.price = String(getVariation()?.[0]?.sale_price);
    }

    return { ...pr, ...attributes };
  };

  return (
    <div  style={(type === ProductCartType.SHOP ? { width: '100%' }: {...styles.productCard,...( type === ProductCartType.HOME ? { width: getImageWidth() }: {}), ...(isRecommendedProducts && { maxWidth: 135, cursor: 'default' })}) as CSSProperties}>
      {(addWishlistData?.isLoading || removeWishlistData?.isLoading) && (
        <Loader isLoading={(addWishlistData?.isLoading || removeWishlistData?.isLoading) && !isVsSearchPage} />
      )}
      <div className={style.flashLoadContent} style={isRecommendedProducts ? { display:'flex', flexDirection: 'column', gap:'5px', cursor: 'default', justifyContent:'space-between', height: '100%' } : { width: '100%' }}>
      <ProductImgWrapper
        onIntersection={onIntersection}
        productImage={productImage}
        encodedFallbackImg={encodedFallbackImg}
        productImagesSet={productImagesSet}
        productImageStyle={productImageStyle}
        tag={tag}
        isVideoAvailable={isVideoAvailable}
        rating={rating}
        handleProductCardClick={handleProductCardClick}
        alt={name}
        feedPosition={feedPosition}
        productId={productId}
        type={type}
      />
      <ProductInfo
        productId={productId}
        discount={discount}
        minSalePrice={minSalePrice}
        maxSalePrice={maxSalePrice}
        oldPrice={oldPrice}
        shouldShowPriceRange={shouldShowPriceRange}
        type={type}
        wishList={wishList}
        showWishList={showWishList}
        handleWishListClick={handleWishListClick}
        handleDeleteWishlist={handleDeleteWishlist}
        isRecommendedProducts={isRecommendedProducts}
      />
      {type &&
        Object.values(ProductCartType).includes(type as ProductCartType) &&
        (isTitleLink ? (
          <Link
            href={`/product/${slug}`}
            style={{
              ...styles.productName,
              textTransform: 'none',
              ...(type === ProductCartType.SIMILAR_PRODUCTS && {
                color: colors.FONT_SECONDARY,
              }),
              ...(isRecommendedProducts && styles.recommendedProductsName)
            }}
            onClick={handleProductCardClick}
            prefetch={false}
          >
            {name}
          </Link>
        ) : (
          <div
            style={{
              ...styles.productName,
              ...(type === ProductCartType.SIMILAR_PRODUCTS && {
                color: colors.FONT_SECONDARY,
              }),
              ...(isRecommendedProducts && styles.recommendedProductsName)
            }}
          >
            {name}
          </div>
        ))}
      {showSizeCards && (
        <>
          <div style={styles.sizeCards as CSSProperties}>
            {category !== LINGERIE &&
              sizeCardsData.map((sizeCard) => (
                <SizeCard key={sizeCard.id} size={sizeCard.size} fastDelivery={sizeCard.fast_delivery} stockStatus={sizeCard.stock_status} />
              ))}
            {category === LINGERIE &&
              getUniqueSizes(sizeCardsData)?.map((size) => (
                <SizeCard key={Number(size)} size={size.toString()} fastDelivery={false} stockStatus={STOCK_STATUS.inStock} />
              ))}
          </div>
          {fastDelivery && type !== ProductCartType.WISHLIST && (
            <div style={styles.deliveryDays as CSSProperties}>
              <FastDeliveryThunder />
              <div style={{ fontSize: '8px', color: '#776666' }}>{fastDeliveryText}</div>
            </div>
          )}
        </>
      )}
      {type && (type === ProductCartType.WISHLIST || isRecommendedProducts) && (
        <>
          {type === ProductCartType.WISHLIST && <div style={styles.dummyBagButton}></div> }
          <button
            disabled={!isInStock}
            className={"customButton customButton--noScale" + (isRecommendedProducts && ' mt-[6px] py-[4px] px-[12px] bg-nm_white text-[12px] text-nm_black font-medium rounded-[8px] w-full')}
            style={{ ...(type === ProductCartType.WISHLIST && styles.addToBagButton), ...(isRecommendedProducts && {border: '1px solid #000'}), ...(!isInStock && styles.disabledButton) } as CSSProperties}
            onClick={handleAddToCartClick}
          >
            {type === ProductCartType.WISHLIST && <CartIcon color={colors.WHITE} style={{ paddingRight: '8px' }} />}
            {isRecommendedProducts ? translate('add_now') : translate('add_to_bag')}
          </button>
        </>
      )}
      </div>
      {openSizeSelectorSheet && (
        <SizeSelectorBottomSheet
          open={openSizeSelectorSheet}
          setOpen={setOpenSizeSelectorSheet}
          variations={sizeCardsData ?? []}
          selectedSize={selectedSize}
          setSelectedSize={setSelectedSize}
          id={String(productId) ?? ''}
          handlePrimaryButtonClick={handlePrimaryButtonClick}
          disableDefaultSizeSelect={disableDefaultSizeSelect}
        />
      )}
      {showPopUp && (
        <ConfirmationPopup
          open={showPopUp}
          handleClose={() => setShowPopUp(false)}
          title={translate('do_you_want')}
          yesBtnLabel={translate('yes')}
          noBtnLabel={translate('no') || ''}
          yesBtnHandle={handleConfirmDeleteClick}
        />
      )}
      {showToast.show && (
        <Toast
          message={showToast.message}
          open={showToast.show}
          type={showToast.type}
          position={Positions.BOTTOM}
          onClose={() => setShowToast(defaultToastState)}
        />
      )}
    </div>
  );
};

export default ProductCard;