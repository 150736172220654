import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Map } from '@Constants/common';

export interface Order {
  order: Map | null;
  checkout: Map | null;
  showPaymentSuccess: boolean;
  coupon_applied_modal_shown: boolean;
  checkout_bottom_sheet_open: Object;
  checkout_bottom_sheet_cod_open: string;
  couponAppliedManually: boolean;
}

const initialState: Order = {
  order: null,
  checkout: null,
  showPaymentSuccess: false,
  coupon_applied_modal_shown: false,
  checkout_bottom_sheet_open: { key: ''},
  checkout_bottom_sheet_cod_open: '',
  couponAppliedManually: false
};

export const checkoutSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setCheckoutDetails: (state, action: PayloadAction<Map>) => {
      state.checkout = action.payload;
    },
    setOrderDetails: (state, action: PayloadAction<Map>) => {
      state.order = action.payload;
    },
    setShowPaymentSuccess: (state, action: PayloadAction<boolean>) => {
      state.showPaymentSuccess = action.payload;
    },
    setCouponAppliedModalShown: (state, action: PayloadAction<boolean>) => {
      state.coupon_applied_modal_shown = action.payload;
    },
    setCheckoutBottomSheetOpen: (state, action: PayloadAction<Object>) => {
      state.checkout_bottom_sheet_open = action.payload;
    },
    setCheckoutBottomSheetCodOpen: (state, action: PayloadAction<string>) => {
      console.log('hi2:', action.payload);
      state.checkout_bottom_sheet_cod_open = action.payload;
    },
    setCouponAppliedManually: (state) => {
      state.couponAppliedManually = true;
    },
    resetCouponAppliedManually: (state) => {
      state.couponAppliedManually = false;
    },
    clearOrderDetails: () => initialState
  }
});

export const { setOrderDetails, clearOrderDetails, setCheckoutDetails, setShowPaymentSuccess, setCouponAppliedModalShown, setCheckoutBottomSheetOpen, setCheckoutBottomSheetCodOpen, setCouponAppliedManually, resetCouponAppliedManually } = checkoutSlice.actions;
export default checkoutSlice.reducer;
