import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ProductDetails } from '@Containers/Product-Details/types';

import { CartProduct, GuestCart, UpdateCart } from './types';
import LocalStorage from '@Utils/storage';

const findCartCount = (data: any) => {
  let count = 0;

  data.forEach((item: any) => {
    count += item.quantity;
  });

  return count;
};

const initialState = (): GuestCart => {
  const guestCartProducts = JSON.parse(LocalStorage.getItem('guestCartProducts') || '[]');

  return {
    products: guestCartProducts, // stores ids of all the products in guest cart
    cart_data: {
      cart: [], // cart items generated from fetching product details for product ids in products[]
      cart_totals: {}, // cart totals calculated from cartItems[]
      cart_item_count: findCartCount(guestCartProducts) // total number of items in cart
    }
  };
};

/**
 *  The products[] stores ids of all the products in guest cart. The productDetails are fetched on
 *  guest cart page visit using products[]. cartItem[] is generated from the API response.
 */
export const guestCartSlice = createSlice({
  name: 'guestCart',
  initialState: initialState(),
  reducers: {
    addItemToCart: (state, action: PayloadAction<Array<ProductDetails>>) => {
      console.log('guest add items to cart', action.payload);
      state.cart_data.cart = [];
      state.cart_data.cart_totals = {};
      let bag_total = 0;
      let total = 0;

      const productDetailsArray = action.payload;

      if (!productDetailsArray.length) return;

      state.products.forEach((item) => {
        const productDetails = productDetailsArray.find((product) => product.product_id === item.product_id);

        if (productDetails === undefined) return;
        const { product_id, slug, name, gallery, variations, image_url, is_returnable } = productDetails;
        const { variation_id, quantity } = item;
        const variationDetails = variations.find((val) => val.id === variation_id);
        const { regular_price, sale_price, size } = variationDetails || {};

        const productData = {
          product_id: product_id,
          slug: slug,
          variation: {
            attribute_pa_size: size
          },
          line_discount: (Number(regular_price) - Number(sale_price)) * quantity,
          line_total: Number(regular_price) * quantity,
          line_subtotal: Number(sale_price) * quantity,
          thumb_url: (gallery && gallery[0]) || image_url,
          title: name,
          variation_id: variation_id,
          variations,
          quantity,
          is_returnable: is_returnable ?? true,
        };

        bag_total += Number(regular_price) * quantity;
        total += Number(sale_price) * quantity;

        state.cart_data.cart.push(productData);
      });

      const cartTotal = {
        subtotal: total,
        bag_discount: bag_total - total,
        total: total.toString()
      };

      state.cart_data.cart_totals = cartTotal;
    },
    addProduct: (state, action: PayloadAction<{ data: CartProduct; index: number }>) => {
      const { data, index } = action.payload;
      console.log('guest add product', action.payload);

      if (index >= 0) state.products[index].quantity += 1;
      else state.products.push(data);

      state.cart_data.cart_item_count = findCartCount(state.products);
      LocalStorage.setItem('guestCartProducts', JSON.stringify(state.products));
    },
    removeProduct: (state, action: PayloadAction<Record<string, number>>) => {
      console.log('guest remove product', action.payload);
      const { index } = action.payload;

      if (state.products.length === 1) {
        state.products = [];
        state.cart_data.cart = initialState().cart_data.cart;
      } else {
        state.products.splice(index, 1);
      }
      state.cart_data.cart_item_count = findCartCount(state.products);
      LocalStorage.setItem('guestCartProducts', JSON.stringify(state.products));
    },
    updateCartItem: (state, action: PayloadAction<UpdateCart>) => {
      console.log('guest update cart', action.payload);
      const { quantity, newId, newSize, index, itemFoundAt } = action.payload;

      if (itemFoundAt >= 0 && itemFoundAt !== index) {
        state.products[itemFoundAt].quantity += quantity;
        state.products.splice(index, 1);
      } else {
        state.cart_data.cart[index].variation_id = newId;
        state.cart_data.cart[index].variation.attribute_pa_size = newSize;
        state.cart_data.cart[index].quantity = quantity;
        state.products[index].variation_id = newId;
        state.products[index].quantity = quantity;
        state.products[index].variation.attribute_pa_size = newSize;
      }
      state.cart_data.cart_item_count = findCartCount(state.products);
      LocalStorage.setItem('guestCartProducts', JSON.stringify(state.products));
    },
    resetGuestCartSlice: () => initialState()
  }
});

export const { addItemToCart, addProduct, removeProduct, updateCartItem, resetGuestCartSlice } = guestCartSlice.actions;
