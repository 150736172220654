import { useState } from "react";
import { Box, TextField, Button } from "@mui/material";
import useTranslation from '@Utils/useTranslation';
import Image from "next/image";
import AngryEmoji from "@assets/icons/AngryEmoji.png";
import styles from "./styles";

export interface ReportIssueProps {
  onSubmitPress: (comment: string) => void;
  disabled: boolean;
}

const ReportBug = ({onSubmitPress, disabled}: ReportIssueProps) => {
  const { t: translate } = useTranslation();

  const [issue, setIssue] = useState("");

  const handleChange = (e) => {
    setIssue(e.target.value);
  };

  const handleSubmitPress = () => {
    onSubmitPress(issue);
  };

  return (
    <Box>
      <Box sx={styles.gradient}>
        <Box>
          <Image src={AngryEmoji.src} width={213} height={128} alt='angry-emoji' style={styles.headerIcon}/>
        </Box>
      </Box>
      <Box sx={styles.title}>{translate("hard_time")}</Box>
        <Box sx={styles.description}>
          {translate("report_issue_description")}
        </Box>
      <Box sx={styles.container}>
        <Box sx={styles.inputContainer}>
          <TextField
            variant="filled"
            placeholder={translate("write_your_issue") || ""}
            multiline
            rows={4}
            InputProps={{
              style: styles.input,
            }}
            value={issue}
            onChange={handleChange}
          />
        </Box>
        <Button
          onClick={handleSubmitPress}
          disabled={issue?.trim()?.length < 40 || disabled}
          variant="contained"
          color="primary"
          sx={styles.saveBtn}
        >
          {translate("submit")}
        </Button>
      </Box>
    </Box>
  );
};

export default ReportBug;
